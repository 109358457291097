import React, { useEffect, useState } from "react";
import FanfluencerDealViewModel from "../../../viewmodels/product/fanfluencerdeal.viewmodel";
import { FanfluencerDeal, FanfluencerSpecialDeal } from "../../../models/product.model";
import { Box, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Tab, Tabs, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProductFanfluencerType from "./product-fanfluencertype";

interface ContainerProps {
  id: string;
  price: number;
}

const ProductFanfluencerDeal: React.FC<ContainerProps> = ({ id, price }) => {
  const { t } = useTranslation();

  const { fandeals, specialdeals, fanfluencerdealmaxdisc, originalPrice, briefing, setPrice, update, updateMax, addSpecial, removeSpecial, change, submit, submitted } =
    FanfluencerDealViewModel(id);

  const [tabValue, setTabValue] = useState(0);

  const maxOption = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    setPrice(price);
  }, [price]);

  const renderTab = () => {
    let type = "Story";
    if (tabValue === 1) {
      type = "Feed";
    } else if (tabValue === 2) {
      type = "Reel";
    }
    let typedeals: FanfluencerDeal[] = fandeals.filter((x) => x.type === type);
    let typespecialdeals: FanfluencerSpecialDeal[] = specialdeals.filter((x) => x.type === type);
    return (
      <React.Fragment>
        <Grid container spacing={1} paddingTop={1}>
          {typedeals.map((deal, idx) => (
            <ProductFanfluencerType
              key={idx}
              id={deal.id}
              type={type}
              deal={deal}
              specialdeals={typespecialdeals.filter((x) => x.fanId === deal.id)}
              originalPrice={originalPrice}
              update={update}
              addSpecial={addSpecial}
              removeSpecial={removeSpecial}
            ></ProductFanfluencerType>
          ))}
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={1} marginTop={1}>
        <Grid xs={10} item>
          <h5 className="subtext">INFLUENCER DEALS</h5>
        </Grid>
        <Grid xs={2} item>
          <TextField
            label={t("field.postmaxcount")}
            type="number"
            variant="outlined"
            size="small"
            value={fanfluencerdealmaxdisc}
            onChange={(e) => updateMax(Number(e.target.value as string))}
            fullWidth
          />
          {/* 
          <FormControl fullWidth>
            <InputLabel id="postmaxcount" size={"small"}>
              {t("field.postmaxcount")}
            </InputLabel>
            <Select
              labelId="postmaxcount"
              label={t("field.postmaxcount")}
              value={fanfluencerdealmaxdisc}
              onChange={(e) => updateMax(Number(e.target.value as string))}
              size={"small"}
            >
              {maxOption.map((opt, idx) => (
                <MenuItem key={idx} value={opt}>{opt}</MenuItem>
              ))}
            </Select>
          </FormControl>
          */}
        </Grid>
      </Grid>

      <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
        <Tab key="0" label="STORY-POST" />
        <Tab key="1" label="FEED-POST" />
        <Tab key="2" label="REEL-POST" />
      </Tabs>
      {renderTab()}


      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit()
        }}
      >
        <Grid container spacing={1} marginTop={1}>
          <Grid xs={9} item>
            <h5 className="subtext">INFLUENCER DEALS POST BRIEFING</h5>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputLabel>
              {t("text.photodetails")}
              <span style={{ marginLeft: "10px", fontStyle: "italic" }}>{t("text.photosubdetails")}</span>
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              placeholder="ENGLISH"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
              value={briefing.dealphotodetails_en}
              onChange={(e) => change("dealphotodetails_en", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              placeholder="GERMAN"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
              value={briefing.dealphotodetails_de}
              onChange={(e) => change("dealphotodetails_de", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} >
            <InputLabel>
              {t("text.textdetails")}
              <span style={{ marginLeft: "10px", fontStyle: "italic" }}>{t("text.textsubdetails")}</span>
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              placeholder="ENGLISH"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
              value={briefing.dealtextdetails_en}
              onChange={(e) => change("dealtextdetails_en", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              placeholder="GERMAN"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
              value={briefing.dealtextdetails_de}
              onChange={(e) => change("dealtextdetails_de", e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel>
              {t("text.hashtagdetails")}
              <span style={{ marginLeft: "10px", fontStyle: "italic" }}>{t("text.hashtagsubdetails")}</span>
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <TextField
              placeholder="ENGLISH"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
              value={briefing.dealhashtagdetails_en}
              onChange={(e) => change("dealhashtagdetails_en", e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              placeholder="GERMAN"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={4}
              value={briefing.dealhashtagdetails_de}
              onChange={(e) => change("dealhashtagdetails_de", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} marginTop={2} container direction="row" justifyContent="center" alignItems="center">
            <Button type="submit" variant="contained" size="large" sx={{ mx: 1 }} disabled={submitted}>
              {t("button.save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default ProductFanfluencerDeal;
