import React from "react";
import { Link } from "react-router-dom";
import "./breadcrumbs.css";

function Breadcrumbs({ datas }) {
  function link(data, idx) {
    if (data.url === undefined) {
      return (
        <span key={idx} className="me-2">
          {data.title}
        </span>
      );
    } else {
      return (
        <Link key={idx} to={data.url} className="me-2">
          {data.title} <span className="ms-1">{">"}</span>
        </Link>
      );
    }
  }

  return (
    <React.Fragment>
      <div id="breadcrumbsdiv">
        {datas.map((x, idx) => {
          return link(x, idx);
        })}
      </div>
    </React.Fragment>
  );
}

export default Breadcrumbs;
