import React from "react";
import ProductListViewModel from "../../viewmodels/product/list.viewmodel";
import TableLayout from "../../components/table/table";
import { useTranslation } from "react-i18next";

const DashboardProduct: React.FC = () => {
  const { t } = useTranslation();
  const { fields, productLists, searches } = ProductListViewModel("moderate");

  return (
    <React.Fragment>
      <h5 style={{ marginTop: "2rem", color: "#64748B" }}>{t('title.productstomoderate')}</h5>
      <TableLayout fields={fields} data={searches !== undefined ? searches : productLists}></TableLayout>
    </React.Fragment>
  );
};

export default DashboardProduct;
