import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import AdminRegisterViewModel from "../../viewmodels/admin/register.viewmodel";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../components/confirm/confirm";

const AdminCreate: React.FC = () => {
  const { t } = useTranslation();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  const { model, change, getError, getErrorMessage, onSubmit, onReset, useAsAdmin, onUseAsAdmin } = AdminRegisterViewModel();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    onReset();
    setOpen(false);
  };

  const onHandleSubmit = async (e: any) => {
    e.preventDefault();
    let isSuccessful = await onSubmit();
    if (isSuccessful) {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" size="medium" onClick={handleOpen}>
        {t("button.create").toUpperCase()}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <form onSubmit={onHandleSubmit}>
          <Box sx={style}>
            <Box sx={headerStyle}>{t("text.createadministratoraccount")}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t("field.email")}
                    type="email"
                    variant="outlined"
                    size="small"
                    value={model.email}
                    error={getError("email") !== undefined}
                    helperText={getErrorMessage("email")}
                    onChange={(e) => change("email", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t("field.firstname")}
                    type="text"
                    variant="outlined"
                    size="small"
                    value={model.firstname}
                    error={getError("firstname") !== undefined}
                    helperText={getErrorMessage("firstname")}
                    onChange={(e) => change("firstname", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t("field.lastname")}
                    type="text"
                    variant="outlined"
                    size="small"
                    value={model.lastname}
                    error={getError("lastname") !== undefined}
                    helperText={getErrorMessage("lastname")}
                    onChange={(e) => change("lastname", e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t("field.defaultpassword")}
                    type="password"
                    variant="outlined"
                    size="small"
                    value={model.password}
                    error={getError("password") !== undefined}
                    helperText={getErrorMessage("password")}
                    onChange={(e) => change("password", e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} type="submit">
                {t("button.create").toUpperCase()}
              </Button>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={handleClose}>
                {t("button.cancel").toUpperCase()}
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>

      <ConfirmDialog title="Confirm Deletion" open={useAsAdmin} action={(value: boolean) => onUseAsAdmin(value)}>
        Email already used as either Customer or Supplier, Do you wish to use it as well as an administrator? Take note that the they will share the same password to login.
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default AdminCreate;
