import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Box, Grid, TextField } from "@mui/material";
import "./login.css";
import { AccountService } from "../../../services/account.service";
import { useNavigate } from "react-router-dom";
import { SupplierUserService } from "../../../services/supplieruser.service";
import { AdminService } from "../../../services/admin.service";
import { SupplierService } from "../../../services/supplier.service";
import validator from "validator";
import { useTranslation } from "react-i18next";
import { StorageService } from "../../../services/storage.service";
import { Brand, BrandUser } from "../../../models/brand.model";
import { Admin } from "../../../models/admin.model";
import bg from "../../../assets/img/wallpaper/bg.png";
import logo from "../../../assets/img/logo.png";
import DoneIcon from "@mui/icons-material/Done";

function Login() {
  const { t, i18n } = useTranslation();

  const [login, setLogin] = useState(true);

  const storageService = new StorageService();

  const navigate = useNavigate();

  const [loginData, setloginData] = useState({
    email: "",
    password: "",
  });

  const [validation, setValidation] = useState({
    email: "",
    password: "",
  });

  const [resetData, setResetData] = useState("");
  const [resetValidation, setResetValidation] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (storageService.isUserLogin()) {
      navigate("/");
    }

    i18n.changeLanguage(storageService.getLanguage());
  }, []);

  const validateInput = (data: any) => {
    let pass = true;

    if (validator.isEmpty(data.email)) {
      setValidation((validationData) => ({
        ...validationData,
        email: "error.emailrequire",
      }));
      pass = false;
    } else if (!validator.isEmail(data.email)) {
      setValidation((validationData) => ({
        ...validationData,
        email: "error.notavalidemail",
      }));
      pass = false;
    } else {
      setValidation((validationData) => ({
        ...validationData,
        email: "",
      }));
    }

    if (validator.isEmpty(data.password)) {
      setValidation((validationData) => ({
        ...validationData,
        password: "error.passwordrequire",
      }));
      pass = false;
    } else {
      setValidation((validationData) => ({
        ...validationData,
        password: "",
      }));
    }

    return pass;
  };

  const onChange = (e: any) => {
    const [key, value] = [e.target.name, e.target.value];
    let data = loginData;
    data[key as keyof typeof loginData] = value;
    setloginData(data);

    if (submitted) {
      validateInput(data);
    }
  };

  const OnLogin = () => {
    setSubmitted(true);
    setLoading(true);

    if (!validateInput(loginData)) {
      setLoading(false);
      return;
    }

    let service = new AccountService();
    service
      .authenticate(loginData.email, loginData.password)
      .then((userdata) => {
        verifySupplier(userdata.user.uid)
          .then((valid) => {
            setLoading(false);
            if (valid) navigate("/");
          })
          .catch((err) => {
            verifyAdministrator(userdata.user.uid)
              .then((valid) => {
                setLoading(false);
                if (valid) navigate("/");
              })
              .catch((err) => {
                let val = validation;
                val.email = "error.accountnotfound";
                setValidation(val);
                setLoading(false);
              });
          });
      })
      .catch((error) => {
        setLoading(false);
        setValidation((validationData) => ({
          email: "error.loginfailed",
          password: "error.loginfailed",
        }));
      });
  };

  const verifySupplier = (id: string) =>
    new Promise((resolve, rejects) => {
      let supplierUserService = new SupplierUserService();
      supplierUserService
        .getById(id)
        .then((user) => {
          let brandUser: BrandUser = user as BrandUser;
          if (brandUser.active) {
            let supplierService = new SupplierService();
            supplierService
              .getById(brandUser.brandid)
              .then((supplier) => {
                let brand: Brand = supplier as Brand;
                if (brand.active) {
                  storageService.saveLogin(
                    JSON.stringify({
                      type: "brand",
                      data: user,
                      supplier: supplier,
                    })
                  );
                  resolve(true);
                } else {
                  let val = validation;
                  val.email = "error.brandinactive";
                  setValidation(val);
                  resolve(false);
                }
              })
              .catch((err) => {
                let val = validation;
                val.email = "error.accountnotbrand";
                setValidation(val);
                resolve(false);
              });
          } else {
            let val = validation;
            val.email = "error.accountnotactive";
            setValidation(val);
            resolve(false);
          }
        })
        .catch((err) => {
          rejects("Not Supplier");
        });
    });

  const verifyAdministrator = (id: string) =>
    new Promise((resolve, rejects) => {
      let adminService = new AdminService();
      adminService
        .getById(id)
        .then((data) => {
          let admin: Admin = data as Admin;
          if (admin.active) {
            storageService.saveLogin(
              JSON.stringify({
                type: "admin",
                data: admin,
              })
            );
            resolve(true);
          } else {
            setValidation((validationData) => ({
              ...validationData,
              email: "error.accountnotactive",
            }));
            resolve(false);
          }
        })
        .catch((err) => {
          rejects("Not Administrator");
        });
    });

  const OnReset = async () => {
    setSubmitted(true);
    setLoading(true);

    let pass = true;

    if (validator.isEmpty(resetData)) {
      setResetValidation("error.emailrequire");
      pass = false;
    } else if (!validator.isEmail(resetData)) {
      setResetValidation("error.notavalidemail");
      pass = false;
    } else {
      setResetValidation("");
    }

    if (pass) {
      try {
        const accountService: AccountService = new AccountService();
        let account = await accountService.searchEmail(resetData);
        if (account.length > 0) {
          await accountService.sendChangePassword(resetData);
          setSuccess(true);
          setLoading(false);
          setSubmitted(false);
        } else {
          setLoading(false);
          setResetValidation("error.emailnotfound");
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const resetFields = () => {
    setSubmitted(false);
    setLoading(false);
    setSuccess(false);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight="100vh">
      <Box className="content">
        <img id="bg-img" src={bg} alt="" />
        <Stack className="form" spacing={2} direction="column" sx={{ display: login ? "block" : "none" }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              OnLogin();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
            
                <img id="logo" src={logo} alt="" />
                <h2 className="my-title">Admin Portal</h2>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label={t("field.email")}
                  variant="outlined"
                  name="email"
                  error={validation.email !== ""}
                  helperText={validation.email !== "" ? t(validation.email) : ""}
                  type="email"
                  fullWidth
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="password"
                  label={t("field.password")}
                  variant="outlined"
                  name="password"
                  type="password"
                  error={validation.password !== ""}
                  helperText={validation.password !== "" ? t(validation.password) : ""}
                  fullWidth
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} textAlign="center" marginTop={1}>
                <Button type="submit" className="button button-login" variant="contained" size="large" disabled={loading} disableElevation>
                  {loading ? t("button.loading") + "..." : t("button.login")}
                </Button>
              </Grid>
              <Grid item xs={12} textAlign="center" marginTop={1}>
                <span
                  className="forgot"
                  onClick={(e) => {
                    setLogin(false);
                    resetFields();
                  }}
                >
                  {t("text.forgotpassword")}?
                </span>
              </Grid>
            </Grid>
          </form>
        </Stack>
        <Stack className="form" spacing={2} direction="column" sx={{ display: login === false ? "block" : "none" }}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              OnReset();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                
                <img id="logo" src={logo} alt="" />
                <h2 className="my-title">Admin Portal</h2>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label={t("field.email")}
                  variant="outlined"
                  name="email"
                  error={resetValidation !== ""}
                  helperText={resetValidation !== "" ? t(resetValidation) : ""}
                  type="email"
                  fullWidth
                  onChange={(e) => setResetData(e.target.value)}
                />
                <span style={{ color: "green" }} hidden={!success}>
                  <DoneIcon></DoneIcon>Check your Email for Password Reset.
                </span>
              </Grid>
              <Grid item xs={12} textAlign="center" marginTop={1}>
                <Button type="submit" className="button" variant="contained" size="large" disabled={loading} disableElevation>
                  {loading ? t("button.loading") + "..." : t("button.confirm")}
                </Button>
              </Grid>
              <Grid item xs={12} textAlign="center" marginTop={1}>
                <span
                  className="forgot"
                  onClick={(e) => {
                    setLogin(true);
                    resetFields();
                  }}
                >
                  Back to login
                </span>
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Box>
    </Box>
  );
}

export default Login;
