import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductDetailViewModel from "../../viewmodels/product/detail.viewmodel";
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import FormLayout from "../../components/form/FormLayout";
import Chips from "../../components/chips/chips";
import Toggle from "../../components/toggle/toggle";
import ProductImages from "./product-images";
import ProductSpecication from "./specification/product-specification";
import ProductBuyOnlyDeal from "./buyonly/product-buyonlydeal";
import ProductFanfluencerDeal from "./fanfluencer/product-fanfluencerdeal";
import ProductLimitedOffer from "./limited-offer/product-limited-offer";
import { useTranslation } from "react-i18next";
import { previewLink, reviewstatus, stockstatus } from "../../config";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './product-detail.css';
import { blue } from "@mui/material/colors";
import CurrencyInput from "react-currency-input-field";

const ProductDetail: React.FC = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { initialize, supplier, suppliers, categories, tags, product, originalPrice, getError, getErrorMessage, change, submit, savingProcess } = ProductDetailViewModel(id!);

  const [tabSelected, setTabSelected] = React.useState("details");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [descriptionDE, setDescriptionDE] = useState("");

  useEffect(() => {
    setDescriptionEN(product.description_en);
  }, [product.description_en])

  useEffect(() => {
    setDescriptionDE(product.description_de);
  }, [product.description_de])

  const renderBrand = () => {
    if (!supplier) {
      return (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="size" size={"small"}>
                  {t("field.brand")}
                </InputLabel>
                <Select
                  labelId="size"
                  label={t("field.brand")}
                  value={product.brandid ? product.brandid.toString() : ""}
                  onChange={(e) => change("brandid", e.target.value as string)}
                  size={"small"}
                  error={getError("brandid") !== undefined}
                >
                  {suppliers.map((supplier, idx) => (
                    <MenuItem key={idx} value={supplier.id}>
                      {supplier.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{getErrorMessage("brandid")}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Chips
                tags={tags}
                id="code"
                value="code"
                start="#"
                selected={product.tags}
                update={(selected: string[]) => change("tags", selected)}
              ></Chips>
            </Grid>
          </Grid>
          <hr />
        </Grid>
      );
    } else {
      return;
    }
  };

  const getPreviewLink = (): string => {
    const frontend: string | undefined = process.env.REACT_APP_FRONTEND_SITE;
    const link: string | undefined = previewLink;
    if (frontend && link) return frontend + link.replace("[ID]", product.id);
    else return "";
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabSelected(newValue);
  };

  const getReview = () => {
    if (product && product.review) {
      let status = reviewstatus.find((x) => x.id === product.review);
      return (
        <React.Fragment>
          <Chip
            label={status!.value.toUpperCase()}
            color={status!.color as "primary" | "error" | "success" | "default" | "secondary" | "info" | "warning" | undefined}
            variant="outlined"
          />
          <span style={{ marginLeft: "20px" }}>{product.reason ? "Reason/Note: " + product.reason : ""}</span>
        </React.Fragment>
      );
    } else return;
  };

  const renderStockStatus = (stat: 'in-stock' | 'out-of-stock' | 'limited-time') => {
    let status = stockstatus[0];
    if (stat) status = stockstatus.find((x) => x.id === stat)!;
    return (
      <Chip
        label={status.value.toUpperCase()}
        color={status.color as "primary" | "error" | "success" | "default" | "secondary" | "info" | "warning" | undefined}
        variant="outlined"
        size="small"
      />
    );
  }

  return (
    <React.Fragment>
      <FormLayout
        PageTitle={t("title.products")}
        Breadcrumbs={[
          { title: t("title.dashboard"), url: "/" },
          { title: t("title.products"), url: "/products" },
          { title: t("title.productdetails") },
        ]}
      ></FormLayout>
      {
        initialize.current === false &&
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
          <CircularProgress size={20} /><label>Loading...</label>
        </div>
      }

      {
        initialize.current === true &&
        <React.Fragment>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <span>ID: {product ? product.id : ""}</span>
            <Tabs value={tabSelected} onChange={handleChange}>
              <Tab value="details" label={t("title.productdetails")} />
              <Tab value="images" label={t("title.images")} />
              <Tab value="specs" label={t("title.specification")} />
              <Tab value="buyonly" label="RaceClub Deals" disabled={!product.buyonly} />
              <Tab value="influencer" label="Influencer Deals" disabled={!product.fanfluencerdeal} />
              <Tab value="limited" label={t("title.limitedoffer")} />
            </Tabs>
          </div>

          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <div hidden={tabSelected !== "details"}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    submit(descriptionEN, descriptionDE);
                  }}
                >
                  <Grid container spacing={1} rowGap={1} marginTop={1}>
                    <Grid xs={6} item>
                      <h5 className="subtext">{t("title.productdetails").toUpperCase()}</h5>
                    </Grid>
                    <Grid xs={6} item container direction="row" justifyContent="right" alignItems="center">
                      {renderStockStatus(product.stockstatus)}
                    </Grid>
                    {renderBrand()}
                    <Grid container item xs={12} spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          label={t("field.article")}
                          variant="outlined"
                          size="small"
                          value={product.article ? product.article : ""}
                          error={getError("article") !== undefined}
                          helperText={getErrorMessage("article")}
                          onChange={(e) => change("article", e.target.value)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Toggle value={product.active} label="Active" onChange={(value: boolean) => change("active", value)}></Toggle>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={t("field.name") + "( EN)"}
                        variant="outlined"
                        size="small"
                        placeholder="Enter Name in English"
                        value={product.name_en ? product.name_en : ""}
                        error={getError("name_en") !== undefined}
                        helperText={getErrorMessage("name_en")}
                        onChange={(e) => change("name_en", e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={t("field.name") + " (DE)"}
                        variant="outlined"
                        size="small"
                        value={product.name_de ? product.name_de : ""}
                        error={getError("name_de") !== undefined}
                        helperText={getErrorMessage("name_de")}
                        onChange={(e) => change("name_de", e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <label style={{ paddingBottom: "5px" }}>{t("field.description") + " (EN)"}</label>
                      <div>
                        <ReactQuill theme="snow" placeholder="Enter Description in English" value={descriptionEN} onChange={(value: string) => setDescriptionEN(value)} />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <label style={{ paddingBottom: "5px" }}>{t("field.description") + " (DE)"}</label>
                      <div>
                        <ReactQuill theme="snow" placeholder="Enter Description in German" value={descriptionDE} onChange={(value: string) => setDescriptionDE(value)} />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={t("field.warehouse")}
                        variant="outlined"
                        size="small"
                        value={product.warehouse ? product.warehouse : ""}
                        onChange={(e) => change("warehouse", e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={t("field.productlink")}
                        variant="outlined"
                        size="small"
                        value={product.link ? product.link : ""}
                        onChange={(e) => change("link", e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="category" size={"small"}>
                          {t("field.category")}
                        </InputLabel>
                        <Select
                          labelId="category"
                          label={t("field.category")}
                          value={product.categoryid ? product.categoryid.toString() : ""}
                          onChange={(e) => change("categoryid", e.target.value as string)}
                          size={"small"}
                          error={getError("categoryid") !== undefined}
                        >
                          {categories.map((category, idx) => (
                            <MenuItem key={idx} value={category.id}>
                              {category.text_en}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{getErrorMessage("categoryid")}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="priceinputdiv">
                        <label>{t("field.originalprice")}</label>
                        <CurrencyInput
                          className="priceinput"
                          defaultValue={Number(product.originalprice) === 0 ? "" : Number(product.originalprice).toFixed(2)}
                          suffix=" €"
                          decimalsLimit={2}
                          decimalSeparator=","
                          groupSeparator="."
                          step={1}
                          onValueChange={(value, name, values) => {
                            console.log(values)
                            if (values) change("originalprice", values.float)
                          }}
                        />
                      </div>
                      {/* 
                      <TextField
                        label={t("field.originalprice")}
                        variant="outlined"
                        value={Number(product.originalprice) === 0 ? "" : product.originalprice}
                        error={getError("originalprice") !== undefined}
                        helperText={getErrorMessage("originalprice")}
                        size="small"
                        onChange={(e) => change("originalprice", e.target.value)}
                        onBlur={(e) => { }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">€</InputAdornment>,
                        }}
                        inputProps={{
                          style: { textAlign: "right" },
                        }}
                        fullWidth
                      />
                      */}
                    </Grid>
                    <Grid item xs={6}>
                      <Toggle value={product.buyonly} label="Buy Only" onChange={(value: boolean) => change("buyonly", value)}></Toggle>
                      <Toggle
                        value={product.fanfluencerdeal}
                        label="Influencer Deal"
                        onChange={(value: boolean) => change("fanfluencerdeal", value)}
                      ></Toggle>
                    </Grid>
                    <Grid item xs={6}>
                      {getReview()}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" paddingTop={3}>
                    <Button type="submit" variant="contained" size="large" sx={{ mx: 1 }} disabled={savingProcess}>
                      {
                        savingProcess &&
                        <CircularProgress size={15}
                          sx={{
                            color: blue,
                            marginRight: "10px"
                          }}
                        />
                      }
                      {t("button.save")}
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      size="large"
                      color="success"
                      sx={{ mx: 1 }}
                      onClick={() => window.open(getPreviewLink())}
                      disabled={savingProcess}
                    >
                      {t("button.preview")}
                    </Button>
                  </Grid>
                </form>
              </div>
              <div hidden={tabSelected !== "images"}>
                <ProductImages id={id || ""}></ProductImages>
              </div>
              <div hidden={tabSelected !== "specs"}>
                <ProductSpecication id={id || ""}></ProductSpecication>
              </div>
              <div hidden={tabSelected !== "buyonly"}>
                <ProductBuyOnlyDeal id={id || ""} price={originalPrice || 0}></ProductBuyOnlyDeal>
              </div>
              <div hidden={tabSelected !== "influencer"}>
                <ProductFanfluencerDeal id={id || ""} price={originalPrice || 0}></ProductFanfluencerDeal>
              </div>
              <div hidden={tabSelected !== "limited"}>
                <ProductLimitedOffer id={id || ""}></ProductLimitedOffer>
              </div>
              {/* 
          <div hidden={tabSelected !== "views"}>
            <ProductViews id={id || ""} mode="view"></ProductViews>
          </div>
          <div hidden={tabSelected !== "opens"}>
            <ProductViews id={id || ""} mode="link"></ProductViews>
          </div>
          */}
            </Grid>
          </Grid>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ProductDetail;
