export class Color {
    public id: string;
    public description: string;
    public description_de: string;
    public hex: string;
    public active: boolean;
    public arrangement: number;
}

export class ColorList extends Color {
    public action: any;
}