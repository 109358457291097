import { useEffect, useState } from "react";
import { Size, SizeList } from "../../models/size.model";
import { TableField } from "../../models/table.model";
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";
import { useTranslation } from "react-i18next";
import { Action } from "../../components/table/table";
import SizeDetails from "../../views/size/size-detail";

function SizeListViewModel() {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const { t, i18n } = useTranslation();

    const [sizes, setSizes] = useState<Size[] | undefined>(undefined);
    const [sizeList, setSizeList] = useState<SizeList[] | undefined>(undefined);
    const [search, setSearch] = useState<string>("");
    const [searches, setSearches] = useState<SizeList[] | undefined>(undefined);

    const fields = [
        { id: "id", display: "#", align: "left", sort: false, type: 'string' },
        { id: "description", display: t('field.description').toUpperCase() + " (DE)", align: "left", sort: true, type: 'string' },
        { id: "description_de", display: t('field.description').toUpperCase() + " (EN)", align: "left", sort: true, type: 'string' },
        { id: "arrangement", display: t('field.arrangement').toUpperCase(), align: "right", sort: true, type: 'number' },
        { id: "active", display: t("field.active").toUpperCase(), align: "center", sort: false, type: 'status' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' }
    ] as TableField[];

    useEffect(() => {
        const q = query(collection(firestore, "sizes"), orderBy("created", "desc"));
        let items: Size[] = [];
        let itemList: SizeList[] = [];

        let unSubcribe = onSnapshot(q, (snap) => {

            snap.docChanges().forEach((change) => {
                let size: Size = change.doc.data() as Size;
                size.id = change.doc.id;

                if (change.type === 'added') {
                    items.push(size);
                    itemList.push(convertSizeList(size))
                }
                if (change.type === 'modified') {
                    let idx = items.findIndex(x => x.id === change.doc.id);
                    if (idx >= 0) items[change.newIndex] = { ...items[change.newIndex], ...change.doc.data() }

                    idx = itemList.findIndex(x => x.id === change.doc.id);
                    if (idx >= 0) itemList[idx] = { ...itemList[idx], ...size }
                }
            });

            setSizes([...items])
            setSizeList([...itemList])
        });

        return () => {
            unSubcribe();
        }
    }, [])

    useEffect(() => {
        if (search !== '' && sizes){
            let items: Size[] = []
            sizes.forEach(item => {
                let description: string = item.description ? item.description.toLowerCase() : '';
                let descriptionde: string = item.description_de ? item.description_de.toLowerCase() : '';
                if (description.includes(search.toLowerCase()) || descriptionde.includes(search.toLowerCase())) {
                    items.push(item);
                }
            })
            let itemList: SizeList[] = [];
            items.forEach(item => itemList.push(convertSizeList(item)));
            setSearches([...itemList])
        }
        else{
            setSearches(undefined)
        }
    }, [search])

    const convertSizeList = (size: Size): SizeList => {
        let action: Action = new Action();
        action.type = 'component';
        action.component = SizeDetails;
        action.props = {
            mode: 'edit',
            size: { ...size }
        }
        return { ...size, action: action }
    }

    return { sizeList, searches, fields, setSearch };
}

export default SizeListViewModel;