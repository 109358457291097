import { useEffect, useState } from "react";
import { NotificationService } from "../../services/notification.service";
import { Notification, NotificationDisplay } from "../../models/notification.model";
import { StorageService } from "../../services/storage.service";
import { collection, doc, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";

function NotificationViewModel() {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);

    const [id, setId] = useState<string | undefined>(undefined);
    const [type, setType] = useState<"admin" | "supplier" | undefined>(undefined);
    const [ids, setIds] = useState<string[] | undefined>(undefined);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [notificationDisplays, setNotificationDisplays] = useState<NotificationDisplay[]>([]);
    const [count, setCount] = useState(0);

    
    const [read, setRead] = useState<string[]>([]);
    const [all, setAll] = useState<NotificationDisplay[]>([]);
    const [notificationDisplay, setNotificationDisplay] = useState<NotificationDisplay[]>([]);
    const [showAll, setShowAll] = useState(true);

    useEffect(() => {
        const storageService: StorageService = new StorageService();
        let data = storageService.getSaveLogin();
        if (data) {
            setId(data.data.id)
            setType(storageService.isAdmin() ? "admin" : "supplier")
        }
    }, [])

    useEffect(() => {
        if (id && id !== '') {
            const q = doc(firestore, 'usernotifications' + '/' + id)
            let unSubcribe = onSnapshot(q, (snap) => {
                if (snap.data() !== undefined) setIds([...snap.data()!['notifications'] as string[]])
                else setIds([]);
            });

            return () => {
                unSubcribe();
            }
        }
    }, [id])

    useEffect(() => {
        if (id && id !== '' && type) {
            let constraints = [];
            constraints.push(where('to', '==', type));
            if (type === "supplier") constraints.push(where('brandid', '==', id));
            constraints.push(orderBy("datetime", "desc"))
            const q = query(collection(firestore, "notifications"), ...constraints);
            let unSubcribe = onSnapshot(q, (snap) => {
                let items: Notification[] = getNotification();
                snap.docChanges().forEach((change) => {
                    let notification: Notification = change.doc.data() as Notification;
                    notification.id = change.doc.id;
                    if (change.type === "added") {
                        items.push(notification);
                    }
                    else if (change.type === 'modified') {
                        let idx = items.findIndex(x => x.id === notification.id);
                        items[idx] = { ...items[idx], ...change.doc.data() }
                    }
                });
                setNotifications([...items])
            });

            return () => {
                unSubcribe();
            }
        }
    }, [id, type])

    useEffect(() => {
        let list = notificationDisplays;
        notifications.forEach(item => {
            let idx = list.findIndex(x => x.id === item.id);
            if (idx < 0) {
                let notificationDisplay: NotificationDisplay = new NotificationDisplay();
                notificationDisplay.id = item.id;
                notificationDisplay.type = item.type;
                notificationDisplay.to = item.to;
                notificationDisplay.brandid = item.brandid;
                notificationDisplay.data = [item.data];
                notificationDisplay.datetime = item.datetime;
                notificationDisplay.read = ids ? ids.findIndex(x => x === item.id) >= 0 : false;
                list.push(notificationDisplay);
            }
            else {
                list[idx].read = ids ? ids.findIndex(x => x === item.id) >= 0 : false;
            }
        })
        setNotificationDisplays([...list])
    }, [ids, notifications])

    useEffect(() => {
        let count = notificationDisplays.filter(x => x.read === false).length;
        setCount(count);
    }, [notificationDisplays])

    const getNotification = () => {
        return notifications;
    }

    const getIds = (): string[] => {
        return ids ? ids : [];
    }

    const addRead = (notificationId: string) => {
        let list = getIds();
        let idx = list.findIndex(x => x === notificationId)
        if (idx < 0 && id) {
            const notificationService: NotificationService = new NotificationService();
            notificationService.setUserNotifications(id, [...list, notificationId]);
        }
    }

    const AddReadAll = (): Promise<void> => {
        return new Promise(resolve => {         let newIds = notifications.filter(x => x.read !== true).map(x => x.id);
            const notificationService: NotificationService = new NotificationService();
            return notificationService.setUserNotifications(id!, notifications.map(x => x.id));
        })
    }

    return { count, notificationDisplays, addRead, AddReadAll, showAll, setShowAll };
}

export default NotificationViewModel;