import { useEffect, useState } from "react";
import { BrandUser, BrandUserList } from "../../models/brand.model";
import { SupplierUserService } from "../../services/supplieruser.service";
import { TableField } from "../../models/table.model";
import { collection, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";
import { useTranslation } from "react-i18next";
import { Action } from "../../components/table/table";
import SupplierUserDetail from "../../views/supplier/supplier-user-detail";

function SupplierUserListViewModel(id: string){
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const { t, i18n } = useTranslation();

    const [users, setUsers] = useState<BrandUserList[]>([]);

    const supplierUserService: SupplierUserService = new SupplierUserService();

    const fields = [
        { id: "firstname", display: t("field.firstname").toUpperCase(), align: "left", sort: false, type: 'string' },
        { id: "lastname", display: t("field.lastname").toUpperCase(), align: "left", sort: false, type: 'string' },
        { id: "email", display: t("field.email").toUpperCase(), align: "left", sort: false, type: 'string' },
        { id: "active", display: t("field.status").toUpperCase(), align: "left", sort: false, type: 'status' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' }
    ] as TableField[];

    useEffect(() => {
        if (id){
            let items: BrandUserList[] = [];
            const q = query(collection(firestore, "brandusers"), where('brandid', '==', id), orderBy('created','desc'));
            let unSubcribe = onSnapshot(q, (snap) => {
                snap.docChanges().forEach((change) => {
                    let item: BrandUserList = change.doc.data() as BrandUserList;
                    item.id = change.doc.id;
                    if (change.type === 'added') {
                        let action: Action = new Action();
                        action.type = 'component';
                        action.component = SupplierUserDetail;
                        action.props = {
                            branduser: {...item} as BrandUserList
                        }
                        item.action = action
                        items.push(item);
                    }
                    if (change.type === 'modified') {
                        let idx = items.findIndex(x => x.id === change.doc.id);
                        if (idx >= 0) items[change.newIndex] = { ...items[change.newIndex], ...change.doc.data() }
                    }
                });
                setUsers([...items])
            });
    
            return () => {
                unSubcribe();
            }
        }
    }, [id]);

    return { fields, users }
}

export default SupplierUserListViewModel;