import { useEffect, useState } from "react";
import { Brand } from "../../models/brand.model";
import { Category } from "../../models/category.model";
import { Product } from "../../models/product.model";
import { CustomerService } from '../../services/customer.service';
import { StorageService } from "../../services/storage.service";
import { ProductService } from "../../services/product.service";
import { CategoryService } from "../../services/category.service";
import { SupplierService } from "../../services/supplier.service";
import { useTranslation } from "react-i18next";
import { TableField } from "../../models/table.model";
import { Customer, CustomerWishList } from "../../models/customer.model";
import { StringLink } from "../../components/table/table";

class ProductsRanking {
    product: Product;
    ctr: number;
}

function CustomerWishlistViewModel() {
    const { t, i18n } = useTranslation();

    const [categories, setCategories] = useState<Category[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [supplier, setSupplier] = useState<string | undefined>(undefined);
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [wishes, setWishes] = useState<CustomerWishList[]>([]);
    const [productsRanking, setProductsRanking] = useState<ProductsRanking[]>([]);

    const customerService: CustomerService = new CustomerService();
    const productService: ProductService = new ProductService();
    const storageService: StorageService = new StorageService();
    const supplierService: SupplierService = new SupplierService();
    const categoryService: CategoryService = new CategoryService();

    const fields = [
        { id: "customername", display: t("field.customername").toUpperCase(), align: "left", sort: true, type: 'stringlink' },
        { id: "customeremail", display: t("field.customeremail").toUpperCase(), align: "left", sort: true, type: 'stringlink' },
        { id: "productname", display: t("field.name").toUpperCase(), align: "left", sort: true, type: 'stringlink' },
        { id: "category", display: t("field.category").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "originalprice", display: t("field.originalprice").toUpperCase(), align: "right", sort: true, type: 'decimal' },
        { id: "buyonly", display: t("field.buyonlydeal").toUpperCase(), align: "center", sort: true, type: 'boolean' },
        { id: "fanfluencerdeal", display: t("field.influencerdeal").toUpperCase(), align: "center", sort: true, type: 'boolean' },
        { id: "active", display: t("field.status").toUpperCase(), align: "center", sort: true, type: 'status' },
        { id: "stockstatus", display: t("field.stockstatus").toUpperCase(), align: "center", sort: true, type: 'stockstatus' },
        { id: "review", display: t("field.review").toUpperCase(), align: "center", sort: true, type: 'review' }
    ] as TableField[];

    useEffect(() => {
        loadCategories();
        loadBrands();

        if (storageService.isUserLogin()) {
            if (storageService.isSupplier()) {
                let data = storageService.getSaveLogin()
                if (data) {
                    setSupplier(data.supplier.id);
                }
                else {
                    setSupplier("");
                }
            }
            else {
                setSupplier("");
            }
        }

        loadCustomers();
    }, [])

    useEffect(() => {
        if (customers.length > 0 && supplier) {
            let ids: string[] = []
            customers.map(x => ids = [...ids, ...x.wishes])
            if (ids.length > 0) {
                productService.getByIds(ids).then(data => {
                    if (supplier !== '') setProducts((data as Product[]).filter(x => x.brandid === supplier))
                    else setProducts(data as Product[])
                })
            }
        }
    }, [customers, supplier]);

    useEffect(() => {
        if (customers.length > 0 && products.length > 0 && brands.length > 0 && categories.length > 0) {
            let items: CustomerWishList[] = [];
            customers.forEach(customer => {
                customer.wishes.forEach(wish => {
                    let product = products.find(x => x.id == wish);
                    if (product) {
                        let item: CustomerWishList = new CustomerWishList();
                        item.customerid = customer.id;
                        if (customer.firstname) {
                            let link: StringLink = new StringLink();
                            link.text = customer.firstname ? customer.firstname + ' ' + customer.lastname : "";
                            link.url = `/customers/` + customer.id
                            item.customername = link;
                        }
                        if (customer.email) {
                            let link: StringLink = new StringLink();
                            link.text = customer.email;
                            link.url = `/customers/` + customer.id
                            item.customeremail = link;
                        }
                        item.productid = product.id;
                        if (product.name_en) {
                            let link: StringLink = new StringLink();
                            link.text = product.name_en;
                            link.url = `/products/` + product.id
                            item.productname = link;
                        }
                        let category = categories.find(x => x.id === (product ? product.categoryid : ''));
                        if (category) item.category = category.text_en;

                        item.originalprice = product.originalprice;
                        item.buyonly = product.buyonly;
                        item.fanfluencerdeal = product.fanfluencerdeal;
                        item.active = product.active;
                        item.stockstatus = product.stockstatus;
                        item.review = product.review;

                        items.push(item);
                    }
                })
            })
            setWishes([...items])
        }
    }, [customers, products])

    useEffect(() => {
        let items: ProductsRanking[] = [];
        wishes.forEach(wish => {
            let product = products.find(x => x.id === wish.productid)
            if (product) {
                let idx = items.findIndex(x => x.product.id === wish.productid);
                if (idx < 0) {
                    let item: ProductsRanking = new ProductsRanking();
                    item.product = product;
                    item.ctr = 1;
                    items.push(item)
                }
                else {
                    items[idx].ctr = items[idx].ctr + 1;
                }
            }
        })
        items = items.sort((n1, n2) => n2.ctr - n1.ctr).splice(0, 5);
        setProductsRanking([...items])
    }, [wishes])

    const loadCustomers = async () => {
        let data = await customerService.get() as Customer[];
        setCustomers([...data.filter(x => x.wishes && x.wishes.length > 0)]);
    }

    const loadBrands = async () => {
        let data = await supplierService.get() as Brand[];
        setBrands([...data]);
    }

    const loadCategories = async () => {
        let data = await categoryService.get() as Category[];
        setCategories([...data]);
    }

    return { fields, wishes, productsRanking }
}

export default CustomerWishlistViewModel;