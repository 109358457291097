import { useEffect, useState } from "react";
import { EmailService } from "../../services/email.service";
import { Email } from "../../models/email.model";
import { templates } from "../../config";

function EmailLogViewModel() {
    const [emails, setEmails] = useState<Email[]>([]);
    const [search, setSearch] = useState<Email[] | undefined>(undefined);
    const [filter, setFilter] = useState<"Transaction" | "Account" | undefined>(undefined)

    useEffect(() => {
        const emailService: EmailService = new EmailService();
        emailService.get().then(data => {
            if ((data)) {
                let logs = data as Email[];
                setEmails(logs.reverse().filter(x => x.type !== undefined))
            }
        })
    }, [])

    useEffect(() => {
        console.log(filter)
        if (filter !== undefined) {
            setSearch(emails.filter(x => x.type === filter))
        }
        else {
            setSearch(undefined)
        }
    }, [filter])

    const getName = (templateid: number) => {
        let idx: number = templates.findIndex(x => x.id === templateid);
        if (idx >= 0) return templates[idx].name;
        else return '';
    }

    return { emails, search, setFilter, getName }
}

export default EmailLogViewModel;