import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './views/dashboard/dashboard';
import Layout from './views/layout/layout';
import Login from './views/account/login/login';
import Admins from './views/admin/admin-list';
import Suppliers from './views/supplier/supplier-list';
import Customers from './views/customer/customer-list';
import CustomerRegister from './views/customer/customer-register';
import AdminRegister from './views/admin/admin-register';
import AdminDetail from './views/admin/admin.detail';
import SupplierRegister from './views/supplier/supplier-register';
import SupplierDetail from './views/supplier/supplier-detail';
import CategoryRegister from './views/category/category-detail';
import CategoryList from './views/category/category-list';
import SupplierUserRegister from './views/supplier/supplier-user-register';
import SupplierUserDetail from './views/supplier/supplier-user-detail';
import SupplierContactDetail from './views/supplier/supplier-contact-detail';
import CustomerWishlist from './views/customer/customer-wishlist';
import SizeList from './views/size/size-list';
import SizeDetail from './views/size/size-detail';
import ColorList from './views/color/color-list';
import ColorDetail from './views/color/color-detail';
import Products from './views/product/product-list';
import ProductCreate from './views/product/product-create';
import ProductDetail from './views/product/product-detail';
import TagList from './views/tag/tag-list';
import TagDetail from './views/tag/tag-detail';
import Profile from './views/account/profile/profile';
import ChangePassword from './views/account/changepassword/changepassword';
import ProductView from './views/product/product-view';
import Transactions from './views/transaction/transaction-list';
import TransactionDetail from './views/transaction/transaction-detail';
import PartnerList from './views/content/partner-list';
import Settings from './views/settings/settings';
import CustomerDetail from './views/customer/customer-detail';
import EmailLog from './views/emaillog/emaillog';


function App() {
  console.log(process.env.REACT_APP_APPNAME);

  return (<>
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />}></Route>
          
          <Route path='admins' element={<Admins />}></Route>
          <Route path='admins/new' element={<AdminRegister />}></Route>
          <Route path='admins/:id' element={<AdminDetail />}></Route>

          <Route path='brands' element={<Suppliers />}></Route>
          <Route path='brands/new' element={<SupplierRegister />}></Route>
          <Route path='brands/:id' element={<SupplierDetail />}></Route>
          <Route path='branduser/new/:brandid' element={<SupplierUserRegister />}></Route>
          <Route path='branduser/edit/:id' element={<SupplierUserDetail />}></Route>
          <Route path='brandcontact/new/:brandid' element={<SupplierContactDetail />}></Route>
          <Route path='brandcontact/edit/:id' element={<SupplierContactDetail />}></Route>
          
          <Route path='mydetails/:id' element={<SupplierDetail />}></Route>
          <Route path='mydetailsuser/new/:brandid' element={<SupplierUserRegister />}></Route>
          <Route path='mydetailsuser/edit/:id' element={<SupplierUserDetail />}></Route>
          <Route path='mydetailscontact/new/:brandid' element={<SupplierContactDetail />}></Route>
          <Route path='mydetailscontact/edit/:id' element={<SupplierContactDetail />}></Route>

          <Route path='customers' element={<Customers mode='' />}></Route>
          <Route path='customerstomoderate' element={<Customers mode='moderate' />}></Route>
          <Route path='customers/new' element={<CustomerRegister />}></Route>
          <Route path='customers/:id' element={<CustomerDetail />}></Route>
          <Route path='customers/wishlist' element={<CustomerWishlist />}></Route>
          
          <Route path='categories/' element={<CategoryList />}></Route>
          <Route path='categories/new' element={<CategoryRegister />}></Route>
          <Route path='categories/:id' element={<CategoryRegister />}></Route>

          <Route path='sizes/' element={<SizeList />}></Route>
          <Route path='sizes/new' element={<SizeDetail />}></Route>
          <Route path='sizes/:id' element={<SizeDetail />}></Route>

          <Route path='colors/' element={<ColorList />}></Route>
          <Route path='colors/new' element={<ColorDetail />}></Route>
          <Route path='colors/:id' element={<ColorDetail />}></Route>

          <Route path='tags/' element={<TagList />}></Route>
          <Route path='tags/new' element={<TagDetail />}></Route>
          <Route path='tags/:id' element={<TagDetail />}></Route>

          <Route path='products/' element={<Products mode="" />}></Route>
          <Route path='products/new' element={<ProductCreate />}></Route>
          <Route path='products/view/:id' element={<ProductView />}></Route>
          <Route path='products/:id' element={<ProductDetail />}></Route>
          <Route path='productstomoderate/' element={<Products mode="moderate" />}></Route>

          <Route path='transactions/' element={<Transactions />}></Route>
          <Route path='transactions/:id' element={<TransactionDetail />}></Route>

          <Route path='partners/' element={<PartnerList />}></Route>
          <Route path='settings/' element={<Settings />}></Route>
          <Route path='emails/' element={<EmailLog />}></Route>

          <Route path='profile/' element={<Profile />}></Route>
          <Route path='changepassword/' element={<ChangePassword />}></Route>
        </Route>
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </BrowserRouter>
   
  </>);
}

export default App;
