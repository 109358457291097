import { useEffect, useState } from "react";
import { AdminList } from "../../models/admin.model";
import { useTranslation } from "react-i18next";
import { TableField } from "../../models/table.model";
import { Action } from "../../components/table/table";
import AdminEdit from "../../views/admin/admin-edit";
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";

function AdminListViewModel() {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const { t, i18n } = useTranslation();

    const [admins, setAdmins] = useState<AdminList[] | undefined>(undefined);
    const [searches, setSearches] = useState<AdminList[] | undefined>(undefined);

    const fields = [
        { id: "id", display: "#", align: "left", sort: false, type: 'string' },
        { id: "email", display: t("field.email").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "firstname", display: t("field.firstname").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "lastname", display: t("field.lastname").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "active", display: t("field.status").toUpperCase(), align: "center", sort: false, type: 'status' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' }
    ] as TableField[];

    useEffect(() => {
        const q = query(collection(firestore, "administrators"), orderBy("created", "desc"));
        let list: AdminList[] = [];
        let unSubcribe = onSnapshot(q, (snap) => {            
            snap.docChanges().forEach((change) => {
                let admin: AdminList = change.doc.data() as AdminList;
                admin.id = change.doc.id;
                if (change.type === 'added') {
                    let action: Action = new Action();
                    action.type = 'component';
                    action.component = AdminEdit;
                    action.props = {
                        admin: { ...admin }
                    }
                    admin.action = action
                    list.push(admin);
                }
                if (change.type === 'modified') {
                    let idx = list.findIndex(x => x.id === change.doc.id);
                    if (idx >= 0) list[change.newIndex] = { ...list[change.newIndex], ...change.doc.data() }
                }
            });
            setAdmins([...list])
        });

        return () => {
            unSubcribe();
        }
    }, []);

    const search = (_search: string) => {
        if (_search !== '') {
            if (admins) {
                let search: AdminList[] = [];
                admins.forEach(item => {
                    let id: string = item.id ? item.id.toLowerCase() : '';
                    let email: string = item.email ? item.email.toLowerCase() : '';
                    let firstname: string = item.firstname ? item.firstname.toLowerCase() : '';
                    let lastname: string = item.lastname ? item.lastname.toLowerCase() : '';
                    if (id.toLowerCase() === _search.toLowerCase() || email.includes(_search.toLowerCase()) || firstname.includes(_search.toLowerCase()) || lastname.includes(_search.toLowerCase())) {
                        search.push(item);
                    }
                })
                setSearches([...search]);
            }
        }
        else {
            setSearches(undefined)
        }
    }

    return { admins, searches, fields, search };
}

export default AdminListViewModel;