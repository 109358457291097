import { Box, Button, Card, CardContent, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import FormLayout from "../../components/form/FormLayout";
import "./supplier.css";
import SupplierRegisterViewModel from "../../viewmodels/supplier/register.viewmodel";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { red } from "@mui/material/colors";

const SupplierRegister: React.FC = () => {
  const { t } = useTranslation();
  const { model, change, getError, getErrorMessage, submit, process } = SupplierRegisterViewModel();

  const [descriptionEN, setDescriptionEN] = useState("");
  const [descriptionDE, setDescriptionDE] = useState("");

  return (
    <React.Fragment>
      <FormLayout
        PageTitle={t("title.brands")}
        Breadcrumbs={[{ title: t("title.dashboard"), url: "/" }, { title: t("title.brands"), url: "/brands" }, { title: t("title.registerbrands") }]}
      ></FormLayout>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit(descriptionEN, descriptionDE);
        }}
      >
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <Grid container spacing={2} paddingTop={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t("field.brandname")}
                  variant="outlined"
                  size="small"
                  value={model.name}
                  error={getError("name") !== undefined}
                  helperText={getErrorMessage("name")}
                  onChange={(e) => change("name", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t("field.website")}
                  variant="outlined"
                  size="small"
                  value={model.website}
                  onChange={(e) => change("website", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <label style={{ paddingBottom: "5px" }}>{t("field.description") + " (EN)"}</label>
                <div>
                  <ReactQuill theme="snow" placeholder="Enter Description in English" value={descriptionEN} onChange={(value: string) => setDescriptionEN(value)} />
                </div>
                {
                  getErrorMessage("description") &&
                  <label style={{ color: red[500] }}>{getErrorMessage("description")}</label>
                }
              </Grid>
              <Grid item xs={6}>
                <label style={{ paddingBottom: "5px" }}>{t("field.description") + " (DE)"}</label>
                <div>
                  <ReactQuill theme="snow" placeholder="Enter Description in German" value={descriptionDE} onChange={(value: string) => setDescriptionDE(value)} />
                </div>
                {
                  getErrorMessage("description_de") &&
                  <label style={{ color: red[500] }}>{getErrorMessage("description_de")}</label>
                }
              </Grid>
              <Grid item xs={12}>
                <h5 className="address mb-0">{t("field.address").toUpperCase()}</h5>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t("field.street")}
                  variant="outlined"
                  size="small"
                  value={model.address}
                  onChange={(e) => change("address", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t("field.street") + " 2"}
                  variant="outlined"
                  size="small"
                  value={model.address2}
                  onChange={(e) => change("address2", e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t("field.divergingaddress")}
                  variant="outlined"
                  size="small"
                  value={model.diverging}
                  onChange={(e) => change("diverging", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label={t("field.city")}
                  variant="outlined"
                  size="small"
                  value={model.city}
                  onChange={(e) => change("city", e.target.value)}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={t("field.state")}
                  variant="outlined"
                  size="small"
                  value={model.state}
                  onChange={(e) => change("state", e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  label={t("field.zip")}
                  variant="outlined"
                  size="small"
                  value={model.zip}
                  onChange={(e) => change("zip", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" paddingTop={3}>
                <Button type="submit" variant="contained" size="large" disabled={process}>
                  {t("button.save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </form>
    </React.Fragment >
  );
};
export default SupplierRegister;
