import { useEffect, useRef, useState } from "react";
import { Product } from "../../models/product.model";
import { Brand } from "../../models/brand.model";
import pass, { Validation } from "../../models/validation.model";
import { Category } from "../../models/category.model";
import { ProductService } from "../../services/product.service";
import { SupplierService } from "../../services/supplier.service";
import { CategoryService } from "../../services/category.service";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Tag } from "../../models/tag.model";
import { TagService } from "../../services/tag.service";
import { useTranslation } from "react-i18next";
import { StorageService } from "../../services/storage.service";

function ProductDetailViewModel(id: string) {
    const { t } = useTranslation();

    const initialize = useRef(false);

    const [product, setProduct] = useState<Product>(new Product());
    const [originalPrice, setOriginalPrice] = useState(0);
    const [supplier, setSupplier] = useState('');
    const [suppliers, setSupplliers] = useState<Brand[]>([]);
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [savingProcess, setSavingProcess] = useState<boolean>(false);
     
    const productService: ProductService = new ProductService();
    const supplierService: SupplierService = new SupplierService();
    const categoryService: CategoryService = new CategoryService();
    const tagService: TagService = new TagService();

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const rules = [
        {
            field: "brandid",
            type: "required",
            message: "error.brandrequire",
        },
        {
            field: "article",
            type: "required",
            message: "error.articlerequire",
        },
        {
            field: "name_en",
            type: "required",
            message: "error.nameisrequired",
        },
        {
            field: "name_de",
            type: "required",
            message: "error.nameisrequired",
        },
        {
            field: "categoryid",
            type: "required",
            message: "error.categoryrequire",
        },
    ];

    const moderationrules = [
        {
            field: "reason",
            type: "required",
            message: "error.reasonrequire",
        },
    ]

    useEffect(() => {
        loadStorageSupplier();
        loadCategories();
        loadSuppliers();
        loadTags();
    }, [])

    useEffect(() => {
        const getProduct = async () => {
            let item = await productService.getById(id) as Product;
            console.log(item.originalprice)
            setProduct(item);
            setOriginalPrice(item.originalprice);
            initialize.current = true;
        }

        getProduct();
    }, [id])

    useEffect(() => {
        if (submitted) {
            isValid();
        }
    }, [product])

    const loadStorageSupplier = () => {
        const storageService: StorageService = new StorageService()
        if (storageService.isUserLogin()) {
            if (storageService.isSupplier()) {
                let data = storageService.getSaveLogin()
                if (data) setSupplier(data.supplier.id);
            }
        }
    }

    const loadCategories = async () => {
        let data = await categoryService.get() as Category[];
        setCategories(data);
    }

    const loadSuppliers = async () => {
        let data: Brand[] = await supplierService.get() as Brand[];
        setSupplliers(data);
    }

    const loadTags = async () => {
        let data: Tag[] = await tagService.get() as Tag[];
        setTags(data);
    }

    const getCategory = (id: string) => {
        return categories.find(x => x.id === id);
    }

    const getSupplier = (id: string) => {
        return suppliers.find(x => x.id === id);
    }

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message);
        }
        return message;
    }

    const change = (field: string, value: any) => {
        setProduct({ ...product, [field]: value });
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, product[validation.field as keyof typeof product]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const submit = async (descriptionEN: string, descriptionDE: string) => {
        setSubmitted(true);

        let data: Product = product;
        data.description_en = descriptionEN;
        data.description_de = descriptionDE

        setProduct({ ...data });
        
        if (isValid() === false) {
            return;
        }

        setSavingProcess(true)
        
        await productService.update(id, data);

        //for recalculation
        setOriginalPrice(data.originalprice);
        
        enqueueSnackbar("Product Updated", {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });

        setSavingProcess(false)
        setSubmitted(false);
        navigate("/products/" + id);
    }

    const moderation = async (review: "pending" | "approved" | "rejected", reason: string) => {
        setErrors([]);
        if ((review === "rejected") && (reason === "")){
            let values: Validation[] = [];
            moderationrules.forEach(rule => {
                let validation: Validation = rule as Validation;
                if (pass(validation, reason) === false) {
                    values.push(validation);
                }
            })
            setErrors([...values]);
            return;
        }

        await productService.updateModeration(id, review, reason);
        enqueueSnackbar("Product Moderation Updated", {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
    }

    return { initialize, supplier, suppliers, categories, tags, product, originalPrice ,getCategory, getSupplier, getError, getErrorMessage, change, submit, moderation, savingProcess }
}

export default ProductDetailViewModel;