import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import Menus from "../../components/menu/menus";
import "./layout.css";
import { useTranslation } from "react-i18next";
import { StorageService } from "../../services/storage.service";
import { AdminService } from "../../services/admin.service";
import { Admin } from "../../models/admin.model";
import { BrandUser } from "../../models/brand.model";
import { SupplierUserService } from "../../services/supplieruser.service";

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const storageService = new StorageService();
  const adminService = new AdminService();
  const supplierUserService = new SupplierUserService();

  useEffect(() => {
    if (!storageService.isUserLogin()) navigate("/login");
    i18n.changeLanguage(storageService.getLanguage());
  }, []);

  const getPortal = () => {
    if (storageService.isUserLogin()) {
      if (storageService.isSupplier()) return <div className="portal p-supplier">{t("title.supplierportal").toUpperCase()}</div>;
      else return <div className="portal p-admin">{t("title.adminportal").toUpperCase()}</div>;
    }
  };

  const onChangeLanguage = (language: "en" | "de") => {
    i18n.changeLanguage(language);
    storageService.setLanguage(language);

    let data = storageService.getSaveLogin();
    if (data !== undefined) {
      if (storageService.isAdmin()) {
        let admin: Admin = data.data as Admin;
        adminService.updateLanguage(admin.id, language);
      }
      else {
        let brandUser: BrandUser = data.data as BrandUser;
        supplierUserService.updateLanguage(brandUser.id, language);
      }
    }
  }

  return (
    <React.Fragment>
      <Header onChangeLanguage={onChangeLanguage} />
      <div className="main">
        <div className="menu-div">
          {getPortal()}
          <Menus />
        </div>
        <div className="outlet-div">
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Layout;
