import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FormLayout from "../../components/form/FormLayout";
import CustomerDetailViewModel from "../../viewmodels/customer/detail.viewmodel";
import { Button, Chip, Grid, Tab, Tabs, TextField } from "@mui/material";
import Toggle from "../../components/toggle/toggle";
import { fanfluencers } from "../../config";
import TableLayout from "../../components/table/table";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const CustomerDetail: React.FC = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { customer, fields, wishes, searchFeilds, searches, change, processing, getError, getErrorMessage, submit } = CustomerDetailViewModel(id!);

  const [tabSelected, setTabSelected] = React.useState("details");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabSelected(newValue);
  };

  const convertTimestampToDate = (birthday: any) => {
    let dt = new Date(birthday.seconds * 1000);
    return dt.toLocaleDateString("en-CA");
  };


  return (
    <React.Fragment>
      <FormLayout
        PageTitle="Customer Detail"
        Breadcrumbs={[{ title: t("title.dashboard"), url: "/" }, { title: t("title.customers"), url: "/customers" }, { title: "Customer Detail" }]}
      ></FormLayout>

      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <span>ID: {customer ? customer.id : ""}</span>
        <Tabs value={tabSelected} onChange={handleChange}>
          <Tab value="details" label={t("title.details")} />
          <Tab value="wishes" label="Wishes" />
          <Tab value="searches" label="Searches" />
        </Tabs>
      </div>

      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <div hidden={tabSelected !== "details"}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submit();
              }}
            >
              <Grid container spacing={1} rowGap={1}>
                <Grid xs={12} item>
                  <h5 className="subtext mb-1">{t("title.details").toUpperCase()}</h5>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      label={t("field.email")}
                      variant="outlined"
                      size="small"
                      value={customer && customer.email ? customer.email : ""}
                      error={getError("email") !== undefined}
                      helperText={getErrorMessage("email")}
                      onChange={(e) => change("email", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      label={t("field.firstname")}
                      variant="outlined"
                      size="small"
                      value={customer && customer.firstname ? customer.firstname : ""}
                      error={getError("firstname") !== undefined}
                      helperText={getErrorMessage("firstname")}
                      onChange={(e) => change("firstname", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={t("field.lastname")}
                      variant="outlined"
                      size="small"
                      value={customer && customer.lastname ? customer.lastname : ""}
                      error={getError("lastname") !== undefined}
                      helperText={getErrorMessage("lastname")}
                      onChange={(e) => change("lastname", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      type="date"
                      label={t("field.bday")}
                      variant="outlined"
                      size="small"
                      value={customer && customer.birthday ? convertTimestampToDate(customer.birthday) : ""}
                      error={getError("birthday") !== undefined}
                      helperText={getErrorMessage("birthday")}
                      onChange={(e) => change("birthday", e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Toggle
                      value={customer && customer.active !== undefined ? customer.active : true}
                      label="Active"
                      onChange={(value: boolean) => change("active", value)}
                    ></Toggle>
                    <Toggle
                      value={customer && customer.newsletter ? customer.newsletter : false}
                      label="Newsletter"
                      onChange={(value: boolean) => change("newsletter", value)}
                    ></Toggle>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1} rowGap={1}>
                <Grid xs={12} item marginTop={2}>
                  <h5 className="subtext mb-1">DMSB Details</h5>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={3}>
                    <TextField
                      label="DMSB ID"
                      variant="outlined"
                      size="small"
                      value={customer && customer.dmsbid ? customer.dmsbid : ""}
                      aria-readonly="true"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={9}>
                    {
                      customer && customer.withLicense === true &&
                      <Chip icon={<CheckCircleOutlineIcon />} label="Driver's License" color="info" style={{ marginRight: '10px' }}></Chip>
                    }
                    {
                      customer && customer.withLicense !== true &&
                      <Chip icon={<HighlightOffIcon />} label="Driver's License" style={{ marginRight: '10px' }}></Chip>
                    }

                    {
                      customer && customer.withOfficial === true &&
                      <Chip icon={<CheckCircleOutlineIcon />} label="Official's License" color="info" style={{ marginRight: '10px' }}></Chip>
                    }
                    {
                      customer && customer.withOfficial !== true &&
                      <Chip icon={<HighlightOffIcon />} label="Official's License" style={{ marginRight: '10px' }}></Chip>
                    }
                  </Grid>
                  {/* 
                  <Grid item xs={6}>
                    <TextField
                      label="Driver License"
                      variant="outlined"
                      size="small"
                      value={customer && customer.license ? customer.license : ""}
                      error={getError("license") !== undefined}
                      helperText={getErrorMessage("license")}
                      onChange={(e) => change("license", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Sport Official License Number"
                      variant="outlined"
                      size="small"
                      value={customer && customer.official ? customer.official : ""}
                      error={getError("official") !== undefined}
                      helperText={getErrorMessage("official")}
                      onChange={(e) => change("official", e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  */}
                </Grid>
              </Grid>
              <Grid container spacing={1} rowGap={1}>
                <Grid xs={12} item marginTop={2}>
                  <h5 className="subtext mb-1">Social Media Details</h5>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Instagram"
                    variant="outlined"
                    size="small"
                    value={customer && customer.instagram ? customer.instagram : ""}
                    error={getError("instagram") !== undefined}
                    helperText={getErrorMessage("instagram")}
                    onChange={(e) => change("instagram", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="number"
                    label="Follower Count"
                    variant="outlined"
                    size="small"
                    value={customer && customer.followers ? customer.followers : 0}
                    error={getError("followers") !== undefined}
                    helperText={getErrorMessage("followers")}
                    onChange={(e) => change("followers", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Ratings"
                    variant="outlined"
                    size="small"
                    value={
                      customer && customer.ratings ? fanfluencers.find((x) => x.id === customer.ratings)!.display : fanfluencers[0].display
                    }
                    aria-readonly="true"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" paddingTop={3}>
                  <Button type="submit" variant="contained" size="large" sx={{ mx: 1 }} disabled={processing}>
                    {t("button.save")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
          <div hidden={tabSelected !== "wishes"}>
            <Grid container sx={{ paddingBottom: 2 }}>
              <Grid xs={12} item>
                <h5 className="subtext mb-1">WISHES</h5>
              </Grid>
            </Grid>
            <TableLayout fields={fields} data={wishes}></TableLayout>
          </div>
          <div hidden={tabSelected !== "searches"}>
            <Grid container sx={{ paddingBottom: 2 }}>
              <Grid xs={12} item>
                <h5 className="subtext">SEARCHES</h5>
              </Grid>
              <TableLayout fields={searchFeilds} data={searches ? searches : []}></TableLayout>

            </Grid>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CustomerDetail;
