const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

export default firebaseConfig;
export const maxPostCount = 3;
export const stockWarning = 10;
export const previewLink = "/productview/[ID]";

export const decimalformat = { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 };

export const fanfluencers = [
  {
    id: "NOFANFLUENCER",
    tag: "NF",
    display: "No Influencer",
    minimum: 0,
    maximum: 1000,
  },
  {
    id: "MFANFLUENCER",
    tag: "MF",
    display: "M-Influencer",
    minimum: 1001,
    maximum: 10000,
    story: 100,
    feed: 200,
    reel: 300,
  },
  {
    id: "LFANFLUENCER",
    tag: "LF",
    display: "L-Influencer",
    minimum: 10001,
    maximum: 75000,
    story: 200,
    feed: 400,
    reel: 600,
  },
  {
    id: "XLFANFLUENCER",
    tag: "XLF",
    display: "XL-Influencer",
    minimum: 75001,
    maximum: 250000,
    story: 500,
    feed: 1000,
    reel: 1500,
  },
  {
    id: "XXLFANFLUENCER",
    tag: "XXLF",
    display: "XXL-Influencer",
    minimum: 250001,
    maximum: 100000000,
    story: 1000,
    feed: 2000,
    reel: 3000,
  },
];

export const stockstatus = [
  {
    id: "in-stock",
    value: "In Stock",
    color: "primary",
    withTag: false,
  },
  {
    id: "out-of-stock",
    value: "Out of Stock",
    color: "error",
    withTag: true,
  },
  {
    id: "limited-time",
    value: "Limited Time Offer",
    color: "success",
    withTag: true,
  },
];

export const racedealstatus = {
  id: "approve",
  color: "success",
}

export const influencerstatus = {
  id: "pending",
  color: "primary",
}

export const racedealstatuses = [
  {
    id: "approve",
    color: "success",
  },
  {
    id: "complete",
    color: "secondary",
  },
]

export const influencerstatuses = [
  {
    id: "pending",
    color: "primary",
  },
  {
    id: "approve",
    color: "success",
  },
  {
    id: "cancel",
    color: "error",
  },
  {
    id: "complete",
    color: "secondary",
  },
]

export const transactionstatus = [
  {
    id: "pending",
    color: "primary",
  },
  {
    id: "approve",
    color: "success",
  },
  {
    id: "cancel",
    color: "error",
  },
  {
    id: "complete",
    color: "secondary",
  },
];

export const reviewstatus = [
  {
    id: "pending",
    value: "Pending",
    color: "warning",
  },
  {
    id: "approved",
    value: "Approved",
    color: "primary",
  },
  {
    id: "rejected",
    value: "Rejected",
    color: "error",
  }
]

export const productfields = [
  { field: "originalprice", datatype: "number", display: "Original Price" },
  { field: "active", datatype: "boolean", display: "Active" },
  { field: "buyonly", datatype: "boolean", display: "RaceClub Deal" },
  { field: "fanfluencerdeal", datatype: "boolean", display: "Influencer Deal" },
  { field: "stockstatus", datatype: "custom", display: "Stock Status" }
]

export const accountfields = [
  { field: "instagram", datatype: "string", display: "Instagram" },
  { field: "followers", datatype: "number", display: "Followers Count" },
  { field: "active", datatype: "boolean", display: "Status" },
  { field: "license", datatype: "string", display: "License #" },
  { field: "official", datatype: "string", display: "Official #" }
]

export const templates = [
  { id: 1, name: "Account Confirmation" },
  { id: 2, name: "Account Confirmation" },
  { id: 9, name: "Order Entry Buy-Only" },
  { id: 10, name: "Order Entry Buy-Only" },
  { id: 5, name: "Influencer Deal Brand Confirmation" },
  { id: 6, name: "Influencer Deal Brand Confirmation" },
  { id: 7, name: "Order Confirmation Buy-Only" },
  { id: 8, name: "Order Confirmation Buy-Only" },
  { id: 11, name: "Received Influencer Order Deal" },
  { id: 12, name: "Received Influencer Order Deal" },
  { id: 16, name: "Order Cancelation" },
  { id: 17, name: "Order Confirmation" }
]