export class StorageService {

    isUserLogin = (): boolean => {
        return localStorage.getItem("raceclub") !== null;
    }

    saveLogin = (data: any) => {
        localStorage.setItem("raceclub", data);
    }

    getSaveLogin = (): any => {
        if (localStorage.getItem("raceclub") !== null) return JSON.parse(localStorage.getItem("raceclub")!);
        else return undefined;
    }

    isAdmin = (): boolean => {
        if (localStorage.getItem("raceclub") !== null){
            let data = JSON.parse(localStorage.getItem("raceclub")!);
            return data.type === "admin"
        }
        else return false;
    }

    isSupplier = (): boolean => {
        if (localStorage.getItem("raceclub") !== null){
            let data = JSON.parse(localStorage.getItem("raceclub")!);
            return data.type === "brand"
        }
        else return false;
    }
    
    setLanguage = (langugage: string) => {
        localStorage.setItem("raceclub-language", langugage);
    } 

    getLanguage = (): string => {
        return localStorage.getItem("raceclub-language")!;
    }

    setNotifications = (list: string[]) => {
        localStorage.setItem("raceclub-notifications", JSON.stringify(list));
    }

    getNotifications = () => {
        if (localStorage.getItem("raceclub-notifications") !== null) return JSON.parse(localStorage.getItem("raceclub-notifications")!);
        else return [];
    }
}