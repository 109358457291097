export class Tag {
    public id: string;
    public code: string;
    public description: string;
    public description_de: string;
    public display_en: string;
    public display_de: string;
    public arrangement: number;
    public background: string;
    public fontcolor: string;
    public active: boolean;
}

export class TagList extends Tag {
    public action: any;
}