import { Timestamp } from "firebase/firestore";
import { StringLink } from "../components/table/table";

export class Customer {
  public id: string;
  public email: string;
  public firstname: string;
  public lastname: string;
  public birthday: Date | null;
  public status: "pending" | "approved" | "suspend" | "deleted" = "pending";
  public image: string;
  public instagramid: string;
  public instagram: string;
  public instagramprofile: string;
  public followers: number;
  public ratings: string;
  public dmsbid: string;
  public withLicense: boolean;
  public withOfficial: boolean;
  public country: string;
  public newsletter: boolean;
  public created: Timestamp;
  public appovedby: string;
  public approveddate: Timestamp;
  public suspendby: string;
  public suspenddate: Timestamp;
  public recents: string[];
  public carts: string[];
  public payments: string[];
  public wishes: string[];
  public orders: string[] = [];
  public shippingaddress: string = "";
  public billingaddress: string = "";
  public devices: Device[] = [];
  public active: boolean;
}

export class Device {
  public deviceId: string = "";
  public onesignal_id: string = "";
  public deviceOs: string = "";
  public status: boolean = false;
}

export class Instagram {
  public id: string = "";
  public username: string = "";
  public followers: number = 0;
  public profile: string = "";
}

export class CustomerList {
  public id: string;
  public dmsbid: string;
  public email: string;
  public name: string;
  public birthday: Date | null;
  public withLicense: boolean;
  public withOfficial: boolean;
  public instagram: string;
  public followers: number;
  public ratings: string;
  public status: "pending" | "approved" | "suspend" | "deleted" = "pending";
  public active: boolean;
  public action: any;
}

export class CustomerWishList {
  public customerid: string;
  public customername: StringLink;
  public customeremail: StringLink;
  public productid: string;
  public productname: StringLink;
  public brand: string;
  public category: string;
  public originalprice: number;
  public buyonly: boolean;
  public fanfluencerdeal: boolean;
  public active: boolean;
  public stockstatus: 'in-stock' | 'out-of-stock' | 'limited-time';
  public review: 'pending' | 'approved' | 'rejected';
}