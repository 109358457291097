import React from "react";
import SizeListViewModel from "../../viewmodels/size/list.viewmodel";
import {
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import FormLayout from "../../components/form/FormLayout";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import SizeDetails from "./size-detail";
import { Size } from "../../models/size.model";
import { useTranslation } from "react-i18next";
import TableLayout from "../../components/table/table";

const SizeList: React.FC = () => {
  const { t } = useTranslation();

  const { sizeList, searches, fields, setSearch } = SizeListViewModel();

  const handleKeyUp = (event: any) => {
    setSearch(event.target.value);
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle={t('title.sizes')} Breadcrumbs={[{ title: t('title.dashboard'), url: "/" }, { title: t('title.sizes') }]}></FormLayout>

      <Grid container sx={{ paddingBottom: 2 }}>
        <Grid xs={6} container item>
          <TextField
            id="outlined-search"
            size="small"
            placeholder="Search"
            type="search"
            label={t('text.search')}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onKeyUp={(e) => handleKeyUp(e)}
          />
        </Grid>
        <Grid xs={6} container direction="row" justifyContent="right" alignItems="center" item>
          <SizeDetails mode="add" size={new Size()}></SizeDetails>
        </Grid>
      </Grid>

      <TableLayout fields={fields} data={searches !== undefined ? searches : sizeList}></TableLayout>
    </React.Fragment>
  );
};
export default SizeList;
