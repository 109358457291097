import validator from "validator";

async function validation(rules, model) {
  if (rules && rules.length > 0) {
    let errors = {};

    rules.forEach((rule) => {
      if (errors[rule.field] === undefined) {
        if (rule.type === "required") {
          if (validator.isEmpty(model[rule.field] !== null ? model[rule.field] : "")) {
            errors[rule.field] = rule.message;
          }
        } else if (rule.type === "valid") {
          if (rule.datatype === "email") {
            if (!validator.isEmail(model[rule.field] !== null ? model[rule.field] : "")) {
              errors[rule.field] = rule.message;
            }
          }
          if (rule.datatype === "number") {
            if (!validator.isNumeric(model[rule.field] !== null ? model[rule.field].toString() : "")) {
              errors[rule.field] = rule.message;
            }
          }
        } else if (rule.type === "min") {
          if (rule.value > (model[rule.field] !== null ? model[rule.field] : 0)) {
            errors[rule.field] = rule.message;
          }
        } else if (rule.type === "max") {
          if (rule.value < (model[rule.field] !== null ? model[rule.field] : 0)) {
            errors[rule.field] = rule.message;
          }
        } else if (rule.type === "same") {
          if ((model[rule.field] !== null ? model[rule.field] : '') !== (model[rule.other] !== null ? model[rule.other] : '')) {
            errors[rule.field] = rule.message;
          }
        } else if (rule.type === "minlength") {
          if ((model[rule.field] !== null ? model[rule.field] : 0).length < rule.value) {
            errors[rule.field] = rule.message;
          }
        }
      }
    });

    return Object.keys(errors).length !== 0 ? errors : null;
  } else {
    return null;
  }
}

export default validation;
