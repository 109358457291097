import { Box, Button, Card, CardContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React from "react";
import FormLayout from "../../components/form/FormLayout";
import ProductCreateViewModel from "../../viewmodels/product/create.viewmodel";
import { useTranslation } from "react-i18next";

const ProductCreate: React.FC = () => {
  const { t } = useTranslation();

  const { supplier, suppliers, categories, product, getCategory, getError, getSupplier, getErrorMessage, change, submit } = ProductCreateViewModel();

  const renderBrand = () => {
    if (!supplier) {
      return (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="size" size={"small"}>
                  {t("field.brand")}
                </InputLabel>
                <Select
                  labelId="size"
                  label={t("field.brand")}
                  value={product.brandid}
                  onChange={(e) => change("brandid", e.target.value as string)}
                  size={"small"}
                  error={getError("brandid") !== undefined}
                >
                  {suppliers.map((supplier) => (
                    <MenuItem value={supplier.id}>{supplier.name}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{getErrorMessage("brandid")}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <hr />
        </Grid>
      );
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <FormLayout
        PageTitle={t("title.products")}
        Breadcrumbs={[
          { title: t("title.dashboard"), url: "/" },
          { title: t("title.products"), url: "/products" },
          { title: t("title.createproduct") },
        ]}
      ></FormLayout>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1} paddingBottom={4}>
              <Grid item xs={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid xs={12} item>
                          <h5 className="subtext">{t("title.productdetails").toUpperCase()}</h5>
                        </Grid>
                        {renderBrand()}
                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              label={t("field.article")}
                              variant="outlined"
                              size="small"
                              value={product.article ? product.article : ""}
                              error={getError("article") !== undefined}
                              helperText={getErrorMessage("article")}
                              onChange={(e) => change("article", e.target.value)}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label={t("field.name") + "( EN)"}
                            variant="outlined"
                            size="small"
                            value={product.name_en ? product.name_en : ""}
                            error={getError("name_en") !== undefined}
                            helperText={getErrorMessage("name_en")}
                            onChange={(e) => change("name_en", e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label={t("field.name") + " (DE)"}
                            variant="outlined"
                            size="small"
                            value={product.name_de ? product.name_de : ""}
                            error={getError("name_de") !== undefined}
                            helperText={getErrorMessage("name_de")}
                            onChange={(e) => change("name_de", e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label={t("field.description") + " (EN)"}
                            variant="outlined"
                            size="small"
                            value={product.description_en ? product.description_en : ""}
                            onChange={(e) => change("description_en", e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label={t("field.description") + " (DE)"}
                            variant="outlined"
                            size="small"
                            value={product.description_de ? product.description_de : ""}
                            onChange={(e) => change("description_de", e.target.value)}
                            fullWidth
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label={t("field.warehouse")}
                            variant="outlined"
                            size="small"
                            value={product.warehouse ? product.warehouse : ""}
                            onChange={(e) => change("warehouse", e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label={t("field.productlink")}
                            variant="outlined"
                            size="small"
                            value={product.link ? product.link : ""}
                            onChange={(e) => change("link", e.target.value)}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel id="category" size={"small"}>
                              {t("field.category")}
                            </InputLabel>
                            <Select
                              labelId="category"
                              label={t("field.category")}
                              value={product.categoryid ? product.categoryid.toString() : ""}
                              onChange={(e) => change("categoryid", e.target.value as string)}
                              size={"small"}
                              error={getError("categoryid") !== undefined}
                            >
                              {categories.map((category, idx) => (
                                <MenuItem key={idx} value={category.id}>
                                  {category.text_en}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>{getErrorMessage("categoryid")}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" paddingTop={3}>
                        <Button type="submit" variant="contained" size="large">
                          {t("button.save")}
                        </Button>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default ProductCreate;
