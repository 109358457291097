import React, { useState } from "react";
import ColorDetailViewModel from "../../viewmodels/color/detail.viewmodel";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import Toggle from "../../components/toggle/toggle";
import { Color } from "../../models/color.model";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  mode: "add" | "edit";
  color: Color
}

const ColorDetails: React.FC<ContainerProps> = ({ mode, color }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { model, submit, change, getError, getErrorMessage, process } = ColorDetailViewModel(mode, color);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  const getButton = () => {
    if (mode === "add") {
      return (
        <Button variant="contained" size="medium" onClick={handleOpen}>
          {t('button.create')}
        </Button>
      );
    } else {
      return (
        <Button color="secondary" size="medium" onClick={handleOpen}>
        {t('button.open')}
        </Button>
      );
    }
  };

  const getTitle = () => {
    if (mode === "add") return t('text.createcolor');
    else return t('text.colordetails');
  };

  const getButtonTitle = () => {
    if (mode === "add") return t('button.create');
    else return t('button.update');
  };

  return (
    <React.Fragment>
      {getButton()}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <Box sx={headerStyle}>{getTitle().toUpperCase()}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label={t('field.description') + ' (EN)'}
                    variant="outlined"
                    size="small"
                    value={model.description}
                    error={getError("description") !== undefined}
                    helperText={getErrorMessage("description")}
                    onChange={(e) => change("description", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('field.description') + ' (DE)'}
                    variant="outlined"
                    size="small"
                    value={model.description_de}
                    error={getError("description_de") !== undefined}
                    helperText={getErrorMessage("description_de")}
                    onChange={(e) => change("description_de", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Hex Code"
                    variant="outlined"
                    size="small"
                    value={model.hex}
                    error={getError("hex") !== undefined}
                    helperText={getErrorMessage("hex")}
                    onChange={(e) => change("hex", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    label={t('field.arrangement')}
                    variant="outlined"
                    size="small"
                    value={model.arrangement}
                    error={getError("arrangement") !== undefined}
                    helperText={getErrorMessage("arrangement")}
                    onChange={(e) => change("arrangement", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Toggle value={model.active} label="ACTIVE" onChange={(value: boolean) => change("active", value)}></Toggle>
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} type="submit" disabled={process}>
                {getButtonTitle()}
              </Button>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={handleClose} disabled={process}>
                {t('button.cancel')}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

/*
function ColorDetail() {
  const { id } = useParams();

  let _model = {
    description: "",
    hex: "",
    arrangement: 0,
    active: true,
  };

  let _validate = {
    rules: [
      {
        field: "description",
        type: "required",
        message: "Description is Required",
      },
      {
        field: "arrangement",
        type: "valid",
        datatype: "number",
        message: "Not a Valid Number",
      },
    ],
    errors: {}, 
  };

  const [viewModel, dispatch] = useEditViewModel(
    _model,
    "colors",
    _validate,
    { open: "/colors" }
  );

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch({ type: ACTION.LOAD, id: id });
    }

    if (id) {
      setBreadcrumbs([
        { title: "Dashboard", url: "/" },
        { title: "Product Colors", url: "/colors" },
        { title: "Color Details" },
      ]);
    } else {
      setBreadcrumbs([
        { title: "Dashboard", url: "/" },
        { title: "Product Colors", url: "/colors" },
        { title: "Create Colors" },
      ]);
    }
  }, []);

  return(
    <React.Fragment>
      <FormLayout PageTitle="Product Colors" Breadcrumbs={breadcrumbs}></FormLayout>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          <EditForm OnSave={(e) => dispatch({ type: ACTION.SAVE })}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox />}
                label="Active"
                checked={viewModel.model.active}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "active", value: e.target.checked },
                  })
                }
              />
              <hr />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Description"
                required
                variant="outlined"
                size="small"
                value={viewModel.model.description}
                error={viewModel.validate.description !== undefined}
                helperText={viewModel.validate.description}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "description", value: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Hex"
                variant="outlined"
                size="small"
                value={viewModel.model.hex}
                error={viewModel.validate.hex !== undefined}
                helperText={viewModel.validate.hex}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "hex", value: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Arrangement"
                variant="outlined"
                size="small"
                value={viewModel.model.arrangement===0?"":viewModel.model.arrangement}
                error={viewModel.validate.arrangement !== undefined}
                helperText={viewModel.validate.arrangement}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "arrangement", value: e.target.value },
                  })
                }
                inputProps={{style: { textAlign: 'right' }}}
              />
            </Grid>
          </EditForm>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
*/
export default ColorDetails;
