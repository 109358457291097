import React from "react";
import FormLayout from "../../../components/form/FormLayout";
import ChangePasswordViewModel from "../../../viewmodels/account/changepassword/changepassword.viewmodel";
import { Box, Button, Card, CardContent, Grid, TextField } from "@mui/material";

function ChangePassword() {
  const { data, errors, onChange, onSubmit } = ChangePasswordViewModel();

  return (
    <React.Fragment>
      <FormLayout PageTitle="Change Password" Breadcrumbs={[{ title: "Dashboard", url: "/" }, { title: "Change Password" }]}></FormLayout>
      <Grid container direction="row" justifyContent="center" alignItems="top" spacing={1}>
        <Grid item xs={6}>
          <Box sx={{ flexGrow: 1 }}>
            <Card variant="outlined">
              <form onSubmit={onSubmit}>
                <CardContent>
                  <Grid container spacing={2} paddingTop={3}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Current Password"
                        variant="outlined"
                        size="small"
                        type="password"
                        value={data.password}
                        error={errors.password !== undefined}
                        helperText={errors.password}
                        onChange={(e) => onChange("password", e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} paddingTop={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="New Password"
                        variant="outlined"
                        size="small"
                        type="password"
                        value={data.newpassword}
                        error={errors.newpassword !== undefined}
                        helperText={errors.newpassword}
                        onChange={(e) => onChange("newpassword", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Confirm Password"
                        variant="outlined"
                        size="small"
                        type="password"
                        value={data.confirmpassword}
                        error={errors.confirmpassword !== undefined}
                        helperText={errors.confirmpassword}
                        onChange={(e) => onChange("confirmpassword", e.target.value)}
                      />
                    </Grid>
                    <Grid container spacing={2} paddingTop={4}>
                      <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
                        <Button type="submit" variant="contained" size="large">
                          Change Password
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ChangePassword;
