import React, { useState } from "react";
import ProductListViewModel from "../../viewmodels/product/list.viewmodel";
import FormLayout from "../../components/form/FormLayout";
import { Link } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "react-i18next";
import { reviewstatus, stockstatus } from "../../config";
import { Button, FormControl, Grid, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import TableLayout from "../../components/table/table";

interface ContainerProps {
  mode: "" | "moderate";
}

const Products: React.FC<ContainerProps> = ({ mode }) => {
  const { t } = useTranslation();

  const { tags, fields, productLists, setSearch, setFilter, searches } = ProductListViewModel(mode);

  const getTitle = () => {
    if (mode === "") return t("title.products");
    else return t("title.productstomoderate");
  };

  const handleKeyUp = (event: any) => {
    setSearch(event.target.value);
  };

  const renderFilter = () => {
    if (mode === "") {
      return (
        <FormControl fullWidth size="small">
          <InputLabel htmlFor="grouped-select">Filter</InputLabel>
          <Select defaultValue="" id="grouped-select" label="Filter" onChange={(e) => setFilter(e.target.value)}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"raceclubdeal"}>RaceClub Deals</MenuItem>
            <MenuItem value={"influencerdeal"}>Influencer Deals</MenuItem>
            <ListSubheader>Status</ListSubheader>
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"inactive"}>Inactive</MenuItem>
            <ListSubheader>Stock Status</ListSubheader>
            {stockstatus.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.value}
              </MenuItem>
            ))}
            <ListSubheader>Review Status</ListSubheader>
            {reviewstatus.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.value}
              </MenuItem>
            ))}
            <ListSubheader>Tags</ListSubheader>
            {tags.map((tag) => (
              <MenuItem key={tag.id} value={tag.code}>
                #{tag.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl fullWidth size="small">
          <InputLabel htmlFor="grouped-select">Filter</InputLabel>
          <Select defaultValue="" id="grouped-select" label="Filter" onChange={(e) => setFilter(e.target.value)}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"raceclubdeal"}>RaceClub Deals</MenuItem>
            <MenuItem value={"influencerdeal"}>Influencer Deals</MenuItem>
            <ListSubheader>Status</ListSubheader>
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"inactive"}>Inactive</MenuItem>
            <ListSubheader>Stock Status</ListSubheader>
            {stockstatus.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle={getTitle()} Breadcrumbs={[{ title: "Dashboard", url: "/" }, { title: getTitle() }]}></FormLayout>

      <Grid container sx={{ paddingBottom: 2 }}>
        <Grid xs={6} container item>
          <TextField
            id="outlined-search"
            size="small"
            label={t("text.search")}
            type="search"
            name="search"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onKeyUp={(e) => handleKeyUp(e)}
          />
        </Grid>
        <Grid xs={2} item sx={{ paddingInline: 1 }}>
          {renderFilter()}
        </Grid>
        <Grid xs={4} container direction="row" justifyContent="right" alignItems="center" item>
          <Button component={Link} to="/products/new" variant="contained" size="medium">
            {t("button.create")}
          </Button>
        </Grid>
      </Grid>

      <TableLayout fields={fields} data={searches !== undefined ? searches : productLists}></TableLayout>

    </React.Fragment>
  );
};

export default Products;
