import React, { useState } from "react";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import Toggle from "../../components/toggle/toggle";
import SupplierUserRegisterViewModel from "../../viewmodels/supplier/userregister.viewmodel";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  brandid: string;
}

const SupplierUserRegister: React.FC<ContainerProps> = ({ brandid }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { model, change, submit, getError, getErrorMessage, process } = SupplierUserRegisterViewModel(brandid);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  return (
    <React.Fragment>
      <Button variant="contained" size="medium" onClick={handleOpen}>
        {t("button.register")}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit()
            }}
          >
            <Box sx={headerStyle}>{t("text.registerbranduser")}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label={t("field.firstname")}
                    variant="outlined"
                    size="small"
                    value={model.firstname}
                    error={getError("firstname") !== undefined}
                    helperText={getErrorMessage("firstname")}
                    onChange={(e) => change("firstname", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t("field.lastname")}
                    variant="outlined"
                    size="small"
                    value={model.lastname}
                    error={getError("lastname") !== undefined}
                    helperText={getErrorMessage("lastname")}
                    onChange={(e) => change("lastname", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t("field.email")}
                    variant="outlined"
                    size="small"
                    type="email"
                    value={model.email}
                    error={getError("email") !== undefined}
                    helperText={getErrorMessage("email")}
                    onChange={(e) => change("email", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Toggle value={model.active} label="ACTIVE" onChange={(value: boolean) => change("active", value)}></Toggle>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t("field.password")}
                    variant="outlined"
                    size="small"
                    type="password"
                    value={model.password}
                    error={getError("password") !== undefined}
                    helperText={getErrorMessage("password")}
                    onChange={(e) => change("password", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t("field.confirm")}
                    variant="outlined"
                    size="small"
                    type="password"
                    value={model.confirm}
                    error={getError("confirm") !== undefined}
                    helperText={getErrorMessage("confirm")}
                    onChange={(e) => change("confirm", e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} type="submit" disabled={process}>
                {t("button.register").toUpperCase()}
              </Button>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={handleClose}>
                {t("button.cancel").toUpperCase()}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default SupplierUserRegister;
