import { useState } from "react";
import { Brand } from "../../models/brand.model";
import pass, { Validation } from "../../models/validation.model";
import { SupplierService } from "../../services/supplier.service";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function SupplierRegisterViewModel() {
    const [model, setModel] = useState(new Brand());
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [process, setProcess] = useState(false);

    const supplierService: SupplierService = new SupplierService();

    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar();

    const rules: any[] = [
        {
            field: "name",
            type: "required",
            message: "Brand Name is Required",
        }
    ];

    const change = (field: string, value: any) => {
        setModel({ ...model, [field]: value });
        if (submitted){
            isValid();
        }
    }

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = errors.find(x => x.field === field)?.message
        }
        return message;
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, model[validation.field as keyof typeof model]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const submit = async (descriptionEN: string, descriptionDE: string): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);
            setProcess(true);
            
            if (isValid() === false) {
                setProcess(false);
                resolve(false);
                return;
            }

            let data: Brand = model;
            data.description = descriptionEN;
            data.description_de = descriptionDE;

            let id: string = await supplierService.add(data);
            setSubmitted(false);
            setProcess(false);
            enqueueSnackbar("Brand Created", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            navigate('/brands/' + id);
        });
    }

    return { model, change, getError, getErrorMessage, submit, process }
}

export default SupplierRegisterViewModel;