import { Avatar, Box, Card, CardContent, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import SupplierContactListViewModel from "../../viewmodels/supplier/contactlist.viewmodel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SupplierContactDetail from "./supplier-contact-detail";
import { Contact } from "../../models/brand.model";
import { useTranslation } from "react-i18next";
import TableLayout from "../../components/table/table";

interface ContainerProps {
  id: string;
}

const SupplierContactList: React.FC<ContainerProps> = ({ id }) => {
  const { t } = useTranslation();

  const { fields, contacts } = SupplierContactListViewModel(id);

  return (
    <React.Fragment>
      <Grid container marginBottom={1}>
        <Grid item xs={12} container direction="row" alignItems="center" justifyContent="space-between">
          <h5 className="subtext mb-0">{t('title.contacts').toUpperCase()}</h5>
          <SupplierContactDetail mode="add" brandid={id} contact={new Contact()}></SupplierContactDetail>
        </Grid>
      </Grid>

      <TableLayout fields={fields} data={contacts}></TableLayout>
    </React.Fragment>
  );
};

export default SupplierContactList;
