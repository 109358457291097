import React, { useState } from "react";
import TagListViewModel from "../../viewmodels/tag/list.viewmodel";
import {
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import FormLayout from "../../components/form/FormLayout";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TagDetails from "./tag-detail";
import { Tag } from "../../models/tag.model";
import { useTranslation } from "react-i18next";
import TagArrangement from "./tag-arrangement";
import TableLayout from "../../components/table/table";

const TagList: React.FC = () => {
  const { t } = useTranslation();

  const { tags, tagList, searches, fields, setSearch } = TagListViewModel();

  const handleKeyUp = (event: any) => {
    setSearch(event.target.value);
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle={t("title.tags")} Breadcrumbs={[{ title: t("title.dashboard"), url: "/" }, { title: t("title.tags") }]}></FormLayout>

      <Grid container sx={{ paddingBottom: 2 }}>
        <Grid xs={6} container item>
          <TextField
            id="outlined-search"
            size="small"
            placeholder="Search"
            type="search"
            label={t("text.search")}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onKeyUp={(e) => handleKeyUp(e)}
          />
        </Grid>
        <Grid xs={6} container direction="row" justifyContent="right" alignItems="center" item>
          <TagDetails mode="add" tag={new Tag()}></TagDetails>
          {
            tags && <TagArrangement tags={tags}></TagArrangement>  
          }
        </Grid>
      </Grid>

      <TableLayout fields={fields} data={searches !== undefined ? searches : tagList}></TableLayout>
    </React.Fragment>
  );
};

export default TagList;
