import React, { useEffect, useState } from "react";
import { Tag } from "../../models/tag.model";
import { Box, Button, Modal } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface ContainerProps {
  tags: Tag[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid gray",
  boxShadow: 24,
};
const headerStyle = {
  bgcolor: "#1976d2",
  color: "white",
  p: 2,
  fontSize: "20px",
};
const detailStyle = {
  pl: 2,
  pr: 2,
  pt: 4,
  pb: 4,
};
const footerStyle = {
  p: 2,
  borderTop: "1px solid #eee",
  textAlign: "right",
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
  
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",
  
    // styles we need to apply on draggables
    ...draggableStyle
  });

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250,
});

const TagArrangement: React.FC<ContainerProps> = ({ tags }) => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<Tag[]>([]);

  useEffect(() => {
    setItems(tags);
  }, [tags]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Button variant="contained" size="medium" onClick={handleOpen} style={{ marginLeft: "10px" }}>
        ARRANGEMENT
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={headerStyle}>Tag Arrangement</Box>
          <Box sx={detailStyle}>
            <DragDropContext onDragEnd={(e) => console.log(e)}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {items.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            {item.code}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default TagArrangement;
