import { addDoc, collection, doc, getFirestore, onSnapshot, orderBy, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import { BaseService } from './baseservice';
import { BuyOnlyDeal, DealPostBriefing, FanfluencerDeal, Product, Specs } from '../models/product.model';

export class ProductService extends BaseService {
    colname = "products";

    constructor() {
        super("products", "");
        this.firestore = getFirestore(this.app);
    }

    getProducts = (mode: '' | 'moderate'): Promise<Product[]> => {
        return new Promise((resolve) => {
            let col = collection(this.firestore, this.colname);
            let constraints = [];
            let data: any[] = [];
            if (mode === 'moderate') constraints.push(where('review', '==', 'pending'));
            constraints.push(orderBy('created', 'desc'));
            const q = query(col, ...constraints);
            onSnapshot(q, snap => {
                snap.docs.forEach(doc => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    })
                });
                resolve(data);
            })
        })
    }

    getBrand = (mode: '' | 'moderate', brandid: string): Promise<Product[]> => {
        return new Promise((resolve) => {
            let col = collection(this.firestore, this.colname);
            let constraints = [];
            let data: any[] = [];
            constraints.push(where('brandid', '==', brandid));
            if (mode === 'moderate') constraints.push(where('review', '==', 'pending'));
            constraints.push(orderBy('created', 'desc'));
            const q = query(col, ...constraints);
            onSnapshot(q, snap => {
                snap.docs.forEach(doc => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    })
                });
                resolve(data);
            })
        })
    }

    add = (product: Product): Promise<string> => {
        return new Promise(resolve => {
            const col = collection(this.firestore, this.colname);
            addDoc(col, {
                name_en: product.name_en ? product.name_en : '',
                name_de: product.name_de ? product.name_de : '',
                description_en: product.description_en ? product.description_en : '',
                description_de: product.description_de ? product.description_de : '',
                tags: product.tags ? product.tags : [],
                brandid: product.brandid ? product.brandid : '',
                article: product.article ? product.article : '',
                active: product.active ? product.active : false,
                warehouse: product.warehouse ? product.warehouse : '',
                link: product.link ? product.link : '',
                categoryid: product.categoryid ? product.categoryid : '',
                originalprice: product.originalprice ? product.originalprice : 0,
                stockstatus: product.stockstatus ? product.stockstatus : 'out-of-stock',
                buyonly: product.buyonly ? product.buyonly : false,
                fanfluencerdeal: product.fanfluencerdeal ? product.fanfluencerdeal : false,
                review: 'pending',
                created: serverTimestamp(),
                createdby: this.userid
            }).then(value => {
                resolve(value.id);
            });
        })
    }

    update = (id: string, product: Product): Promise<void> => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        return updateDoc(docs, {
            name_en: product.name_en ? product.name_en : '',
            name_de: product.name_de ? product.name_de : '',
            description_en: product.description_en ? product.description_en : '',
            description_de: product.description_de ? product.description_de : '',
            tags: product.tags ? product.tags : [],
            brandid: product.brandid ? product.brandid : '',
            article: product.article ? product.article : '',
            active: product.active ? product.active : false,
            warehouse: product.warehouse ? product.warehouse : '',
            link: product.link ? product.link : '',
            categoryid: product.categoryid ? product.categoryid : '',
            originalprice: product.originalprice ? product.originalprice : 0,
            buyonly: product.buyonly ? product.buyonly : false,
            fanfluencerdeal: product.fanfluencerdeal ? product.fanfluencerdeal : false,
            modified: serverTimestamp(),
            modifiedby: this.userid
        })
    };

    updateSpecs = (id: string, specs: Specs[]): Promise<void> => {
        let data: any[] = [];
        specs.forEach(spec => {
            data.push({
                id: spec.id,
                article: spec.article ? spec.article : "",
                color: spec.color,
                size: spec.size,
                image: spec.image,
                stock: Number(spec.stock),
                sold: Number(spec.sold),
                isDeleted: spec.isDeleted ? spec.isDeleted : false
            })
        })
        const docs = doc(this.firestore, this.colname + '/' + id);
        return updateDoc(docs, {
            specs: data,
            modified: serverTimestamp(),
            modifiedby: this.userid
        });
    }

    updateBuyOnlyDeals = (id: string, deals: BuyOnlyDeal[]): Promise<void> => {
        let values: any[] = [];
        deals.forEach(deal => {
            values.push({
                id: deal.id, amount: deal.amount, percent: deal.percent, price: deal.price
            })
        });
        const docs = doc(this.firestore, this.colname + '/' + id);
        return updateDoc(docs, {
            buyonlydeals: values,
            modified: serverTimestamp(),
            modifiedby: this.userid
        });
    }

    updateFanfluencerDeals = (id: string, deals: FanfluencerDeal[]): Promise<void> => {
        let values: any[] = [];
        deals.forEach(deal => {
            values.push({
                id: deal.id, type: deal.type, discount: deal.discount, enable: deal.enable
            })
        });
        const docs = doc(this.firestore, this.colname + '/' + id);
        return updateDoc(docs, {
            fanfluencerdeals: values,
            modified: serverTimestamp(),
            modifiedby: this.userid
        });
    }

    updateModeration = (id: string, review: "pending" | "approved" | "rejected", reason: string): Promise<void> => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        return updateDoc(docs, {
            review: review,
            reason: reason,
            modified: serverTimestamp(),
            modifiedby: this.userid
        });
    }

    updateBriefing = (id: string, briefing: DealPostBriefing): Promise<void> => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        return updateDoc(docs, {
            dealbriefing: {
                dealphotodetails_en: briefing.dealphotodetails_en ? briefing.dealphotodetails_en : "",
                dealphotodetails_de: briefing.dealphotodetails_de ? briefing.dealphotodetails_de : "",
                dealtextdetails_en: briefing.dealtextdetails_en ? briefing.dealtextdetails_en : "",
                dealtextdetails_de: briefing.dealtextdetails_de ? briefing.dealtextdetails_de : "",
                dealhashtagdetails_en: briefing.dealhashtagdetails_en ? briefing.dealhashtagdetails_en : "",
                dealhashtagdetails_de: briefing.dealhashtagdetails_de ? briefing.dealhashtagdetails_de : "",

            },
            modified: serverTimestamp(),
            modifiedby: this.userid
        });
    }

    updateModified = (id: string): Promise<void> => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        return updateDoc(docs, {
            modified: serverTimestamp()
        })
    };
}
