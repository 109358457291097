import { TagService } from './../../services/tag.service';
import { useEffect, useState } from "react";
import { Brand } from "../../models/brand.model";
import { SupplierService } from "../../services/supplier.service";
import { Product } from "../../models/product.model";
import pass, { Validation } from '../../models/validation.model';
import { Category } from '../../models/category.model';
import { CategoryService } from '../../services/category.service';
import { ProductService } from "../../services/product.service";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StorageService } from "../../services/storage.service";
import { Tag } from '../../models/tag.model';

function ProductCreateViewModel() {
    const { t } = useTranslation();

    const [product, setProduct] = useState(new Product());
    const [supplier, setSupplier] = useState('');
    const [suppliers, setSupplliers] = useState<Brand[]>([]);
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [categories, setCategories] = useState<Category[]>([]);

    const productService: ProductService = new ProductService();
    const supplierService: SupplierService = new SupplierService();
    const categoryService: CategoryService = new CategoryService();
    const tagService: TagService = new TagService();

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const rules = [
        {
            field: "brandid",
            type: "required",
            message: "error.brandrequire",
        },
        {
            field: "article",
            type: "required",
            message: "error.articlerequire",
        },
        {
            field: "name_en",
            type: "required",
            message: "error.nameisrequired",
        },
        {
            field: "name_de",
            type: "required",
            message: "error.nameisrequired",
        },
        {
            field: "categoryid",
            type: "required",
            message: "error.categoryrequire",
        },
    ];

    useEffect(() => {
        loadStorageSupplier();
        loadCategories();
        loadSuppliers();
    }, [])

    useEffect(() => {
        if (submitted) {
            isValid();
        }
    }, [product])

    const loadStorageSupplier = () => {
        const storageService: StorageService = new StorageService();
        if (storageService.isUserLogin()) {
            if (storageService.isSupplier()) {
                let data = storageService.getSaveLogin();
                if (data) {
                    setSupplier(data.supplier.id);
                    change("brandid", data.supplier.id);
                }
            }
        }
    }

    const loadCategories = async () => {
        let data = await categoryService.get() as Category[];
        setCategories(data);
    }

    const loadSuppliers = async () => {
        let data: Brand[] = await supplierService.get() as Brand[];
        setSupplliers(data);
    }

    const getCategory = (id: string) => {
        return categories.find(x => x.id === id);
    }

    const getSupplier = (id: string) => {
        return suppliers.find(x => x.id === id);
    }

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message);
        }
        return message;
    }

    const change = (field: string, value: any) => {
        setProduct({ ...product, [field]: value });
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, product[validation.field as keyof typeof product]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const submit = async () => {
        setSubmitted(true);

        if (isValid() === false) {
            return;
        }

        let tags: Tag[] = (await tagService.get()) as Tag[];
        if (tags.findIndex(x => x.code === 'new') >= 0){
            product.tags = ['new'];
        }
        
        let id: string = await productService.add(product);
        enqueueSnackbar("Product Created", {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
        setSubmitted(false);
        navigate("/products/" + id);
    }

    return { supplier, suppliers, categories, product, getCategory, getSupplier, getError, getErrorMessage, change, submit }
}

export default ProductCreateViewModel;