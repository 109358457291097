import React from "react";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
import "./FormLayout.css";

function FormLayout(props) {
  return (
    <React.Fragment>
      <h3 className="pagetitle">{props.PageTitle}</h3>
      <Breadcrumbs datas={props.Breadcrumbs} />
    </React.Fragment>
  );
}

export default FormLayout;
