import { useEffect, useState } from "react";
import validation from "../../../views/shared/valdiation";
import { AccountService } from "../../../services/account.service";
import { useSnackbar } from "notistack";
import { StorageService } from "../../../services/storage.service";

function ChangePasswordViewModel() {
  const [email, setEmail] = useState("");
  const [data, setData] = useState({
    password: "",
    newpassword: "",
    confirmpassword: "",
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false); 
  const { enqueueSnackbar } = useSnackbar();

  const snackbarSuccess = {
    variant: "success",
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  let rules = [
    {
      field: "password",
      type: "required",
      message: "Password is Required",
    },
    {
      field: "newpassword",
      type: "required",
      message: "New Password is Required",
    },
    {
      field: "confirmpassword",
      type: "required",
      message: "Confirm Password is Required",
    },
    {
      field: "password",
      type: "minlength",
      value: 5,
      message: "Password Minimum Length is 5 characters",
    },
    {
      field: "newpassword",
      type: "minlength",
      value: 5,
      message: "New Password Minimum Length is 5 characters",
    },
    {
      field: "confirmpassword",
      type: "minlength",
      value: 5,
      message: "Confirm Password Minimum Length is 5 characters",
    },
    {
      field: "newpassword",
      other: "confirmpassword",
      type: "same",
      message: "Please Confirm Password",
    },
    {
      field: "confirmpassword",
      other: "newpassword",
      type: "same",
      message: "Please Confirm Password",
    },
  ];

  useEffect(() => {
    const storageService = new StorageService();
    let data = storageService.getSaveLogin();
    if (data) setEmail(data.data.email);
  }, []);

  const onChange = (field, value) => {
    let values = data;
    data[field] = value;
    setData((data) => ({
      ...values,
    }));
    if (submitted) {
      validation(rules, values).then((response) => {
        setErrors(response === null ? {} : response);
      });
    }
  };

  const changepassword = () => {
    let accountService = new AccountService();
    accountService
      .authenticate(email, data.password)
      .then((authen) => {
        accountService.changepassword(authen.user, data.newpassword).then(() => {
            clear();
            enqueueSnackbar("Password Updated", snackbarSuccess);
        })
      })
      .catch((err) => {
        setErrors({
          password: "Invalid Password",
        });
      });
  };

  const onSubmit = (e) => {
    setSubmitted(true);
    e.preventDefault();
    validation(rules, data).then((response) => {
      setErrors(response === null ? {} : response);
      if (response === null) {
        changepassword();
      }
    });
  };

  const clear = () => {
    setData({
        password: "",
        newpassword: "",
        confirmpassword: "",
      })
  }

  return { data, errors, onChange, onSubmit };
}

export default ChangePasswordViewModel;
