import { Timestamp } from "@google-cloud/firestore";

export class Notification {
    public id: string;
    public type: "product-create" | "product-update" | "product-moderation" | "account-create" | "account-update" | "transaction-create" | "transaction-update";
    public to: "admin" | "brand";
    public brandid: string;
    public data: ProductCreate | ProductUpdate | AccountCreate | AccountUpdate | TransactionCreate | TransactionUpdate;
    public datetime: Timestamp;
    public read: boolean;
}

export class ProductCreate {
    public id: string;
    public name_en: string;
    public name_de: string;
    public brand: string;
}

export class ProductUpdate {
    public id: string;
    public name_en: string;
    public name_de: string;
    public brand: string;
    public fields: FieldChange[];
}

export class ProductModeration {
    public id: string;
    public name_en: string;
    public name_de: string;
    public brand: string;
    public old: 'pending' | 'approved' | 'rejected';
    public new: 'pending' | 'approved' | 'rejected';
    public reason: string;
}

export class AccountCreate {
    public id: string;
    public email: string;
    public firstname: string;
    public lastname: string;
    public license: string;
    public official: string;
}

export class AccountUpdate {
    public id: string;
    public email: string;
    public firstname: string;
    public lastname: string;
    public fields: FieldChange[];
}

export class TransactionCreate {
    public id: string;
    public customeremail: string;
    public customername: string;
    public brand: string;
    public productnames: string[];
    public type: "raceclubdeal" | "influencerdeal"; 
    public status: string;
}

export class TransactionUpdate {
    public id: string;
    public customeremail: string;
    public customername: string;
    public brand: string;
    public productnames: string[];
    public type: "raceclubdeal" | "influencerdeal"; 
    public oldstatus: string
    public newstatus: string;
    public reason: string;
}

export class FieldChange {
    public fieldname: string;
    public old: any;
    public new: any;
    public datatype: 'string' | 'number' | 'boolean' | 'timestamp' | 'custom';
}

export class NotificationDisplay {
    public id: string;
    public type: "product-create" | "product-update" | "product-moderation" | "account-create" | "account-update" | "transaction-create" | "transaction-update";
    public to: "admin" | "brand"
    public brandid: string;
    public data: any[];
    public datetime: Timestamp;
    public read: boolean;
}