import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./ImageView.css";
import { ImageService } from "../../services/image.service";

interface ContainerProps {
  image: string;
}

const ImageView: React.FC<ContainerProps> = ({ image }) => {
  
  const [mode, setMode] = useState("loading");
  const [path, setPath] = useState('');
  
  useEffect(() => {

    setPath("");
    setMode("loading");

    if (image) {
      const service: ImageService = new ImageService();
      service.getURL(image).then((path) => {
        setPath(path);
        setMode("loaded");
      });
    }
  }, [image]);

  return (
    <React.Fragment>
      <Box className="imageview-img" style={mode === "loading" ? {} : { display: "none" }}>
        <CircularProgress size={12} />
      </Box>
      <Box className="imageview-img" style={mode === "loaded" ? {} : { display: "none" }}>
        <img src={path} className="imageviewimage" alt="" />
      </Box>
    </React.Fragment>
  );
};

export default ImageView;
