import { initializeApp } from "firebase/app";
import firebaseConfig from "../config";
import { BaseService } from "./baseservice";
import { ProductCard } from "../models/setting.model";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";

export class SettingService extends BaseService {
    app = initializeApp(firebaseConfig);

    constructor() {
        super("settings", "");
    }

    colname = "settings";

    updateCardSetting = (id: string, value: ProductCard) => {
        new Promise<void>((resolve) => {
            const docs = doc(this.firestore, this.colname + "/" + id);
            setDoc(docs, {
                id: value.id,
                card: {
                    background: value.card.background,
                    border: value.card.border
                },
                tag: {
                    background: value.tag.background,
                    font: value.tag.font
                },
                modified: serverTimestamp(),
                modifiedby: this.userid
            }).then(() => {
                resolve();
            });
        });
    }

}