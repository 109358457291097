import { useEffect, useState } from "react";
import { ProductService } from "../../services/product.service";
import { Product } from "../../models/product.model";
import { useSnackbar } from "notistack";

function ProductImagesViewModel(id: string) {
    const [images, setImages] = useState<string[]>([]);
    const [primary, setPrimary] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    const productService: ProductService = new ProductService();

    useEffect(() => {
        if (id) {
            productService.getById(id).then(product => {
                let data: Product = product as Product;
                let list: string[] = data.images ? data.images : [];
                list.push("");
                setImages(list);
                setPrimary(data.primary);
            });
        }
    }, [id]);

    const addimage = async (path: string) => {
        if (path !== '') {
            let list: string[] = images.filter(x => x !== '');
            list.push(path);
            await productService.updateField(id, 'images', list);
            list.push("");
            setImages(list);
            if (!primary || primary === '') {
                await productService.updateField(id, 'primary', path);
                setPrimary(path);
            }
            enqueueSnackbar("Image Added", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
        }
    }

    const change = (image: string): Promise<void> => {
        return new Promise(async resolve => {
            await productService.updateField(id, 'primary', image);
            setPrimary(image);
            enqueueSnackbar("Primary Image Updated", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            resolve()
        })
    }

    const remove = (image: string): Promise<void> => {
        return new Promise(async resolve => {
            let list: string[] = images.filter(x => x !== '').filter(x => x !== image);
            await productService.updateField(id, 'images', list);
            list.push("");
            setImages(list);
            if (primary === image){
                if (list.length === 0) {
                    setPrimary("");
                    await productService.updateField(id, 'primary', "");
                }
                else{
                    setPrimary(list[0]);
                    await productService.updateField(id, 'primary', list[0]);
                }
            }
            enqueueSnackbar("Image Deleted", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });;
            resolve()
        })
    }

    return { images, primary, addimage, change, remove }
}

export default ProductImagesViewModel;