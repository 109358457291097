import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function Toggle(props) {
  const [on, setOn] = useState(false);
  const [label, setLabel] = useState("");

  useEffect(() => {
    if (props.value !== undefined) setOn(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.label !== undefined) setLabel(props.label);
  }, [props.label]);

  const click = (value) => {
    setOn(value);
    props.onChange(value);
  }

  const render = () => {
    if (on){
        return <Chip icon={<CheckCircleOutlineIcon />} label={label} color="info" onClick={(e) => click(false)} style={{marginRight: '10px'}}></Chip>
    }
    else{
        return <Chip icon={<HighlightOffIcon />} label={label} onClick={(e) => click(true)}  style={{marginRight: '10px'}}></Chip>
    }
  }

  return render();
}

export default Toggle;
