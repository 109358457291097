export class Category {
  public id: string;
  public code: string;
  public text_en: string;
  public text_de: string;
  public image: string;
  public orderno: string;
  public active: boolean;
}

export class CategoryList {
  public id: string;
  public code: string;
  public text_en: string;
  public text_de: string;
  public orderno: string;
  public active: boolean;
  public action: any;
}