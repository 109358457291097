import { Box, Button, Grid, MenuItem, Modal, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fanfluencers } from "../../config";
import { useTranslation } from "react-i18next";
import { Customer, CustomerList } from "../../models/customer.model";

interface ContainerProps {
  customer: Customer;
  onRatingsChange: (id: string, followers: number, ratings: string) => void;
}

const CustomerRatings: React.FC<ContainerProps> = ({ customer, onRatingsChange }) => {
  const { t } = useTranslation();

  const subtext = {
    fontWeight: "300",
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  const [open, setOpen] = useState(false);
  const [model, setModel] = useState<Customer>(customer);
  const [followers, setFollowers] = useState(customer.followers);
  const [ratings, setRatings] = useState(customer.ratings);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setModel(customer)
  }, [customer])

  useEffect(() => {
    setRatings(getRatings(followers));
  }, [followers]);

  const getRatings = (follower: number) => {
    let value: string = "";
    fanfluencers.forEach((x) => {
      if (x.minimum <= follower && x.maximum >= follower) {
        value = x.id;
      }
    });
    return value;
  };

  return (
    <React.Fragment>
      <MenuItem onClick={handleOpen}>Update Followers</MenuItem>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={headerStyle}>{t("text.customerratings")}</Box>
          <Box sx={detailStyle}>
            <Box paddingBottom={1}>
              <span style={subtext}>{t("text.updatefollowersfor")} </span>
              <span>
                {model.firstname && model.lastname? model.firstname + ' ' + model.lastname: model.email}
              </span>
            </Box>
            <Box paddingBottom={1}>
              <span style={subtext}>{t("field.followers")}: </span>
              <span>{model.followers ? model.followers : 0}</span>
            </Box>
            <Box>
              <span style={subtext}>{t("field.ratings")}: </span>
              <span>{fanfluencers.find((x) => x.id === model.ratings)?.display}</span>
            </Box>
            <hr />
            <Grid container paddingBottom={1}>
              <Grid item xs={4} height={"100%"} paddingY={1} textAlign={"center"}>
                {t("field.followers")}
              </Grid>
              <Grid item xs={8} container justifyContent={"right"}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={Number(followers ? followers : '')}
                  onChange={(e) => {
                    setFollowers(Number(e.target.value));
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} height={"100%"} paddingY={1} textAlign={"center"}>
                {t("field.ratings")}
              </Grid>
              <Grid item xs={8} padding={1} border={1} borderRadius={1} borderColor={"lightgray"}>
                {fanfluencers.find((x) => x.id === ratings)?.display}
              </Grid>
            </Grid>
          </Box>
          <Box sx={footerStyle}>
            <Button variant="outlined" size="medium" sx={{ ml: 1 }} onClick={(e) => {
              onRatingsChange(model.id, followers, ratings);
              setOpen(false);
            }}>
              {t("button.confirm")}
            </Button>
            <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={(e) => {
              setFollowers(model.followers);
              setRatings(model.ratings);
              setOpen(false);
            }}>
              {t("button.cancel")}
            </Button>
          </Box>

          {/*  <div className="row p-4 m-0" style={header}>
            <div className="col-6">
              <label>Customer Status</label>
            </div>
            <div className="col-6 text-end">
              <CloseIcon onClick={handleClose}></CloseIcon>
            </div>
          </div>
          <div className="row p-4 m-0">
            <div className="col-12 mb-2">
                Ratings: {getFanfluencer(ratings)}
            </div>
            <div className="col-7 my-auto">
              <label style={title}>Update followers for {name}</label>
            </div>
            <div className="col-5 text-end my-auto">
              <TextField fullWidth label="" variant="outlined" size="small" type="number" value={tempFollowers} onChange={handleChange} />
            </div>
            <div className="col-12 text-center mt-5">
              <Button variant="outlined" onClick={handleConfirm}>
                CONFIRM
              </Button>
            </div>
          </div>
  */}
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerRatings;
