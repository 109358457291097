import { Navbar, Container, Nav } from "react-bootstrap";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import imagePath1 from "../../assets/img/logo.png";
import germanflag from "../../assets/img/de.png";
import englishflag from "../../assets/img/en.png";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StorageService } from "../../services/storage.service";
import Notif from "../notifications/notif";

function Header(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState("USERNAME");
  const [supplier, setSupplier] = useState("");

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const { t, i18n } = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (e) => {
    if (anchorRef.current && anchorRef.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    const storageService = new StorageService();
    if (storageService.isUserLogin()) {
      let data = storageService.getSaveLogin();
      if (data) setUser(data.data.firstname);
      if (storageService.isSupplier()) setSupplier(data.supplier.name);
    }
  }, []);

  const handleLogout = (e) => {
    localStorage.removeItem("raceclub");
    navigate("/login");
  };

  const onChangeLanguage = async () => {
    props.onChangeLanguage(i18n.language === "en" ? "de" : "en");
    
  };

  return (
    <Navbar bg="white" expand="lg" fixed="top">
      <Container fluid>
        <Navbar.Brand onClick={(e) => navigate("/")} className="title">
          <img id="my-logo" src={imagePath1} alt="" />
        </Navbar.Brand>
        <label>{supplier}</label>
        <Nav className="justify-content-end">
          <Button onClick={onChangeLanguage}>
            <img src={i18n.language === "en" ? germanflag : englishflag} style={{ height: "20px", marginRight: "5px" }} />{i18n.language === "en" ? "DE" : "EN"}
          </Button>
          <Button
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            {user}
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
                      <MenuItem onClick={(e) => navigate("/profile")}>{t("text.profile")}</MenuItem>
                      <MenuItem onClick={(e) => navigate("/changepassword")}>{t("text.changepassword")}</MenuItem>
                      <MenuItem onClick={handleLogout}>{t("text.logout")}</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Notif></Notif>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Header;
