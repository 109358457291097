import React from "react";
import CategoryListViewModel from "../../viewmodels/category/list.viewmodel";
import FormLayout from "../../components/form/FormLayout";
import {
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CategoryDetails from "./category-detail";
import { Category } from "../../models/category.model";
import { useTranslation } from "react-i18next";
import TableLayout from "../../components/table/table";

const CategoryList: React.FC = () => {
  const { t } = useTranslation();

  const { categoryList, searches, fields, setSearch } = CategoryListViewModel();

  const handleKeyUp = (event: any) => {
    setSearch(event.target.value);
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle={t('title.categories')} Breadcrumbs={[{ title: t('title.dashboard'), url: "/" }, { title: t('title.categories') }]}></FormLayout>

      <Grid container sx={{ paddingBottom: 2 }}>
        <Grid xs={6} container item>
          <TextField
            id="outlined-search"
            size="small"
            label={t('text.search')}
            type="search"
            name="search"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onKeyUp={(e) => handleKeyUp(e)}
          />
        </Grid>
        <Grid xs={6} container direction="row" justifyContent="right" alignItems="center" item>
          <CategoryDetails mode="add" category={new Category()}></CategoryDetails>
        </Grid>
      </Grid>

      <TableLayout fields={fields} data={searches !== undefined ? searches : categoryList}></TableLayout>
    </React.Fragment>
  );
};
/*
function CategoryList() {
  const [viewModel, dispatch] = useListViewModel(
    "categories",
    [],
    ["code", "text_en", "text_de"]
  );

  const onSearch = (event) => {
    event.preventDefault();
    dispatch({
      type: ACTION.SEARCH,
      search: event.target.search.value,
    });
  };

  useEffect(() => {
    dispatch({ type: ACTION.GETALLCOUNT });
    dispatch({ type: ACTION.GETALL });
  }, []);

  return (
    <React.Fragment>
      <FormLayout
        PageTitle="Categories"
        Breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Categories" },
        ]}
      ></FormLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Card variant="outlined">
          <CardContent>
            <Grid container>
              <Grid xs={6} container item>
                <form onSubmit={onSearch} style={{ width: "100%" }}>
                  <TextField
                    id="outlined-search"
                    size="small"
                    placeholder="Search"
                    type="search"
                    name="search"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Grid>
              <Grid
                xs={6}
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
                item
              >
                <Button
                  component={Link} to={"/categories/new"}
                  variant="contained"
                  size="medium"
                >
                  CREATE
                </Button>
              </Grid>
            </Grid>
            <ListForm
              {...viewModel}
              columnDefinition={[
                { label: "CODE", field: "code", align: "left", format: "" },
                {
                  label: "ORDER NO",
                  field: "orderno",
                  align: "left",
                  format: "",
                },
                {
                  label: "TEXT (EN)",
                  field: "text_en",
                  align: "left",
                  format: "",
                },
                {
                  label: "TEXT (DE)",
                  field: "text_de",
                  align: "left",
                  format: "",
                },
                {
                  label: "STATUS",
                  field: "active",
                  align: "center",
                  format: "",
                  datatype: Boolean,
                  values: ["Active", "Inactive"],
                },
                {
                  label: "ACTION",
                  field: "button",
                  align: "right",
                  format: "",
                  link: "/categories/",
                },
              ]}
              OnNewPage={(event, newPage) => {
                dispatch({ type: ACTION.SETPAGENO, pageNo: newPage });
              }}
              onRowsPerPageChange={(event) => {
                dispatch({
                  type: ACTION.SETROWSPERPAGE,
                  rowsPerPage: parseInt(
                    event.target.value,
                    viewModel.formState.rowsPerPage
                  ),
                });
              }}
            />
          </CardContent>
        </Card>
      </Box>
    </React.Fragment>
  );
}
*/
export default CategoryList;
