import { Box, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import ProductBuyOnlyDealViewModel from "../../../viewmodels/product/buyonlydeal.viewmodel";
import ProductBuyOnly from "./product-buyonly";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  id: string;
  price: number;
}

const ProductBuyOnlyDeal: React.FC<ContainerProps> = ({ id, price }) => {
  const { t } = useTranslation();

  const { buyonlymax, buyonlydeals, originalprice, setPrice, save, savemax } = ProductBuyOnlyDealViewModel(id);
  const maxOption = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    setPrice(price)
  }, [price])

  return (
    <React.Fragment>
      <Grid container spacing={1} marginTop={1}>
        <Grid xs={10} item>
          <h5 className="subtext">RACECLUB DEALS</h5>
        </Grid>
        <Grid xs={2} item container justifyContent={"right"}>
          <TextField
            label={t("field.buyonlymax")}
            type="number"
            variant="outlined"
            size="small"
            value={buyonlymax}
            onChange={(e) => {
              var val = Number(e.target.value);
              if (val === 0) {
                val = 1;
              }
              savemax(val);
            }}
            fullWidth
          />
          {/* 
            <FormControl fullWidth>
            <InputLabel id="category" size={"small"}>
              {t('field.buyonlymax')}
            </InputLabel>
            <Select
              labelId="size"
              label={t('field.buyonlymax')}
              value={buyonlymax}
              onChange={(e) => {
                var val = Number(e.target.value);
                if (val === 0) {
                  val = 1;
                }
                savemax(val);
              }}
              size={"small"}
            >
              {maxOption.map((opt, id) => (
                <MenuItem value={opt} key={id}>{opt}</MenuItem>
              ))}
            </Select>
          </FormControl>
            */}

        </Grid>
      </Grid>
      <Grid container spacing={1} paddingTop={2} columns={10}>
        {buyonlydeals.map((x, id) => {
          return (
            <Grid item xs={2} key={id}>
              <ProductBuyOnly deal={x} originalprice={originalprice} save={save}></ProductBuyOnly>
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

export default ProductBuyOnlyDeal;
