import React, { useEffect, useState } from "react";
import FormLayout from "../../components/form/FormLayout";
import { useLocation, useParams } from "react-router-dom";
import SupplierDetailsViewModel from "../../viewmodels/supplier/details.viewmodel";
import { Button, Grid, Tab, Tabs, TextField } from "@mui/material";
import Upload from "../../components/upload/Upload";
import SupplierPaymentDetail from "./supplier-payment-detail";
import SupplierContactList from "./supplier-contact-list";
import SupplierUserList from "./supplier-user-list";
import Toggle from "../../components/toggle/toggle";
import { useTranslation } from "react-i18next";
import SupplierEmails from "./supplier-emails";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./supplier-detail.css"
import red from "@mui/material/colors/red";
import Chips from "../../components/chips/chips";

const SupplierDetail: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();

  const { model, categories, change, submit, uploadImage, getError, getErrorMessage, process } = SupplierDetailsViewModel(id as string);

  const [tabSelected, setTabSelected] = React.useState("details");
  const [descriptionEN, setDescriptionEN] = useState("");
  const [descriptionDE, setDescriptionDE] = useState("");

  useEffect(() => {
    setDescriptionEN(model.description);
  }, [model.description])

  useEffect(() => {
    setDescriptionDE(model.description_de);
  }, [model.description_de])

  const getCategoryDiv = () => {
    return (
      <Grid item xs={12}>
        <Chips
          tags={categories}
          id="id"
          value="text_en"
          selected={model.categories}
          update={(categories: any[]) => change("categories", categories)}
        ></Chips>
      </Grid>
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabSelected(newValue);
  };

  const renderBreadcrumbs = () => {
    if (location.pathname.indexOf("/mydetails") >= 0)
      return (
        <FormLayout
          PageTitle={t("title.mydetails")}
          Breadcrumbs={[{ title: t("title.dashboard"), url: "/" }, { title: t("title.mydetails") }]}
        ></FormLayout>
      );
    else
      return (
        <FormLayout
          PageTitle={t("title.brands")}
          Breadcrumbs={[{ title: t("title.dashboard"), url: "/" }, { title: t("title.brands"), url: "/brands" }, { title: t("title.brandprofile") }]}
        ></FormLayout>
      );
  };

  return (
    <React.Fragment>
      {renderBreadcrumbs()}

      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <span>ID: {model ? model.id : ""}</span>
        <Tabs value={tabSelected} onChange={handleChange}>
          <Tab value="details" label={t("title.details")} />
          <Tab value="contacts" label={t("title.contacts")} />
          <Tab value="users" label={t("title.users")} />
          <Tab value="payment" label="Payment" />
          <Tab value="emails" label={t("title.emails")} />
        </Tabs>
      </div>

      <div hidden={tabSelected !== "details"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submit(descriptionEN, descriptionDE);
          }}
        >
          <Grid container>
            <Grid item xs={12} container direction="row" alignItems="center" justifyContent="space-between">
              <h5 className="subtext mb-0">{t('title.details').toUpperCase()}</h5>
              <Toggle
                value={model.active}
                label={model.active ? "Active".toUpperCase() : "Inactive".toUpperCase()}
                onChange={(value: boolean) => change("active", value)}
              ></Toggle>
            </Grid>
            <hr />
            <Grid item xs={12} container spacing={2} rowGap={2} marginTop={0}>
              <Grid item xs={2}>
                <div style={{ width: "100%", aspectRatio: "1/1", border: "1px solid lightgray" }}>
                  <Upload category={"brands"} id={id} image={model.image} onSetImage={(image: string) => uploadImage(image)}></Upload>
                </div>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={1} rowGap={1}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.brandname")}
                      type="text"
                      variant="outlined"
                      size="small"
                      value={model.name || ""}
                      error={getError("name") !== undefined}
                      helperText={getErrorMessage("name")}
                      onChange={(e) => change("name", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.website")}
                      type="url"
                      variant="outlined"
                      size="small"
                      value={model.website || ""}
                      onChange={(e) => change("website", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label style={{ paddingBottom: "5px" }}>{t("field.description") + " (EN)"}</label>
                    <div>
                      <ReactQuill theme="snow" placeholder="Enter Description in English" value={descriptionEN} onChange={(value: string) => setDescriptionEN(value)} />
                    </div>
                    {
                      getErrorMessage("description") &&
                      <label style={{ color: red[500] }}>{getErrorMessage("description")}</label>
                    }
                  </Grid>
                  <Grid item xs={6}>
                    <label style={{ paddingBottom: "5px" }}>{t("field.description") + " (DE)"}</label>
                    <div>
                      <ReactQuill theme="snow" placeholder="Enter Description in German" value={descriptionDE} onChange={(value: string) => setDescriptionDE(value)} />
                    </div>
                    {
                      getErrorMessage("description_de") &&
                      <label style={{ color: red[500] }}>{getErrorMessage("description_de")}</label>
                    }
                  </Grid>
                </Grid>
                <Grid container spacing={1} rowGap={1} paddingTop={2}>
                  <Grid item xs={12}>
                    <h5 className="subtext mb-0">ADDRESS</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.street")}
                      variant="outlined"
                      size="small"
                      value={model.address || ""}
                      onChange={(e) => change("address", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.street") + " 2"}
                      variant="outlined"
                      size="small"
                      value={model.address2 || ""}
                      onChange={(e) => change("addresss", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.divergingaddress")}
                      variant="outlined"
                      size="small"
                      value={model.diverging || ""}
                      onChange={(e) => change("diverging", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.city")}
                      variant="outlined"
                      size="small"
                      value={model.city || ""}
                      onChange={(e) => change("city", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={t("field.state")}
                      variant="outlined"
                      size="small"
                      value={model.state || ""}
                      onChange={(e) => change("state", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label={t("field.zip")}
                      variant="outlined"
                      size="small"
                      value={model.zip || ""}
                      onChange={(e) => change("zip", e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} rowGap={1} paddingTop={2}>
                  <Grid item xs={6}>
                    <h5 className="subtext mb-0">LINKS (EN)</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <h5 className="subtext mb-0">LINKS (DE)</h5>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.agreementlink")}
                      type="url"
                      variant="outlined"
                      size="small"
                      value={model.agreementlink || ""}
                      onChange={(e) => change("agreementlink", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.agreementlink")}
                      type="url"
                      variant="outlined"
                      size="small"
                      value={model.agreementlink_de || ""}
                      onChange={(e) => change("agreementlink_de", e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.privacylink")}
                      type="url"
                      variant="outlined"
                      size="small"
                      value={model.privacylink || ""}
                      onChange={(e) => change("privacylink", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.privacylink")}
                      type="url"
                      variant="outlined"
                      size="small"
                      value={model.privacylink_de || ""}
                      onChange={(e) => change("privacylink_de", e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.cancelationlink")}
                      type="url"
                      variant="outlined"
                      size="small"
                      value={model.cancelpolicylink || ""}
                      onChange={(e) => change("cancelpolicylink", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label={t("field.cancelationlink")}
                      type="url"
                      variant="outlined"
                      size="small"
                      value={model.cancelpolicylink_de || ""}
                      onChange={(e) => change("cancelpolicylink_de", e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} rowGap={1} paddingTop={2}>
                  <Grid item xs={12}>
                    <h5 className="subtext mb-0">{t("field.categories").toUpperCase()}</h5>
                  </Grid>
                  {getCategoryDiv()}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" paddingTop={3}>
              <Button type="submit" variant="contained" size="large" disabled={process}>
                {t("button.save")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <div hidden={tabSelected !== "contacts"}>
        <SupplierContactList id={id || ""}></SupplierContactList>
      </div>
      <div hidden={tabSelected !== "users"}>
        <SupplierUserList id={id || ""}></SupplierUserList>
      </div>
      <div hidden={tabSelected !== "payment"}>
        <SupplierPaymentDetail id={id ? id : ""} title="Credit Card" type="creditcard" enable={model.creditcard}></SupplierPaymentDetail>
        <hr />
        <SupplierPaymentDetail id={id ? id : ""} title="PayPal" type="paypal" enable={model.paypal}></SupplierPaymentDetail>
        <hr />
        <SupplierPaymentDetail id={id ? id : ""} title="Invoice" type="invoice" enable={model.invoice}></SupplierPaymentDetail>
      </div>
      <div hidden={tabSelected !== "emails"}>
        <SupplierEmails id={id ? id : ""}></SupplierEmails>
      </div>
    </React.Fragment>
  );
};

export default SupplierDetail;
