import { SupplierService } from './../../services/supplier.service';
import { useEffect, useState } from "react";
import { Brand } from "../../models/brand.model";
import pass, { Validation } from "../../models/validation.model";
import { CategoryService } from "../../services/category.service";
import { Category } from "../../models/category.model";
import { useSnackbar } from "notistack";

function SupplierDetailsViewModel(id: string) {
    const [model, setModel] = useState(new Brand());
    const [categories, setCategories] = useState<Category[]>([]);
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [process, setProcess] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const supplierService: SupplierService = new SupplierService();
    const categoryService: CategoryService = new CategoryService()

    const rules: any[] = [
        {
            field: "name",
            type: "required",
            message: "Required",
        },
        {
            field: "description",
            type: "required",
            message: "Required",
        },
        {
            field: "description_de",
            type: "required",
            message: "Required",
        }
    ];

    useEffect(() => {
        categoryService.get().then(categories => setCategories(categories as Category[]))
    }, [])

    useEffect(() => {
        supplierService.getById(id).then(brand => setModel(brand as Brand));
    }, [id]);

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = errors.find(x => x.field === field)?.message
        }
        return message;
    }

    const change = (field: string, value: any) => {
        setModel({ ...model, [field]: value });
        if (submitted) isValid();
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, model[validation.field as keyof typeof model]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const submit = async (descriptionEN: string, descriptionDE: string): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);

            let data: Brand = model;
            data.description = descriptionEN;
            data.description_de = descriptionDE;

            setModel({ ...data });

            setProcess(true);

            if (isValid() === false) {
                setProcess(false);
                resolve(false);
                return;
            }

            await supplierService.update(model.id, model);
            setSubmitted(false);
            setProcess(false);
            enqueueSnackbar("Brand Profile Saved", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
        });
    }

    const uploadImage = (image: string) => {
        supplierService.updateField(model.id, 'image', image).then(() => {
            enqueueSnackbar("Image Uploaded", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
        })
    }

    return { model, categories, change, submit, uploadImage, getError, getErrorMessage, process }
}

export default SupplierDetailsViewModel;