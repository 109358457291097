import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { ProductCardSetting, ProductCardTagSetting } from "../../models/setting.model";
import ProductCardSettingViewModel from "../../viewmodels/settings/productcard";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  value: any;
}

const CardSetting: React.FC<ContainerProps> = ({ value }) => {
  const { t } = useTranslation();

  const { settings, setSettings, save } = ProductCardSettingViewModel(value.id);

  const renderTag = () => {
    if (value.withTag) {
      return (
        <React.Fragment>
          <Grid xs={2} item marginTop={2}>
            <TextField
              type="color"
              label="Tag Background"
              variant="outlined"
              value={settings.tag && settings.tag.background ? settings.tag.background : "#FFFFFF"}
              onChange={(e) => {
                let data = settings;
                data.tag = settings.tag ? settings.tag : new ProductCardTagSetting();
                data.tag.background = e.target.value;
                setSettings({ ...settings, tag: data.tag });
              }}
              placeholder=""
              fullWidth
              inputProps={{
                style: {
                  height: "50px",
                },
              }}
            />
          </Grid>
          <Grid xs={2} item marginTop={2}>
            <TextField
              type="color"
              label="Tag Font"
              variant="outlined"
              value={settings.tag && settings.tag.font ? settings.tag.font : "#FFFFFF"}
              onChange={(e) => {
                let data = settings;
                data.tag = settings.tag ? settings.tag : new ProductCardTagSetting();
                data.tag.font = e.target.value;
                setSettings({ ...settings, tag: data.tag });
              }}
              placeholder=""
              fullWidth
              inputProps={{
                style: {
                  height: "50px",
                },
              }}
            />
          </Grid>
        </React.Fragment>
      );
    } else return;
  };

  return (
    <React.Fragment>
      <Grid container paddingInline={2} spacing={1} alignItems="center">
        <Grid xs={2} item>
          <h5 className="subtext mb-0">{value.value}</h5>
        </Grid>
        <Grid xs={2} item marginTop={2}>
          <TextField
            type="color"
            label="Card Background"
            variant="outlined"
            value={settings.card && settings.card.background ? settings.card.background : "#FFFFFF"}
            onChange={(e) => {
              let data = settings;
              data.card = settings.card ? settings.card : new ProductCardSetting();
              data.card.background = e.target.value;
              setSettings({ ...settings, card: data.card });
            }}
            placeholder=""
            fullWidth
            inputProps={{
              style: {
                height: "50px",
              },
            }}
          />
        </Grid>
        <Grid xs={2} item marginTop={2}>
          <TextField
            type="color"
            label="Card Border"
            variant="outlined"
            value={settings.card && settings.card.border ? settings.card.border : "#FFFFFF"}
            onChange={(e) => {
              let data = settings;
              data.card = settings.card ? settings.card : new ProductCardSetting();
              data.card.border = e.target.value;
              setSettings({ ...settings, card: data.card });
            }}
            placeholder=""
            fullWidth
            inputProps={{
              style: {
                height: "50px",
              },
            }}
          />
        </Grid>
        {renderTag()}
      </Grid>
      <Grid container item display="flex" flexDirection="row" justifyContent="center" marginTop={2}>
        <Button type="button" variant="contained" size="large" sx={{ mx: 1 }} onClick={(e) => save()}>
          {t("button.save")}
        </Button>
      </Grid>
    </React.Fragment>
  );
};

export default CardSetting;
