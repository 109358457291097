import { useState } from "react";
import { Admin } from "../../models/admin.model";
import pass, { Validation } from "../../models/validation.model";
import { useSnackbar } from "notistack";
import { AdminService } from "../../services/admin.service";
import { AccountService } from "../../services/account.service";
import { useTranslation } from "react-i18next";
import { CustomerService } from "../../services/customer.service";
import { SupplierUserService } from "../../services/supplieruser.service";

function AdminRegisterViewModel() {
    const { t, i18n } = useTranslation();

    const [model, setModel] = useState({ email: '', firstname: '', lastname: '', password: '' });
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [useAsAdmin, setUseAsAdmin] = useState(false);

    const adminService: AdminService = new AdminService();
    const accountService: AccountService = new AccountService();

    const { enqueueSnackbar } = useSnackbar();

    const rules = [
        {
            field: "email",
            type: "required",
            message: "error.emailrequire",
        },
        {
            field: "firstname",
            type: "required",
            message: "error.firstnamerequire",
        },
        {
            field: "firstname",
            type: "maxlength",
            length: 50,
            message: "error.mustnotexceed50",
        },
        {
            field: "lastname",
            type: "required",
            message: "error.lastnamerequire",
        },
        {
            field: "lastname",
            type: "maxlength",
            length: 50,
            message: "error.mustnotexceed50",
        },
        {
            field: "password",
            type: "required",
            message: "error.passwordrequire",
        },
        {
            field: "password",
            type: "minlength",
            length: 5,
            message: "error.mustnotlessthan5",
        }
    ];

    const change = (field: string, value: any) => setModel({ ...model, [field]: value });

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message)
        }
        return message;
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, model[validation.field as keyof typeof model]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const onReset = () => {
        setSubmitted(false);
        setModel({ email: '', firstname: '', lastname: '', password: '' });
        setErrors([]);
    }

    const onSubmit = async (): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);
            if (isValid() === false) {
                resolve(false);
                return;
            }

            let admins: Admin[] = await adminService.searchBy('email', model.email) as Admin[];
            if (admins.length > 0) {
                let validation: Validation = new Validation();
                validation.field = 'email';
                validation.type = 'inuse';
                validation.message = t('error.emailused');
                setErrors([validation]);
                resolve(false);
                return;
            }

            const customerService: CustomerService = new CustomerService();
            const brandUserService: SupplierUserService = new SupplierUserService()
            let account = await customerService.searchEmail(model.email);
            let brandUser = await brandUserService.searchEmail(model.email);
            if (account !== undefined || brandUser !== undefined) {
                let validation: Validation = new Validation();
                validation.field = 'email';
                validation.type = 'inuse';
                validation.message = t('error.emailisnotadmin');
                setErrors([validation]);
                setUseAsAdmin(true);
                resolve(false);
                return;
            }

            let id: string = await accountService.register(model.email, model.password);
            let newAdmin: Admin = new Admin();
            newAdmin.id = id;
            newAdmin.email = model.email;
            newAdmin.firstname = model.firstname;
            newAdmin.lastname = model.lastname;
            newAdmin.active = true;
            newAdmin.language = i18n.language === 'en' ? 'en' : 'de';
            await adminService.set(id, newAdmin);

            enqueueSnackbar("Administrator saved.", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });

            onReset();
            setSubmitted(false);
            resolve(true);
        })
    }

    const onUseAsAdmin = (value: boolean): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);
            if (value === true) {
                setUseAsAdmin(false);
                const customerService: CustomerService = new CustomerService();
                const brandUserService: SupplierUserService = new SupplierUserService()
                let account = await customerService.searchEmail(model.email);
                let brandUser = await brandUserService.searchEmail(model.email);
                let newAdmin: Admin = new Admin();
                newAdmin.id = account ? account.id : (brandUser ? brandUser.id : 'brandUser.id');
                newAdmin.email = model.email;
                newAdmin.firstname = model.firstname;
                newAdmin.lastname = model.lastname;
                newAdmin.active = true;
                newAdmin.language = i18n.language === 'en' ? 'en' : 'de';

                await adminService.set(account ? account.id : (brandUser ? brandUser.id : 'brandUser.id'), newAdmin);

                enqueueSnackbar("Administrator saved.", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                });

                onReset();
                resolve(true);
            }
            else {
                setUseAsAdmin(false);
                resolve(false);
            }
        })
    }

    return { model, change, getError, getErrorMessage, onSubmit, onReset, useAsAdmin, onUseAsAdmin }
}

export default AdminRegisterViewModel;