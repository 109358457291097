import { BaseService } from "./baseservice";
import { BrandUser } from "../models/brand.model";
import { collection, doc, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";

export class SupplierUserService extends BaseService {
    constructor() {
        super("brandusers", "");
    }

    colname: string = 'brandusers';

    searchEmail = (email: string) => new Promise<BrandUser | undefined>((resolve) => {
        let col = collection(this.firestore, this.colname);
            let constraints = [];
            constraints.push(where('email', '==', email));
    
            const q = query(col, ...constraints);
            onSnapshot(q, snap => {
                if (snap.docs.length > 0) {
                  let account: BrandUser = snap.docs[0].data() as BrandUser;
                  account.id = snap.docs[0].id
                  resolve(account)
                }
                else {
                  resolve(undefined);
                }
            })
      })

    getByBrandId = (id: string) => new Promise<BrandUser[]>(resolve => {
        let col = collection(this.firestore, this.colname);
        let constraints = [];
        let data: BrandUser[] = [];
        constraints.push(where('brandid', '==', id));
        constraints.push(orderBy('created','desc'));
        const q = query(col, ...constraints);
        onSnapshot(q, snap => {
            snap.docs.forEach(doc => {
                let user: BrandUser = doc.data() as BrandUser;
                user.id = doc.id;
                data.push(user)
            });
            resolve(data);
        })
    });

    updateLanguage = (id: string, language: 'en' | 'de'): Promise<void> => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        return updateDoc(docs, {
            language: language
        });
    }
}