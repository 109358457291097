import { QueryOrderByConstraint, collection, onSnapshot, query } from "firebase/firestore";
import { BaseService } from "./baseservice";
import { ProductLog } from "../models/productlog.model";

export class ProductLogService extends BaseService {
    constructor() {
        super("productlogs", "id");
    }

    getAll = (): Promise<ProductLog[]> => {
        return new Promise((resolve) => {
            let col = collection(this.firestore, this.colname);
            let data: ProductLog[] = [];
            const q = query(col);
            onSnapshot(col, snap => {
                snap.docs.forEach(doc => {
                    let log: ProductLog = doc.data() as ProductLog;
                    log.id = doc.id;
                    data.push(log)
                });
                resolve(data);
            })
        })
    }
}