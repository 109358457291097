import { Box, Card, CardContent, Grid } from "@mui/material";
import React from "react";
import ImageControl from "../../components/Image/ImageControl";
import Upload from "../../components/upload/Upload";
import ProductImagesViewModel from "../../viewmodels/product/images.viewmodel";
import { t } from "i18next";

interface ContainerProps {
  id: string;
}

const ProductImages: React.FC<ContainerProps> = ({ id }) => {
  const { images, primary, addimage, change, remove } = ProductImagesViewModel(id);

  const renderImage = (key: number, image: string) => {
    if (image !== "") {
      return (
        <Grid item xs={1} key={key} padding="1">
          <ImageControl image={image} primary={image === primary} change={change} remove={remove}></ImageControl>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={1} key={key} padding="1">
          <Box border={1} borderColor={"lightgray"}>
            <Upload category={"products"} id={id} image={image} onSetImage={addimage}></Upload>
          </Box>
        </Grid>
      );
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={1} marginTop={1}>
        <Grid xs={7} item>
          <h5 className="subtext">{t('title.images').toUpperCase()}</h5>
        </Grid>
      </Grid>
      <Grid container spacing={1} columns={7} >
        {images.map((image, idx) => {
          return renderImage(idx, image);
        })}
      </Grid>
    </React.Fragment>
  );
};

export default ProductImages;
