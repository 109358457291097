import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProductService } from "../../services/product.service";
import { LimitedOffer, Product, Specs } from "../../models/product.model";
import pass, { Validation } from "../../models/validation.model";
import { useTranslation } from "react-i18next";

function  ProductLimitedOfferViewModel(id: string) {
  const { t } = useTranslation();

  const [data, setData] = useState(new LimitedOffer());
  const [isWithStock, setIsWithStock] = useState(false);
  const [stockStatus, setStockStatus] = useState<'in-stock' | 'out-of-stock' | 'limited-time'>('out-of-stock');
  const [limited, setLimited] = useState(false);
  const [errors, setErrors] = useState<Validation[]>([]);
  const [submitted, setSubmitted] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const productService: ProductService = new ProductService();

  let rules = [
    {
      field: "start",
      type: "required",
      message: "error.startrequire",
    },
    {
      field: "end",
      type: "required",
      message: "error.endrequire",
    },
  ];

  useEffect(() => {
    if (id) {
      productService.getById(id).then(product => {
        let data: Product = product as Product;
        if (data.stockstatus) {
          if(data.limitedoffer) setData(data.limitedoffer)
          if (data.specs) {
            let filter: Specs[] = data.specs.filter(x => (x.stock ? x.stock : 0) > 0);
            setIsWithStock(filter.length > 0);
          }
          else {
            setIsWithStock(false)
          }
          setStockStatus(data.stockstatus);
          setLimited(data.stockstatus === 'limited-time');
        }
      });
    }
  }, [id]);

  const getError = (field: string): Validation | undefined => {
    return errors.find(x => x.field === field);
  }

  const getErrorMessage = (field: string): string | undefined => {
    let message: string | undefined = undefined;
    if (errors.find(x => x.field === field)) {
      message = t(errors.find(x => x.field === field)!.message)
    }
    return message;
  }

  const change = (field: string, value: any) => {
    setData({ ...data, [field]: value });
    if (submitted) isValid();
  }

  const isValid = (): boolean => {
    let values: Validation[] = [];
    rules.forEach(rule => {
      let validation: Validation = rule as Validation;
      if (pass(validation, data[validation.field as keyof typeof data]) === false) {
        values.push(validation);
      }
    })
    setErrors([...values]);
    return values.length === 0;
  }

  const save = async () => {
    setSubmitted(true);

    if (isValid() === false) {
      return;
    }

    await productService.updateField(id, 'limitedoffer', data);
    await productService.updateField(id, 'stockstatus', stockStatus);

    setSubmitted(false);
    enqueueSnackbar("Limited offer saved", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  const onEnable = (value: boolean) => {
    setLimited(value)
    if (value) {
      setStockStatus("limited-time");
    }
    else {
      setStockStatus(isWithStock ? "in-stock" : 'out-of-stock');
    }
  }

  return { data, limited, change, save, onEnable, getError, getErrorMessage };
}

export default ProductLimitedOfferViewModel;
