import { useEffect, useState } from "react";
import { ProductService } from "../../services/product.service";
import { BuyOnlyDeal, Product } from "../../models/product.model";
import { useSnackbar } from "notistack";
import { fanfluencers } from "../../config";
import { CalculationService } from "../../services/calculation.service";

function ProductBuyOnlyDealViewModel(id: string) {
    const [buyonlymax, setBuyonlymax] = useState(1);
    const [buyonlydeals, setBuyonlydeals] = useState<BuyOnlyDeal[]>([]);
    const [originalprice, setOriginalprice] = useState(0);
    const [price, setPrice] = useState(0);

    const { enqueueSnackbar } = useSnackbar();

    const productService: ProductService = new ProductService();

    useEffect(() => {
        if (id) {
            productService.getById(id).then(product => {
                let data: Product = product as Product;
                setBuyonlymax(data.buyonlymax ? data.buyonlymax : 1);
                setOriginalprice(data.originalprice ? data.originalprice : 0);
                setPrice(data.originalprice ? data.originalprice : 0);
                if (data.buyonlydeals) {
                    if (data.buyonlydeals.length > 0) {
                        setBuyonlydeals(data.buyonlydeals);
                    }
                    else {
                        generateDefaultDeal();
                    }
                }
                else {
                    generateDefaultDeal();
                }
            });
        }
    }, [id]);

    useEffect(() => {
        if (price !== originalprice) {
            setOriginalprice(price);
            const calculationService: CalculationService = new CalculationService();
            buyonlydeals.forEach(buyonlydeal => {
                let result = calculationService.racedealcalculationbypercent(Number(buyonlydeal.percent), Number(price));
                let temp: BuyOnlyDeal = { ...buyonlydeal, amount: Number(result.amount), price: Number(result.price) };
                save(temp);
            })
        }
    }, [price])

    const generateDefaultDeal = async () => {
        let deals: BuyOnlyDeal[] = [];
        fanfluencers.forEach((fanfluencer) => {
            let deal: BuyOnlyDeal = new BuyOnlyDeal();
            deal.id = fanfluencer.id;
            deal.percent = 0;
            deal.amount = 0;
            deal.price = originalprice;
            deals.push(deal);
        });
        setBuyonlydeals([...deals])
        await productService.updateBuyOnlyDeals(id, deals);
    }

    const save = (deal: BuyOnlyDeal): Promise<void> => {
        return new Promise(async resolve => {
            let deals: BuyOnlyDeal[] = buyonlydeals;
            let idx: number = deals.findIndex(x => x.id === deal.id);
            if (idx >= 0) {
                deals[idx] = deal
            };
            setBuyonlydeals(deals);
            await productService.updateBuyOnlyDeals(id, deals);
            enqueueSnackbar("Buy Only Deal Saved", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            resolve();
        });
    }

    const savemax = (value: number): Promise<void> => {
        return new Promise(async resolve => {
            setBuyonlymax(value);
            await productService.updateField(id, 'buyonlymax', value);
            enqueueSnackbar("Buy Only Deal Maximum Quantity Updated", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            resolve();
        });
    }

    return { buyonlymax, buyonlydeals, originalprice, setPrice, save, savemax }
}

export default ProductBuyOnlyDealViewModel;