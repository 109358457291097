import { addDoc, collection, doc, getFirestore, serverTimestamp, updateDoc } from "firebase/firestore";
import { BaseService } from "./baseservice";
import firebaseConfig from "../config";
import { initializeApp } from "firebase/app";
import { Partner } from "../models/partner";

export class PartnerService extends BaseService {
    app = initializeApp(firebaseConfig);

    constructor() {
        super("partners", "order");
        this.firestore = getFirestore(this.app);
    }

    colname = "partners";

    add = (data: Partner) => new Promise((resolve) => {
        const col = collection(this.firestore, this.colname);
        addDoc(col, {
            image: data.image,
            title: data.title ? data.title : '',
            active: data.active,
            order: data.order,
            created: serverTimestamp(),
            createdby: this.userid
        }).then(value => {
            resolve(value.id);
        });
    });

    updateStatus = (id: string, value: boolean) => {
        new Promise<void>((resolve) => {
            const docs = doc(this.firestore, this.colname + "/" + id);
            updateDoc(docs, {
                active: value,
                modified: serverTimestamp(),
                modifiedby: this.userid
            }).then(() => {
                resolve();
            });
        });
    }

    updateTitle = (id: string, value: string) => {
        new Promise<void>((resolve) => {
            const docs = doc(this.firestore, this.colname + "/" + id);
                updateDoc(docs, {
                    title: value,
                    modified: serverTimestamp(),
                    modifiedby: this.userid
                }).then(() => {
                    resolve();
                });
        });
    }

    updateOrder = (id: string, value: number) => {
        new Promise<void>((resolve) => {
            const docs = doc(this.firestore, this.colname + "/" + id);
                updateDoc(docs, {
                    order: value,
                    modified: serverTimestamp(),
                    modifiedby: this.userid
                }).then(() => {
                    resolve();
                });
        });
    }
}