import React, { useEffect, useState } from "react";
import FormLayout from "../../components/form/FormLayout";
import { Box, Card, CardContent, Grid, Tab, Tabs } from "@mui/material";
import CardSetting from "./card-settings";
import { stockstatus } from "../../config";
import { StorageService } from "../../services/storage.service";
import "./settings.css"
import { useSnackbar } from "notistack";

const Settings: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [tabSelected, setTabSelected] = useState("details");
  const [stockStatusSelected, setStockStatusSelected] = useState(stockstatus[0]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const storageService: StorageService = new StorageService();
    setTabSelected(storageService.isSupplier() ? "api" : "details");
    setIsAdmin(!storageService.isSupplier());
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabSelected(newValue);
  };

  const handleStockStatusChange = (event: React.SyntheticEvent, newValue: string) => {
    setStockStatusSelected(stockstatus.find((x) => x.id === newValue)!);
  };

  const renderAdmin = () => {
    return (
      <React.Fragment>
        <Grid container>
          <Grid xs={12} item>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <h5 className="subtext mb-0">{"Stock Status".toUpperCase()}</h5>
              <Tabs value={stockStatusSelected.id} onChange={handleStockStatusChange}>
                {stockstatus.map((status, idx) => (
                  <Tab key={idx} value={status.id} label={status.value}></Tab>
                ))}
              </Tabs>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <CardSetting value={stockStatusSelected}></CardSetting>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };


  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar("Copied", {
      variant: "success",
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  }

  const renderSupplier = () => {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid xs={12} item>
            <h5 className="subtext mb-0">{"API URLs".toUpperCase()}</h5>
          </Grid>
        </Grid>
        <Grid container spacing={0} marginTop={2} paddingInline={2}>
          <Grid xs={2} item borderTop={1} borderLeft={1} padding={1}>
            Documentation
          </Grid>
          <Grid xs={8} item borderTop={1} borderLeft={1} padding={1}>
            {process.env.REACT_APP_API_DOCUMENTATION}
          </Grid>
          <Grid xs={2} item borderTop={1} borderLeft={1} borderRight={1} padding={1}>
            <a href={process.env.REACT_APP_API_DOCUMENTATION} download className="ms-2">DOWNLOAD</a>
          </Grid>

          <Grid xs={2} item borderTop={1} borderLeft={1} padding={1}>
            Authentication
          </Grid>
          <Grid xs={8} item borderTop={1} borderLeft={1} padding={1}>
            {process.env.REACT_APP_API_AUTHENTICATION}
          </Grid>
          <Grid xs={2} item borderTop={1} borderLeft={1} borderRight={1} padding={1}>
            <a className="copy ms-2" onClick={(e) => copyToClipboard(process.env.REACT_APP_API_AUTHENTICATION!)}>COPY TO CLIPBOARD</a>
          </Grid>

          <Grid xs={2} item borderTop={1} borderLeft={1} padding={1}>
            Get Products
          </Grid>
          <Grid xs={8} item borderTop={1} borderLeft={1} padding={1}>
            {process.env.REACT_APP_API_GETPRODUCTS}
          </Grid>
          <Grid xs={2} item borderTop={1} borderLeft={1} borderRight={1} padding={1}>
            <a className="copy ms-2" onClick={(e) => copyToClipboard(process.env.REACT_APP_API_GETPRODUCTS!)}>COPY TO CLIPBOARD</a>
          </Grid>

          <Grid xs={2} item borderTop={1} borderLeft={1} padding={1}>
            Get Transactions
          </Grid>
          <Grid xs={8} item borderTop={1} borderLeft={1} padding={1}>
            {process.env.REACT_APP_API_GETTRANSACTIONS}
          </Grid>
          <Grid xs={2} item borderTop={1} borderLeft={1} borderRight={1} padding={1}>
            <a className="copy ms-2" onClick={(e) => copyToClipboard(process.env.REACT_APP_API_GETTRANSACTIONS!)}>COPY TO CLIPBOARD</a>
          </Grid>

          <Grid xs={2} item borderTop={1} borderLeft={1} padding={1}>
            Update Stock Count
          </Grid>
          <Grid xs={8} item borderTop={1} borderLeft={1} padding={1}>
            {process.env.REACT_APP_API_UPDATESTOCKCOUNT}
          </Grid>
          <Grid xs={2} item borderTop={1} borderLeft={1} borderRight={1} padding={1}>
            <a className="copy ms-2" onClick={(e) => copyToClipboard(process.env.REACT_APP_API_UPDATESTOCKCOUNT!)}>COPY TO CLIPBOARD</a>
          </Grid>

          <Grid xs={2} item borderTop={1} borderBottom={1} borderLeft={1} padding={1}>
            Update Product Status
          </Grid>
          <Grid xs={8} item borderTop={1} borderBottom={1} borderLeft={1} padding={1}>
            {process.env.REACT_APP_API_UPDATESTATUS}
          </Grid>
          <Grid xs={2} item borderTop={1} borderBottom={1} borderLeft={1} borderRight={1} padding={1}>
            <a className="copy ms-2" onClick={(e) => copyToClipboard(process.env.REACT_APP_API_UPDATESTATUS!)}>COPY TO CLIPBOARD</a>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle="Settings" Breadcrumbs={[{ title: "Dashboard", url: "/" }, { title: "Settings" }]}></FormLayout>
      {isAdmin ? renderAdmin() : renderSupplier()}
    </React.Fragment>
  );
};

export default Settings;
