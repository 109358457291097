import { Box, Button, Grid, MenuItem, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Customer, CustomerList } from "../../models/customer.model";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  customer: Customer;
  onStatusChange: (id: string, status: "pending" | "approved" | "suspend" | 'deleted') => void;
}

const CustomerStatus: React.FC<ContainerProps> = ({ customer, onStatusChange }) => {
  const { t } = useTranslation();

  const subtext = {
    fontWeight: "300",
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  const [open, setOpen] = useState(false);
  const [model, setModel] = useState<Customer>(customer);
  const [status, setStatus] = useState<"pending" | "approved" | "suspend" | "deleted">("pending");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setModel(customer)
  }, [customer])

  useEffect(() => {
    setStatus(customer.status);
  }, [customer.status]);

  const updateStatus = async () => {
    await onStatusChange(model.id, status);
    let data: Customer = model;
    data.status = status;
    setModel(data);
    setOpen(false);
  };

  const cancel = () => {
    setStatus(model.status);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <MenuItem onClick={handleOpen}>Update Status</MenuItem>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={headerStyle}>{t("text.customerstatus")}</Box>
          <Box sx={detailStyle}>
            <Box paddingBottom={1}>
              <span style={subtext}>{t("text.updatestatusfor")} </span>
              <span>{model.firstname && model.lastname? model.firstname + ' ' + model.lastname: model.email}</span>
            </Box>
            <Box paddingBottom={1}>
              <span style={subtext}>{t("field.status")} </span>
              <span>{model.status ? model.status.toUpperCase() : ""}</span>
            </Box>
            <hr />
            <Grid container>
              <Grid item xs={4} height={"100%"} paddingY={1} textAlign={"center"}>
                {t("field.status")}
              </Grid>
              <Grid item xs={8} container justifyContent={"right"}>
                <select
                  className="form-select form-select"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value as "pending" | "approved" | "suspend");
                  }}
                >
                  <option value={"pending"}>PENDING</option>
                  <option value={"approved"}>APPROVED</option>
                  <option value={"suspend"}>SUSPEND</option>
                </select>
              </Grid>
            </Grid>
          </Box>
          <Box sx={footerStyle}>
            <Button variant="outlined" size="medium" sx={{ ml: 1 }} onClick={(e) => updateStatus()}>
              {t("button.confirm")}
            </Button>
            <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={(e) => cancel()}>
              {t("button.cancel")}
            </Button>
          </Box>
          {/*
          <div className="row p-4 m-0" style={header}>
            <div className="col-6">
              <label>Customer Status</label>
            </div>
            <div className="col-6 text-end">
              <CloseIcon onClick={handleClose}></CloseIcon>
            </div>
          </div>
          <div className="row p-4 m-0">
            <div className="col-7 my-auto">
              <label style={title}>Update Status for {name}</label>
            </div>
            <div className="col-5 text-end my-auto">
              <select className="form-select form-select" value={temp} onChange={handleChange}>
                <option value={"pending"}>PENDING</option>
                <option value={"approved"}>APPROVED</option>
                <option value={"suspend"}>SUSPEND</option>
              </select>
            </div>
            <div className="col-12 text-center mt-5">
              <Button variant="outlined" onClick={handleConfirm}>
                CONFIRM
              </Button>
            </div>
          </div>
          */}
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerStatus;
