import { useTranslation } from "react-i18next";
import "./dashboard.css";
import FormLayout from "../../components/form/FormLayout";
import DashboardViewModel from "../../viewmodels/dashboard/dashboard.viewmodel";
import React from "react";
import DashboardProduct from "./product";
import DashboardPayment from "./transaction";

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  const {
    isSupplier,
    brands,
    products,
    categories,
    activeCustomers,
    pendingCustomers,
    admins,
    racedeals,
    influencerdeals
  } = DashboardViewModel();

  return (
    <div>
      <FormLayout PageTitle={t("title.dashboard")} Breadcrumbs={[]}></FormLayout>
      <div className="row">
        <div className="col-md-3">
          <div className="card mb-2">
            <div className="card-body">
              <div className="mb-2">{t('title.totalproducts')}</div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <span className="badge text-bg-primary p-3">
                  <i className="bi bi-card-checklist fs-2"></i>
                </span>
                <span className="ms-3 my-count">{new Intl.NumberFormat("en-DE").format(Number(products.length))}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card mb-2">
            <div className="card-body">
              <div className="mb-2">{t('title.totalraceclubdeals')}</div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <span className="badge text-bg-primary p-3">
                  <i className="bi bi-cash-coin fs-2"></i>
                </span>
                <span className="ms-3 my-count">{new Intl.NumberFormat("en-DE").format(Number(racedeals.length))}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card mb-2">
            <div className="card-body">
              <div className="mb-2">{t('title.totalinfluencerdeals')}</div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <span className="badge text-bg-primary p-3">
                  <i className="bi bi-person-vcard fs-2"></i>
                </span>
                <span className="ms-3 my-count">{new Intl.NumberFormat("en-DE").format(Number(influencerdeals.length))}</span></div>
            </div>
          </div>
        </div>
        {isSupplier === false && (
          <div className="col-md-3">
            <div className="card mb-2">
              <div className="card-body">
                <div className="mb-2">{t('title.totalbrands')}</div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <span className="badge text-bg-primary p-3">
                    <i className="bi bi-shop fs-2"></i>
                  </span>
                  <span className="ms-3 my-count">{new Intl.NumberFormat("en-DE").format(Number(brands.length))}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {isSupplier === false && (
          <div className="col-md-3">
            <div className="card mb-2">
              <div className="card-body">
                <div className="mb-2">{t('title.totalcategories')}</div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <span className="badge text-bg-primary p-3">
                    <i className="bi bi-diagram-3 fs-2"></i>
                  </span>
                  <span className="ms-3 my-count">{new Intl.NumberFormat("en-DE").format(Number(categories.length))}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {isSupplier === false && (
          <div className="col-md-3">
            <div className="card mb-2">
              <div className="card-body">
                <div className="mb-2">{t('title.totalactiveusers')}</div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <span className="badge text-bg-primary p-3">
                    <i className="bi bi-person-check fs-2"></i>
                  </span>
                  <span className="ms-3 my-count">{new Intl.NumberFormat("en-DE").format(Number(activeCustomers.length))}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {isSupplier === false && (
          <div className="col-md-3">
            <div className="card mb-2">
              <div className="card-body">
                <div className="mb-2">{t('title.customerforapproval')}</div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <span className="badge text-bg-primary p-3">
                    <i className="bi bi-person-exclamation fs-2"></i>
                  </span>
                  <span className="ms-3 my-count">{new Intl.NumberFormat("en-DE").format(Number(pendingCustomers.length))}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {isSupplier === false && (
          <div className="col-md-3">
            <div className="card mb-2">
              <div className="card-body">
                <div className="mb-2">{t('title.totalactiveadmins')}</div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <span className="badge text-bg-primary p-3">
                    <i className="bi bi-person-workspace fs-2"></i>
                  </span>
                  <span className="ms-3 my-count">{new Intl.NumberFormat("en-DE").format(Number(admins.length))}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {isSupplier === false && (
          <div className="col-md-12">
            <DashboardProduct></DashboardProduct>
          </div>
        )}
        <div className="col-md-12 mb-4">
          <DashboardPayment></DashboardPayment>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
