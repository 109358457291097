import { expand } from "rxjs";
import { Action } from "../components/table/table";

export class Brand {
    public id: string;
    public name: string;
    public description: string;
    public description_de: string;
    public image: string;
    public website: string;
    public address: string;
    public address2: string;
    public diverging: string;
    public city: string;
    public state: string;
    public zip: string;
    public active: boolean;
    public agreementlink: string;
    public agreementlink_de: string;
    public privacylink: string;
    public privacylink_de: string;
    public cancelpolicylink: string;
    public cancelpolicylink_de: string;
    public categories: string[];
    public creditcard: boolean;
    public creditcarddetails: CreditCardDetail = new CreditCardDetail();
    public paypal: boolean;
    public paypaldetails: PayPalDetail = new PayPalDetail();
    public invoice: boolean;
    public emails: string[];
  }

  export class BrandList {
    public id: string;
    public image: string;
    public name: string;
    public website: string;
    public active: boolean;
    public categories: string[];
    public gateway: string[];
    public openlink: number;
    public views: number;
    public action: Action;
  }
  
  export class CreditCardDetail {
    public apikey: string;
    public secretkey: string;
  }
  
  export class PayPalDetail {
    public clientid: string;
  }

  export class Contact {
    public id: string;
    public brandid: string;
    public name: string;
    public email: string;
    public phone: string;
    public active: boolean;
  }

  export class ContactList extends Contact{
    public action: Action
  }

  export class BrandUser {
    public id: string;
    public brandid: string;
    public email: string;
    public firstname: string;
    public lastname: string;
    public active: boolean;
    public language: 'en' | 'de';
  }

  export class BrandUserList extends BrandUser{
    public action: Action
  }
  