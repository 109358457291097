import { useEffect, useState } from "react";
import { Admin } from "../../models/admin.model";
import pass, { Validation } from "../../models/validation.model";
import { useSnackbar } from "notistack";
import { AdminService } from "../../services/admin.service";
import { useTranslation } from "react-i18next";

function AdminEditViewModel(admin: Admin) {
    const { t } = useTranslation();

    const [model, setModel] = useState<Admin>(admin);
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);

    const adminService: AdminService = new AdminService();

    const { enqueueSnackbar } = useSnackbar();
    const rules = [
        {
            field: "firstname",
            type: "required",
            message: "error.firstnamerequire",
        },
        {
            field: "firstname",
            type: "maxlength",
            length: 50,
            message: "error.mustnotexceed50",
        },
        {
            field: "lastname",
            type: "required",
            message: "error.lastnamerequire",
        },
        {
            field: "lastname",
            type: "maxlength",
            length: 50,
            message: "error.mustnotexceed50",
        }
    ];

    useEffect(() => { setModel(admin) }, [admin])

    const change = (field: string, value: any) => setModel({ ...model, [field]: value });

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message)
        }
        return message;
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, model[validation.field as keyof typeof model]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const onSubmit = async (): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);
            if (isValid() === false) {
                resolve(false);
                return;
            }

            await adminService.update(model.id, model);

            enqueueSnackbar(t('text.accountupdated'), {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });

            setSubmitted(false);
            resolve(true);
        })
    }

    return { model, change, getError, getErrorMessage, onSubmit }
}

export default AdminEditViewModel;