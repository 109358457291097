export class Admin {
    public id: string;
    public email: string;
    public firstname: string;
    public lastname: string;
    public active: boolean;
    public language: 'en' | 'de';
}

export class AdminSubs extends Admin{
    public subtype: 'added' | 'removed' | 'modified';
  }

export class AdminList {
    public id: string;
    public email: string;
    public firstname: string;
    public lastname: string;
    public active: boolean;
    public language: 'en' | 'de';
    public action: any;
}