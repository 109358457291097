import { PayPalDetail } from './../models/brand.model';

import { BaseService } from './baseservice';
import { Brand, CreditCardDetail } from '../models/brand.model';
import { addDoc, collection, doc, getFirestore, serverTimestamp, updateDoc } from 'firebase/firestore';
import firebaseConfig from '../config';
import { initializeApp } from 'firebase/app';

export class SupplierService extends BaseService {
    app = initializeApp(firebaseConfig);

    constructor() {
        super("brands", "");
        this.firestore = getFirestore(this.app);
    }

    colname: string = 'brands';

    add = (brand: Brand) => new Promise<string>(resolve => {
        const col = collection(this.firestore, this.colname);
        addDoc(col, {
            name: brand.name,
            website: brand.website ? brand.website : '',
            description: brand.description ? brand.description : '',
            description_de: brand.description_de ? brand.description_de : '',
            address: brand.address ? brand.address : '',
            address2: brand.address2 ? brand.address2 : '',
            diverging: brand.diverging ? brand.diverging : '',
            city: brand.city ? brand.city : '',
            state: brand.state ? brand.state : '',
            zip: brand.city ? brand.zip : '',
            active: brand.active ? brand.active : false,
            created: serverTimestamp(),
            createdby: this.userid
        }).then(value => {
            resolve(value.id);
        });
    });

    update = (id: string, brand: Brand) => new Promise<void>(resolve => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        updateDoc(docs, {
            name: brand.name,
            website: brand.website ? brand.website : '',
            description: brand.description ? brand.description : '',
            description_de: brand.description_de ? brand.description_de : '',
            address: brand.address ? brand.address : '',
            address2: brand.address2 ? brand.address2 : '',
            diverging: brand.diverging ? brand.diverging : '',
            city: brand.city ? brand.city : '',
            state: brand.state ? brand.state : '',
            zip: brand.city ? brand.zip : '',
            agreementlink: brand.agreementlink ? brand.agreementlink : '',
            agreementlink_de: brand.agreementlink_de ? brand.agreementlink_de : '',
            privacylink: brand.privacylink ? brand.privacylink : '',
            privacylink_de: brand.privacylink_de ? brand.privacylink_de : '',
            cancelpolicylink: brand.cancelpolicylink ? brand.cancelpolicylink : '',
            cancelpolicylink_de: brand.cancelpolicylink_de ? brand.cancelpolicylink_de : '',
            categories: brand.categories ? brand.categories : [],
            active: brand.active ? brand.active : false,
            modified: serverTimestamp(),
            modifiedby: this.userid
        }).then(() => {
            resolve();
        })
    });

    saveCreditCard = (id: string, enable: boolean, data: CreditCardDetail) => new Promise<void>(resolve => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        updateDoc(docs, {
            creditcard: enable,
            creditcarddetails: {
                apikey: data.apikey,
                secretkey: data.secretkey
            },
            modified: serverTimestamp(),
            modifiedby: this.userid
        }).then(() => {
            resolve();
        })

    });

    savePayPal = (id: string, enable: boolean, data: PayPalDetail) => new Promise<void>(resolve => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        updateDoc(docs, {
            paypal: enable,
            paypaldetails: {
                clientid: data.clientid
            },
            modified: serverTimestamp(),
            modifiedby: this.userid
        }).then(() => {
            resolve();
        })
    })

    saveInvoice = (id: string, enable: boolean) => new Promise<void>(resolve => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        updateDoc(docs, {
            invoice: enable,
            modified: serverTimestamp(),
            modifiedby: this.userid
        }).then(() => {
            resolve();
        })
    })

    saveEmails = (id: string, emails: string[]) => new Promise<void>(resolve => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        updateDoc(docs, {
            emails: emails,
            modified: serverTimestamp(),
            modifiedby: this.userid
        }).then(() => {
            resolve();
        })
    });
}