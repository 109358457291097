import { Avatar, Box, Card, CardContent, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import SupplierUserListViewModel from "../../viewmodels/supplier/userlist.viewmodel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import SupplierUserRegister from "./supplier-user-register";
import SupplierUserDetail from "./supplier-user-detail";
import { useTranslation } from "react-i18next";
import TableLayout from "../../components/table/table";

interface ContainerProps {
  id: string;
}

const SupplierUserList: React.FC<ContainerProps> = ({ id }) => {
  const { t } = useTranslation();

  let headerstyle = {
    color: "#FFF",
    paddingTop: "10px",
    paddingBottom: "10px"
  };
  let detailstyle = {
    paddingTop: "10px",
    paddingBottom: "10px"
  };

  const { fields, users } = SupplierUserListViewModel(id);

  const getStatus = (active: boolean) => {
    if (active) {
      return (
        <Chip
          avatar={
            <Avatar>
              <CheckIcon></CheckIcon>
            </Avatar>
          }
          label="ACTIVE"
          color="primary"
          variant="outlined"
          size="small"
        />
      );
    } else {
      return (
        <Chip
          avatar={
            <Avatar>
              <CloseIcon></CloseIcon>
            </Avatar>
          }
          label="INACTIVE"
          color="error"
          variant="outlined"
          size="small"
        />
      );
    }
  };

  return (
    <React.Fragment>
      <Grid container marginBottom={1}>
        <Grid item xs={12} container direction="row" alignItems="center" justifyContent="space-between">
          <h5 className="subtext mb-0">{t('title.users').toUpperCase()}</h5>
          <SupplierUserRegister brandid={id}></SupplierUserRegister>
        </Grid>
      </Grid>
      <TableLayout fields={fields} data={users}></TableLayout>
    </React.Fragment>
  );
};
/*
function SupplierUserList(props) {
  const location = useLocation();
  const [viewModel, dispatch] = useListViewModel("brandusers", { field: "brandid", value: props["id"] }, []);

  useEffect(() => {
    dispatch({ type: ACTION.GET });
  }, []);

  const getCreateLink = () => {
    if (location.pathname.indexOf("/mydetails") >= 0) {
      return "/mydetailsuser/new/" + props["id"];
    } else {
      return "/branduser/new/" + props["id"];
    }
  };

  const getFields = () => {
    let link = '/branduser/edit/';
    if (location.pathname.indexOf("/mydetails") >= 0) {
      link = '/mydetailsuser/edit/';
    } 

    return [
      { label: "EMAIL", field: "email", align: "left", format: "" },
      {
        label: "FIRST NAME",
        field: "firstname",
        align: "left",
        format: "",
      },
      {
        label: "LAST NAME",
        field: "lastname",
        align: "left",
        format: "",
      },
      {
        label: "STATUS",
        field: "active",
        align: "center",
        format: "",
        datatype: Boolean,
        values: ["Active", "Inactive"],
      },
      {
        label: "ACTION",
        field: "button",
        align: "right",
        format: "",
        link: link,
      },
    ];
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <Card variant="outlined">
          <CardContent>
            <Grid container>
              <Grid xs={6} item>
                <h3 className="subtitle">Users</h3>
              </Grid>
              <Grid xs={6} item>
                <Grid xs={12} container direction="row" justifyContent="right" alignItems="center" item>
                  <Button component={Link} to={getCreateLink()} variant="contained" size="medium">
                    CREATE
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <ListForm
              {...viewModel}
              columnDefinition={getFields()}
              OnNewPage={(event, newPage) => {
                dispatch({ type: ACTION.SETPAGENO, pageNo: newPage });
              }}
              onRowsPerPageChange={(event) => {
                dispatch({
                  type: ACTION.SETROWSPERPAGE,
                  rowsPerPage: parseInt(event.target.value, viewModel.formState.rowsPerPage),
                });
              }}
            ></ListForm>
          </CardContent>
        </Card>
      </Box>
    </React.Fragment>
  );
}
*/
export default SupplierUserList;
