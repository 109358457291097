import { TableField } from "../../models/table.model";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { blue, blueGrey, deepPurple, green, grey, lightBlue, purple, red, yellow } from "@mui/material/colors";
import { fanfluencers, reviewstatus, stockstatus, transactionstatus } from "../../config";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { KeyboardArrowDownSharp, KeyboardArrowUpSharp } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import ImageView from "../Image/ImageView";

interface ContainerProps {
    fields: TableField[];
    data: any[] | undefined;
}

export class Action {
    public type: "url" | "component";
    public display: string;
    public url: string;
    public component: React.ComponentType<any>;
    public props: any;
}

export class StringLink {
    public text: string;
    public url: string;
}

const TableLayout: React.FC<ContainerProps> = ({ fields, data }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);
    const [values, setValues] = useState<any[]>([]);
    const [pages, setPages] = useState<number>(0);
    const [current, setCurrent] = useState<number>(0);
    const [start, setStart] = useState<number>(-1);
    const [end, setEnd] = useState<number>(-1);
    const [sort, setSort] = useState<TableField | undefined>(undefined);
    const [max, setMax] = useState<number>(10);
    const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        if (data) {
            setLoading(false);
            setValues(data);
            if (data.length > 0) {
                let count = Math.ceil(data.length / max);
                setPages(count);
                setCurrent(1);
            }
            else {
                setCurrent(0);
            }
        }
        else {
            setLoading(true)
        }
    }, [data, max])

    useEffect(() => {
        if (current > 0) {
            setStart((current * max) - max);
            setEnd(current * max);
        }
    }, [current, max])

    useEffect(() => {
        if (sort) {
            let items = [...values];

            if (sort.type === 'string') {
                if (direction === 'asc') items = items.sort((a, b) => (a[sort.id] > b[sort.id] ? 1 : -1));
                if (direction === 'desc') items = items.sort((a, b) => (a[sort.id] > b[sort.id] ? -1 : 1));
            }
            if (sort.type === 'number' || sort.type === 'decimal') {
                if (direction === 'asc') items = items.sort((a, b) => (Number(a[sort.id] ? a[sort.id] : 0) > Number(b[sort.id] ? b[sort.id] : 0) ? 1 : -1));
                if (direction === 'desc') items = items.sort((a, b) => (Number(a[sort.id] ? a[sort.id] : 0) > Number(b[sort.id] ? b[sort.id] : 0) ? -1 : 1));
            }

            setValues([...items])
        }

    }, [sort, direction])

    const renderField = (field: TableField) => {
        if (field.sort && sort && field.id === sort.id && direction === "asc") {
            return <label style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(e) => changeSort(field)}>
                {field.display} <KeyboardArrowDownSharp></KeyboardArrowDownSharp>
            </label>
        }
        else if (field.sort && sort && field.id === sort.id && direction === "desc") {
            return <label style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(e) => changeSort(field)}>
                {field.display} <KeyboardArrowUpSharp></KeyboardArrowUpSharp>
            </label>
        }
        else if (field.sort) {
            return <label style={{ cursor: "pointer", fontWeight: "bold" }} onClick={(e) => changeSort(field)}>
                {field.display}
            </label>
        }
        else {
            return <label>{field.display}</label>
        }
    }

    const changeSort = (field: TableField) => {
        if (field === sort) {
            setDirection(direction === "asc" ? "desc" : "asc");
        }
        else {
            setDirection("asc");
            setSort(field)
        }
    }

    const getBoolean = (value: boolean) => {
        if (value) {
            return (
                <CheckIcon fontSize="small" color="primary"></CheckIcon>
            );
        } else {
            return (
                <CloseIcon fontSize="small" sx={{ color: red[500] }}></CloseIcon>
            );
        }
    };

    const getDate = (date: any) => {
        if (date && date !== null) {
            let timestamp = date.seconds * 1000;
            return (new Date(timestamp)).toLocaleDateString();
        }
    }

    const getDateTime = (date: any) => {
        if (date && date !== null) {
            let timestamp = date.seconds * 1000;
            return (new Date(timestamp)).toLocaleDateString() + ' ' + (new Date(timestamp)).toLocaleTimeString();
        }
    }

    const getStatus = (active: boolean) => {
        if (active) {
            return (
                <label style={{ color: blue[800] }}>ACTIVE</label>
            );
        } else {
            return (
                <label style={{ color: red[500] }}>INACTIVE</label>
            );
        }
    };

    const getStockStatus = (stat: "in-stock" | "out-of-stock" | "limited-time") => {
        let status = stockstatus[0];
        if (stat) {
            status = stockstatus.find((x) => x.id === stat)!;
            if (status && status.id === "in-stock") {
                return <label style={{ color: blue[800] }}>{status.value.toUpperCase()}</label>
            }
            else if (status && status.id === "out-of-stock") {
                return <label style={{ color: red[500] }}>{status.value.toUpperCase()}</label>
            }
            else if (status && status.id === "limited-time") {
                return <label style={{ color: green[500] }}>{status.value.toUpperCase()}</label>
            }
        }
    };

    const getReview = (review: "pending" | "approved" | "rejected") => {
        let status = reviewstatus.find((x) => x.id === review);
        if (status && status.id === "pending") {
            return <label style={{ color: yellow[800] }}>{status.value.toUpperCase()}</label>
        }
        else if (status && status.id === "approved") {
            return <label style={{ color: blue[800] }}>{status.value.toUpperCase()}</label>
        }
        else if (status && status.id === "rejected") {
            return <label style={{ color: red[500] }}>{status.value.toUpperCase()}</label>
        }
    };

    const getRatings = (rating: string) => {
        let fanfleuncer = fanfluencers.find(x => x.id === rating);
        if (fanfleuncer) {
            if (fanfleuncer.id === 'NOFANFLUENCER') return <label style={{ color: grey[500] }}>{fanfleuncer.display.toUpperCase()}</label>
            if (fanfleuncer.id === 'MFANFLUENCER') return <label style={{ color: blueGrey[800] }}>{fanfleuncer.display.toUpperCase()}</label>
            if (fanfleuncer.id === 'LFANFLUENCER') return <label style={{ color: green[500] }}>{fanfleuncer.display.toUpperCase()}</label>
            if (fanfleuncer.id === 'XLFANFLUENCER') return <label style={{ color: lightBlue[500] }}>{fanfleuncer.display.toUpperCase()}</label>
            if (fanfleuncer.id === 'XXLFANFLUENCER') return <label style={{ color: deepPurple[500] }}>{fanfleuncer.display.toUpperCase()}</label>
        }
        if (fanfleuncer) return fanfleuncer.display.toUpperCase();
    }

    const getGateway = (gateway: "" | "Paypal" | "CreditCard" | "Invoice") => {
        if (gateway === 'Invoice') return <label style={{ color: blue[800] }}>{gateway}</label>
        if (gateway === 'Paypal') return <label style={{ color: green[500] }}>{gateway}</label>
        if (gateway === 'CreditCard') return <label style={{ color: deepPurple[900] }}>Credit Card</label>
    }

    const getTransaction = (transaction: "" | "RaceClub Deal" | "Influencer Deal") => {
        if (transaction === 'RaceClub Deal') return <label style={{ color: blue[800] }}>RaceClub Deal</label>
        if (transaction === 'Influencer Deal') return <label style={{ color: green[800] }}>Influencer Deal</label>
    }

    const getTransactionStatus = (status: string) => {
        let stat = transactionstatus.find(x => x.id === status)
        if (stat && stat.id === 'pending') return <label style={{ color: blue[800] }}>{stat.id.toUpperCase()}</label>
        if (stat && stat.id === 'approve') return <label style={{ color: green[800] }}>{stat.id.toUpperCase()}</label>
        if (stat && stat.id === 'cancel') return <label style={{ color: red[700] }}>{stat.id.toUpperCase()}</label>
        if (stat && stat.id === 'complete') return <label style={{ color: purple[500] }}>{stat.id.toUpperCase()}</label>
    }

    const getArray = (array: string[]) => {
        return (
            <div className="array">
                {
                    array.map((i, idx) => {
                        return <label key={idx} className="smaller">{i}</label>
                    })
                }
            </div>
        )
    }

    const getAction = (action: Action) => {
        if (action) {
            if (action.type === 'url') {
                return <Link to={action.url}>
                    <button className="btn btn-primary border-0" style={{ color: "#9c27b0" }}>{t(action.display).toUpperCase()}</button>
                </Link>
            }
            else if (action.type === 'component') {
                return React.createElement(action.component, action.props);
            }
            else return;
        }
    }

    const getCustomerReview = (review: "pending" | "approved" | "suspend" | "deleted") => {
        if (review === "pending") {
            return <label style={{ color: yellow[800] }}>{review.toUpperCase()}</label>
        }
        else if (review === "approved") {
            return <label style={{ color: blue[800] }}>{review.toUpperCase()}</label>
        }
        else if (review === "suspend") {
            return <label style={{ color: red[500] }}>{review.toUpperCase()}</label>
        }
        else if (review === "deleted") {
            return <label style={{ color: grey[500] }}>{review.toUpperCase()}</label>
        }
    }

    const getColor = (color: string) => {
        let _color = color !== '' ? color : '#ffffff';
        if (_color === '#ffffff') return <div style={{ background: _color, height: "30px", aspectRatio: "1.8/1", border: "1px solid lightgray" }}></div>
        else return <div style={{ background: _color, height: "30px", aspectRatio: "1.8/1" }}></div>
    }

    const getImage = (image: string) => {
        if (image && image !== '') {
            return <div style={{ width: "45px", aspectRatio: "1/1" }}>
                <ImageView image={image}></ImageView>
            </div>
        }

    }

    const getStringLink = (link: StringLink) => {
        if (link) {
            return <Link to={link.url} style={{ textDecorationLine: "none", color: "rgb(21, 101, 192)" }}>{link.text}</Link>
        }
    }

    const render = () => {
        if (loading) {
            return (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5px" }}>
                    <CircularProgress size={20} /><label>Loading...</label>
                </div>
            )
        }
        else {
            return (
                <table className="w-100 table-custom">
                    <thead>
                        <tr>
                            {
                                fields.map((field, idx) => {
                                    return (
                                        <td key={idx} align={field.align as "left" | "center" | "right" | "justify" | "char"}>{renderField(field)}</td>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            values.slice(start, end).map((item, idx) => (
                                <tr key={idx}>
                                    {
                                        fields.map((field, id) => (
                                            <td key={id} align={field.align as "left" | "center" | "right" | "justify" | "char"}>
                                                {field.type === "string" && item[field.id]}
                                                {field.type === "number" && new Intl.NumberFormat('en-DE').format(Number(item[field.id] ? item[field.id] : 0))}
                                                {field.type === "decimal" && new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(item[field.id] ? item[field.id] : 0))}
                                                {field.type === "boolean" && getBoolean(item[field.id] as boolean)}
                                                {field.type === "date" && getDate(item[field.id])}
                                                {field.type === "datetime" && getDateTime(item[field.id])}
                                                {field.type === "status" && getStatus(item[field.id] as boolean)}
                                                {field.type === "stockstatus" && getStockStatus(item[field.id])}
                                                {field.type === "review" && getReview(item[field.id])}
                                                {field.type === "customerreview" && getCustomerReview(item[field.id])}
                                                {field.type === "gateway" && getGateway(item[field.id])}
                                                {field.type === "transaction" && getTransaction(item[field.id])}
                                                {field.type === "transactionstatus" && getTransactionStatus(item[field.id])}
                                                {field.type === "ratings" && getRatings(item[field.id])}
                                                {field.type === "array" && getArray(item[field.id] as string[])}
                                                {field.type === "color" && getColor(item[field.id])}
                                                {field.type === "image" && getImage(item[field.id])}
                                                {field.type === "action" && getAction(item[field.id] as Action)}
                                                {field.type === "stringlink" && getStringLink(item[field.id] as StringLink)}
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={fields.length}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}>
                                    {
                                        values.length === 0 && <label>No Records</label>
                                    }
                                    {
                                        values.length > 0 &&
                                        <React.Fragment>
                                            <label>Record Count: {values.length}</label>
                                            <div className="text-center">
                                                <label hidden={values.length <= max}>Page: {current} of {pages}</label>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-end"
                                            }}>
                                                <select className="form-select form-select-sm" aria-label=".form-select-sm example" onChange={(e) => e.target.value !== "all" ? setMax(Number(e.target.value)) : setMax(values.length)}>
                                                    <option value="10">10</option>
                                                    <option value="20">20</option>
                                                    <option value="30">30</option>
                                                    <option value="50">50</option>
                                                    <option value="80">80</option>
                                                    <option value="100">100</option>
                                                    <option value="all">ALL</option>
                                                </select>
                                                <button type="button" className="btn btn-sm btn-primary border-0" disabled={current === 1} onClick={(e) => setCurrent(1)} hidden={values.length <= max}>
                                                    <i className="bi bi-chevron-double-left"></i>
                                                </button>
                                                <button type="button" className="btn btn-sm btn-primary border-0" disabled={current === 1} onClick={(e) => setCurrent(current - 1)} hidden={values.length <= max}>
                                                    <i className="bi bi-chevron-left"></i>
                                                </button>
                                                <button type="button" className="btn btn-sm btn-primary border-0" disabled={current === pages} onClick={(e) => setCurrent(current + 1)} hidden={values.length <= max}>
                                                    <i className="bi bi-chevron-right"></i>
                                                </button>
                                                <button type="button" className="btn btn-sm btn-primary border-0" disabled={current === pages} onClick={(e) => setCurrent(pages)} hidden={values.length <= max}>
                                                    <i className="bi bi-chevron-double-right"></i>
                                                </button>
                                            </div>

                                        </React.Fragment>
                                    }
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            )
        }
    }

    return render();
}

export default TableLayout;