import { Grid } from "@mui/material";
import React from "react";
import ProductSpecificationsViewModel from "../../../viewmodels/product/specifications.viewmodel";
import ProductSpec from "./product-spec";
import ProductSpecCreate from "./product-spec-create";
import { useTranslation } from "react-i18next";
import TableLayout from "../../../components/table/table";

interface ContainerProps {
  id: string;
}

const ProductSpecication: React.FC<ContainerProps> = ({ id }) => {
  const { t } = useTranslation();

  const { fields, list, specs, sizes, colors, add, update, onDelete } = ProductSpecificationsViewModel(id);

  return (
    <React.Fragment>
      <Grid container spacing={1} marginTop={1} marginBottom={1}>
        <Grid xs={7} item>
          <h5 className="subtext">{t('title.specification').toUpperCase()}</h5>
        </Grid>
        <Grid xs={5} item container justifyContent={'right'}>
          <ProductSpecCreate colors={colors} sizes={sizes} save={add}></ProductSpecCreate>
        </Grid>
      </Grid>
      {/*
      <Grid container spacing={1} sx={{ paddingTop: 2 }} columns={5}>
        {specs.filter(x => x.isDeleted !== true).map((x, idx) => <ProductSpec key={idx} specs={x} sizes={sizes} colors={colors} save={update} onDelete={() => onDelete(x.id)}></ProductSpec>)}
      </Grid>
      */}

      <TableLayout fields={fields} data={list}></TableLayout>
    </React.Fragment>
  )
}
export default ProductSpecication;
