export class Size {
    public id: string;
    public description: string;
    public description_de: string;
    public active: boolean;
    public arrangement: number;
}

export class SizeList extends Size {
    public action: any;
}