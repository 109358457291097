import { useEffect, useState } from "react";
import { ProductService } from "../../services/product.service";
import { DealPostBriefing, FanfluencerDeal, FanfluencerSpecialDeal, Product } from "../../models/product.model";
import { useSnackbar } from "notistack";
import { fanfluencers } from "../../config";
import { Console } from "console";

function FanfluencerDealViewModel(id: string) {
    const [fandeals, setFanDeals] = useState<FanfluencerDeal[]>([]);
    const [fanfluencerdealmaxdisc, setFanfluencerdealmaxdisc] = useState(0);
    const [specialdeals, setSpecialDeals] = useState<FanfluencerSpecialDeal[]>([]);
    const [originalPrice, setOriginalPrice] = useState(0);
    const [price, setPrice] = useState(0);
    const [briefing, setBriefing] = useState<DealPostBriefing>(new DealPostBriefing());
    const [submitted, setSubmitted] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const productService: ProductService = new ProductService();

    useEffect(() => {
        if (id) {
            productService.getById(id).then(product => {
                let data: Product = product as Product;
                setOriginalPrice(data.originalprice ? data.originalprice : 0);
                setPrice(data.originalprice ? data.originalprice : 0)
                setFanfluencerdealmaxdisc(data.fanfluencerdealmaxdisc ? data.fanfluencerdealmaxdisc : 1);
                if (data.fanfluencerdeals) {
                    if (data.fanfluencerdeals.length > 0)
                        setFanDeals(data.fanfluencerdeals);
                    else {
                        generateDefault();
                    }
                }
                else {
                    generateDefault();
                }
                if (data.dealbriefing) setBriefing(data.dealbriefing)
                setSpecialDeals(data.fanfluencerspecialdeals ? data.fanfluencerspecialdeals : []);
            });
        }
    }, [id]);

    useEffect(() => {
        if (price !== originalPrice) {
            setOriginalPrice(price);
            let deals: FanfluencerSpecialDeal[] = specialdeals;
            deals.forEach(deal => {
                if (Number(deal.price) > Number(price)) {
                    deal.price = price
                }
            });
            setSpecialDeals([...deals]);
            saveSpecial(deals);
        }
    }, [price])

    const generateDefault = async () => {
        let deals: FanfluencerDeal[] = [];
        ["Story", "Feed", "Reel"].forEach(type => {
            fanfluencers.forEach(fanfluencer => {
                if (fanfluencer.id !== 'NOFANFLUENCER') {
                    let deal: FanfluencerDeal = new FanfluencerDeal();
                    deal.id = fanfluencer.id;
                    deal.name = fanfluencer.display;
                    deal.type = type as "Story" | "Feed" | "Reel";
                    if (deal.type === 'Story') deal.discount = Number(fanfluencers.find(x => x.id === fanfluencer.id)!.story)
                    else if (deal.type === 'Feed') deal.discount = Number(fanfluencers.find(x => x.id === fanfluencer.id)!.feed)
                    else deal.discount = Number(fanfluencers.find(x => x.id === fanfluencer.id)!.reel)
                    deal.enable = true;
                    deals.push(deal);
                }
            })
        })
        await productService.updateFanfluencerDeals(id, deals);
        setFanDeals(deals);
    }

    const update = (fandeal: FanfluencerDeal): Promise<void> => {
        return new Promise(async resolve => {
            let deals = fandeals;
            let idx: number = deals.findIndex(x => x.id === fandeal.id && x.type === fandeal.type);
            if (idx >= 0) {
                deals[idx] = fandeal;
                setFanDeals([...deals]);
            }
            await productService.updateFanfluencerDeals(id, deals);
            enqueueSnackbar("Influencer deals updated", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            resolve();
        })
    }

    const updateMax = (value: number): Promise<void> => {
        return new Promise(async resolve => {
            setFanfluencerdealmaxdisc(value);
            await productService.updateField(id, 'fanfluencerdealmaxdisc', value);
            enqueueSnackbar("Influencer deals updated", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            resolve();
        });
    }

    const saveSpecial = async (deals: FanfluencerSpecialDeal[]) => {
        await productService.updateField(id, 'fanfluencerspecialdeals', deals);
        enqueueSnackbar("Influencer special deals saved", {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
    }

    const addSpecial = (value: FanfluencerSpecialDeal): Promise<void> => {
        return new Promise(async resolve => {
            let deals: FanfluencerSpecialDeal[] = specialdeals;
            deals.push(value);
            setSpecialDeals([...deals]);
            await saveSpecial(deals);
            resolve();
        });
    }


    const removeSpecial = (value: FanfluencerSpecialDeal): Promise<void> => {
        return new Promise(async resolve => {
            let deals: FanfluencerSpecialDeal[] = specialdeals;
            deals = deals.filter(x => x !== value);
            setSpecialDeals([...deals]);
            await saveSpecial(deals);
            resolve();
        });
    }

    const change = (field: string, value: any) => {
        setBriefing({ ...briefing,[field]: value });
    }

    const submit = async () => {
        setSubmitted(true);
    
        await productService.updateBriefing(id, briefing);

        enqueueSnackbar("Influencer Deal Post Briefing Updated.", {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });

        setSubmitted(false);
    }

    return { fandeals, specialdeals, fanfluencerdealmaxdisc, originalPrice, briefing, setPrice, update, updateMax, addSpecial, removeSpecial, change, submit, submitted }
}

export default FanfluencerDealViewModel;