import React, { useEffect, useState } from "react";
import { FanfluencerDeal, FanfluencerSpecialDeal } from "../../../models/product.model";
import { Box, Button, Grid, IconButton, InputAdornment, Modal, Switch, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import pass, { Validation } from "../../../models/validation.model";
import { fanfluencers } from "../../../config";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input-field";

interface ContainerProps {
  id: string;
  type: string;
  deal: FanfluencerDeal;
  specialdeals: FanfluencerSpecialDeal[];
  originalPrice: number;
  update: (deal: FanfluencerDeal) => void;
  addSpecial: (deal: FanfluencerSpecialDeal) => void;
  removeSpecial: (deal: FanfluencerSpecialDeal) => void;
}

const ProductFanfluencerType: React.FC<ContainerProps> = ({ id, type, deal, specialdeals, originalPrice, update, addSpecial, removeSpecial }) => {
  const { t } = useTranslation();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  let temp: FanfluencerSpecialDeal = new FanfluencerSpecialDeal();
  temp.post = 0;
  temp.price = 0;

  const [fanId, setFanId] = useState(id);
  const [fanType, setFanType] = useState(type);
  const [fanDeal, setFanDeal] = useState<FanfluencerDeal>(deal);
  const [fanSpecialDeals, setFanSpecialDeals] = useState<FanfluencerSpecialDeal[]>(specialdeals);
  const [fanSpecialDeal, setFanSpecialDeal] = useState<FanfluencerSpecialDeal>(temp);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<Validation[]>([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setFanDeal(deal);
  }, [deal]);

  useEffect(() => {
    setFanSpecialDeals(specialdeals);
  }, [specialdeals]);

  useEffect(() => {
    setFanType(type);
  }, [type]);

  useEffect(() => {
    setFanId(id);
  }, [id]);

  const rules: any[] = [
    {
      field: "post",
      type: "valid",
      datatype: "number",
      message: "Not a Valid Number",
    },
    {
      field: "post",
      type: "min",
      length: 1,
      message: "0 Post Count is not allowed",
    },
    {
      field: "price",
      type: "valid",
      datatype: "number",
      message: "Not a Valid Number",
    },
    {
      field: "price",
      type: "max",
      datatype: "number",
      message: "Must not exceed Original Price",
      length: Number(originalPrice) + 1
    },
  ];

  const getDiscount = () => {
    if (deal.discount) {
      return deal.discount;
    }
    return 0;
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getDisplayName = () => {
    if (fanDeal.enable) {
      return (
        <React.Fragment>
          <span>
            <strong>€ {getDiscount()}</strong> {t("text.discountper")} {fanDeal.type} Post
          </span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span style={{ textDecoration: "line-through" }}>
            <strong>€ {getDiscount()}</strong> {t("text.discountper")} {fanDeal.type} Post
          </span>
        </React.Fragment>
      );
    }
  };

  const renderSpecialDeals = () => {
    if (fanSpecialDeals.length > 0) {
      return (
        <Box paddingX={1} paddingY={1} borderTop={1} borderColor={"lightgray"}>
          <Grid container>
            <Grid item xs={12}>
              <span>{t("title.specialdeals").toUpperCase()}</span>
            </Grid>
          </Grid>
          {fanSpecialDeals.map((special) => (
            <Grid container key={fanId + type + fanSpecialDeals.indexOf(special)}>
              <Grid item xs={11} container alignItems="center">
                <span>
                  <strong>{Number(special.post)}</strong> {special.type} post(s) {t("text.for")} <strong>{new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(special.price))} €</strong>
                </span>
              </Grid>
              <Grid item xs={1} container justifyContent="flex-end">
                <IconButton onClick={(e) => remove(special)}>
                  <CloseIcon></CloseIcon>
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Box>
      );
    } else {
      return;
    }
  };

  const clear = () => {
    setErrors([]);
    setFanSpecialDeal({ ...fanSpecialDeal, price: 0, post: 0 });
  };

  const change = (field: string, value: number) => {
    setFanSpecialDeal({ ...fanSpecialDeal, [field]: value });
    if (submitted) isValid();
  };

  const getError = (field: string): Validation | undefined => {
    return errors.find((x) => x.field === field);
  };

  const getErrorMessage = (field: string): string | undefined => {
    let message: string | undefined = undefined;
    if (errors.find((x) => x.field === field)) {
      message = t(errors.find((x) => x.field === field)!.message);
    }
    return message;
  };

  const isValid = (): boolean => {
    let values: Validation[] = [];
    rules.forEach((rule) => {
      let validation: Validation = rule as Validation;
      if (pass(validation, fanSpecialDeal[validation.field as keyof typeof fanSpecialDeal].toString()) === false) {
        values.push(validation);
      }
    });
    setErrors([...values]);
    return values.length === 0;
  };

  const save = async () => {
    setSubmitted(true);
    if (isValid() === false) {
      return;
    }
    let deal: FanfluencerSpecialDeal = fanSpecialDeal;
    deal.fanId = fanId;
    deal.type = fanType as "" | "Story" | "Feed" | "Reel";
    await addSpecial(deal);
    let deals: FanfluencerSpecialDeal[] = fanSpecialDeals;
    deals.push(deal);
    setFanSpecialDeals([...deals]);
    setSubmitted(false);
    clear();
    handleClose();
  };

  const remove = async (deal: FanfluencerSpecialDeal) => {
    await removeSpecial(deal);
    let deals: FanfluencerSpecialDeal[] = fanSpecialDeals;
    deals = deals.filter((x) => x !== deal);
    setFanSpecialDeals(deals);
  };

  return (
    <React.Fragment>
      <Grid item xs={3}>
        <Box border={1} borderColor={"lightgray"} borderRadius={1}>
          <Box paddingX={1} paddingTop={1} borderBottom={1} borderColor={"lightgray"}>
            <h6>{fanfluencers.find((x) => x.id === fanDeal.id)!.display}</h6>
          </Box>
          <Box paddingX={1} paddingY={1}>
            <Grid container>
              <Grid item xs={10}>
                {getDisplayName()}
              </Grid>
              <Grid item xs={2} container justifyContent="flex-end">
                <Switch
                  size="small"
                  checked={fanDeal.enable}
                  onChange={(e) => {
                    let deal = fanDeal;
                    deal.enable = e.target.checked;
                    setFanDeal((fanDeal) => ({
                      ...deal,
                    }));
                    update(deal);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          {renderSpecialDeals()}
          <Box borderTop={1} borderColor={"lightgray"} textAlign={"right"}>
            <Button onClick={handleOpen}>{t("button.addspecialdeal")}</Button>
          </Box>
        </Box>
      </Grid>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={headerStyle}>{t("title.specialinfluencerdeals")}</Box>
          <Box sx={detailStyle}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span>
                  {t("text.specialinfluencerdealfor")} {fanfluencers.find((x) => x.id === id)!.display}'s {fanType}-Post
                </span>
              </Grid>
              <Grid item xs={12} paddingBottom={1}>
                <span>
                  {t("field.originalprice")}: {new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(originalPrice))}
                </span>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={t("field.post")}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={Number(fanSpecialDeal.post ? fanSpecialDeal.post : 0)}
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  onChange={(e) => {
                    change("post", Number(e.target.value));
                  }}
                  onBlur={(e) => {
                    change("post", Number(e.target.value));
                  }}
                  onFocus={(e) => e.target.select()}
                  error={getError("post") !== undefined}
                  helperText={getErrorMessage("post")}
                />
              </Grid>
              <Grid item xs={6}>
                <div className="priceinputdiv">
                  <label>{t("field.price")}</label>
                  <CurrencyInput
                    className="priceinput"
                    defaultValue={Number(fanSpecialDeal.price) === 0 ? "" : fanSpecialDeal.price.toFixed(2)}
                    decimalsLimit={2}
                    decimalScale={2}
                    fixedDecimalLength={2}
                    suffix=" €"
                    decimalSeparator=","
                    groupSeparator="."
                    onValueChange={(value, name, values) => {
                      if (values) change("price", Number(values.float))
                    }}
                  />
                </div>
                {/* 
                <TextField
                  label={t("field.price")}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={Number(fanSpecialDeal.price ? fanSpecialDeal.price : 0)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                  }}
                  inputProps={{
                    style: { textAlign: "right" },
                  }}
                  onChange={(e) => {
                    change("price", Number(e.target.value));
                  }}
                  onBlur={(e) => {
                    change("price", Number(e.target.value));
                  }}
                  onFocus={(e) => e.target.select()}
                  error={getError("price") !== undefined}
                  helperText={getErrorMessage("price")}
                />
                */}
              </Grid>
            </Grid>
          </Box>
          <Box sx={footerStyle}>
            <Button
              variant="outlined"
              size="medium"
              sx={{ ml: 1 }}
              onClick={(e) => {
                save();
              }}
            >
              {t("button.save")}
            </Button>
            <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={(e) => {
              clear();
              handleClose();
            }}>
              {t("button.cancel")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ProductFanfluencerType;
