import {
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import FormLayout from "../../components/form/FormLayout";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AdminListViewModel from "../../viewmodels/admin/list.viewmodel";
import AdminCreate from "./admin-create";
import { useTranslation } from "react-i18next";
import TableLayout from "../../components/table/table";

function Admins() {
  const { t } = useTranslation();

  const { admins, searches, fields, search } = AdminListViewModel();

  const handleKeyUp = (event: any) => {
    search(event.target.value);
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle={t("title.administrators")} Breadcrumbs={[{ title: t('title.dashboard'), url: "/" }, { title: t("title.administrators") }]}></FormLayout>

      <Grid container sx={{ paddingBottom: 2 }}>
        <Grid xs={6} container item>
          <TextField
            id="outlined-search"
            size="small"
            placeholder={t("text.search")}
            type="search"
            name="search"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onKeyUp={(e) => handleKeyUp(e)}
          />
        </Grid>
        <Grid xs={6} container direction="row" justifyContent="right" alignItems="center" item>
          <AdminCreate />
        </Grid>
      </Grid>

      <TableLayout fields={fields} data={searches !== undefined ? searches : admins}></TableLayout>
    </React.Fragment>
  );
}

export default Admins;
