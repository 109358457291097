import React from "react";
import { useTranslation } from "react-i18next";
import TransactionListViewModel from "../../viewmodels/transaction/list.viewmodel";
import TableLayout from "../../components/table/table";

const DashboardPayment: React.FC = () => {
  const { t } = useTranslation();
  const { paymentList, fields } = TransactionListViewModel();

  return (
    <React.Fragment>
      <h5 style={{ marginTop: "2rem", color: "#64748B" }}>{t('title.transactions')}</h5>
      <TableLayout fields={fields} data={paymentList ? paymentList.filter(x => x.status === 'pending') : undefined}></TableLayout>
    </React.Fragment>
  );
};

export default DashboardPayment;
