import { useEffect, useState } from "react";
import { Customer, CustomerList } from "../../models/customer.model";
import { ExcelService } from "../../services/excel.service";
import { useTranslation } from "react-i18next";
import { TableField } from "../../models/table.model";
import { collection, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";
import CustomerAction from "../../views/customer/customer-actions";
import { Action } from "../../components/table/table";

function CustomerListViewModel(_mode: "" | "moderate") {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const { t } = useTranslation();

    const [mode, setMode] = useState<'' | 'moderate' | undefined>(undefined);
    const [customers, setCustomers] = useState<Customer[] | undefined>(undefined);
    const [customerList, setCustomerList] = useState<CustomerList[] | undefined>(undefined);
    const [search, setSearch] = useState<string>("");
    const [filter, setFilter] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [searches, setSearches] = useState<CustomerList[] | undefined>(undefined);

    const excelService: ExcelService = new ExcelService();

    const fields = [
        { id: "id", display: "#", lign: "left", sort: false, type: 'string' },
        { id: "dmsbid", display: "DMSB ID", lign: "left", sort: false, type: 'string' },
        { id: "email", display: t("field.email").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "name", display: t("field.name").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "birthday", display: t("field.bday").toUpperCase(), align: "right", sort: false, type: 'date' },
        { id: "withLicense", display: "DRIVER'S LICENSE", align: "center", sort: false, type: 'boolean' },
        { id: "withOfficial", display: "OFFICIAL'S LICENSE", align: "center", sort: false, type: 'boolean' },
        { id: "instagram", display: "instagram".toUpperCase(), align: "left", sort: false, type: 'string' },
        { id: "followers", display: t("field.followers").toUpperCase(), align: "right", sort: true, type: 'number' },
        { id: "ratings", display: t("field.ratings").toUpperCase(), align: "center", sort: false, type: 'ratings' },
        { id: "active", display: t("field.status").toUpperCase(), align: "center", sort: false, type: 'status' },
        { id: "status", display: t("field.reviewstatus").toUpperCase(), align: "center", sort: false, type: 'customerreview' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' },
    ] as TableField[]

    useEffect(() => {
        setCustomers(undefined);
        setCustomerList(undefined);
        setSearches(undefined);
        setSearch("");
        setFilter("");
        setStatus("");
        setMode(_mode);
    }, [_mode])

    useEffect(() => {
        if (mode !== undefined) {
            let constraints = [];
            let items: Customer[] = [];
            let itemList: CustomerList[] = [];
            if (mode !== '') constraints.push(where('status', '==', 'pending'));
            constraints.push(orderBy('created', 'desc'));
            const q = query(collection(firestore, "accounts"), ...constraints);
            let unSubcribe = onSnapshot(q, (snap) => {

                snap.docChanges().forEach((change) => {
                    let customer: Customer = change.doc.data() as Customer;
                    customer.id = change.doc.id;

                    if (change.type === 'added') {
                        items.push(customer);
                        itemList.push(convertCustomerList(customer))
                    }
                    if (change.type === 'modified') {
                        let idx = items.findIndex(x => x.id === change.doc.id);
                        if (idx >= 0) items[idx] = { ...items[idx], ...change.doc.data() }

                        idx = itemList.findIndex(x => x.id === change.doc.id);
                        if (idx >= 0) itemList[idx] = { ...itemList[idx], ...customer }
                    }
                });

                setCustomers([...items])
                setCustomerList([...itemList])
            });

            return () => {
                unSubcribe();
            }
        }
    }, [mode]);

    useEffect(() => {
        if (search !== '' || filter !== '' || status !== '') {
            let items = customers ? customers : [];

            if (filter !== '') {
                if (filter === "pending") items = items.filter(x => x.status === "pending")
                if (filter === "approved") items = items.filter(x => x.status === "approved")
                if (filter === "suspend") items = items.filter(x => x.status === "suspend")
                if (filter === "deleted") items = items.filter(x => x.status === "deleted")
                if (filter === "newsletter") items = items.filter(x => (x.newsletter !== undefined ? x.newsletter : false) === true)
            }

            if (status !== '') {
                if (status === "active") items = items.filter(x => (x.active !== undefined ? x.active : true) === true)
                else if (status === "inactive") items = items.filter(x => (x.active !== undefined ? x.active : true) === false)
            }

            if (search !== '') {
                let list: Customer[] = []
                items.forEach(item => {
                    let email: string = item.email ? item.email.toLowerCase() : '';
                    let firstname: string = item.firstname ? item.firstname.toLowerCase() : '';
                    let lastname: string = item.lastname ? item.lastname.toLowerCase() : '';
                    let instagram: string = item.instagram ? item.instagram.toLowerCase() : '';
                    let dmsbid: string = item.dmsbid ? item.dmsbid.toString() : '';

                    console.log(dmsbid, search)
                    if (email.includes(search.toLowerCase()) ||
                        firstname.includes(search.toLowerCase()) ||
                        lastname.includes(search.toLowerCase()) ||
                        instagram.includes(search.toLowerCase()) ||
                        dmsbid === search.toLowerCase()
                    ) {
                        list.push(item);
                    }
                })
                items = [...list];
            }

            let itemList: CustomerList[] = [];
            items.forEach(item => itemList.push(convertCustomerList(item)));
            setSearches([...itemList])

        }
        else {
            setSearches(undefined)
        }
    }, [search, filter, status])

    const convertCustomerList = (customer: Customer) => {
        let item: CustomerList = new CustomerList();
        item.id = customer.id;
        item.email = customer.email;
        item.name = customer.firstname && customer.firstname ? customer.firstname + " " + customer.lastname : '';
        item.birthday = customer.birthday;
        item.withLicense = customer.withLicense;
        item.withOfficial = customer.withOfficial;
        item.instagram = customer.instagram;
        item.followers = customer.followers;
        item.ratings = customer.ratings;
        item.dmsbid = customer.dmsbid ? customer.dmsbid : "";
        item.status = customer.status;
        item.active = customer.active !== undefined ? customer.active : true;
        let action: Action = new Action();
        action.type = 'component';
        action.component = CustomerAction;
        action.props = {
            customer: { ...customer },
            mode: mode
        }
        item.action = action
        return item;
    }

    const exporttoexcel = () => {
        if (customers) excelService.customers(customers);
    }
    /*
    const updateStatus = async (id: string, status: "pending" | "approved" | "suspend" | "deleted") => {
        await customerService.updateStatus(id, status);
        customers.find(x => x.id === id)!.status = status;
        setCustomers([...customers])
        enqueueSnackbar("Status Change", {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
    }

    const updateRatings = async (id: string, followers: number, ratings: string) => {
        await customerService.updateRatings(id, followers, ratings);
        customers.find(x => x.id === id)!.followers = followers;
        customers.find(x => x.id === id)!.ratings = ratings;
        setCustomers([...customers])
        enqueueSnackbar("Status Change", {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
    }

    const refresh = (): Promise<void> => {
        return new Promise(resolve => {
            resolve()
        })
    }

    const exporttoexcel = () => {
        excelService.customers(customers);
    }

    const sentPasswordLink = (email: string): Promise<void> => {
        return new Promise(async resolve => {
            await accountService.sendChangePassword(email);
            enqueueSnackbar("Password Reset Email is on the way", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            resolve()
        })
    }
    */
    return { customers, customerList, searches, fields, setSearch, setFilter, setStatus, exporttoexcel };
}

export default CustomerListViewModel;