import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { Customer, CustomerList } from "../../models/customer.model";
import { useNavigate } from "react-router-dom";
import CustomerRatings from "./customer-ratings";
import CustomerStatus from "./customer-status";
import { useTranslation } from "react-i18next";
import { CustomerService } from "../../services/customer.service";
import { useSnackbar } from "notistack";
import { AccountService } from "../../services/account.service";

interface ContainerProps {
  customer: Customer;
  mode: "" | "moderate";
}

const CustomerAction: React.FC<ContainerProps> = ({ customer, mode }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const customerService: CustomerService = new CustomerService();
  const accountService: AccountService = new AccountService();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateRatings = (id: string, followers: number, ratings: string) => {
    handleClose();
    customerService.updateRatings(id, followers, ratings).then(() => {
      enqueueSnackbar("Status Change", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    })
  }

  const updateStatus = (id: string, status: "pending" | "approved" | "suspend" | "deleted") => {
    handleClose();
    customerService.updateStatus(id, status).then(() => {
      enqueueSnackbar("Status Change", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    })
  }

  const sentPasswordLink = (email: string): Promise<void> => {
    handleClose();
    return new Promise(async resolve => {
      await accountService.sendChangePassword(email);
      enqueueSnackbar("Password Reset Email is on the way", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      resolve()
    })
  }

  const renderActions = () => {
    if (customer.status !== "deleted") {
      if (mode === "")
        return (
          <React.Fragment>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              size="small"
              color="secondary"
              onClick={handleClick}
            >
              {t("field.actions")}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  navigate("/customers/" + customer.id);
                }}
              >
                Open
              </MenuItem>
              <CustomerRatings customer={customer} onRatingsChange={updateRatings}></CustomerRatings>
              <CustomerStatus customer={customer} onStatusChange={updateStatus}></CustomerStatus>
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  sentPasswordLink(customer.email);
                }}
              >
                Send Reset Link
              </MenuItem>
            </Menu>
          </React.Fragment>
        );
      else
        return (
          <React.Fragment>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              size="small"
              color="secondary"
              onClick={handleClick}
            >
              {t("field.actions")}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  navigate("/customers/" + customer.id);
                }}
              >
                Open
              </MenuItem>
              <CustomerStatus customer={customer} onStatusChange={updateStatus}></CustomerStatus>
            </Menu>
          </React.Fragment>
        );
    }
  };

  return <React.Fragment>{renderActions()}</React.Fragment>;
};

export default CustomerAction;
