import { Box, Button, Card, CardContent, Chip, Grid, Stack, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import SupplierEmailViewModel from "../../viewmodels/supplier/emails.viewmodel";

interface ContainerProps {
  id: string;
}

const SupplierEmails: React.FC<ContainerProps> = ({ id }) => {
  const { t } = useTranslation();

  const { values, value, getError, getErrorMessage, change, process, submit, onDelete } = SupplierEmailViewModel(id);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <Grid container>
        <Grid xs={12} item >
          <h5 className="subtext mb-0">{t("title.emails").toUpperCase()}</h5>
        </Grid>
        <Grid container spacing={2} paddingTop={4}>
          <Grid xs={3} item>
            <TextField
              fullWidth
              label={t("field.email")}
              variant="outlined"
              size="small"
              value={value}
              error={getError("email") !== undefined}
              helperText={getErrorMessage("email")}
              onChange={(e) => change(e.target.value)}
            />
          </Grid>
          <Grid xs={2} item>
            <Button type="submit" variant="contained" disabled={process}>ADD</Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={4}>
          <Grid xs={12} item>
            <Stack direction="row" spacing={1} rowGap={1} flexWrap="wrap">
              {values.map((x) => (
                <Chip key={x} label={x} color="primary" variant="outlined" onDelete={(e) => onDelete(x)} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default SupplierEmails;
