import React from "react";
import { Customer } from "../../models/customer.model";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import LicenseViewModel from "../../viewmodels/customer/license.viewmodel";


interface ContainerProps {
    customers: Customer[] | undefined;
}

const CustomerLicenseValidation: React.FC<ContainerProps> = ({ customers }) => {

    const { items, open, running, output, startProcess, stopProcess, onHandleOpen, onHandleClose } = LicenseViewModel(customers);

    return (
        <React.Fragment>
            <Button variant="contained" color={"warning"} size="medium" disabled={items === undefined || items.length == 0} onClick={(e) => onHandleOpen()}>License Validation</Button>
            <Dialog
                open={open}
                onClose={onHandleClose}
                scroll={'paper'}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} height={50} borderBottom={-0.1} borderColor={"lightgray"} paddingInline={3}>
                    <label>License Validation</label>
                    {
                        running === true && <CircularProgress size={10} />
                    }
                </Box>
                <DialogContent dividers={true}>
                    <Box height={150} display={"flex"} flexDirection={"column"}>
                        {
                            output.map((value, idx) => <label key={idx}>{value}</label>)
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    {
                        running === true && <Button onClick={(e) => stopProcess()}>Stop</Button>
                    }
                    {
                        running === false && <Button onClick={(e) => startProcess()}>Start</Button>
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default CustomerLicenseValidation;