import React, { useEffect, useState } from "react";
import FormLayout from "../../components/form/FormLayout";
import { Box, Button, Card, CardContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import TransactionDetailViewModel from "../../viewmodels/transaction/detail.viewmodel";
import { PaymentAddress, PaymentItem } from "../../models/payment.model";
import { Product, Specs } from "../../models/product.model";
import ImageView from "../../components/Image/ImageView";
import { Color } from "../../models/color.model";
import { Size } from "../../models/size.model";
import { useTranslation } from "react-i18next";
import { transactionstatus } from "../../config";
import { getPaymentStatus, getPaymentStatuses } from "../../helper/transaction.helper";

const TransactionDetail: React.FC = () => {
  const { t } = useTranslation();

  let subtext = {
    fontWeight: "300",
  };

  const { id } = useParams();

  const { payment, statuses, brand, customer, products, colors, sizes, changeStatus, getError, getErrorMessage } = TransactionDetailViewModel(id!);

  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (payment && payment.status) setStatus(payment.status);
    if (payment && payment.reason) setReason(payment.reason);
    if (payment) setStatus(getPaymentStatus(payment).id)
  }, [payment]);

  const onChangeStatus = () => {
    changeStatus(status, reason);
  };

  const renderItem = (idx: number, item: PaymentItem) => {
    let product: Product | undefined = products.find((x) => x.id === item.productid);
    let specs: Specs | undefined = product!.specs ? product!.specs.find((x) => x.id === item.specsid) : undefined;
    let color: Color | undefined = specs ? colors.find((x) => x.id === specs!.color) : undefined;
    let size: Size | undefined = specs ? sizes.find((x) => x.id === specs!.size) : undefined;
    let image: string = product!.primary;
    let specimage = specs && specs.image !== "" ? specs.image : "";

    return (
      <Grid item xs={12} key={"payitem-" + idx.toString()} container paddingY={2} paddingX={1} borderBottom={1} borderColor={"lightgray"}>
        <Grid item xs={1} className="imageview-div" border={1} borderColor={"lightgray"}>
          <ImageView image={image}></ImageView>
        </Grid>
        <Grid item xs={5} paddingLeft={1}>
          <Box>
            <Link to={"/products/" + item.productid} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
              {product ? product.name_en : ""}
            </Link>
          </Box>
          <Box>
            <span>{product ? product.article : ""}</span>
          </Box>
          <Box>
            <span style={subtext}>{t("field.quantity")}: </span>
            <span>{item.quantity}</span>
          </Box>
          <Box>
            <span style={subtext}>{t("field.originalprice")}: </span>
            <span>{product ? new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(product.originalprice)) : ""} €</span>
          </Box>
          <Box>
            <span style={subtext}>{t("field.warehouse")}: </span>
            <span>{product ? product.warehouse : ""}</span>
          </Box>
        </Grid>
        <Grid item xs={5} paddingLeft={1}>
          {specs ? (
            <React.Fragment>
              <Box>
                <span style={subtext}>{t("title.specification").toUpperCase()}</span>
              </Box>
              <Box sx={{ width: 50, aspectRatio: "1/1", objectFit: "cover", padding: "2px", border: "1px solid lightgray" }}>
                <ImageView image={specimage}></ImageView>
              </Box>
              <Box>
                <span style={subtext}>{t("field.color")}: </span>
                <span>{color ? color.description : ""}</span>
              </Box>
              <Box>
                <span style={subtext}>{t("field.size")}: </span>
                <span>{size ? size.description : ""}</span>
              </Box>
            </React.Fragment>
          ) : (
            ""
          )}
        </Grid>

        <Grid item xs={1} container justifyContent={"right"} height={"100%"}>
          <span>{new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(item.price))} €</span>
        </Grid>
      </Grid>
    );
  };

  const renderPayment = () => {
    if (payment) {
      if (payment.gateway === "Paypal" && payment.paypaldetails) {
        return (
          <React.Fragment>
            <Box>
              <span style={subtext}>{t("field.method")}: </span>
              <span>PayPal</span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>Payer ID: </span>
              <span>{payment.paypaldetails!.payerid}</span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>{t("field.email")}: </span>
              <span>{payment.paypaldetails!.email}</span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>{t("field.name")}: </span>
              <span>
                {payment.paypaldetails!.firstname} {payment.paypaldetails!.lastname}
              </span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>{t("field.status")}: </span>
              <span>{payment.paypaldetails!.status}</span>
            </Box>
          </React.Fragment>
        );
      } else if (payment.gateway === "CreditCard" && payment.stripedetails) {
        return (
          <React.Fragment>
            <Box>
              <span style={subtext}>{t("field.method")}: </span>
              <span>Credit Card</span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>{t("field.type")}: </span>
              <span>{payment.stripedetails!.type.toUpperCase()}</span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>{t("field.cardbrand")}: </span>
              <span>{payment.stripedetails!.brand.toUpperCase()}</span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>{t("field.email")}: </span>
              <span>{payment.stripedetails!.email}</span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>{t("field.name")}: </span>
              <span>{payment.stripedetails!.name}</span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>{t("field.status")}: </span>
              <span>{payment.stripedetails!.status.toUpperCase()}</span>
            </Box>
          </React.Fragment>
        );
      } else if (payment.gateway === "Invoice") {
        return (
          <React.Fragment>
            <Box>
              <span style={subtext}>{t("field.method")}: </span>
              <span>Invoice</span>
            </Box>
          </React.Fragment>
        );
      }
      if (payment.dealdetails && payment.dealdetails !== null) {
        return (
          <React.Fragment>
            <Box>
              <span style={subtext}>Influencer Deal: </span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>{t("field.type")}: </span>
              <span>{payment.dealdetails.type.toUpperCase()}</span>
            </Box>
            <Box paddingTop={1}>
              <span style={subtext}>{t("field.postcount")}: </span>
              <span>{payment.dealdetails.count}</span>
            </Box>
          </React.Fragment>
        );
      }
    }
  };

  const renderAddress = (type: string, address: PaymentAddress | undefined) => {
    if (address) {
      return (
        <Grid container borderTop={1} borderColor={"lightgray"} marginTop={2} paddingTop={2}>
          <Grid item>
            <span style={subtext}>
              {type} {">"} {address.title} {">"} {address.name} {">"} {address.address}, {address.state}, {address.city} {address.postcode}
            </span>
          </Grid>
        </Grid>
      );
    }
  };

  const isDisable = () => {
    return (
      (payment && payment.status ? payment.status : transactionstatus[0].id) === status &&
      (payment && payment.reason ? payment.status : "") === reason
    );
  };

  return (
    <React.Fragment>
      <FormLayout
        PageTitle={t("title.transactions")}
        Breadcrumbs={[
          { title: t("title.dashboard"), url: "/" },
          { title: t("title.transactions"), url: "/transactions" },
          { title: t("title.transactiondetail") },
        ]}
      ></FormLayout>
      <Grid container direction="row" justifyContent="center" alignItems="center" marginBottom={4}>
        <Grid item xs={9}>
          <Card variant="outlined">
            <CardContent>
              <Grid container>
                <Grid item xs={12} borderBottom={1} borderColor={"lightgray"} paddingBottom={2} container>
                  <Grid item xs={6}>
                    <span style={subtext}>{t("field.transaction")}: </span>
                    <span>#{payment ? payment.id : ""}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span style={subtext}>{t("field.status")}: </span>
                    <span>{payment ? getPaymentStatus(payment).id.toUpperCase() : ""}</span>
                  </Grid>
                </Grid>
                <Grid item xs={6} paddingTop={2}>
                  <span style={subtext}>{t("field.brand")}: </span>
                  <span>
                    <Link
                      to={"/brands/" + (payment ? payment.brandid : "")}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      {brand ? brand.name : ""}
                    </Link>
                  </span>
                </Grid>
                <Grid item xs={6} paddingTop={2}>
                  <span style={subtext}>{t("field.date")}: </span>
                  <span>{payment && payment.added ? payment.added.toDate().toLocaleString() : ""}</span>
                </Grid>
                <Grid item xs={12} paddingTop={1}>
                  <span style={subtext}>{t("field.customer")}: </span>
                  <span>
                    {payment ? "#" + payment.accountid : ""} - {customer ? customer.firstname : ""} {customer ? customer.lastname : ""}
                  </span>
                </Grid>
              </Grid>
              <Grid container borderTop={1} borderColor={"lightgray"} marginTop={2}>
                {payment ? payment.items.map((item, idx) => renderItem(idx, item)) : ""}
              </Grid>
              <Grid container marginTop={2} paddingX={1}>
                <Grid item xs={6}>
                  {renderPayment()}
                </Grid>
                <Grid item xs={6} container>
                  <Grid item xs={6}>
                    <span style={subtext}>Total: </span>
                  </Grid>
                  <Grid item xs={6} container justifyContent={"right"}>
                    <span>{payment ? new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(payment.price)) : ""} €</span>
                  </Grid>
                </Grid>
              </Grid>
              {renderAddress("Shipping Address", payment && payment.shipping ? payment.shipping : undefined)}
              {renderAddress("Billing Address", payment && payment.billing ? payment.billing : undefined)}
              <Grid container borderTop={1} borderColor={"lightgray"} marginTop={2} paddingTop={2}>
                <Grid item xs={4} paddingRight={1}>
                  <FormControl fullWidth>
                    <InputLabel id="size" size={"small"}>
                      {t("field.status")}
                    </InputLabel>
                    <Select
                      labelId="size"
                      label={t("field.status")}
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      size={"small"}
                    >
                      {getPaymentStatuses(payment).map((stat, idx) => (
                        <MenuItem value={stat.id} key={idx}>
                          {stat.id.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8} paddingX={1}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Reasons/Notes"
                    multiline
                    maxRows={4}
                    size={"small"}
                    fullWidth
                    value={reason}
                    error={getError("reason") !== undefined}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  />
                  <FormHelperText>{getErrorMessage("reason")}</FormHelperText>
                </Grid>
                <Grid xs={12} item container justifyContent={"center"} marginTop={3}>
                  <Button type="button" variant="contained" disabled={isDisable()} onClick={(e) => onChangeStatus()}>
                    {t("button.confirm")}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TransactionDetail;
