import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import EditForm from "../../components/form/EditForm";
import FormLayout from "../../components/form/FormLayout";
import useEditViewModel, { ACTION } from "../shared/useEditViewModel";

function AdminDetail() {
  const { id } = useParams();

  let _model = {
    email: "",
    firstname: "",
    lastname: "",
    active: false,
  };

  let _validate = {
    rules: [
      {
        field: "email",
        type: "required",
        message: "Email Address is Required"
      },
      {
        field: "email",
        datatype: "email",
        type: "valid",
        message: "Not a Valid Email Address"
      }
    ],
    errors: {
    }
  }

  const [viewModel, dispatch] = useEditViewModel(_model, "administrators", _validate);

  useEffect(() => {
    dispatch({ type: ACTION.LOAD, id: id });
  }, []);

  return (
    <React.Fragment>
      <FormLayout
        PageTitle="Administrators"
        Breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Administrators", url: "/admins" },
          { title: "Administrator Profile" },
        ]}
      ></FormLayout>

      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <EditForm OnSave={(e) => dispatch({ type: ACTION.SAVE })}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox />}
                label="Active"
                checked={viewModel.model.active}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "active", value: e.target.checked },
                  })
                }
              />
              <hr />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                type="email"
                variant="outlined"
                size="small"
                value={viewModel.model.email}
                error={viewModel.validate.email !== undefined}
                helperText={viewModel.validate.email}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "email", value: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="First Name"
                required
                variant="outlined"
                size="small"
                value={viewModel.model.firstname}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "firstname", value: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                required
                variant="outlined"
                size="small"
                value={viewModel.model.lastname}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "lastname", value: e.target.value },
                  })
                }
              />
            </Grid>
          </EditForm>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AdminDetail;
