import { useEffect, useState } from "react";
import { Brand, CreditCardDetail, PayPalDetail } from "../../models/brand.model";
import pass, { Validation } from "../../models/validation.model";
import { useSnackbar } from "notistack";
import { SupplierService } from "../../services/supplier.service";
import { useTranslation } from "react-i18next";

function SupplierPaymentDetailsViewModel(id: string, enable: boolean, type: 'creditcard' | 'paypal' | 'invoice') {
  const { t } = useTranslation();

  const [paymentEnable, setPaymentEnable] = useState(false);
  const [model, setModel] = useState<CreditCardDetail | PayPalDetail | undefined>(undefined);
  const [errors, setErrors] = useState<Validation[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [process, setProcess] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const supplierService: SupplierService = new SupplierService();

  useEffect(() => {
    if (id) {
      loadBrand();
    }
  }, [id]);

  const loadBrand = async () => {
    let brand: Brand = await supplierService.getById(id) as Brand;
    if (type === 'creditcard') {
      setPaymentEnable(brand.creditcard ? brand.creditcard : false);
      setModel(brand.creditcarddetails ? brand.creditcarddetails : new CreditCardDetail());
    }
    else if (type === 'paypal') {
      setPaymentEnable(brand.paypal ? brand.paypal : false);
      setModel(brand.paypaldetails ? brand.paypaldetails : new PayPalDetail());
    }
    else if (type === 'invoice') {
      setPaymentEnable(brand.invoice ? brand.invoice : false);
    }
  }

  let creditcardRules = [
    {
      field: "apikey",
      type: "required",
      message: "error.apikeyrequire",
    },
    {
      field: "secretkey",
      type: "required",
      message: "error.secretkeyrequire",
    }
  ]

  let paypalRules = [
    {
      field: "clientid",
      type: "required",
      message: "error.clientidrequire",
    }
  ]

  const disablePayment = () => {
    setPaymentEnable(!paymentEnable);
  }

  const change = (field: string, value: any) => {
    if (model) {
      setModel({ ...model, [field]: value });
    }
    if (submitted) isValid();
  };

  const getError = (field: string): Validation | undefined => {
    return errors.find(x => x.field === field);
  }

  const getErrorMessage = (field: string): string | undefined => {
    let message: string | undefined = undefined;
    if (errors.find(x => x.field === field)) {
      message = t(errors.find(x => x.field === field)!.message)
    }
    return message;
  }

  const isValid = (): boolean => {
    let values: Validation[] = [];
    if (type === 'creditcard') {
      creditcardRules.forEach(rule => {
        let validation: Validation = rule as Validation;
        if (model) {
          if (pass(validation, model[validation.field as keyof typeof model]) === false) {
            values.push(validation);
          }
        }
      })
    }
    else if (type === 'paypal') {
      paypalRules.forEach(rule => {
        let validation: Validation = rule as Validation;
        if (model) {
          if (pass(validation, model[validation.field as keyof typeof model]) === false) {
            values.push(validation);
          }
        }
      });
    }

    setErrors([...values]);
    return values.length === 0;
  }

  const submit = async (): Promise<boolean> => {
    return new Promise(async resolve => {
      setSubmitted(true);

      if (isValid() === false) {
        setProcess(false);
        resolve(false);
        return;
      }

      if (type === 'creditcard') {
        await supplierService.saveCreditCard(id, paymentEnable, model as CreditCardDetail)
      }
      else if (type === 'paypal') {
        await supplierService.savePayPal(id, paymentEnable, model as PayPalDetail)
      }
      else if (type === 'invoice') {
        await supplierService.saveInvoice(id, paymentEnable);
      }

      setSubmitted(false);
      setProcess(false);
      enqueueSnackbar("Saved Payment Details", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      resolve(true);

    });
  }

  return { paymentEnable, model, getError, getErrorMessage, change, disablePayment, process, submit };
}

export default SupplierPaymentDetailsViewModel;
