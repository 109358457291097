import React, { useState } from "react";
import FormLayout from "../../components/form/FormLayout";
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CustomerListViewModel from "../../viewmodels/customer/list.viewmodel";
import CustomerRegister from "./customer-register";
import { useTranslation } from "react-i18next";
import TableLayout from "../../components/table/table";
import CustomerLicenseValidation from "./customer-license-validation";

interface ContainerProps {
  mode: "" | "moderate";
}

const Customers: React.FC<ContainerProps> = ({ mode }) => {
  const { t } = useTranslation();

  const { customers, customerList, searches, fields, setSearch, setFilter, setStatus, exporttoexcel } = CustomerListViewModel(mode);

  const getTitle = () => {
    if (mode === "moderate") {
      return t("title.customerstomoderate");
    } else {
      return t("title.customers");
    }
  };

  const handleKeyUp = (event: any) => {
    setSearch(event.target.value);
  };

  const getExportButton = () => {
    if (mode === "")
      return (
        <Button variant="contained" color="secondary" disabled={customerList=== undefined || customerList.length === 0} onClick={(e) => exporttoexcel()}>
          {t("button.exporttoexcel")}
        </Button>
      );
    else return;
  };

  const getFilter = () => {
    if (mode === "")
      return (
        <FormControl fullWidth size="small">
          <InputLabel htmlFor="grouped-select">Filter</InputLabel>
          <Select defaultValue="" id="grouped-status" label="Filter" onChange={(e) => setFilter(e.target.value)}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"newsletter"}>Newsletter</MenuItem>
            <ListSubheader>Review Status</ListSubheader>
            <MenuItem value={"pending"}>Pending</MenuItem>
            <MenuItem value={"approved"}>Approved</MenuItem>
            <MenuItem value={"suspend"}>Suspended</MenuItem>
            <MenuItem value={"deleted"}>Deleted</MenuItem>
          </Select>
        </FormControl>
      );
    else return;
  };

  const getStatusFilter = () => {
    if (mode === "")
      return (
        <FormControl fullWidth size="small">
          <InputLabel htmlFor="grouped-select">Status</InputLabel>
          <Select defaultValue="" id="grouped-status" label="Filter" onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"inactive"}>Inactive</MenuItem>
          </Select>
        </FormControl>
      );
    else return;
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle={getTitle()} Breadcrumbs={[{ title: t("title.dashboard"), url: "/" }, { title: getTitle() }]}></FormLayout>

      <Grid container sx={{ paddingBottom: 2 }}>
        <Grid xs={4} container item>
          <TextField
            id="outlined-search"
            size="small"
            placeholder={t("text.search")}
            type="search"
            name="search"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onKeyUp={(e) => handleKeyUp(e)}
          />
        </Grid>
        <Grid xs={2} item sx={{ paddingInline: 1 }}>
          {getFilter()}
        </Grid>
        <Grid xs={2} item sx={{ paddingInline: 1 }}>
          {getStatusFilter()}
        </Grid>
        <Grid xs={4} container direction="row" justifyContent="right" gap={1} alignItems="center" item>
          <CustomerRegister></CustomerRegister>
          {getExportButton()}
          {
            mode === '' && <CustomerLicenseValidation customers={customers}></CustomerLicenseValidation>
          }
        </Grid>
      </Grid>

      <TableLayout fields={fields} data={searches !== undefined ? searches : customerList}></TableLayout>
      
    </React.Fragment>
  );
};

export default Customers;
