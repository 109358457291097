import { useEffect, useState } from "react";
import { Customer } from "../../models/customer.model";
import { CustomerService } from "../../services/customer.service";
import { useSnackbar } from "notistack";
import pass, { Validation } from "../../models/validation.model";
import { fanfluencers, stockstatus } from "../../config";
import { useTranslation } from "react-i18next";
import { Product, ProductList } from "../../models/product.model";
import { ProductService } from "../../services/product.service";
import { Brand } from "../../models/brand.model";
import { SupplierService } from "../../services/supplier.service";
import { Category } from "../../models/category.model";
import { CategoryService } from "../../services/category.service";
import { SearchLogService } from "../../services/searchlog.service";
import { SearchLog } from "../../models/searchlog.model";
import { TableField } from "../../models/table.model";

export class SearchString {
    public id: number;
    public search: string;
}

function CustomerDetailViewModel(id: string) {
    const { t, i18n } = useTranslation();

    const [brands, setBrands] = useState<Brand[] | undefined>(undefined);
    const [categories, setCategories] = useState<Category[] | undefined>(undefined);
    const [customer, setCustomer] = useState<Customer>(new Customer());
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [wishes, setWishes] = useState<ProductList[]>([]);
    const [searches, setSearches] = useState<SearchString[] | undefined>(undefined);

    const { enqueueSnackbar } = useSnackbar();

    const fields = [
        { id: "id", display: "#", align: "left", sort: false, type: 'string' },
        { id: "brand", display: t("field.brand").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "article", display: t("field.article").toUpperCase(), align: "left", sort: false, type: 'string' },
        { id: "name", display: t("field.name").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "category", display: t("field.category").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "originalprice", display: t("field.originalprice").toUpperCase(), align: "right", sort: true, type: 'decimal' },
        { id: "buyonly", display: t("field.buyonlydeal").toUpperCase(), align: "center", false: true, type: 'boolean' },
        { id: "fanfluencerdeal", display: t("field.influencerdeal").toUpperCase(), align: "center", sort: false, type: 'boolean' },
        { id: "active", display: t("field.status").toUpperCase(), align: "center", sort: false, type: 'status' },
        { id: "stockstatus", display: t("field.stockstatus").toUpperCase(), align: "center", sort: false, type: 'stockstatus' }
    ] as TableField[];

    const searchFeilds = [
        { id: "id", display: "#", align: "left", sort: false, type: 'string' },
        { id: "search", display: "SEARCH", align: "left", sort: false, type: 'string' }
    ] as TableField[];

    const rules = [
        {
            field: "email",
            type: "required",
            message: "error.emailrequire",
        },
        {
            field: "firstname",
            type: "required",
            message: "error.firstnamerequire",
        },
        {
            field: "firstname",
            type: "maxlength",
            length: 50,
            message: "error.mustnotexceed50",
        },
        {
            field: "lastname",
            type: "required",
            message: "error.lastnamerequire",
        },
        {
            field: "lastname",
            type: "maxlength",
            length: 50,
            message: "error.mustnotexceed50",
        }
    ];

    useEffect(() => {
        loadBrands();
        loadCategories();

        const customerService: CustomerService = new CustomerService();
        customerService.getById(id).then(customer => setCustomer(customer as Customer));
    }, [id])

    useEffect(() => {
        setCustomer({ ...customer, ratings: getRatings(Number(customer.followers ? customer.followers : 0)) });
    }, [customer.followers])

    useEffect(() => {
        if (customer.wishes && customer.wishes.length > 0 && brands && categories) {
            const productService: ProductService = new ProductService();
            productService.getByIds(customer.wishes).then(products => {
                let items: ProductList[] = [];
                (products as Product[]).forEach(product => {
                    let item: ProductList = new ProductList();
                    item.id = product.id;

                    let brand: Brand | undefined = undefined;
                    if (product.brandid) brand = brands.find(x => x.id === product.brandid)
                    item.brand = brand ? brand.name : '';
                    item.article = product.article;
                    item.name = i18n.language === "en" ? product.name_en : product.name_de;

                    let category: Category | undefined = undefined;
                    if (product.categoryid) category = categories.find(x => x.id === product.categoryid);
                    let categoryname = i18n.language === "en" ? (category ? category.text_en : '') : (category ? category.text_de : '');
                    item.category = categoryname;

                    item.originalprice = product.originalprice ? product.originalprice : 0;
                    item.buyonly = product.buyonly ? product.buyonly : false;
                    item.fanfluencerdeal = product.fanfluencerdeal ? product.fanfluencerdeal : false;
                    item.active = product.active ? product.active : false;
                    item.stockstatus = product.stockstatus ? product.stockstatus : stockstatus[0].id as 'in-stock' | 'out-of-stock' | 'limited-time';
                    item.review = product.review ? product.review : 'pending';
                    items.push(item)
                })
                setWishes([...items])
            })
        }
    }, [customer.wishes, brands, categories])

    useEffect(() => {
        if (customer.id) {
            const searchLogService: SearchLogService = new SearchLogService();
            searchLogService.getById(id).then(data => {
                let searchStrings: SearchString[] = [];
                (data as SearchLog).searches.forEach((search, idx) => {
                    let searchString: SearchString = new SearchString();
                    searchString.id = idx;
                    searchString.search = search
                    searchStrings.push(searchString)
                })
                setSearches([...searchStrings])
            })
        }
    }, [customer.id])

    const loadBrands = async () => {
        const brandService: SupplierService = new SupplierService();
        let data = await brandService.get() as Brand[];
        setBrands(data)
    }

    const loadCategories = async () => {
        const categoryService: CategoryService = new CategoryService();
        let data = (await categoryService.get()) as Category[];
        setCategories(data)
    }

    const getRatings = (follower: number) => {
        let value: string = '';
        fanfluencers.forEach(x => {
            if (x.minimum <= follower && x.maximum >= follower) {
                value = x.id;
            }
        });
        return value;
    };

    const change = (field: string, value: any) => {
        setCustomer({ ...customer, [field]: value });
        if (submitted) isValid();
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation
            if (pass(validation, customer[validation.field as keyof typeof customer]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message)
        }
        return message;
    }

    const submit = async () => {
        setSubmitted(true);

        if (isValid() === false) {
            return;
        }
        try {
            setProcessing(true);

            const customerService: CustomerService = new CustomerService();
            await customerService.update(customer.id, customer);

            enqueueSnackbar('Customer profile details updated.', {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
        }
        finally {
            setSubmitted(false);
            setProcessing(false);
        }
    }

    return { customer, fields, wishes, searchFeilds, searches, change, processing, getError, getErrorMessage, submit };
}

export default CustomerDetailViewModel;
