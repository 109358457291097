import { PaymentService } from './../../services/payment.service';
import { useEffect, useState } from "react";
import { Payment, PaymentList } from "../../models/payment.model";
import { Brand } from '../../models/brand.model';
import { SupplierService } from '../../services/supplier.service';
import { Customer } from '../../models/customer.model';
import { CustomerService } from '../../services/customer.service';
import { ExcelService } from '../../services/excel.service';
import { StorageService } from '../../services/storage.service';
import { TableField } from '../../models/table.model';
import { collection, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";
import { Action, StringLink } from "../../components/table/table";
import { useTranslation } from 'react-i18next';
import { isRaceClubTransaction } from '../../helper/transaction.helper';

function TransactionListViewModel() {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const { t } = useTranslation();

    const [brands, setBrands] = useState<Brand[] | undefined>(undefined);
    const [customers, setCustomers] = useState<Customer[] | undefined>(undefined);

    const [payments, setPayments] = useState<Payment[] | undefined>(undefined);
    const [paymentList, setPaymentList] = useState<PaymentList[] | undefined>(undefined);

    const storageService: StorageService = new StorageService();
    const supplierService: SupplierService = new SupplierService();
    const customerService: CustomerService = new CustomerService();
    const excelService: ExcelService = new ExcelService();

    const fields = [
        { id: "id", display: "#", align: "left", sort: false, type: 'string' },
        { id: "brand", display: t('field.brand').toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "customer", display: t('field.customer').toUpperCase(), align: "left", sort: true, type: 'stringlink' },
        { id: "gateway", display: t('field.gateway').toUpperCase(), align: "center", sort: false, type: 'gateway' },
        { id: "type", display: t("field.type").toUpperCase(), align: "center", sort: false, type: 'transaction' },
        { id: "price", display: "TOTAL", align: "right", sort: true, type: 'decimal' },
        { id: "added", display: t("field.date").toUpperCase(), align: "right", sort: false, type: 'datetime' },
        { id: "status", display: t("field.status").toUpperCase(), align: "center", sort: false, type: 'transactionstatus' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' }
    ] as TableField[];

    useEffect(() => {
        loadBrands();
        loadCustomers();
    }, [])

    useEffect(() => {
        if (brands !== undefined && customers !== undefined) {
            let isAdmin = storageService.isAdmin();
            let user = storageService.getSaveLogin();
            let items: Payment[] = [];
            let list: PaymentList[] = [];

            let constraints: any[] = [];
            if (isAdmin == false && user && user.supplier && user.supplier.id !== '') {
                constraints.push(where("brandid", "==", user.supplier.id));
            }
            constraints.push(orderBy("added", "desc"));
            const q = query(collection(firestore, "payments"), ...constraints);

            let unSubcribe = onSnapshot(q, (snap) => {

                snap.docChanges().forEach((change) => {
                    let item: Payment = change.doc.data() as Payment;
                    item.id = change.doc.id;

                    if (change.type === 'added') {
                        items.push(item);
                        list.push(convertPaymentList(item))
                    }
                    if (change.type === 'modified') {
                        let idx = items.findIndex(x => x.id === change.doc.id);
                        if (idx >= 0) items[change.newIndex] = { ...items[change.newIndex], ...change.doc.data() }

                        idx = list.findIndex(x => x.id === change.doc.id);
                        if (idx >= 0) list[idx] = { ...list[idx], ...item }
                    }
                });

                setPayments([...items])
                setPaymentList([...list])
            });

            return () => {
                unSubcribe();
            }
        }
    }, [brands, customers])

    const loadBrands = async () => {
        let isAdmin = storageService.isAdmin();
        let user = storageService.getSaveLogin();
        if (isAdmin === true) {
            let data: Brand[] = await supplierService.get() as Brand[];
            setBrands(data);
        }
        else {
            if (user && user.supplier && user.supplier.id !== '') {
                let data: Brand = await supplierService.getById(user.supplier.id) as Brand
                setBrands([data])
            }
        }
    }

    const loadCustomers = async () => {
        let data: Customer[] = await customerService.get() as Customer[];
        setCustomers(data);
    }

    const convertPaymentList = (payment: Payment): PaymentList => {
        let item: PaymentList = new PaymentList();
        item.id = payment.id;

        let brand = getBrand(payment.brandid);
        if (brand) item.brand = brand.name;
        else item.brand = "";

        let customer = getCustomer(payment.accountid);
        if (customer) {
            let link: StringLink = new StringLink();
            link.text = customer.firstname && customer.firstname !== '' ? customer.firstname + " " + customer.lastname : customer.email;
            link.url = `/customers/` + customer.id;
            item.customer = link;
        }

        item.gateway = payment.gateway;

        if (isRaceClubTransaction(payment)) item.type = "RaceClub Deal"
        else item.type = "Influencer Deal";

        item.added = payment.added;

        item.price = payment.price;

        item.status = payment.status ? payment.status : "pending";

        let action: Action = new Action();
        action.type = 'url';
        action.display = t("button.view");
        action.url = `/transactions/` + payment.id;
        item.action = action;
        return item
    }

    const getBrand = (id: string): Brand | undefined => {
        if (brands) {
            let idx: number = brands.findIndex(x => x.id === id)
            if (idx >= 0) return brands[idx]
            else return undefined;
        }
        else return undefined;
    }

    const getCustomer = (id: string): Customer | undefined => {
        if (customers) {
            let idx: number = customers.findIndex(x => x.id === id);
            if (idx >= 0) return customers[idx]
            else return undefined;
        }
        else return undefined;

    }

    const exporttoexcel = () => {
        if (payments) excelService.transactions(payments);
    }

    return { paymentList, fields, exporttoexcel }
}

export default TransactionListViewModel;