import React, { useEffect, useRef, useState } from "react";
import FormLayout from "../../components/form/FormLayout";
import { Box, Grid } from "@mui/material";
import PartnerCreate from "./partner-create";
import PartnersListViewModel from "../../viewmodels/partner/list.viewmodel";
import PartnerView from "./partner-view";
import { Partner } from "../../models/partner";

const PartnerList: React.FC = () => {
  const [list, setList] = useState<Partner[]>([]);

  const dragItem = useRef(0);
  const dragOverItem = useRef(0);

  const { partners, create, changeStatus, changeTitle, changeOrder } = PartnersListViewModel();

  useEffect(() => {
    setList(partners);
  }, [partners]);

  const dragStart = (e: any, position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (e: any, position: number) => {
    dragOverItem.current = position;
  };

  const drop = (e: any) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = 0;
    dragOverItem.current = 0;
    copyListItems.map((copyListItem, index) => copyListItem.order = index + 1);
    setList(copyListItems);
    changeOrder(copyListItems);
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle="Partners" Breadcrumbs={[{ title: "Dashboard", url: "/" }, { title: "Partners" }]}></FormLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="row" justifyContent="right" alignItems="center" item>
          <PartnerCreate create={create}></PartnerCreate>
        </Grid>
        <Grid container paddingTop={"20px"}>
          {list.map((partner, index) => {
            return (
              <Grid item xs={2} key={partner.id} sx={{ marginRight: "5px", marginBottom: "5px" }}>
                <div draggable onDragStart={(e) => dragStart(e, Number(index))} onDragEnter={(e) => dragEnter(e, Number(index))} onDragEnd={drop}>
                  <PartnerView partner={partner} changeActive={changeStatus} changeTitle={changeTitle}></PartnerView>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default PartnerList;
