import { useEffect, useState } from "react";
import { SupplierService } from "../../services/supplier.service";
import { Brand } from "../../models/brand.model";
import pass, { Validation } from "../../models/validation.model";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

function SupplierEmailViewModel(id: string) {
    const { t } = useTranslation();

    const [values, setValues] = useState<string[]>([]);
    const [value, setValue] = useState("");
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [process, setProcess] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const supplierService: SupplierService = new SupplierService();

    let rules = [
        {
            field: "email",
            type: "required",
            message: "error.emailrequire",
        },
        {
            field: "email",
            datatype: "email",
            type: "valid",
            message: "error.notavalidemail",
        }
    ]

    useEffect(() => {
        if (id) {
            loadBrand();
        }
    }, [id]);

    const loadBrand = async () => {
        let brand: Brand = await supplierService.getById(id) as Brand;
        setValues(brand.emails ? brand.emails : []);
    }

    const isValid = (): boolean => {
        let temp: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, value) === false) {
                temp.push(validation);
            }
        })

        setErrors([...temp]);
        return temp.length === 0;
    }

    const change = (val: string) => {
        setValue(val);
        if (submitted) isValid();
    };

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message)
        }
        return message;
    }


    const submit = async (): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);

            if (isValid() === false) {
                setProcess(false);
                resolve(false);
            }
            else {
                let idx: number = values.findIndex(x => x === value.toLowerCase());
                if (idx < 0) {
                    let list = values;
                    list.push(value.toLowerCase());
                    await supplierService.saveEmails(id, list);
                    setValues([...list]);

                    setValue("");
                    setSubmitted(false);
                    setProcess(false);

                    enqueueSnackbar("Email List Updated", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                    });

                    resolve(true);
                }

                resolve(false);
            }
        });
    }

    const onDelete = (val: string): Promise<boolean> => {
        return new Promise(async resolve => {
            let idx: number = values.findIndex(x => x === val);
            if (idx >= 0) {
                let list = values.filter(x => x !== val);
                await supplierService.saveEmails(id, list);
                setValues([...list]);

                enqueueSnackbar("Email List Updated", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                });

                resolve(true);
            }
            resolve(false);
        });
    }

    return { values, value, getError, getErrorMessage, change, process, submit, onDelete };
}

export default SupplierEmailViewModel;