import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { Category } from '../models/category.model';
import { BaseService } from './baseservice';

export class CategoryService extends BaseService {
    constructor() {
        super("categories", "orderno");
    }

    add = (data: Category) => new Promise((resolve) => {
        const col = collection(this.firestore, this.colname);
        addDoc(col, {
            code: data.code ? data.code : "",
            text_en: data.text_en ? data.text_en : "",
            text_de: data.text_de ? data.text_de : "",
            image: data.image ? data.image : "",
            orderno: data.orderno ? data.orderno : "",
            oractivederno: data.active,
            created: serverTimestamp(),
            createdby: this.userid
        }).then(value => {
            resolve(value.id);
        });
    });
}
