import { useEffect, useState } from "react";
import "./ImageView.css";
import ImageView from "./ImageView";
import { Box, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  image: string;
  primary: boolean;
  change: (image: string) => void;
  remove: (image: string) => void;
}
const ImageControl: React.FC<ContainerProps> = ({ image, primary, change, remove }) => {
  const { t } = useTranslation();

  const [isPrimary, setIsPrimary] = useState(false);

  useEffect(() => {
    setIsPrimary(primary ? primary : false);
  }, [primary]);

  return (
    <Box className="imageview-div" border={1} borderColor={"lightgray"}>
      <ImageView image={image}></ImageView>
      <Box borderTop={1} borderColor={"lightgray"}>
        <Grid container>
          <Grid item xs={6}>
            <Button onClick={(e) => change(image)} style={isPrimary === false ? {} : { display: "none" }}>{t('button.primary')}</Button>
          </Grid>
          <Grid item xs={6} container justifyContent={"right"}>
           <Button onClick={(e) => remove(image)} color="error">{t('button.delete')}</Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ImageControl;
