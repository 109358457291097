import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { Contact } from "../../models/brand.model";
import pass, { Validation } from "../../models/validation.model";
import { useSnackbar } from "notistack";
import { SupplierContactService } from "../../services/suppliercontact.service";

function SupplierContactLDetailViewModel(brandid: string, contact: Contact) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [model, setModel] = useState<Contact>(contact);
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [process, setProcess] = useState(false);

    const supplierContactService: SupplierContactService = new SupplierContactService();

    const rules: any[] = [
        {
            field: "name",
            type: "required",
            message: t('error.nameisrequired'),
        },
        {
            field: "email",
            type: "required",
            message: t('error.emailrequire'),
        },
        {
            field: "email",
            datatype: "email",
            type: "valid",
            message: t('error.notavalidemail'),
        }
    ];

    useEffect(() => {
        setModel(contact)
    }, [contact])

    const change = (field: string, value: any) => {
        setModel({ ...model, [field]: value });
        if (submitted) isValid();
    };

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message)
        }
        return message;
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, model[validation.field as keyof typeof model]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const submit = async (): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);
            setProcess(true);

            if (isValid() === false) {
                setProcess(false);
                resolve(false);
                return;
            }

            if (model.id) {
                await supplierContactService.update(model.id, model);
            }
            else {
                model.brandid = brandid;
                await supplierContactService.add(model);
            }

            setSubmitted(false);
            setProcess(false);
            enqueueSnackbar("Saved Details", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            resolve(true);
        });
    }

    return { model, submit, change, getError, getErrorMessage, process }
}

export default SupplierContactLDetailViewModel;