import React, { useState } from "react";
import { Admin } from "../../models/admin.model";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import AdminEditViewModel from "../../viewmodels/admin/edit.viewmodel";
import Toggle from "../../components/toggle/toggle";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  admin: Admin;
}

const AdminEdit: React.FC<ContainerProps> = ({ admin }) => {
  const { t } = useTranslation();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { model, change, getError, getErrorMessage, onSubmit } = AdminEditViewModel(admin);
  

  const onHandleSubmit = async (e: any) => {
    e.preventDefault();
    await onSubmit();
  };

  return (
    <React.Fragment>
      <Button color="secondary" onClick={handleOpen}>{t('button.open').toUpperCase()}</Button>
      
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <form onSubmit={onHandleSubmit}>
          <Box sx={style}>
            <Box sx={headerStyle}>{t('text.administratoraccount')}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t("field.email")}
                    type="email"
                    variant="outlined"
                    size="small"
                    value={model.email}
                    error={getError("email") !== undefined}
                    helperText={getErrorMessage("email")}
                    onChange={(e) => change("email", e.target.value)}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t("field.firstname")}
                    type="text"
                    variant="outlined"
                    size="small"
                    value={model.firstname}
                    error={getError("firstname") !== undefined}
                    helperText={getErrorMessage("firstname")}
                    onChange={(e) => change("firstname", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t("field.lastname")}
                    type="text"
                    variant="outlined"
                    size="small"
                    value={model.lastname}
                    error={getError("lastname") !== undefined}
                    helperText={getErrorMessage("lastname")}
                    onChange={(e) => change("lastname", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Toggle
                    value={model.active}
                    label={t("field.active").toUpperCase()}
                    onChange={(value: boolean) => change("active", value)}
                  ></Toggle>
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} type="submit">
                {t('button.update').toUpperCase()}
              </Button>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={handleClose}>
                {t('button.cancel').toUpperCase()}
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default AdminEdit;
