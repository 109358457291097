import { initializeApp } from 'firebase/app';
import { BaseService } from './baseservice';
import firebaseConfig from '../config';
import { addDoc, collection, doc, getFirestore, serverTimestamp, updateDoc } from 'firebase/firestore';
import { Size } from '../models/size.model';

export class SizeService extends BaseService {
    app = initializeApp(firebaseConfig);

    constructor() {
        super("sizes", "");
        this.firestore = getFirestore(this.app);
    }

    colname: string = 'sizes';

    add = (data: Size) => new Promise((resolve) => {
        const col = collection(this.firestore, this.colname);
        addDoc(col, {
            description: data.description,
            description_de: data.description_de,
            arrangement: Number(data.arrangement),
            active: data.active,
            created: serverTimestamp(),
            createdby: this.userid
        }).then(value => {
            resolve(value.id);
        });
    });

    update = (id: string, data: Size) => new Promise<void>((resolve) => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        updateDoc(docs, {
            description: data.description,
            description_de: data.description_de,
            arrangement: Number(data.arrangement),
            active: data.active,
            modified: serverTimestamp(),
            modifiedby: this.userid
        }).then(() => {
            resolve();
        })
    });
}
