import { Chip, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";

function Chips(props) {
  const [tags, setTags] = useState([]);
  const [id, setId] = useState('');
  const [value, setValue] = useState('');
  const [start, setStart] = useState('');
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (props.tags !== undefined) setTags(props.tags);
  }, [props.tags]);

  useEffect(() => {
    if (props.id !== undefined) setId(props.id);
  }, [props.id]);

  useEffect(() => {
    if (props.value !== undefined) setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.start !== undefined) setStart(props.start);
  }, [props.start]);

  useEffect(() => {
    if (props.selected !== undefined) setSelected(props.selected);
  }, [props.selected]);

  const getDisplayText = (tag) => {
    return start + tag[value];
  };

  const click = (tag) => {
    let data = selected;
    let index = data.findIndex((x) => x === tag[id]);
    if (index < 0) {
      data = [...data, tag[id]];
    } else {
      data.splice(index, 1);
    }
    setSelected([...data]);
    props.update(data);
  };

  const render = (tag) => {
    let data = selected;
    let index = data.findIndex((x) => x === tag[id]);
    if (index < 0) {
      return <Chip key={tag[id]} label={getDisplayText(tag)} variant="outlined" onClick={(e) => click(tag)} />;
    } else {
      return <Chip key={tag[id]} label={getDisplayText(tag)} color="primary" onClick={(e) => click(tag)} />;
    }
  };

  return (
    <React.Fragment>
      <Stack direction="row" spacing={1} rowGap={1} flexWrap="wrap">
        {tags.map((tag) => render(tag))}
      </Stack>
    </React.Fragment>
  );
}

export default Chips;
