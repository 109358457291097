import { useEffect, useState } from "react";
import { Contact, ContactList } from "../../models/brand.model";
import { SupplierContactService } from "../../services/suppliercontact.service";
import { collection, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";
import { useTranslation } from "react-i18next";
import { TableField } from "../../models/table.model";
import { Action } from "../../components/table/table";
import SupplierContactDetail from "../../views/supplier/supplier-contact-detail";

function SupplierContactListViewModel(id: string){
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const { t } = useTranslation();

    const [contacts, setContacts] = useState<ContactList[]>([]);

    const fields = [
        { id: "name", display: t("field.name").toUpperCase(), align: "left", sort: false, type: 'string' },
        { id: "email", display: t("field.email").toUpperCase(), align: "left", sort: false, type: 'string' },
        { id: "phone", display: t("field.phone").toUpperCase(), align: "left", sort: false, type: 'string' },
        { id: "active", display: t("field.status").toUpperCase(), align: "left", sort: false, type: 'status' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' }
    ] as TableField[];

    useEffect(() => {
        if (id){
            let items: ContactList[] = [];
            const q = query(collection(firestore, "brandcontacts"), where('brandid', '==', id), orderBy('created','desc'));
            let unSubcribe = onSnapshot(q, (snap) => {
                snap.docChanges().forEach((change) => {
                    let item: ContactList = change.doc.data() as ContactList;
                    item.id = change.doc.id;
                    if (change.type === 'added') {
                        let action: Action = new Action();
                        action.type = 'component';
                        action.component = SupplierContactDetail;
                        action.props = {
                            mode: "edit",
                            brandid: item.brandid,
                            contact: {...item} as Contact
                        }
                        item.action = action
                        items.push(item);
                    }
                    if (change.type === 'modified') {
                        let idx = items.findIndex(x => x.id === change.doc.id);
                        if (idx >= 0) items[change.newIndex] = { ...items[change.newIndex], ...change.doc.data() }
                    }
                });
                setContacts([...items])
            });
    
            return () => {
                unSubcribe();
            }
        }
    }, [id]);

    return { fields, contacts }
}

export default SupplierContactListViewModel;