import { Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageService } from "../../services/image.service";

interface ContainerProps {
  create: (image: string) => void;
}

const PartnerCreate: React.FC<ContainerProps> = ({ create }) => {
  const { t } = useTranslation();

  const inputFileRef = useRef<HTMLInputElement>(null);
  const [processing, setProcessing] = useState(false);

  let service = new ImageService();

  const onUpload = (event: any) => {
    setProcessing(true);
    const uploadTask = service.upload("partners", event.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        setProcessing(false);
      },
      () => {
        setProcessing(false);
        create(uploadTask.snapshot.ref.fullPath);
        service.saveImage(uploadTask.snapshot.ref.fullPath);
      }
    );
  };

  const onTrigger = () => {
    if (inputFileRef.current !== null) inputFileRef.current.click();
  };

  return (
    <React.Fragment>
      <Button variant="contained" size="medium" disabled={processing} onClick={(e) => onTrigger()}>
        {t("button.uploadapartner")}
      </Button>
      <input accept="image/*" type="file" className="hidden" ref={inputFileRef} onChange={onUpload} />
    </React.Fragment>
  );
};

export default PartnerCreate;
