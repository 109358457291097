import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProductDetailViewModel from "../../viewmodels/product/detail.viewmodel";
import FormLayout from "../../components/form/FormLayout";
import { Avatar, Box, Button, Card, CardContent, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ImageView from "../../components/Image/ImageView";
import ProductSpecificationsViewModel from "../../viewmodels/product/specifications.viewmodel";
import { fanfluencers } from "../../config";
import { FanfluencerSpecialDeal } from "../../models/product.model";
import { useTranslation } from "react-i18next";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { red } from "@mui/material/colors";

const ProductView: React.FC = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { specs, sizes, colors, add, update } = ProductSpecificationsViewModel(id!);
  const { supplier, suppliers, categories, tags, product, getCategory, getError, getSupplier, getErrorMessage, change, submit, moderation } = ProductDetailViewModel(id!);

  const [review, setReview] = useState<"pending" | "approved" | "rejected">("pending");
  const [reason, setReason] = useState("");

  const status = ["pending", "approved", "rejected"];

  useEffect(() => {
    if (product.review) setReview(product.review);
    if (product.reason) setReason(product.reason);
  }, [product]);

  const getBrand = (id: string) => {
    let brand = suppliers.find((x) => x.id === id);
    if (brand) return brand.name;
    else return "";
  };

  const getStatus = (active: boolean) => {
    if (active) {
      return (
        <Chip
          avatar={
            <Avatar>
              <CheckIcon></CheckIcon>
            </Avatar>
          }
          label="ACTIVE"
          color="primary"
          variant="outlined"
        />
      );
    } else {
      return (
        <Chip
          avatar={
            <Avatar>
              <CloseIcon></CloseIcon>
            </Avatar>
          }
          label="INACTIVE"
          color="error"
          variant="outlined"
        />
      );
    }
  };

  const getTags = () => {
    if (product.tags) {
      return (
        <Stack direction="row" spacing={1} rowGap={1} flexWrap="wrap">
          {product.tags.map((x, id) => (
            <Chip key={"tag-" + id} label={"#" + x} color="primary" />
          ))}
        </Stack>
      );
    } else {
      return;
    }
  };

  const getImages = () => {
    if (product.images) {
      return (
        <Grid container spacing={2} rowGap={2} columns={7} paddingInline={2}>
          {
            product.images.map((image, idx) => (
              <Grid item xs={1} key={"image-" + idx}>
                <Box className="imageview-div" border={1} borderColor={product.primary === image ? "gray" : "lightgray"}>
                  <ImageView image={image}></ImageView>
                </Box>
              </Grid>
            ))
          }
        </Grid>
      )
    } else {
      return <Grid container item justifyContent="center" color={red[500]}><WarningAmberIcon></WarningAmberIcon> No Image Uploaded</Grid>
    }
  };

  const getSpecifications = () => {
    if (specs && specs.length > 0) {
      return (
        <Grid container spacing={2} rowGap={2} columns={7} paddingInline={2}>
          {
            specs.map((spec, idx) =>
            (
              <Grid item xs={1} key={"spec-" + idx}>
                <Box className="imageview-div" border={1} borderColor={"lightgray"}>
                  <ImageView image={spec.image}></ImageView>
                  <Grid container borderTop={1} borderColor={"lightgray"} paddingX={1}>
                    <Grid item xs={4}>
                      {t("field.size")}:
                    </Grid>
                    <Grid item xs={8} container justifyContent={"right"}>
                      {sizes.find((x) => x.id === spec.size)!.description}
                    </Grid>
                  </Grid>
                  <Grid container paddingX={1}>
                    <Grid item xs={4}>
                      {t("field.color")}:
                    </Grid>
                    <Grid item xs={8} container justifyContent={"right"}>
                      {colors.find((x) => x.id === spec.color)!.description}
                    </Grid>
                  </Grid>
                  <Grid container paddingX={1}>
                    <Grid item xs={4}>
                      {t("field.sold")}:
                    </Grid>
                    <Grid item xs={8} container justifyContent={"right"}>
                      {spec.sold}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )
            )
          }
        </Grid>
      )
    } else {
      return <Grid container item justifyContent="center" color={red[500]}><WarningAmberIcon></WarningAmberIcon> No Specification Created</Grid>
    }
  };

  const getBuyOnlyDeal = () => {
    if (product.buyonly) {
      return (
        <React.Fragment>
          <Box sx={{ flexGrow: 1 }} paddingTop={1}>
            <Grid container spacing={1} alignItems="center" paddingRight={2}>
              <Grid item xs={8}>
                <h5 className="subtext mb-0">RACECLUB DEALS</h5>
              </Grid>
              <Grid item xs={3} padding={1} marginTop={1} height={"100%"} container justifyContent={"right"}>
                {t("field.buyonlymax")}
              </Grid>
              <Grid item xs={1}>
                <Box border={1} borderColor={"lightgray"} padding={1} height={34}>
                  {product.buyonlymax}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={1} columns={10} marginTop={2} paddingInline={2}>
              {product.buyonlydeals.map((deal, id) => {
                return (
                  <Grid item xs={2}>
                    <Box key={"buyonlydeal-" + id} border={1} borderColor={"lightgray"}>
                      <Box borderBottom={1} borderColor={"lightgray"} padding={1}>
                        {fanfluencers.find((x) => x.id === deal.id)!.display.toUpperCase()}
                      </Box>
                      <Box padding={1}>
                        <Grid container paddingX={1}>
                          <Grid item xs={6}>
                            {t("field.discount")} %
                          </Grid>
                          <Grid item xs={6} container justifyContent={"right"}>
                            {new Intl.NumberFormat('en-DE').format(Number(deal.percent))}
                          </Grid>
                        </Grid>
                        <Grid container paddingX={1}>
                          <Grid item xs={6}>
                            {t("field.discount")}
                          </Grid>
                          <Grid item xs={6} container justifyContent={"right"}>
                            {new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(deal.amount))}
                          </Grid>
                        </Grid>
                        <Grid container paddingX={1}>
                          <Grid item xs={6}>
                            {t("field.price")}
                          </Grid>
                          <Grid item xs={6} container justifyContent={"right"}>
                            {new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(deal.price))}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <hr />
        </React.Fragment>
      );
    } else {
      return;
    }
  };

  const getSpecial = (type: "Story" | "Feed" | "Reel", id: string) => {
    if (product.fanfluencerspecialdeals) {
      let deals: FanfluencerSpecialDeal[] = product.fanfluencerspecialdeals.filter((x) => x.type === type).filter((x) => x.fanId === id);
      if (deals.length > 0) {
        return (
          <Box padding={1} borderTop={1} borderColor={"lightgray"}>
            {t("title.specialdeals").toUpperCase()}
            {deals.map((deal, id) => {
              return (
                <Box key={"special-" + id}>
                  <strong>{Number(deal.post)}</strong> {deal.type} post(s) for <strong>{new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(deal.price))} €</strong>
                </Box>
              );
            })}
          </Box>
        );
      } else {
        return;
      }
    }
  };

  const getFanfluencerType = (type: "Story" | "Feed" | "Reel") => {
    let deals = product.fanfluencerdeals.filter((x) => x.type === type);

    return (
      <Grid container spacing={1} columns={8} marginTop={1} paddingInline={2}>
        <Grid item xs={8} borderBottom={1} borderColor={"lightgray"} paddingY={1}>
          {type.toUpperCase() + "-POST"}
        </Grid>
        {deals.map((deal, id) => {
          return (
            <Grid item xs={2}>
              <Box key={"fanfluencer-" + id} border={1} borderColor={"lightgray"}>
                <Box borderBottom={1} borderColor={"lightgray"} padding={1}>
                  {fanfluencers.find((x) => x.id === deal.id)!.display.toUpperCase()}
                </Box>
                <Box padding={1}>
                  <span style={deal.enable ? {} : { textDecoration: "line-through" }}>
                    <strong>€ {deal.discount}</strong> {t("text.discountper")} {deal.type}-Post
                  </span>
                </Box>
                {getSpecial(type, deal.id)}
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const getFanfluencerDeal = () => {
    if (product.fanfluencerdeal) {
      return (
        <React.Fragment>
          <Box sx={{ flexGrow: 1 }} paddingTop={1}>
            <Grid container spacing={1} alignItems="center" paddingRight={2}>
              <Grid item xs={8}>
                <h5 className="subtext mb-0">INFLUENCER DEALS</h5>
              </Grid>
              <Grid item xs={3} padding={1} marginTop={1} container justifyContent={"right"}>
                {t("field.postmaxcount")}
              </Grid>
              <Grid item xs={1}>
                <Box border={1} borderColor={"lightgray"} padding={1} height={34}>
                  {product.fanfluencerdealmaxdisc}
                </Box>
              </Grid>
            </Grid>
            {getFanfluencerType("Story")}
            {getFanfluencerType("Feed")}
            {getFanfluencerType("Reel")}
          </Box>
          <hr />
        </React.Fragment>
      );
    } else {
      return;
    }
  };

  const getLimitOffer = () => {
    if (product.limitedoffer) {
      return (
        <Box sx={{ flexGrow: 1 }} paddingTop={1}>
          <Grid container spacing={1} columns={12}>
            <Grid xs={12} item>
              <h5 className="subtext">{t("title.limitedoffer").toUpperCase()}</h5>
            </Grid>
            <Grid item xs={2} padding={1} marginTop={1} height={"100%"} container justifyContent={"center"}>
              {t("field.startdatetime")}
            </Grid>
            <Grid item xs={4}>
              <Box border={1} borderColor={"lightgray"} padding={1} height={"100%"}>
                {product.limitedoffer.start ? product.limitedoffer.start.toString() : ""}
              </Box>
            </Grid>
            <Grid item xs={2} padding={1} marginTop={1} height={"100%"} container justifyContent={"center"}>
              {t("field.enddatetime")}
            </Grid>
            <Grid item xs={4}>
              <Box border={1} borderColor={"lightgray"} padding={1} height={"100%"}>
                {product.limitedoffer.end ? product.limitedoffer.end.toString() : ""}
              </Box>
            </Grid>
          </Grid>
        </Box>
      );
    } else return;
  };

  return (
    <React.Fragment>
      <FormLayout
        PageTitle={t("title.productstomoderate")}
        Breadcrumbs={[
          { title: t("title.dashboard"), url: "/" },
          { title: t("title.productstomoderate"), url: "/productstomoderate" },
          { title: t("title.productreview") },
        ]}
      ></FormLayout>

      <Grid container direction="row" justifyContent="center" alignItems="center" marginBottom={4}>
        <Grid item xs={12}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container marginBottom={1}>
              <Grid item>
                <h5 className="subtext">{t("title.details").toUpperCase()}</h5>
              </Grid>
            </Grid>

            <Grid container marginBottom={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Grid item xs={2} paddingLeft={2}>
                {t("field.brand")}
              </Grid>
              <Grid item xs={10} paddingRight={2}>
                <Box border={1} borderColor={"lightgrey"} borderRadius={1} padding={1} height={34}>
                  {getBrand(product.brandid)}
                </Box>
              </Grid>
            </Grid>

            <Grid container marginBottom={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Grid item xs={2} paddingLeft={2}>
                {t("field.article")}
              </Grid>
              <Grid item xs={10} paddingRight={2}>
                <Box border={1} borderColor={"lightgrey"} borderRadius={1} padding={1} height={34}>
                  {product.article}
                </Box>
              </Grid>
            </Grid>

            <Grid container marginBottom={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Grid item xs={2} paddingLeft={2}>
                {t("field.name")}
              </Grid>
              <Grid item xs={10} paddingRight={2} container spacing={1}>
                <Grid item xs={6}>
                  <Box border={1} borderColor={"lightgray"} borderRadius={1} padding={1} height={34}>
                    {product.name_en}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box border={1} borderColor={"lightgray"} borderRadius={1} padding={1} height={34}>
                    {product.name_de}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid container marginBottom={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Grid item xs={2} paddingLeft={2}>
                {t("field.description")}
              </Grid>
              <Grid item xs={10} paddingRight={2} container spacing={1}>
                <Grid item xs={6}>
                  <Box border={1} borderColor={"lightgray"} borderRadius={1} padding={1} height={250} overflow="auto">
                    <div className="div-inner-p" dangerouslySetInnerHTML={{ __html: product.description_en }}></div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box border={1} borderColor={"lightgray"} borderRadius={1} padding={1} height={250} overflow="auto">
                    <div className="div-inner-p" dangerouslySetInnerHTML={{ __html: product.description_de }}></div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid container marginBottom={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Grid item xs={2} paddingLeft={2} >
                {t("field.warehouse")}
              </Grid>
              <Grid item xs={10} paddingRight={2}>
                <Box border={1} borderColor={"lightgrey"} borderRadius={1} padding={1} height={34}>
                  {product.warehouse}
                </Box>
              </Grid>
            </Grid>

            <Grid container marginBottom={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Grid item xs={2} paddingLeft={2}>
                {t("field.status")}
              </Grid>
              <Grid item xs={10} paddingRight={2} height={34}>
                {getStatus(product.active)}
              </Grid>
            </Grid>

            <Grid container marginBottom={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Grid item xs={2} paddingLeft={2}>
                {t("field.tags")}
              </Grid>
              <Grid item xs={10} paddingRight={2} height={34}>
                {getTags()}
              </Grid>
            </Grid>

            <Grid container marginBottom={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Grid item xs={2} paddingLeft={2}>
                {t("field.category")}
              </Grid>
              <Grid item xs={10} paddingRight={2} height={34}>
                <Box border={1} borderColor={"lightgrey"} borderRadius={1} padding={1} height={34}>
                  {getCategory(product.categoryid) ? getCategory(product.categoryid)!.text_en : ""}
                </Box>
              </Grid>
            </Grid>

            <Grid container marginBottom={1} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <Grid item xs={2} paddingLeft={2}>
                {t("field.originalprice")}
              </Grid>
              <Grid item xs={10} paddingRight={2} height={34}>
                <Box border={1} borderColor={"lightgrey"} borderRadius={1} padding={1} height={34}>
                  {new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(product.originalprice))}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <hr />

          <Box sx={{ flexGrow: 1 }} paddingTop={1}>
            <Grid container item>
              <h5 className="subtext mb-0">{t("title.images").toUpperCase()}</h5>
            </Grid>
            {getImages()}
          </Box>
          <hr />

          <Box sx={{ flexGrow: 1 }} paddingTop={1}>
            <Grid container item>
              <h5 className="subtext mb-0">{t("title.specifications").toUpperCase()}</h5>
            </Grid>
            {getSpecifications()}
          </Box>
          <hr />

          {getBuyOnlyDeal()}

          {getFanfluencerDeal()}

          {getLimitOffer()}

          <Box sx={{ flexGrow: 1 }} paddingTop={1}>
            <Grid container item>
              <h5 className="subtext">{t("title.moderation").toUpperCase()}</h5>
            </Grid>
            <Grid container spacing={1} paddingInline={2}>
              <Grid xs={4} item>
                <FormControl fullWidth>
                  <InputLabel id="size" size={"small"}>
                    {t("field.status")}
                  </InputLabel>
                  <Select
                    labelId="size"
                    label={t("field.status")}
                    value={review ? review : "pending"}
                    onChange={(e) => {
                      setReview(e.target.value as "pending" | "approved" | "rejected");
                    }}
                    size={"small"}
                  >
                    {status.map((stat) => (
                      <MenuItem value={stat}>{stat.toUpperCase()}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={8} item height={"100%"}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Reasons/Notes"
                  multiline
                  maxRows={4}
                  size={"small"}
                  fullWidth
                  value={reason}
                  error={getError("reason") !== undefined}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
                <FormHelperText>{getErrorMessage("reason")}</FormHelperText>
              </Grid>
            </Grid>
            <Grid container display="flex" flexDirection="row" justifyContent="center" item paddingTop={1}>
              <Button type="button" variant="contained" size="large" onClick={(e) => moderation(review, reason)}>
                Save
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProductView;
