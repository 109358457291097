import { useState } from "react";
import { BrandUser } from "../../models/brand.model";
import pass, { Validation } from "../../models/validation.model";
import { useSnackbar } from "notistack";
import { SupplierUserService } from "../../services/supplieruser.service";
import { useTranslation } from "react-i18next";

function SupplierUserDetailViewModel(branduser: BrandUser) {
    const { t } = useTranslation();

    const [model, setModel] = useState<BrandUser>(branduser);
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [process, setProcess] = useState(false);

    const supplierUserService: SupplierUserService = new SupplierUserService();

    const { enqueueSnackbar } = useSnackbar();

    const rules: any[] = [
        {
            field: "firstname",
            type: "required",
            message: 'error.firstnamerequire',
        },
        {
            field: "lastname",
            type: "required",
            message: 'error.lastnamerequire',
        }
    ]

    const change = (field: string, value: any) => {
        setModel({ ...model, [field]: value });
        if (submitted) isValid();
    };

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message)
        }
        return message;
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, model[validation.field as keyof typeof model]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const submit = async (): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);
            setProcess(true);

            if (isValid() === false) {
                setProcess(false);
                resolve(false);
                return;
            }
            
            await supplierUserService.update(model.id, model);
            
            setSubmitted(false);
            setProcess(false);

            enqueueSnackbar("Brand User Update", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });

            resolve(true);
        });
    }

    return { model, change, submit, getError, getErrorMessage, process }
}

export default SupplierUserDetailViewModel;