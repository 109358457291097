import { Box, Switch, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Partner } from "../../models/partner";
import { ImageService } from "../../services/image.service";

interface ContainerProps {
  partner: Partner;
  changeActive: (id: string, value: boolean) => void;
  changeTitle: (id: string, value: string) => void;
}

const PartnerView: React.FC<ContainerProps> = ({ partner, changeActive, changeTitle }) => {
  const [image, setImage] = useState("");
  const [active, setActive] = useState(false);
  const [title, setTitle] = useState("");

  const imageService: ImageService = new ImageService();

  useEffect(() => {
    if (partner) {
      imageService.getURL(partner.image).then((image) => setImage(image));
      setActive(partner.active);
      setTitle(partner.title);
    }
  }, [partner]);

  const onChangeActive = (value: boolean) => {
    setActive(value);
    changeActive(partner.id, value);
  };

  const onChangeTitle = (value: string) => {
    setTitle(value);
  };

  const onBlurTitle = (value: string) => {
    setTitle(value);
    changeTitle(partner.id, value);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          border: "1px solid lightgray",
          width: "100%",
          height: "100%",
          "&:hover": {
            borderColor: "primary.main",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            aspectRatio: "1/0.7",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
          }}
        >
          <img src={image} alt={partner.title} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </Box>
        <Box
          sx={{
            borderTop: "1px solid lightgray",
            padding: "5px",
          }}
        >
          <TextField
            id={partner.id}
            label="Title"
            variant="outlined"
            size="small"
            margin="dense"
            value={title}
            onChange={(e) => onChangeTitle(e.target.value)}
            onBlur={(e) => onBlurTitle(e.target.value)}
            fullWidth
          />
          <Switch size="small" checked={active} onChange={(e) => onChangeActive(e.target.checked as boolean)} />
          <span>ACTIVE</span>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default PartnerView;
