import React, { useEffect, useState } from "react";
import { Specs } from "../../../models/product.model";
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import ImageView from "../../../components/Image/ImageView";
import "./../product.css";
import { Color } from "../../../models/color.model";
import { Size } from "../../../models/size.model";
import Upload from "../../../components/upload/Upload";
import pass, { Validation } from "../../../models/validation.model";
import { useTranslation } from "react-i18next";
import { stockWarning } from "../../../config";
import ConfirmDialog from "../../../components/confirm/confirm";

interface ContainerProps {
  specs: Specs;
  sizes: Size[];
  colors: Color[];
  save: (specs: Specs) => void;
  onDelete: () => void;
}

const ProductSpec: React.FC<ContainerProps> = ({ specs, sizes, colors, save, onDelete }) => {
  const { t } = useTranslation();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };

  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };

  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };

  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  let rules = [
    {
      field: "color",
      type: "required",
      message: "error.colorrequire",
    },
    {
      field: "size",
      type: "required",
      message: "error.sizerequire",
    },
  ];

  const [model, setModel] = useState<Specs>(new Specs());
  const [editModel, setEditModel] = useState<Specs>(new Specs());
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [errors, setErrors] = useState<Validation[]>([]);
  const [submitted, setSubmitted] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = () => setConfirm(true);

  useEffect(() => {
    setModel(specs);
    setEditModel(specs);
  }, [specs]);

  const renderImageView = (image: string) => {
    if (image === "") return <span>NO IMAGE</span>;
    else return <ImageView image={image}></ImageView>;
  };

  const getSize = (id: string): Size => {
    return sizes.find((x) => x.id === id)!;
  };

  const getSizeDescription = (id: string): string => {
    let size: Size | undefined = undefined;
    if (id) {
      size = getSize(id);
    }
    if (size) return size.description;
    else return "";
  };

  const getColor = (id: string): Color => {
    return colors.find((x) => x.id === id)!;
  };

  const getColorDescription = (id: string): string => {
    let color: Color | undefined = undefined;
    if (id) {
      color = getColor(model.color);
    }
    if (color) return color.description;
    else return "";
  };

  const change = (field: string, value: any) => {
    setEditModel({ ...model, [field]: value });
    if (submitted) isValid();
  };

  const getError = (field: string): Validation | undefined => {
    return errors.find((x) => x.field === field);
  };

  const getErrorMessage = (field: string): string | undefined => {
    let message: string | undefined = undefined;
    if (errors.find((x) => x.field === field)) {
      message = t(errors.find((x) => x.field === field)!.message);
    }
    return message;
  };

  const isValid = (): boolean => {
    let values: Validation[] = [];
    rules.forEach((rule) => {
      let validation: Validation = rule as Validation;
      if (pass(validation, model[validation.field as keyof typeof model]) === false) {
        values.push(validation);
      }
    });
    setErrors([...values]);
    return values.length === 0;
  };

  const submit = async () => {
    setSubmitted(true);
    if (isValid() === false) return;
    await save(editModel);
    setModel(editModel);
    setSubmitted(false);
  };

  const stockColor = (stock: number): string => {
    if (stock > stockWarning) return "black"
    else return "red";
  }

  return (
    <React.Fragment>
      {/* 
      <Grid item xs={1}>
        <Box sx={{ border: 1, borderColor: "lightgray" }} className="spec">
          <Box>
            <Box className="product-image" borderBottom={1} borderColor={"lightgray"}>
              {renderImageView(model.image)}
            </Box>
            <Box padding={1}>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                {t("field.article")}:
                <strong>{model.article}</strong>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                {t("field.size")}:
                <strong>{getSizeDescription(model.size)}</strong>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                {t("field.color")}:
                <strong>{getColorDescription(model.color)}</strong>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                {t("field.stock")}:
                <strong style={{ color: stockColor(model.stock ? model.stock : 0) }}>{model.stock ? model.stock : 0}</strong>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                {t("field.sold")}:
                <strong>{model.sold ? model.sold : 0}</strong>
              </Box>
            </Box>
          </Box>
          <Box borderTop={1} borderColor={"lightgray"}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Button onClick={handleOpen}>{t("button.edit")}</Button>
              <Button onClick={handleDelete} color="error">{t("button.delete")}</Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      */}

      <Button onClick={handleOpen}>{t("button.edit")}</Button>
      <Button onClick={handleDelete} color="error">{t("button.delete")}</Button>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <Box sx={headerStyle}>{t("title.specification").toUpperCase()}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={3} justifyContent={"center"}>
                <Grid item xs={4}>
                  <Box className="product-image" border={1} borderColor={"lightgray"}>
                    <Upload category={"products"} id={editModel.id} image={editModel.image} onSetImage={(url: string) => change("image", url)}></Upload>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box paddingTop={"5px"} paddingBottom={"5px"}>
                    <TextField
                      fullWidth
                      label={t("field.article")}
                      variant="outlined"
                      size="small"
                      type="text"
                      value={editModel.article}
                      onChange={(e) => change("article", e.target.value as string)}
                      error={getError("article") !== undefined}
                    />
                  </Box>
                  <Box paddingTop={"5px"} paddingBottom={"5px"}>
                    <FormControl fullWidth>
                      <InputLabel id="size" size={"small"}>
                        {t("field.size")}
                      </InputLabel>
                      <Select
                        labelId="size"
                        label={t("field.size")}
                        value={editModel.size}
                        onChange={(e) => change("size", e.target.value as string)}
                        size={"small"}
                        error={getError("size") !== undefined}
                      >
                        {sizes.map((size, idx) => (
                          <MenuItem key={idx} value={size.id}>{size.description}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{getErrorMessage("size")}</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box paddingTop={"5px"} paddingBottom={"5px"}>
                    <FormControl fullWidth>
                      <InputLabel id="color" size={"small"}>
                        {t("field.color")}
                      </InputLabel>
                      <Select
                        labelId="color"
                        label={t("field.color")}
                        value={editModel.color}
                        onChange={(e) => change("color", e.target.value as string)}
                        size={"small"}
                        error={getError("color") !== undefined}
                      >
                        {colors.map((color, idx) => (
                          <MenuItem key={idx} value={color.id}>{color.description}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{getErrorMessage("color")}</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box paddingTop={"5px"} paddingBottom={"5px"}>
                    <TextField
                      fullWidth
                      label={t("field.stock")}
                      variant="outlined"
                      size="small"
                      type="number"
                      value={editModel.stock}
                      onChange={(e) => change("stock", Number(e.target.value as string))}
                      error={getError("stock") !== undefined}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} type="submit">
                {t("button.update")}
              </Button>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={handleClose}>
                {t("button.close")}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <ConfirmDialog title="Confirm Deletion" open={confirm} action={(value: boolean) => {
        setConfirm(false);
        if (value === true) onDelete();
      }}>
        Please Confirm Deletion of Specification.
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default ProductSpec;
