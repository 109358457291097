import React from "react";
import FormLayout from "../../components/form/FormLayout";
import EmailLogViewModel from "../../viewmodels/emaillog/emaillog.viewmodel";
import { templates } from "../../config";
import { Email } from "../../models/email.model";
import ListViewModel from "../../viewmodels/list.viewmodel";
import { FormControl, Grid, InputLabel, ListSubheader, MenuItem, Select } from "@mui/material";

const EmailLog: React.FC = () => {

    const { emails, search, setFilter, getName } = EmailLogViewModel();
    const { count, row, page, onRowChange, onPageChange } = ListViewModel(emails.length);

    const renderLink = (email: Email) => {
        if (email.type === "Transaction") return <a className="fs-6" href={"/transactions/" + email.associatedid}>{email.associatedid}</a>
        else return <a className="fs-6" href={"/customers/" + email.associatedid}>{email.associatedid}</a>
    }

    return (
        <React.Fragment>
            <FormLayout PageTitle="Email Logs" Breadcrumbs={[{ title: "Dashboard", url: "/" }, { title: "Email Logs" }]}></FormLayout>

            <Grid container sx={{ paddingBottom: 2 }}>
                <Grid xs={2} item sx={{ paddingInline: 1 }}>
                    <FormControl fullWidth size="small">
                        <InputLabel htmlFor="grouped-select">Filter</InputLabel>
                        <Select defaultValue="" id="grouped-status" label="Filter" onChange={(e) => setFilter(e.target.value as "Transaction" | "Account" | undefined)}>
                            <MenuItem value={undefined}><em>None</em></MenuItem>
                            <MenuItem value={"Transaction"}>Transaction</MenuItem>
                            <MenuItem value={"Account"}>Account</MenuItem>
                            <ListSubheader>Review Status</ListSubheader>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <div className="list-group mb-2">
                {
                    (row > 0 ? (search !== undefined ? search : emails).slice(page * row, page * row + row) : emails).map((email, idx) => (
                        <div key={idx} className="list-group-item list-group-item-action" aria-current="true">
                            <div className="d-flex w-100 justify-content-between">
                                <span className="fs-6 fw-bold">{getName(email.templateid)}</span>
                                <small>{email.attempt.toDate().toLocaleString()}</small>
                            </div>
                            <div className="mt-2">
                                <span className="fs-6">ID: </span>
                                {email.id}
                            </div>
                            <div className="mt-1">
                                <span className="fs-6">Receiver: </span>
                                {
                                    email.receivers.map((receiver, idx) => (
                                        <span key={idx} className="fs-6">{receiver.toname !== receiver.to ? receiver.toname + " - " + receiver.to + ", " : receiver.to + ", "}</span>
                                    ))
                                }
                            </div>
                            <div className="mt-1">
                                <span className="fs-6">Type: </span>
                                {email.type}
                            </div>
                            <div className="mt-1">
                                <span className="fs-6">Associated ID: </span>
                                {renderLink(email)}
                            </div>
                            <div className="mt-1">
                                <span className="fs-6">Status: </span>
                                <span className="fs-6">{email.message}</span>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="border p-2 mb-4 text-end d-flex w-100 justify-content-between align-items-center">
                <span className="fs-6 fw-bold">Count: {emails.length}</span>
                <div className="text-end">
                    <button type="button" className="btn btn-sm btn-info mx-1" onClick={(e) => onPageChange(page - 1)} disabled={page === 0}>
                        <i className="bi bi-chevron-left"></i>
                    </button>
                    <button type="button" className="btn btn-sm btn-info mx-1" onClick={(e) => onPageChange(page + 1)} disabled={(row * (page + 1)) >= count}>
                        <i className="bi bi-chevron-right"></i>
                    </button>
                </div>
            </div>

        </React.Fragment>
    );
}

export default EmailLog;