export class CalculationService {
    
    racedealcalculationbypercent(percent: Number, originalPrice: Number){
        let amountValue: number = (Number(percent) / 100) * Number(originalPrice);
        let priceValue: number = Number(originalPrice) - amountValue;
        return {
            amount: Number(amountValue), price: Number(priceValue)
        }
    }

    racedealcalculationbyamount(amount: Number, originalPrice: Number){
        let percentValue: number = (Number(amount) / Number(originalPrice)) * 100;
        let priceValue: number = Number(originalPrice) - Number(amount);
        return {
            percent: Number(percentValue), price: Number(priceValue)
        }
    }

    racedealcalculationbyprice(price: Number, originalPrice: Number){
        let amountValue = Number(originalPrice) - Number(price);
        let percentValue = (amountValue / Number(originalPrice)) * 100;
        return {
            amount: Number(amountValue), percent: Number(percentValue)
        }
    }

    
}