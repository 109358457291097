import { Observable } from 'rxjs';
import { BaseService } from './baseservice';
import { collection, doc, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore';
import { AdminSubs } from '../models/admin.model';


export class AdminService extends BaseService {
    colname = "administrators";

    constructor() {
        super('administrators', '');
    }

    subs = (): Observable<AdminSubs[]> => {
        return new Observable((subscribe) => {
            const q = query(collection(this.firestore, this.colname), orderBy("created", "desc"));
            onSnapshot(q, (snap) => {
                let data: AdminSubs[] = [];
                snap.docChanges().forEach((change) => {
                    if (change.type === "modified" || change.type === "removed") {
                        let admin: AdminSubs = change.doc.data() as AdminSubs;
                        admin.id = change.doc.id;
                        admin.subtype = change.type;
                        data.push(admin);
                    }
                });
                if (data.length > 0) subscribe.next(data);
              });
        })
    }

    updateLanguage = (id: string, language: 'en' | 'de'): Promise<void> => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        return updateDoc(docs, {
            language: language
        });
    }
}
