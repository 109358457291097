import { Timestamp } from "firebase/firestore";
import { StringLink } from "../components/table/table";

export class Payment {
  public id: string = "";
  public language: "en" | "de" = "en"
  public accountid: string = "";
  public brandid: string = "";
  public shipping: PaymentAddress | null = null;
  public billing: PaymentAddress | null = null;
  public items: PaymentItem[] = [];
  public price: number = 0;
  public gateway: "" | "Paypal" | "CreditCard" | "Invoice"= "";
  public paypaldetails: PaypalDetails | null = null;
  public stripedetails: StripeDetails | null = null;
  public dealdetails: DealDetails | null = null;
  public added: Timestamp;
  public status: string;
  public reason: string;
  public statuses: Status[];
  public ordersummary: string;
  public postbriefing: string;
}

export class PaypalDetails {
  public id: string = "";
  public payerid: string = "";
  public email: string = "";
  public firstname: string = "";
  public lastname: string = "";
  public status: string = "";
  public countrycode: string = "";
  public created: string = "";
}

export class StripeDetails {
  public intentid: string = "";
  public paymentmethod: string = "";
  public type: string = "";
  public brand: string = "";
  public expiry: string = "";
  public amount: number = 0;
  public currency: string = "";
  public status: string = "";
  public country: string = "";
  public postalcode: string = "";
  public email: string = "";
  public name: string = "";
}

export class PaymentItem {
  public productid: string = "";
  public specsid: string = "";
  public quantity: number = 0;
  public price: number = 0;
}

export class PaymentAddress {
  public title: string = "";
  public name: string = "";
  public address: string = "";
  public state: string = "";
  public city: string = "";
  public postcode: string = "";
}

export class IPaymentItem extends PaymentItem {
  public name?: string = "";
  public description?: string = "";
  public image?: string = "";
}

export class DealDetails {
  public type : "" | "Story" | "Feed" | "Reel";
  public count: number;
}

export class Status {
  status: string;
  reason: string;
  datetime: Timestamp;
  user: string;
}

export class PaymentList {
  public id: string;
  public brand: string;
  public customer: StringLink;
  public type: "" | "RaceClub Deal" | "Influencer Deal";
  public gateway: "" | "Paypal" | "CreditCard" | "Invoice" = "";
  public price: number;
  public added: Timestamp;
  public status: string;
  public action: any;
}