import { useEffect, useState } from "react";
import { SupplierService } from "../../services/supplier.service";
import { Brand } from "../../models/brand.model";
import { Product } from "../../models/product.model";
import { ProductService } from "../../services/product.service";
import { CategoryService } from "../../services/category.service";
import { Category } from "../../models/category.model";
import { CustomerService } from "../../services/customer.service";
import { Customer } from "../../models/customer.model";
import { AdminService } from "../../services/admin.service";
import { Admin } from "../../models/admin.model";
import { PaymentService } from "../../services/payment.service";
import { Payment } from "../../models/payment.model";
import { StorageService } from "../../services/storage.service";

function DashboardViewModel() {
    const [supplier, setSupplier] = useState<string | undefined>(undefined);
    const [isSupplier, setIsSupplier] = useState<boolean>(false);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [customers, setCustomers] = useState<Customer[]>([])
    const [activeCustomers, setActiveCustomers] = useState<Customer[]>([]);
    const [pendingCustomers, setPendingCustomers] = useState<Customer[]>([]);
    const [admins, setAdmins] = useState<Admin[]>([]);
    const [payments, setPayments] = useState<Payment[]>([]);
    const [racedeals, setRacedeals] = useState<Payment[]>([]);
    const [influencerdeals, setInfluencerdeals] = useState<Payment[]>([]);

    const storageService: StorageService = new StorageService()
    const supplierService: SupplierService = new SupplierService();
    const productService: ProductService = new ProductService();
    const categoryService: CategoryService = new CategoryService();
    const customerService: CustomerService = new CustomerService();
    const adminService: AdminService = new AdminService();
    const paymentService: PaymentService = new PaymentService();

    useEffect(() => {
        if (storageService.isUserLogin()) {
            setIsSupplier(storageService.isSupplier());
            if (storageService.isSupplier()) {
                let data = storageService.getSaveLogin()
                if (data) setSupplier(data.supplier.id);
            }
            else {
                setSupplier('');
            }
        }
    }, []);

    useEffect(() => {
        if (supplier !== undefined) {
            if (supplier === '') {
                loadBrands();
                loadProducts();
                loadCategories();
                loadCustomers();
                loadAdmins();
                loadPayments();
            }
            else {
                loadBrands();
                loadProducts();
                loadCategories();
                loadCustomers();
                loadPayments();
            }
        }
    }, [supplier])

    useEffect(() => {
        setActiveCustomers(customers.filter(x => (x.active ? x.active : true) === true)); setPendingCustomers(customers.filter(x => (x.status ? x.status : "pending") === "pending"));
    }, [customers])

    useEffect(() => {
        setRacedeals(payments.filter(x => x.dealdetails === null));
        setInfluencerdeals(payments.filter(x => x.dealdetails !== null));
    }, [payments])

    const loadBrands = () => {
        supplierService.get().then(data => {
            setBrands(data as Brand[])
        })
    }

    const loadProducts = () => {
        if (supplier === '') {
            productService.get().then(data => {
                setProducts(data as Product[])
            })
        }
        else {
            productService.getBrand("", supplier!).then(data => {
                setProducts(data as Product[])
            })
        }
    }

    const loadCategories = () => {
        categoryService.get().then(data => {
            setCategories(data as Category[])
        })
    }

    const loadCustomers = () => {
        customerService.get().then(data => {
            setCustomers(data as Customer[]);
        })
    }

    const loadAdmins = () => {
        adminService.get().then(data => {
            setAdmins((data as Admin[]).filter(x => (x.active ? x.active : true) === true));
        })
    }

    const loadPayments = () => {
        if (supplier === '') {
            paymentService.get().then(data => {
                setPayments(data as Payment[]);
            })
        }
        else {
            paymentService.getBySupplier(supplier!).then(data => {
                setPayments(data as Payment[]);
            })
        }
    }

    return { isSupplier, brands, products, categories, activeCustomers, pendingCustomers, admins, racedeals, influencerdeals};
}

export default DashboardViewModel;