import { Box, Button, Card, CardContent, Grid, TextField } from "@mui/material";
import React from "react";
import SupplierPaymentDetailsViewModel from "../../viewmodels/supplier/paymentdetails.viewmodel";
import "./supplier.css";
import Toggle from "../../components/toggle/toggle";
import { CreditCardDetail, PayPalDetail } from "../../models/brand.model";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  id: string;
  title: string;
  enable: boolean;
  type: "creditcard" | "paypal" | "invoice"
}

const SupplierPaymentDetail: React.FC<ContainerProps> = ({ id, title, enable, type }) => {
  const { t } = useTranslation();

  const { paymentEnable, model, getError, getErrorMessage, change, disablePayment, process, submit } = SupplierPaymentDetailsViewModel(id, enable, type);

  const isReadOnly = () => {
    return paymentEnable ? false : true;
  };

  const render = () => {
    if (model) {
      if (type === "creditcard") {
        return (
          <Grid container spacing={2} paddingTop={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="API Key"
                variant="outlined"
                size="small"
                value={(model as CreditCardDetail).apikey}
                error={getError("apikey") !== undefined}
                helperText={getErrorMessage("apikey")}
                onChange={(e) => change("apikey", e.target.value)}
                disabled={isReadOnly()}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Secret Key"
                variant="outlined"
                size="small"
                value={(model as CreditCardDetail).secretkey}
                error={getError("secretkey") !== undefined}
                helperText={getErrorMessage("secretkey")}
                onChange={(e) => change("secretkey", e.target.value)}
                disabled={isReadOnly()}
              />
            </Grid>
          </Grid>
        );
      }
      else if (type === "paypal") {
        return (
          <Grid container spacing={2} paddingTop={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Client ID"
                variant="outlined"
                size="small"
                value={(model as PayPalDetail).clientid}
                error={getError("clientid") !== undefined}
                helperText={getErrorMessage("clientid")}
                onChange={(e) => change("clientid", e.target.value)}
                disabled={isReadOnly()}
              />
            </Grid>
          </Grid>
        );
      }
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <Grid container>
        <Grid xs={12} item container direction="row" alignItems="center" justifyContent="space-between">
          <h5 className="subtext mb-0">{title.toUpperCase()}</h5>
          <Toggle value={paymentEnable} label={t('field.enable')} onChange={(value: boolean) => disablePayment()}></Toggle>
        </Grid>
        {render()}
        <Grid container spacing={2} paddingTop={4}>
          <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
            <Button type="submit" variant="contained" size="large" disabled={process}>
              {t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default SupplierPaymentDetail;
