import { initializeApp } from "firebase/app";
import { getStorage, getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import firebaseConfig from "../config";
import { Firestore, doc, getDocFromServer, getFirestore, setDoc, } from "firebase/firestore";
import { FirebaseStorage } from 'firebase/storage'
import { Image } from "../models/image.model";

export class ImageService {
    colname = "images";

    app = initializeApp(firebaseConfig);
    firestore: Firestore;
    storage: FirebaseStorage;

    constructor() {
        this.firestore = getFirestore(this.app);
        this.storage = getStorage(this.app);
    }

    getRandomString() {
        let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 20; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    getURL(path: string): Promise<string> {
        return new Promise(async resolve => {
            let url: string = await this.get(path)
            if (url !== "") resolve(url)
            else url = await getDownloadURL(ref(this.storage, path))
            resolve(url);
        })
    }

    upload = (category: string, file: File) => {
        let id = this.getRandomString();
        const reference = ref(this.storage, category + '/' + id + '/' + file.name);
        return uploadBytesResumable(reference, file);
    }

    get(path: string): Promise<string> {
        return new Promise(async resolve => {
            const docs = doc(this.firestore, this.colname + '/' + path.replaceAll("/", "\\"));
            getDocFromServer(docs).then(doc => {
                let image: Image = doc.data() as Image;
                resolve(image.url);
            }).catch(err => resolve(""))
        })
    }

    saveImage(path: string): Promise<void> {
        return new Promise(async resolve => {
            let url: string = await getDownloadURL(ref(this.storage, path));
            const docs = doc(this.firestore, this.colname + '/' + path.replaceAll("/", "\\"));
            setDoc(docs, {
                url: url
            }).then(() => {
                resolve();
            })
        })
    }
}