import { addDoc, collection, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { Contact } from "../models/brand.model";
import { BaseService } from "./baseservice";
import firebaseConfig from '../config';
import { initializeApp } from 'firebase/app';

export class SupplierContactService extends BaseService {
    app = initializeApp(firebaseConfig);

    constructor() {
        super("brandcontacts", "");
    }

    colname: string = 'brandcontacts';

    getById = (id: string) => new Promise<Contact[]>(resolve => {
        let col = collection(this.firestore, this.colname);
        let constraints = [];
        let data: Contact[] = [];
        constraints.push(where('brandid', '==', id));
        constraints.push(orderBy('created','desc'));
        const q = query(col, ...constraints);
        onSnapshot(q, snap => {
            snap.docs.forEach(doc => {
                let contact: Contact = doc.data() as Contact;
                contact.id = doc.id;
                data.push(contact)
            });
            resolve(data);
        })
    });

    add = (contact: Contact) => new Promise<string>(resolve => {
        const col = collection(this.firestore, this.colname);
        addDoc(col, {
            brandid: contact.brandid,
            name: contact.name,
            email: contact.email,
            phone: contact.phone ? contact.phone : '',
            active: contact.active ? contact.active : false,
            created: serverTimestamp(),
            createdby: this.userid
        }).then(value => {
            resolve(value.id);
        });
    });

    update = (id: string, contact: Contact) => new Promise<void>(resolve => {
        const docs = doc(this.firestore, this.colname + '/' + contact.id);
        updateDoc(docs, {
            brandid: contact.brandid,
            name: contact.name,
            email: contact.email,
            phone: contact.phone ? contact.phone : '',
            active: contact.active ? contact.active : false,
            modified: serverTimestamp(),
            modifiedby: this.userid
        }).then(() => {
            resolve();
        })
    });
}