import { StorageService } from './storage.service';
import { Customer } from "../models/customer.model";
import { BaseService } from "./baseservice";
import { collection, doc, getFirestore, onSnapshot, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { Account, LicenseValidationResult } from '../../functions/src/models/account';
import { httpsCallable } from 'firebase/functions';

export class CustomerService extends BaseService {
  colname = "accounts";

  constructor() {
    super("accounts", "");
    this.firestore = getFirestore(this.app);

    const storageService: StorageService = new StorageService();
    if (storageService.isUserLogin()) {
      let data = storageService.getSaveLogin();
      if (data) this.userid = data.data.id;
    }
  }

  searchEmail = (email: string) => new Promise<Account | undefined>((resolve) => {
    let col = collection(this.firestore, this.colname);
        let constraints = [];
        let data: any[] = [];
        constraints.push(where('email', '==', email));

        const q = query(col, ...constraints);
        onSnapshot(q, snap => {
            if (snap.docs.length > 0) {
              let account: Account = snap.docs[0].data() as Account;
              account.id = snap.docs[0].id
              resolve(account)
            }
            else {
              resolve(undefined);
            }
        })
  })

  set = (id: string, data: Customer) => new Promise<void>((resolve) => {
    const docs = doc(this.firestore, this.colname + '/' + id);
    setDoc(docs, {
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      status: data.status,
      followers: data.followers,
      ratings: data.ratings,
      active: data.active,
      withLicense: false,
      license: "",
      withOfficial: false,
      official: "",
      created: serverTimestamp(),
      createdBy: this.userid
    }).then(() => {
      resolve();
    })
  });

  update = (id: string, data: Customer) => new Promise<void>((resolve) => {
    const docs = doc(this.firestore, this.colname + '/' + id);
    updateDoc(docs, {
      email: data.email ? data.email : "",
      firstname: data.firstname ? data.firstname : "",
      lastname: data.lastname ? data.lastname : "",
      birthday: data.birthday ? data.birthday : null,
      instagram: data.instagram ? data.instagram : "",
      followers: data.followers ? Number(data.followers) : 0,
      ratings: data.ratings,
      withLicense: data.withLicense !== undefined ? data.withLicense : false,
      withOfficial: data.withOfficial !== undefined ? data.withOfficial : false,
      newsletter: data.newsletter !== undefined ? data.newsletter : false,
      active: data.active !== undefined ? data.active : true,
      modified: serverTimestamp(),
      modifiedby: this.userid
    }).then(() => {
      resolve();
    })
  });

  updateStatus = (id: string, status: "pending" | "approved" | "suspend" | "deleted"): Promise<void> => {
    return new Promise((resolve) => {
      const docs = doc(this.firestore, this.colname + "/" + id);
      updateDoc(docs, {
        status: status,
        approveddate: serverTimestamp(),
        appovedby: this.userid
      }).then(() => {
        resolve();
      });
    });
  }

  updateRatings = (id: string, followers: number, ratings: string): Promise<void> => {
    return new Promise((resolve) => {
      const docs = doc(this.firestore, this.colname + "/" + id);
      updateDoc(docs, {
        followers: Number(followers),
        ratings: ratings,
        modified: serverTimestamp(),
        modifiedby: this.userid
      }).then(() => {
        resolve();
      });
    });
  }

  getWishlist = (productIds: string[]) => new Promise<Account[]>((resolve) => {
    let col = collection(this.firestore, this.colname);
    let constraints = [];
    let data: Account[] = [];

    constraints.push(where('wishes', 'array-contains-any', productIds));

    const q = query(col, ...constraints);
    onSnapshot(q, snap => {
      snap.docs.forEach(doc => {
        let account: Account = doc.data() as Account;
        account.id = doc.id;
        data.push(account);
      });
      resolve(data);
    })
  });

  licensevalidate = (id: string): Promise<LicenseValidationResult> =>
    new Promise((resolve) => {
      const licensevalidate = httpsCallable(this.functions, "licensevalidate");
      licensevalidate(id).then((values: any) => resolve(values.data as LicenseValidationResult));
    });
}
