import { Account } from './../../../functions/src/models/account';
import { useEffect, useState } from "react";
import { Payment, PaymentItem, Status } from "../../models/payment.model";
import { PaymentService } from "../../services/payment.service";
import { CustomerService } from '../../services/customer.service';
import { Brand } from '../../models/brand.model';
import { SupplierService } from '../../services/supplier.service';
import { Product } from '../../models/product.model';
import { ProductService } from '../../services/product.service';
import { Color } from '../../models/color.model';
import { Size } from '../../models/size.model';
import { ColorService } from '../../services/color.service';
import { SizeService } from '../../services/size.service';
import { useSnackbar } from 'notistack';
import { Timestamp } from 'firebase/firestore';
import { StorageService } from '../../services/storage.service';
import pass, { Validation } from '../../models/validation.model';
import { useTranslation } from 'react-i18next';

function TransactionDetailViewModel(id: string) {
    const { t } = useTranslation();

    const [payment, setPayment] = useState<Payment | undefined>(undefined);
    const [brand, setBrand] = useState<Brand | undefined>(undefined);
    const [customer, setCustomer] = useState<Account | undefined>(undefined);
    const [products, setProducts] = useState<Product[]>([]);
    const [colors, setColors] = useState<Color[]>([])
    const [sizes, setSizes] = useState<Size[]>([])
    const [statuses, setStatuses] = useState<Status[]>([]);
    const [errors, setErrors] = useState<Validation[]>([]);

    const { enqueueSnackbar } = useSnackbar();

    const paymentService: PaymentService = new PaymentService();
    const supplierService: SupplierService = new SupplierService();
    const customerService: CustomerService = new CustomerService();
    const productService: ProductService = new ProductService();
    const colorService: ColorService = new ColorService();
    const sizeService: SizeService = new SizeService();

    const moderationrules = [
        {
            field: "reason",
            type: "required",
            message: "error.reasonrequire",
        },
    ]

    useEffect(() => { loadPayment() }, []);

    const loadPayment = async () => {
        let data: Payment = await paymentService.getById(id) as Payment;
        await loadBrand(data.brandid);
        await loadCustomer(data.accountid);
        await loadItems(data.items);
        await loadColors();
        await loadSizes();
        setPayment(data);
        if (data.statuses) setStatuses(data.statuses);
    }

    const loadBrand = (id: string): Promise<void> => {
        return new Promise(async resolve => {
            let data: Brand = await supplierService.getById(id) as Brand;
            setBrand(data);
            resolve();
        })
    }

    const loadCustomer = (id: string): Promise<void> => {
        return new Promise(async resolve => {
            let data: Account = await customerService.getById(id) as Account;
            setCustomer(data);
            resolve();
        })
    }

    const loadItems = (items: PaymentItem[]): Promise<void> => {
        return new Promise(async resolve => {
            let data: Product[] = [];

            for await (const item of items) {
                let product: Product = await productService.getById(item.productid) as Product;
                data.push(product);
            }

            setProducts(data);
            resolve();
        })
    }

    const loadColors = (): Promise<void> => {
        return new Promise(async resolve => {
            let data: Color[] = await colorService.get() as Color[];
            setColors(data);
            resolve();
        })
    }

    const loadSizes = (): Promise<void> => {
        return new Promise(async resolve => {
            let data: Size[] = await sizeService.get() as Size[];
            setSizes(data);
            resolve();
        })
    }

    const changeStatus = (status: string, reason: string): Promise<void> => {
        return new Promise(async resolve => {


            if ((status === 'cancel') && (reason === "")) {
                let values: Validation[] = [];
                moderationrules.forEach(rule => {
                    let validation: Validation = rule as Validation;
                    if (pass(validation, reason) === false) {
                        values.push(validation);
                    }
                })
                setErrors([...values]);
                resolve();
            }
            else {
                setErrors([]);
                const storageService: StorageService = new StorageService();
                let user = storageService.getSaveLogin();
                let model: Status = new Status();
                model.status = status;
                model.reason = reason;
                model.user = user.data.id
                model.datetime = Timestamp.now();
                let _statuses = statuses;
                _statuses.push(model);
                setStatuses(_statuses);

                await paymentService.updateStatus(id, status, reason, _statuses);

                let data: Payment = await paymentService.getById(id) as Payment;
                setPayment(data);
                if (data.statuses) setStatuses(data.statuses);
                enqueueSnackbar("Status change to " + status.toUpperCase(), {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                });
                resolve();
            }
        })
    }

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message);
        }
        return message;
    }

    return { payment, statuses, brand, customer, products, colors, sizes, changeStatus, getError, getErrorMessage }
}

export default TransactionDetailViewModel;