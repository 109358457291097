import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Contact } from "../../models/brand.model";
import SupplierContactLDetailViewModel from "../../viewmodels/supplier/contactdetail.viewmodel";
import Toggle from "../../components/toggle/toggle";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  mode: "add" | "edit";
  brandid: string;
  contact: Contact;
}

const SupplierContactDetail: React.FC<ContainerProps> = ({ mode, brandid, contact }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { model, submit, change, getError, getErrorMessage, process } = SupplierContactLDetailViewModel(brandid, contact);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  const getButton = () => {
    if (mode === "add") {
      return (
        <Button variant="contained" size="medium" onClick={handleOpen}>
          {t('button.create').toUpperCase()}
        </Button>
      );
    } else {
      return (
        <Button color="secondary" size="medium" onClick={handleOpen}>
          {t('button.open').toUpperCase()}
        </Button>
      );
    }
  };

  const getTitle = () => {
    if (mode === "add") return t('text.createcontact');
    else return t('text.contactprofile');
  };

  const getButtonTitle = () => {
    if (mode === "add") return t('button.create').toUpperCase();
    else return t('button.update').toUpperCase();
  };

  return (
    <React.Fragment>
      {getButton()}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <Box sx={headerStyle}>{getTitle().toUpperCase()}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label={t('field.name')}
                    variant="outlined"
                    size="small"
                    value={model.name}
                    error={getError("name") !== undefined}
                    helperText={getErrorMessage("name")}
                    onChange={(e) => change("name", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t('field.email')}
                    variant="outlined"
                    size="small"
                    type="email"
                    value={model.email}
                    error={getError("email") !== undefined}
                    helperText={getErrorMessage("email")}
                    onChange={(e) => change("email", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField label={t('field.phone')} variant="outlined" size="small" value={model.phone} onChange={(e) => change("phone", e.target.value)} fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <Toggle value={model.active} label="ACTIVE" onChange={(value: boolean) => change("active", value)}></Toggle>
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} type="submit" disabled={process}>
                {getButtonTitle()}
              </Button>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={handleClose} disabled={process}>
                CANCEL
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};


export default SupplierContactDetail;
