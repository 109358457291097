import { Grid, TextField } from "@mui/material";
import React from "react";
import EditForm from "../../components/form/EditForm";
import FormLayout from "../../components/form/FormLayout";
import useEditViewModel, { ACTION } from "../shared/useEditViewModel";

function AdminRegister() {
  let _model = {
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    confirm: "",
    active: true,
  };

  let _validate = {
    rules: [
      {
        field: "email",
        type: "required",
        message: "Email Address is Required",
      },
      {
        field: "email",
        datatype: "email",
        type: "valid",
        message: "Not a Valid Email Address",
      },
      {
        field: "password",
        type: "required",
        message: "Password is Required",
      },
      {
        field: "confirm",
        type: "required",
        message: " Password is Required",
      },
      {
        field: "password",
        type: "same",
        other: "confirm",
        message: "Password Mismatch",
      },
      {
        field: "confirm",
        type: "same",
        other: "password",
        message: "Password Mismatch",
      },
    ],
    errors: {},
  };

  const [viewModel, dispatch] = useEditViewModel(
    _model,
    "administrators",
    _validate
  );

  return (
    <React.Fragment>
      <FormLayout
        PageTitle="Administrators"
        Breadcrumbs={[
          { title: "Dashboard", url: "/" },
          { title: "Administrators", url: "/admins" },
          { title: "Register Administrator" },
        ]}
      ></FormLayout>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          <EditForm OnRegister={(e) => dispatch({ type: ACTION.REGISTER })}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                required
                type="email"
                variant="outlined"
                size="small"
                value={viewModel.model.email}
                error={viewModel.validate.email !== undefined}
                helperText={viewModel.validate.email}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "email", value: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="First Name"
                required
                variant="outlined"
                size="small"
                value={viewModel.model.firstname}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "firstname", value: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Last Name"
                required
                variant="outlined"
                size="small"
                value={viewModel.model.lastname}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "lastname", value: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="password"
                label="Password"
                required
                variant="outlined"
                size="small"
                value={viewModel.model.password}
                error={viewModel.validate.password !== undefined}
                helperText={viewModel.validate.password}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "password", value: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="password"
                label="Confirm Password"
                required
                variant="outlined"
                size="small"
                value={viewModel.model.confirm}
                error={viewModel.validate.confirm !== undefined}
                helperText={viewModel.validate.confirm}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "confirm", value: e.target.value },
                  })
                }
              />
            </Grid>
          </EditForm>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AdminRegister;
