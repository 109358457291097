import { useEffect, useState } from "react";
import { Partner } from "../../models/partner";
import { useSnackbar } from "notistack";
import { PartnerService } from "../../services/partner.service";
import { useTranslation } from "react-i18next";

function PartnersListViewModel() {
    const { t } = useTranslation();

    const [partners, setPartners] = useState<Partner[]>([]);

    const { enqueueSnackbar } = useSnackbar();
    const partnerService: PartnerService = new PartnerService();

    useEffect(() => {
        loadCustomers();
    }, [])

    const loadCustomers = async () => {
        let data = await partnerService.get() as Partner[];
        setPartners([...data]);
    }

    const create = async (image: string) => {
        let partner: Partner = new Partner();
        partner.image = image;
        partner.active = true;
        partner.order = partners.length + 1;
        await partnerService.add(partner);
        await loadCustomers();
        enqueueSnackbar(t('text.partnerimageuploaded'), {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
    }

    const changeStatus = async (id: string, value: boolean) => {
        await partnerService.updateStatus(id, value);
        enqueueSnackbar(t('text.statuschange'), {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
    }

    const changeTitle = async (id: string, value: string) => {
        await partnerService.updateTitle(id, value);
        enqueueSnackbar(t('text.titlechange'), {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
    }

    const changeOrder = async (partners: Partner[]) => {
        partners.forEach(async partner => {
            await partnerService.updateOrder(partner.id, partner.order);
        })
    }

    return { partners, create, changeStatus, changeTitle, changeOrder }
}

export default PartnersListViewModel;
