import { useEffect, useState } from "react";
import { ColorService } from "../../services/color.service";
import { Color, ColorList } from "../../models/color.model";
import firebaseConfig from "../../config";
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { useTranslation } from "react-i18next";
import { Action } from "../../components/table/table";
import { TableField } from "../../models/table.model";
import ColorDetails from "../../views/color/color-detail";

function ColorListViewModel() {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const { t, i18n } = useTranslation();

    const [colors, setColors] = useState<Color[] | undefined>(undefined);
    const [colorList, setColorList] = useState<ColorList[] | undefined>(undefined);
    const [search, setSearch] = useState<string>("");
    const [searches, setSearches] = useState<ColorList[] | undefined>(undefined);

    const colorService: ColorService = new ColorService();

    const fields = [
        { id: "id", display: "#", align: "left", sort: false, type: 'string' },
        { id: "description", display: t("field.description").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "hex", display: "HEX", align: "left", sort: false, type: 'string' },
        { id: "arrangement", display: t("field.arrangement").toUpperCase(), align: "center", sort: true, type: 'number' },
        { id: "active", display: t("field.status").toUpperCase(), align: "center", sort: false, type: 'status' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' }
    ] as TableField[];

    useEffect(() => {
        const q = query(collection(firestore, "colors"), orderBy("arrangement", "asc"));
        let items: Color[] = [];
        let itemList: ColorList[] = [];

        let unSubcribe = onSnapshot(q, (snap) => {

            snap.docChanges().forEach((change) => {
                let color: Color = change.doc.data() as Color;
                color.id = change.doc.id;

                if (change.type === 'added') {
                    items.push(color);
                    itemList.push(convertColorList(color))
                }
                if (change.type === 'modified') {
                    let idx = items.findIndex(x => x.id === change.doc.id);
                    if (idx >= 0) items[change.newIndex] = { ...items[change.newIndex], ...change.doc.data() }

                    idx = itemList.findIndex(x => x.id === change.doc.id);
                    if (idx >= 0) itemList[idx] = { ...itemList[idx], ...color }
                }
            });

            setColors([...items])
            setColorList([...itemList])
        });

        return () => {
            unSubcribe();
        }
    }, [])

    useEffect(() => {
        if (search != '' && colors) {
            let items: Color[] = [];
            colors.forEach(item => {
                let description: string = item.description ? item.description.toLowerCase() : '';
                if (description.includes(search.toLowerCase())) {
                    items.push(item);
                }
            })
            let itemList: ColorList[] = [];
            items.forEach(item => itemList.push(convertColorList(item)));
            setSearches([...itemList])
        }
        else setSearches(undefined)
    }, [search])

    const convertColorList = (color: Color): ColorList => {
        let action: Action = new Action();
        action.type = 'component';
        action.component = ColorDetails;
        action.props = {
            mode: 'edit',
            color: { ...color }
        }
        return { ...color, action: action }
    }

    return { colorList, searches, fields, setSearch };
}

export default ColorListViewModel;