import React from "react";
import FormLayout from "../../components/form/FormLayout";
import { Button, Grid, Tab, Tabs } from "@mui/material";
import TransactionListViewModel from "../../viewmodels/transaction/list.viewmodel";
import { useTranslation } from "react-i18next";
import TableLayout from "../../components/table/table";

const Transactions: React.FC = () => {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = React.useState<"completed" | "uncompleted">("completed");

  const { paymentList, fields, exporttoexcel } = TransactionListViewModel();

  return (
    <React.Fragment>
      <FormLayout
        PageTitle={t("title.transactions")}
        Breadcrumbs={[{ title: t("title.dashboard"), url: "/" }, { title: t("title.transactions") }]}
      ></FormLayout>

      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} >
        <Grid item xs={12}>
          <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <Tabs value={tabSelected} onChange={(event: React.SyntheticEvent, newValue: string) => setTabSelected(newValue as "completed" | "uncompleted")}>
              <Tab value="completed" label={"Completed"} />
              <Tab value="uncompleted" label={"Cancelled"} />
            </Tabs>
            <Button onClick={() => exporttoexcel()} variant="contained" color="secondary" sx={{ ml: 1 }} disabled={paymentList && paymentList.length === 0}>
              {t("button.exporttoexcel")}
            </Button>
          </div>
        </Grid>
      </Grid>

      <div hidden={tabSelected !== "completed"}>
        <TableLayout fields={fields} data={paymentList ? paymentList.filter(x => x.status !== 'cancel') : undefined}></TableLayout>
      </div>

      <div hidden={tabSelected !== "uncompleted"}>
        <TableLayout fields={fields} data={paymentList ? paymentList.filter(x => x.status === 'cancel') : undefined}></TableLayout>
      </div>

    </React.Fragment>
  );
};

export default Transactions;
