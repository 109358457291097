import { useEffect, useState } from "react";
import { Category } from "../../models/category.model";
import pass, { Validation } from "../../models/validation.model";
import { CategoryService } from "../../services/category.service";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function CategoryDetailViewModel(mode: 'add'|'edit', category: Category) {
    const { t } = useTranslation();

    const [model, setModel] = useState<Category>(category);
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [process, setProcess] = useState(false);

    const categoryService: CategoryService = new CategoryService();

    const { enqueueSnackbar } = useSnackbar();

    const rules: any[] = [
        {
            field: "code",
            type: "required",
            message: "error.coderequire",
        },
        {
            field: "orderno",
            type: "valid",
            datatype: "number",
            message: "error.notavalidnumber",
        },
        {
            field: "text_en",
            type: "required",
            message: "error.textrequire",
        },
        {
            field: "text_de",
            type: "required",
            message: "error.textrequire",
        },
    ]

    useEffect(() => {
        setModel(category);
    }, [category])

    const change = (field: string, value: any) => {
        setModel({ ...model, [field]: value });
        if (submitted) isValid();
    };

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message)
        }
        return message;
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, model[validation.field as keyof typeof model]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }
    
    const clear = () => {
        setModel(new Category());
    }

    const submit = async (): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);
            setProcess(true);
            
            if (isValid() === false) {
                setProcess(false);
                resolve(false);
                return;
            }

            if (mode === 'add'){
                await categoryService.add(model);
                clear();
            }
            else{
                await categoryService.update(model.id, model);
            }

            setSubmitted(false);
            setProcess(false);
            enqueueSnackbar("Saved Details", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            resolve(true);
        });
    }

    return { model, submit, change, getError, getErrorMessage, process }
}

export default CategoryDetailViewModel;