import { useEffect, useState } from "react";
import { ProductService } from "../../services/product.service";
import { Product, Specs, SpecsList } from "../../models/product.model";
import { Color } from "../../models/color.model";
import { ColorService } from "../../services/color.service";
import { SizeService } from "../../services/size.service";
import { Size } from "../../models/size.model";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { TableField } from "../../models/table.model";
import { Action } from "../../components/table/table";
import ProductSpec from "../../views/product/specification/product-spec";

function ProductSpecificationsViewModel(id: string) {
    const { t, i18n } = useTranslation();

    const [specs, setSpecs] = useState<Specs[]>([]);
    const [list, setList] = useState<SpecsList[] | undefined>(undefined);
    const [sizes, setSizes] = useState<Size[]>([]);
    const [colors, setColors] = useState<Color[]>([]);

    const { enqueueSnackbar } = useSnackbar();

    const productService: ProductService = new ProductService();
    const sizeService: SizeService = new SizeService();
    const colorService: ColorService = new ColorService();

    const fields = [
        { id: "id", display: "#", align: "left", sort: false, type: 'string' },
        { id: "image", display: "Image", align: "center", sort: false, type: 'image' },
        { id: "article", display: "Article", align: "left", sort: false, type: 'string' },
        { id: "color", display: "Color", align: "left", sort: false, type: 'string' },
        { id: "size", display: "Size", align: "left", sort: false, type: 'string' },
        { id: "stock", display: "Stock", align: "right", sort: false, type: 'number' },
        { id: "sold", display: "Sold", align: "right", sort: false, type: 'number' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' }
    ] as TableField[]

    useEffect(() => {
        sizeService.get().then(data => setSizes(data as Size[]));
        colorService.get().then(data => setColors(data as Color[]));
    }, []);

    useEffect(() => {
        if (id) {
            productService.getById(id).then(product => {
                let data: Product = product as Product;
                if (data.specs) setSpecs(data.specs);
            });
        }
    }, [id]);

    useEffect(() => {
        if (specs.length > 0) {
            let temp: SpecsList[] = [];
            specs.forEach(spec => {
                temp.push(convertSpecsList(spec))
            })
            setList([...temp]);
        }
        else setList([])
    }, [specs])

    const add = (data: Specs): Promise<void> => {
        return new Promise((resolve) => {
            let list: Specs[] = specs ? specs : [];
            list.push(data);
            setSpecs([...list]);
            productService.updateSpecs(id, list);
            enqueueSnackbar("Product Specifications Updated", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            resolve();
        })
    }

    const update = (data: Specs): Promise<void> => {
        return new Promise((resolve) => {
            let list: Specs[] = specs;
            let idx: number = list.findIndex(x => x.id === data.id);
            if (idx >= 0) {
                list[idx] = data;
                setSpecs([...list]);
                productService.updateSpecs(id, list);
                enqueueSnackbar("Product Specifications Updated", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                });
            }
            resolve();
        })
    }

    const onDelete = (specId: string): Promise<void> => {
        return new Promise((resolve) => {
            let items: Specs[] = specs;
            let idx = items.findIndex(x => x.id === specId)
            if (idx >= 0) {
                items[idx].isDeleted = true;
                setSpecs([...items]);
                productService.updateSpecs(id, items);
                enqueueSnackbar("Product Specifications Deleted", {
                    variant: "success",
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "right",
                    },
                });
            }

            resolve()
        })
    }

    const convertSpecsList = (specs: Specs): SpecsList => {
        let item: SpecsList = new SpecsList();
        item.id = specs.id;
        item.article = specs.article;

        let size = sizes.find(x => x.id === specs.size);
        if (size) item.size = (i18n.language === 'en' ? size.description : size.description_de).toUpperCase();

        let color = colors.find(x => x.id === specs.color);
        if (color) item.color = (i18n.language === 'en' ? color.description : color.description_de).toUpperCase();

        item.stock = specs.stock;
        item.image = specs.image;
        item.sold = specs.sold;
        let action: Action = new Action();
        action.type = 'component';
        action.component = ProductSpec;
        action.props = {
            specs: specs,
            sizes: sizes,
            colors: colors,
            save: update,
            onDelete: onDelete
        }
        item.action = action;
        return item;
    }

    return { fields, list, specs, sizes, colors, add, update, onDelete }
}

export default ProductSpecificationsViewModel;