import { collection, doc, getFirestore, onSnapshot, orderBy, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { BaseService } from "./baseservice";
import { Status } from "../models/payment.model";

export class PaymentService extends BaseService {
    colname = "payments";

    constructor() {
        super("payments", "added");
        this.firestore = getFirestore(this.app);
    }

    get = () => new Promise((resolve) => {
        let col = collection(this.firestore, this.colname);
        let constraints = [];
        let data: any[] = [];
        constraints.push(orderBy('added', 'desc'));
        const q = query(col, ...constraints);
        onSnapshot(q, snap => {
            snap.docs.forEach(doc => {
                data.push({
                    id: doc.id,
                    ...doc.data(),
                })
            });
            resolve(data);
        })
    });

    getBySupplier = (id: string) => new Promise((resolve) => {
        let col = collection(this.firestore, this.colname);
        let constraints = [];
        let data: any[] = [];
        constraints.push(where('brandid', '==', id))
        constraints.push(orderBy('added', 'desc'));
        const q = query(col, ...constraints);
        onSnapshot(q, snap => {
            snap.docs.forEach(doc => {
                data.push({
                    id: doc.id,
                    ...doc.data(),
                })
            });
            resolve(data);
        })
    });

    updateStatus(id: string, status: string, reason: string, statuses: Status[]) {
        let _statuses: any[] = [];

        statuses.map(stat => _statuses.push({
            status: stat.status,
            reason: stat.reason ? stat.reason : "",
            datetime: stat.datetime,
            user: stat.user
        }))
        new Promise<void>((resolve) => {
            const docs = doc(this.firestore, this.colname + "/" + id);
            updateDoc(docs, {
                status: status,
                reason: reason,
                statuses: _statuses,
                modified: serverTimestamp(),
                modifiedby: this.userid
            }).then(() => {
                resolve();
            });
        });
    }
}