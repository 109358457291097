import React, { useState } from "react";
import { BrandUser } from "../../models/brand.model";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import Toggle from "../../components/toggle/toggle";
import SupplierUserDetailViewModel from "../../viewmodels/supplier/userdetail.viewmodel";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  branduser: BrandUser;
}

const SupplierUserDetail: React.FC<ContainerProps> = ({ branduser }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  
  const { model, change, submit, getError, getErrorMessage, process } = SupplierUserDetailViewModel(branduser)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  return (
    <React.Fragment>
      <Button color="secondary" size="medium" onClick={handleOpen}>
        {t('button.open').toUpperCase()}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <Box sx={headerStyle}>{t('text.registerbranduser').toUpperCase()}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label={t('field.firstname')}
                    variant="outlined"
                    size="small"
                    value={model.firstname}
                    error={getError("firstname") !== undefined}
                    helperText={getErrorMessage("firstname")}
                    onChange={(e) => change("firstname", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t('field.lastname')}
                    variant="outlined"
                    size="small"
                    value={model.lastname}
                    error={getError("lastname") !== undefined}
                    helperText={getErrorMessage("lastname")}
                    onChange={(e) => change("lastname", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t('field.email')}
                    variant="outlined"
                    size="small"
                    type="email"
                    value={model.email}
                    error={getError("email") !== undefined}
                    helperText={getErrorMessage("email")}
                    onChange={(e) => change("email", e.target.value)}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Toggle value={model.active} label="ACTIVE" onChange={(value: boolean) => change("active", value)}></Toggle>
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} type="submit" disabled={process}>
                {t('button.update')}
              </Button>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={handleClose}>
                {t('button.cancel')}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
/*
function SupplierUserDetail(props) {
  const location = useLocation();
  const { id } = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  let _model = {
    email: "",
    firstname: "",
    lastname: "",
    active: true,
  };

  let _validate = {
    rules: [
      {
        field: "email",
        type: "required",
        message: "Email Address is Required",
      },
      {
        field: "email",
        datatype: "email",
        type: "valid",
        message: "Not a Valid Email Address",
      },
    ],
    errors: {},
  };

  const [viewModel, dispatch] = useEditViewModel(_model, "brandusers", _validate);

  useEffect(() => {
    let service = new SupplierService();
    let supplierUserService = new SupplierUserService();
    supplierUserService.getById(id).then((supplier) => {
      service.getById(supplier.brandid).then((brand) => {
        if (location.pathname.indexOf("/mydetailsuser") >= 0) {
          setBreadcrumbs([{ title: "Dashboard", url: "/" }, { title: 'My Details', url: "/mydetails/" + brand.id }, { title: "Brand User Profile" }]);
        } else {
          setBreadcrumbs([{ title: "Dashboard", url: "/" }, { title: brand.name, url: "/brands/" + brand.id }, { title: "Brand User Profile" }]);
        }
      });
    });

    dispatch({ type: ACTION.LOAD, id: id });
  }, []);



  return (
    <React.Fragment>
      <FormLayout PageTitle="Brand User" Breadcrumbs={breadcrumbs}></FormLayout>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={7}>
          <EditForm OnSave={(e) => dispatch({ type: ACTION.SAVE })}>
            <h5
              className="pagetitle"
              style={{
                padding: "1.5rem",
                marginBottom: "1.5rem",
                width: "100%",
              }}
            >
              Brand User
            </h5>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox />}
                label="Active"
                checked={viewModel.model.active}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "active", value: e.target.checked },
                  })
                }
              />
              <hr />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email Address"
                variant="outlined"
                size="small"
                type="email"
                title="Email Address"
                placeholder="Enter Email Adderess"
                value={viewModel.model.email}
                error={viewModel.validate.email !== undefined}
                helperText={viewModel.validate.email}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "email", value: e.target.value },
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                variant="outlined"
                size="small"
                title="First Name"
                placeholder="Enter First Name"
                value={viewModel.model.firstname}
                error={viewModel.validate.firstname !== undefined}
                helperText={viewModel.validate.firstname}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "firstname", value: e.target.value },
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                size="small"
                title="Last Name"
                placeholder="Enter Last Name"
                value={viewModel.model.lastname}
                error={viewModel.validate.lastname !== undefined}
                helperText={viewModel.validate.lastname}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "lastname", value: e.target.value },
                  })
                }
                fullWidth
              />
            </Grid>
          </EditForm>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
*/
export default SupplierUserDetail;
