import React, { useEffect, useRef, useState } from "react";
import "./Upload.css";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import { ImageService } from "../../services/image.service";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

function Upload(props) {
  const { t } = useTranslation();

  const inputFileRef = useRef(null);
  const [mode, setMode] = useState("upload");
  const [progresspercent, setProgresspercent] = useState(0);
  const [image, setImage] = useState();

  let service = new ImageService();

  const onTrigger = () => {
    inputFileRef.current.click();
  };

  const onUpload = (event) => {
    setMode("uploading");
    const uploadTask = service.upload(props.category + "/" + props.id, event.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresspercent(progress);
      },
      (error) => {
        setMode("empty");
        alert(error);
      },
      () => {
        props.onSetImage(uploadTask.snapshot.ref.fullPath);
        load(uploadTask.snapshot.ref.fullPath);
        service.saveImage(uploadTask.snapshot.ref.fullPath);
      }
    );
  };

  const load = (url) => {
    setMode("loading");
    service.getURL(url).then((path) => {
      setMode("empty");
      setImage(path);
    });
  };

  useEffect(() => {
    if (props.image && props.image !== "") {
      load(props.image);
    }
  }, [props.image]);

  return (
    <React.Fragment>
      <div className="upload-div" onClick={onTrigger}>
        <Box className="uploadicon" style={mode === "upload" ? {} : { display: "none" }} textAlign={"center"}>
          <FileUploadSharpIcon fontSize="large" />
          <span>{t('text.clicktoupload')}</span>
        </Box>
        <Box sx={{ width: "80%" }} style={mode === "uploading" ? {} : { display: "none" }}>
          <LinearProgress variant="determinate" value={progresspercent} />
        </Box>
        <Box style={mode === "loading" ? {} : { display: "none" }}>
          <CircularProgress />
        </Box>
        <img src={image} className="uploadimage" style={mode === "empty" ? {} : { display: "none" }} alt="Click to Upload" />
      </div>
      <input accept="image/*" type="file" className="hidden" ref={inputFileRef} onChange={onUpload} />
    </React.Fragment>
  );
}

export default Upload;
