import React, { useEffect, useState } from "react";
import "./../product.css";
import AddIcon from "@mui/icons-material/Add";
import { Size } from "../../../models/size.model";
import { Color } from "../../../models/color.model";
import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { Specs } from "../../../models/product.model";
import pass, { Validation } from "../../../models/validation.model";
import Upload from "../../../components/upload/Upload";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  sizes: Size[];
  colors: Color[];
  save: (specs: Specs) => void;
}

const ProductSpecCreate: React.FC<ContainerProps> = ({ sizes, colors, save }) => {
  const { t } = useTranslation();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  let rules = [
    {
      field: "color",
      type: "required",
      message: "error.colorrequire",
    },
    {
      field: "size",
      type: "required",
      message: "error.sizerequire",
    },
  ];

  const [model, setModel] = useState<Specs>(new Specs());
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<Validation[]>([]);
  const [submitted, setSubmitted] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    change("stock", 0);
  }, []);

  const change = (field: string, value: any) => {
    setModel({ ...model, [field]: value });
    if (submitted) isValid();
  };

  const getError = (field: string): Validation | undefined => {
    return errors.find((x) => x.field === field);
  };

  const getErrorMessage = (field: string): string | undefined => {
    let message: string | undefined = undefined;
    if (errors.find((x) => x.field === field)) {
      message = t(errors.find((x) => x.field === field)!.message);
    }
    return message;
  };

  const isValid = (): boolean => {
    let values: Validation[] = [];
    rules.forEach((rule) => {
      let validation: Validation = rule as Validation;
      if (pass(validation, model[validation.field as keyof typeof model]) === false) {
        values.push(validation);
      }
    });
    setErrors([...values]);
    return values.length === 0;
  };

  const generate = (): string => {
    let randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 10; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  };

  const clear = async () => {
    setModel(new Specs());
  };

  const submit = async () => {
    setSubmitted(true);

    if (isValid() === false) return;

    let data: Specs = model;
    data.id = generate();
    //data.stock = Number(0);
    data.sold = Number(0);
    save(data);
    clear();
    handleClose();
    setSubmitted(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={(e) => handleOpen()}>
        {t("button.create")}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <Box sx={headerStyle}>{t("title.specification")}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={3} justifyContent={"center"}>
                <Grid item xs={4}>
                  <Box className="product-image" border={1} borderColor={"lightgray"}>
                    <Upload category={"products"} id={model.id} image={model.image} onSetImage={(url: string) => change("image", url)}></Upload>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box paddingTop={"10px"} paddingBottom={"10px"}>
                    <TextField
                      fullWidth
                      label={t("field.article")}
                      variant="outlined"
                      size="small"
                      type="text"
                      value={model.article}
                      onChange={(e) => change("article", e.target.value as string)}
                      error={getError("article") !== undefined}
                    />
                  </Box>
                  <Box paddingTop={"10px"} paddingBottom={"10px"}>
                    <FormControl fullWidth>
                      <InputLabel id="size" size={"small"}>
                        {t("field.size")}
                      </InputLabel>
                      <Select
                        labelId="size"
                        label={t("field.size")}
                        value={model.size}
                        onChange={(e) => change("size", e.target.value as string)}
                        size={"small"}
                        error={getError("size") !== undefined}
                      >
                        {sizes.map((size, idx) => (
                          <MenuItem key={idx} value={size.id}>{size.description}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{getErrorMessage("size")}</FormHelperText>
                    </FormControl>
                  </Box>
                  <Box paddingTop={"10px"} paddingBottom={"10px"}>
                    <FormControl fullWidth>
                      <InputLabel id="color" size={"small"}>
                        {t("field.color")}
                      </InputLabel>
                      <Select
                        labelId="color"
                        label={t("field.color")}
                        value={model.color}
                        onChange={(e) => change("color", e.target.value as string)}
                        size={"small"}
                        error={getError("color") !== undefined}
                      >
                        {colors.map((color, idx) => (
                          <MenuItem key={idx} value={color.id}>{color.description}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{getErrorMessage("color")}</FormHelperText>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} type="submit">
                {t("button.create")}
              </Button>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={handleClose}>
                {t("button.cancel")}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ProductSpecCreate;
