import { useEffect, useState } from "react";
import { ProductCard, ProductCardSetting, ProductCardTagSetting } from "../../models/setting.model";
import { SettingService } from "../../services/setting,service";
import { useSnackbar } from "notistack";

function ProductCardSettingViewModel(id: 'in-stock' | 'out-of-stock' | 'limited-time') {
    const [settings, setSettings] = useState<ProductCard>(new ProductCard());

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (id) {
            const settingService: SettingService = new SettingService();
            settingService.getById('card-setting-' + id).then(settings => {
                setSettings(settings as ProductCard);
            }).catch(err => {
                let setting: ProductCard = new ProductCard();
                setting.id = id;
                setting.card = new ProductCardSetting();
                setting.card.background = '#FFFFFF';
                setting.card.border = '#FFFFFF';
                setting.tag = new ProductCardTagSetting();
                setting.tag.background = '#FFFFFF';
                setting.tag.font = '#FFFFFF';
                setSettings(setting)
            })
        }
    }, [id])

    const save = async () => {
        const settingService: SettingService = new SettingService();
        await settingService.updateCardSetting('card-setting-' + id, settings);
        enqueueSnackbar("Product Card Setting Updated", {
            variant: "success",
            anchorOrigin: {
                vertical: "top",
                horizontal: "right",
            },
        });
    }

    return { settings, setSettings, save };
}

export default ProductCardSettingViewModel;