import { initializeApp } from 'firebase/app';
import {
    addDoc, collection, doc, getDocFromServer,
    getFirestore, onSnapshot, query, serverTimestamp,
    setDoc, updateDoc, where, orderBy,
    getCountFromServer, Firestore, QueryOrderByConstraint,
    documentId,
    getDocsFromServer
} from 'firebase/firestore';
import firebaseConfig from '../config';
import { StorageService } from './storage.service';
import { getFunctions } from 'firebase/functions';

export class BaseService {
    app = initializeApp(firebaseConfig);
    functions = getFunctions(this.app);
    firestore: Firestore
    colname = 'brands';
    order = '';
    userid = '';

    constructor(colname: string, order: string) {
        this.colname = colname;
        this.order = order
        this.firestore = getFirestore(this.app);

        const storageService: StorageService = new StorageService();

        if (storageService.isUserLogin()) {
            let data = storageService.getSaveLogin();
            if (data) this.userid = data.data.id;
        }
    }

    get = () => new Promise((resolve) => {
        let col = collection(this.firestore, this.colname);
        let constraints: QueryOrderByConstraint[] = [];
        let data: any[] = [];
        if (this.order === '') {
            constraints.push(orderBy('created', 'desc'));
        }
        else {
            constraints.push(orderBy(this.order, 'asc'));
        }
        const q = query(col, ...constraints);
        onSnapshot(q, snap => {
            snap.docs.forEach(doc => {
                data.push({
                    id: doc.id,
                    ...doc.data(),
                })
            });
            resolve(data);
        })
    });

    getWithId = (field: string, value: string) => new Promise((resolve) => {
        let col = collection(this.firestore, this.colname);
        let constraints = [];
        let data: any[] = [];

        constraints.push(where(field, '==', value));
        constraints.push(orderBy('created', 'desc'));

        const q = query(col, ...constraints);
        onSnapshot(q, snap => {
            snap.docs.forEach(doc => {
                data.push({
                    id: doc.id,
                    ...doc.data(),
                })
            });
            resolve(data);
        })
    });

    getCount = () => new Promise((resolve, rejects) => {
        const col = collection(this.firestore, this.colname);
        const q = query(col);
        getCountFromServer(q).then(data => {
            if (data.data() !== undefined) {
                resolve(data.data().count);
            }
            else {
                resolve(0);
            }
        });
    });

    getCountWithId = (field: string, value: string) => new Promise((resolve, rejects) => {
        const col = collection(this.firestore, this.colname);
        let constraints = [];
        constraints.push(where(field, '==', value));
        const q = query(col, ...constraints);
        getCountFromServer(q).then(data => {
            if (data.data() !== undefined) {
                resolve(data.data().count);
            }
            else {
                resolve(0);
            }
        });
    });

    getById = (id: string) => new Promise((resolve, rejects) => {
        getDocFromServer(doc(this.firestore, this.colname + '/' + id)).then(data => {
            if (data.data() !== undefined) {
                resolve({
                    id: data.id,
                    ...data.data(),
                });
            }
            else {
                rejects("Not Found");
            }
        });
    });

    getByIds = (ids: string[]) => new Promise(resolve => {
        const col = collection(this.firestore, this.colname);
        const q = query(col, where(documentId(), 'in', ids));
        let data: any[] = []; 
        getDocsFromServer(q).then(docs => {
            docs.docs.forEach(doc => {
                data.push({...doc.data(), id: doc.id})
            })
            resolve(data)
        })
    })
    

    getActive = () => new Promise((resolve) => {
        let col = collection(this.firestore, this.colname);
        let data: any[] = [];
        let constraints = [];
        constraints.push(where('active', '==', true));
        const q = query(col, ...constraints);
        onSnapshot(q, snap => {
            snap.docs.forEach(doc => {
                data.push({
                    id: doc.id,
                    ...doc.data(),
                })
            });
            resolve(data);
        })
    });

    add = (data: any) => new Promise((resolve) => {
        const col = collection(this.firestore, this.colname);
        addDoc(col, {
            ...data,
            created: serverTimestamp(),
            createdby: this.userid
        }).then(value => {
            resolve(value.id);
        });
    });

    set = (id: string, data: any) => new Promise<void>((resolve) => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        setDoc(docs, {
            ...data,
            created: serverTimestamp()
        }).then(() => {
            resolve();
        })
    });

    update = (id: string, data: any) => new Promise<void>((resolve) => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        updateDoc(docs, {
            ...data,
            modified: serverTimestamp(),
            modifiedby: this.userid
        }).then(() => {
            resolve();
        })
    });

    searchBy = (field: string, value: any) => new Promise((resolve) => {
        let col = collection(this.firestore, this.colname);
        let constraints = [];
        let data: any[] = [];
        constraints.push(where(field, '>=', value));
        constraints.push(where(field, '<=', value + "\uf8ff"));
        constraints.push(orderBy(field));
        const q = query(col, ...constraints);
        onSnapshot(q, snap => {
            snap.docs.forEach(doc => {
                data.push({
                    id: doc.id,
                    ...doc.data(),
                })
            });
            resolve(data);
        })
    });

    updateField = (id: string, field: string, value: any) => new Promise<void>((resolve) => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        updateDoc(docs, {
            [field]: value,
            modified: serverTimestamp(),
            modifiedby: this.userid
        }).then(() => {
            resolve();
        })
    });
}