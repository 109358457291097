import { Timestamp } from "firebase/firestore";
import { Color } from "./color.model";
import { Size } from "./size.model";
import { ColorService } from "../services/color.service";
import { SizeService } from "../services/size.service";
import { ImageService } from "../services/image.service";
import { Action } from "../components/table/table";

export class Product {
  public id: string;
  public name: string;
  public brandid: string;
  public article: string;
  public name_en: string;
  public name_de: string;
  public description_en: string;
  public description_de: string;
  public warehouse: string;
  public link: string;
  public categoryid: string;
  public active: boolean;
  public images: string[];
  public primary: string;
  public originalprice: number;
  public buyonly: boolean;
  public buyonlymax: number;
  public fanfluencerdeal: boolean;
  public fanfluencerdealmaxdisc: number;
  public specs: Specs[];
  public buyonlydeals: BuyOnlyDeal[];
  public fanfluencerdeals: FanfluencerDeal[];
  public fanfluencerspecialdeals: FanfluencerSpecialDeal[];
  public tags: string[];
  public limitedoffer: LimitedOffer = new LimitedOffer();
  public stockstatus: 'in-stock' | 'out-of-stock' | 'limited-time';
  public review: 'pending' | 'approved' | 'rejected';
  public reason: string;
  public dealbriefing: DealPostBriefing;
}

export class ProductCard {
  public id: string;
  public name_en: string;
  public name_de: string;
  public brandid: string;
  public categoryid: string;
  public active: boolean;
  public primary: string;
  public buyonly: boolean;
  public fanfluencerdeal: boolean;
  public originalprice: number;
  public tags: string[];
  public stockstatus: "in-stock" | "out-of-stock" | "limited-time";
  public review: "pending" | "approved" | "rejected";
  public created: Timestamp | undefined;
}

export class ProductList {
  public id: string;
  public brand: string;
  public brandimage: string;
  public article: string;
  public productimage: string;
  public name: string;
  public category: string;
  public originalprice: number;
  public buyonly: boolean;
  public fanfluencerdeal: boolean;
  public active: boolean;
  public image: string;
  public stockstatus: 'in-stock' | 'out-of-stock' | 'limited-time';
  public review: 'pending' | 'approved' | 'rejected';
  public openlink: number;
  public views: number;
  public action: Action;
  public tags: string[];
}

export class Specs {
  public id: string;
  public article: string;
  public color: string;
  public size: string;
  public stock: number;
  public image: string;
  public sold: number;
  public isDeleted: boolean;
}

export class SpecsFull {
  public id: string = "";
  public article: string = "";
  public color: Color = new Color();
  public size: Size = new Size();
  public stock: number = 0;
  public url: string = "";
  public sold: number = 0;
}

export class SpecsList {
  public id: string = "";
  public article: string = "";
  public color: string = "";
  public size: string = "";
  public stock: number = 0;
  public image: string = "";
  public sold: number = 0;
  public action: any;
}

export class BuyOnlyDeal {
  public id: string;
  public amount: number;
  public percent: number;
  public price: number;
}

export class FanfluencerDeal {
  public id: string;
  public name: string;
  public type: "" | "Story" | "Feed" | "Reel";
  public enable: boolean;
  public discount: number;
}

export class FanfluencerSpecialDeal {
  public fanId: string;
  public type: "" | "Story" | "Feed" | "Reel";
  public post: number;
  public price: number;
}

export class DealPostBriefing {
  public dealphotodetails_en: string;
  public dealphotodetails_de: string;
  public dealtextdetails_en: string;
  public dealtextdetails_de: string;
  public dealhashtagdetails_en: string;
  public dealhashtagdetails_de: string;
}

export class Deal {
  public dealtype: "Price" | "BuyOnly" | "Fanfluencer";
  public price: number;
  public posttype: "" | "Story" | "Feed" | "Reel";
  public postcount: number;
}

export class LimitedOffer {
  public start: Timestamp;
  public end: Timestamp;
}

export const convertToSpecsFull = (specs: Specs): Promise<SpecsFull> => {
  let colorService: ColorService = new ColorService();
  let sizeService: SizeService = new SizeService();
  let fileService: ImageService = new ImageService();

  return new Promise(async (resolve) => {
    let specsfull: SpecsFull = new SpecsFull();
    specsfull.id = specs.id;
    specsfull.article = specs.article;
    specsfull.stock = specs.stock ? specs.stock : 0;
    specsfull.sold = specs.sold ? specs.sold : 0;

    if (specs.color) specsfull.color = (await colorService.getById(specs.color)) as Color;
    if (specs.size) specsfull.size = (await sizeService.getById(specs.size)) as Size;
    if (specs.image) specsfull.url = (await fileService.getURL(specs.image));

    resolve(specsfull);
  });
};
