import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  title: string;
  children: ReactNode;
  open: boolean;
  action: (confirm: boolean) => void;
}

const ConfirmDialog: React.FC<ContainerProps> = ({title, children, open, action}) => {
  const { t } = useTranslation();

  const onClose = () => {
    action(false);
  };

  const onConfirm = () => {
    action(true);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => onClose()} color="error">{t('text.no')}</Button>
        <Button onClick={(e) => onConfirm()} autoFocus>{t('text.yes')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
