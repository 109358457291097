import { useEffect, useState } from "react";
import validation from "../../../views/shared/valdiation";
import { AdminService } from "../../../services/admin.service";
import { useSnackbar } from "notistack";
import { SupplierUserService } from "../../../services/supplieruser.service";
import { StorageService } from "../../../services/storage.service";

function ProfileViewModel() {
  const [type, setType] = useState("admin");
  const [data, setData] = useState({
    id: "",
    email: "",
    firstname: "",
    lastname: "",
  });
  const [supplier, setSupplier] = useState({});
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const snackbarSuccess = {
    variant: "success",
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  let rules = [
    {
      field: "firstname",
      type: "required",
      message: "First Name is Required",
    },
    {
      field: "lastname",
      type: "required",
      message: "Last Name is Required",
    },
  ];

  useEffect(() => {
    const storageService = new StorageService();
    let data = storageService.getSaveLogin();
    if (data) setData(data.data);
    if (data) setType(data.type);
    if (data && data.type === 'brand'){
        setSupplier(data.supplier);
    }
  }, []);

  const onChange = (field, value) => {
    let values = data;
    data[field] = value;
    setData((data) => ({
      ...values,
    }));
    if (submitted) {
      validation(rules, values).then((response) => {
        setErrors(response === null ? {} : response);
      });
    }
  };

  const onSubmit = (e) => {
    setSubmitted(true);
    e.preventDefault();
    validation(rules, data).then((response) => {
      setErrors(response === null ? {} : response);
      if (response === null) {
        if (type === 'admin'){
            let adminService = new AdminService();
            adminService.update(data.id, data).then(() => {
                enqueueSnackbar("Profile Saved", snackbarSuccess);
            })
        }
        else{
            let supplierUserService = new SupplierUserService();
            supplierUserService.update(data.id, data).then(() => {
                enqueueSnackbar("Profile Saved", snackbarSuccess);
            })
        }

        setSubmitted(false);
      }
    });
  };

  return { type, data, supplier, errors, onChange, onSubmit };
}

export default ProfileViewModel;
