import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CustomerWishlistViewModel from "../../viewmodels/customer/customerwishlist.viewmodel";
import { useTranslation } from "react-i18next";
import FormLayout from "../../components/form/FormLayout";
import ImageView from "../../components/Image/ImageView";
import TableLayout from "../../components/table/table";


const CustomerWishlist: React.FC = () => {
  const { t } = useTranslation();

  const { fields, wishes, productsRanking } = CustomerWishlistViewModel();

  return (
    <div>
      <FormLayout
        PageTitle={t("title.customerswishlist")}
        Breadcrumbs={[{ title: t("title.dashboard"), url: "/" }, { title: t("title.customerswishlist") }]}
      ></FormLayout>

      <React.Fragment>
        
        <Grid container marginBottom={1}>
          <Grid xs={12} item>
            <h5 className="subtext mb-2">TOP 5</h5>
          </Grid>
          <Grid xs={12} item>
            <Grid container spacing={1} columns={5}>
              {productsRanking.map((data, idx) => (
                <Grid item xs={1}>
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="mb-3" style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                        <h5 className="mb-0" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>#{idx + 1} {data.product.name}</h5>
                        <label className="mb-0">{data.product.name_en}</label>  
                      </div>
                      <Grid container>
                        <Grid xs={3} item>
                          <div style={{ width: "100%", aspectRatio: "1/1" }}>
                            <ImageView image={data.product.primary}></ImageView>
                          </div>
                        </Grid>
                        <Grid xs={9} item style={{ textAlign: "end" }}>
                          <span className="ms-3 my-count">{data.ctr}</span>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <TableLayout fields={fields} data={wishes}></TableLayout>
        
      </React.Fragment>
    </div>
  );
};

export default CustomerWishlist;
