import { useEffect, useState } from "react";
import { Customer } from "../../models/customer.model";
import { CustomerService } from "../../services/customer.service";
import { start } from "repl";

function LicenseViewModel(customers: Customer[] | undefined) {

    const [items, setItems] = useState<Customer[] | undefined>(undefined);

    const [open, setOpen] = useState(false);
    const [started, setStarted] = useState(false);
    const [running, setRunning] = useState(false);
    const [output, setOutput] = useState<string[]>([]);

    useEffect(() => {
        if (customers) {
            setItems(customers.filter(x => x.dmsbid && x.dmsbid !== ''))
        }
    }, [customers])

    useEffect(() => {
        const process = async () => {
            if (customers) {
                const customerService: CustomerService = new CustomerService();
                setRunning(true);
                log("Start");

                for await (let customer of customers.filter(x => x.dmsbid && x.dmsbid !== '')) {
                    console.log(started)
                    if (started === true) {
                        log("DMSB Id: " + customer.dmsbid);
                        log("Name: " + customer.firstname.toUpperCase() + " " + customer.lastname.toUpperCase())

                        let result = await customerService.licensevalidate(customer.id);
                        if (result.errorcode === 0) {
                            log("Driver's License: " + (result.license === true ? "TRUE" : "FALSE"));
                            log("Official's License: " + (result.official === true ? "TRUE" : "FALSE"));
                        }
                        else {
                            log("Error: " + result.error);
                        }

                    }
                }

                setStarted(false);
                setRunning(false);
                log("Completed");
            }
        }

        if (started) process();
    }, [started])

    const log = (value: string) => {
        let items = output;
        items.push(value)
        setOutput([...items]);
    }

    const startProcess = async () => {
        setStarted(true);
    }

    const stopProcess = () => {
        setStarted(false);
    }

    const onHandleOpen = () => {
        setOutput([])
        setOpen(true)
    }

    const onHandleClose = () => {
        setStarted(false);
        setOpen(false)
    }

    return { items, open, running, output, startProcess, stopProcess, onHandleOpen, onHandleClose }

}

export default LicenseViewModel;
