import isEmail from "validator/lib/isEmail";
import isNumberic from "validator/lib/isNumeric";

export class Validation {
    public field: string;
    public type: string;
    public datatype: 'string' | 'email' | 'number';
    public message: string;
    public length: number
}

const pass = (validation: Validation, value: any) => {
    if (validation.type === 'required') {
        return (value ? value : '') !== '';
    }

    if (validation.type === 'valid') {
        if (validation.datatype === "email") {
            return isEmail(value ? value : '');
        }
        if (validation.datatype === "number") {
            return isNumberic(value);
        }
    }

    if (validation.type === 'minlength') {
        return value.toString().length > validation.length;
    }

    if (validation.type === 'maxlength') {
        return value.toString().length < validation.length;
    }

    if (validation.type === 'min') {
        if (isNumberic(value)){
            return Number(value) >= validation.length;
        }
    }

    if (validation.type === 'max') {
        if (isNumberic(value)){
            return Number(value) < validation.length;
        }
    }
}

export default pass;
