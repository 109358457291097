import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { Customer } from "../models/customer.model";
import { AdminService } from './admin.service';
import { Admin } from '../models/admin.model';
import { Payment } from '../models/payment.model';
import { SupplierService } from './supplier.service';
import { Brand } from '../models/brand.model';
import { CustomerService } from './customer.service';
import { fanfluencers, influencerstatus, racedealstatus, transactionstatus } from '../config';
import { useTranslation } from 'react-i18next';

export class ExcelService {

    private convertTimestampToDate = (birthday: any) => {
        let timestamp = birthday.seconds * 1000;
        return new Date(timestamp);
    };

    private getAdminName(admins: Admin[], id: string): string{
        let idx: number = admins.findIndex(x => x.id === id);
        if (idx >= 0) return admins[idx].firstname + ' ' + admins[idx].lastname;
        else return ';'
    }

    private getBrandName(brands: Brand[], id: string): string{
        let idx: number = brands.findIndex(x => x.id === id);
        if (idx >= 0) return brands[idx].name;
        else return ';'
    }

    private getCustomerEmail(customers: Customer[], id: string): string{
        let idx: number = customers.findIndex(x => x.id === id);
        if (idx >= 0) return customers[idx].email;
        else return ';'
    }

    private getCustomerName(customers: Customer[], id: string): string{
        let idx: number = customers.findIndex(x => x.id === id);
        if (idx >= 0) return customers[idx].firstname + " " + customers[idx].lastname;
        else return ';'
    }

    private getPaymentGatewayID(payment: Payment): string{
        if (payment.gateway === 'Paypal') return payment.paypaldetails ? payment.paypaldetails.id : ''; 
        else if (payment.gateway === 'CreditCard') return payment.stripedetails ? payment.stripedetails.paymentmethod : '';
        else return '';
    }

    private isRaceClubDeal(payment: Payment): boolean {
        return payment.dealdetails || payment.dealdetails === null ? false : true;
    }

    private getPaymentStatus(payment: Payment): any {
        if (payment.status) {
            return transactionstatus.find(x => x.id === payment.status)!
        }
        else{
            if (this.isRaceClubDeal(payment)) return racedealstatus;
            else return influencerstatus;
        }
    }

    async transactions(data: Payment[]) {
        const brandService: SupplierService = new SupplierService();
        const brands: Brand[] = await brandService.get() as Brand[];

        const customerService: CustomerService = new CustomerService();
        const customers: Customer[] = await customerService.get() as Customer[];

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Transactions');

        const headerRow = worksheet.addRow([
            'Transaction ID',
            'Brand ID',
            'Brand Name',
            'Customer ID',
            'Customer Email',
            'Customer Name',
            'Date',
            'Total Price',
            'Payment Gateway',
            'Paypal/Stripe ID',
            'Deal Count',
            'Deal Type',
            'Status'
        ]);
        headerRow.font = { bold: true };
        
        data.forEach(item => {
            worksheet.addRow([
                item.id,
                item.brandid,
                this.getBrandName(brands, item.brandid),
                item.accountid,
                this.getCustomerEmail(customers, item.accountid),
                this.getCustomerName(customers, item.accountid),
                item.added.toDate().toLocaleString(),
                item.price ? new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(item.price)) : '0.00',
                item.gateway,
                this.getPaymentGatewayID(item),
                item.dealdetails ? item.dealdetails.count : '',
                item.dealdetails ? item.dealdetails.type : '',
                this.getPaymentStatus(item).id.toUpperCase()
            ])
        })

        worksheet.getColumn(1).width = 25;
        worksheet.getColumn(1).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(2).width = 25;
        worksheet.getColumn(2).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(3).width = 25;
        worksheet.getColumn(3).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(4).width = 30;
        worksheet.getColumn(4).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(5).width = 25;
        worksheet.getColumn(5).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(6).width = 30;
        worksheet.getColumn(6).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(7).width = 23;
        worksheet.getColumn(7).alignment = {
            horizontal: 'right'
        }
        worksheet.getColumn(8).width = 13;
        worksheet.getColumn(8).alignment = {
            horizontal: 'right'
        }
        worksheet.getColumn(9).width = 15;
        worksheet.getColumn(9).alignment = {
            horizontal: 'center'
        }
        worksheet.getColumn(10).width = 30;
        worksheet.getColumn(10).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(11).width = 13;
        worksheet.getColumn(11).alignment = {
            horizontal: 'center'
        }
        worksheet.getColumn(12).width = 13;
        worksheet.getColumn(12).alignment = {
            horizontal: 'center'
        }
        worksheet.getColumn(13).width = 15;
        worksheet.getColumn(13).alignment = {
            horizontal: 'center'
        }

        const excelBuffer = await workbook.xlsx.writeBuffer();
        const dataBlob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(dataBlob, 'Transactions.xlsx');
    }

    async customers(data: Customer[]) {
        const adminService: AdminService = new AdminService();
        const admins: Admin[] = await adminService.get() as Admin[];

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Customers');
        
        const headerRow = worksheet.addRow([
            'ID',
            'Email',
            'Name',
            'Birthday',
            'Instagram',
            'Followers',
            'Ratings',
            'License',
            'Official',
            'Newsletter',
            'Status',
            'Created',
            'Approved By',
            'Approved On',
            'Suspended By',
            'Suspended By'
        ]);
        headerRow.font = { bold: true };

        data.forEach(item => {
            worksheet.addRow([
                item.id,
                item.email ? item.email : '',
                (item.firstname ? item.firstname : '') + " " + (item.lastname ? item.lastname : ''),
                item.birthday ? this.convertTimestampToDate(item.birthday).toLocaleDateString() : '',
                item.instagram ? item.instagram : '',
                item.followers ? Number(item.followers) : 0,
                item.ratings && fanfluencers.find(x => x.id === item.ratings) ? fanfluencers.find(x => x.id === item.ratings)!.display : fanfluencers[0].display,
                item.withLicense ? "TRUE" : "FALSE",
                item.withOfficial ? "TRUE" : "FALSE",
                item.newsletter ? "Yes" : "No",
                item.status ? item.status.toUpperCase() : 'pending'.toUpperCase(),
                item.created ? this.convertTimestampToDate(item.created).toLocaleDateString() : '',
                item.appovedby ? this.getAdminName(admins, item.appovedby) : "",
                item.approveddate ? this.convertTimestampToDate(item.approveddate).toLocaleDateString() : '',
                item.suspendby ? this.getAdminName(admins, item.suspendby) : "",
                item.suspenddate ? this.convertTimestampToDate(item.suspenddate).toLocaleDateString() : ''
            ])
        })
        
        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(1).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(2).width = 30;
        worksheet.getColumn(2).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(3).width = 20;
        worksheet.getColumn(3).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(4).width = 13;
        worksheet.getColumn(4).alignment = {
            horizontal: 'right'
        }
        worksheet.getColumn(5).width = 13;
        worksheet.getColumn(5).alignment = {
            horizontal: 'center'
        }
        worksheet.getColumn(6).width = 10;
        worksheet.getColumn(6).alignment = {
            horizontal: 'right'
        }
        worksheet.getColumn(7).width = 15;
        worksheet.getColumn(7).alignment = {
            horizontal: 'center'
        }
        worksheet.getColumn(8).width = 15;
        worksheet.getColumn(8).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(9).width = 15;
        worksheet.getColumn(9).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(10).width = 10;
        worksheet.getColumn(10).alignment = {
            horizontal: 'center'
        }
        worksheet.getColumn(11).width = 10;
        worksheet.getColumn(11).alignment = {
            horizontal: 'center'
        }
        worksheet.getColumn(12).width = 13;
        worksheet.getColumn(12).alignment = {
            horizontal: 'right'
        }
        worksheet.getColumn(13).width = 30;
        worksheet.getColumn(13).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(14).width = 13;
        worksheet.getColumn(14).alignment = {
            horizontal: 'right'
        }
        worksheet.getColumn(15).width = 30;
        worksheet.getColumn(15).alignment = {
            horizontal: 'left'
        }
        worksheet.getColumn(16).width = 13;
        worksheet.getColumn(16).alignment = {
            horizontal: 'right'
        }

        const excelBuffer = await workbook.xlsx.writeBuffer();
        const dataBlob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(dataBlob, 'Customers.xlsx');
    }
}