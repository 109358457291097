import React from "react";
import FormLayout from "../../../components/form/FormLayout";
import ProfileViewModel from "../../../viewmodels/account/profile/profile.viewmodel";
import { Box, Button, Card, CardContent, Grid, TextField } from "@mui/material";

function Profile() {
  const { type, data, supplier, errors, onChange, onSubmit } = ProfileViewModel();

  const rendersupplier = () => {
    if (type === "brand") {
      return (
        <Grid container spacing={2} paddingTop={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Brand"
              variant="outlined"
              size="small"
              value={supplier.name}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      );
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle="Account Profile" Breadcrumbs={[{ title: "Dashboard", url: "/" }, { title: "Account Profile" }]}></FormLayout>
      <Grid container direction="row" justifyContent="center" alignItems="top" spacing={1}>
        <Grid item xs={6}>
          <Box sx={{ flexGrow: 1 }}>
            <Card variant="outlined">
              <form onSubmit={onSubmit}>
                <CardContent>
                  {rendersupplier()}
                  <Grid container spacing={2} paddingTop={3}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        size="small"
                        type="email"
                        value={data.email}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} paddingTop={3}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="First Name"
                        variant="outlined"
                        size="small"
                        value={data.firstname}
                        error={errors.firstname !== undefined}
                        helperText={errors.firstname}
                        onChange={(e) => onChange("firstname", e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        variant="outlined"
                        size="small"
                        value={data.lastname}
                        error={errors.lastname !== undefined}
                        helperText={errors.lastname}s
                        onChange={(e) => onChange("lastname", e.target.value)}
                      />
                    </Grid>
                    <Grid container spacing={2} paddingTop={4}>
                      <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
                        <Button type="submit" variant="contained" size="large">
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Profile;
