import React, { useState } from "react";
import ColorListViewModel from "../../viewmodels/color/list.viewmodel";
import ListViewModel from "../../viewmodels/list.viewmodel";
import FormLayout from "../../components/form/FormLayout";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ColorDetails from "./color-detail";
import { Color } from "../../models/color.model";
import { useTranslation } from "react-i18next";
import TableLayout from "../../components/table/table";

const ColorList: React.FC = () => {
  const { t } = useTranslation();

  let headerstyle = {
    color: "#FFF",
    paddingTop: "10px",
    paddingBottom: "10px"
  };
  let detailstyle = {
    paddingTop: "10px",
    paddingBottom: "10px"
  };

  const { colorList, searches, fields, setSearch } = ColorListViewModel();

  const handleKeyUp = (event: any) => {
    setSearch(event.target.value);
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle={t('title.colors')} Breadcrumbs={[{ title: t('title.dashboard'), url: "/" }, { title: t('title.colors') }]}></FormLayout>

      <Grid container sx={{ paddingBottom: 2 }}>
        <Grid xs={6} container item>
          <TextField
            id="outlined-search"
            size="small"
            label={t('text.search')}
            type="search"
            name="search"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onKeyUp={(e) => handleKeyUp(e)}
          />
        </Grid>
        <Grid xs={6} container direction="row" justifyContent="right" alignItems="center" item>
          <ColorDetails mode="add" color={new Color()}></ColorDetails>
        </Grid>
      </Grid>

      <TableLayout fields={fields} data={searches !== undefined ? searches : colorList}></TableLayout>

    </React.Fragment>
  );
};

export default ColorList;
