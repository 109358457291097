import { useEffect, useState } from "react";
import { Tag, TagList } from "../../models/tag.model";
import { TagService } from "../../services/tag.service";
import { TableField } from "../../models/table.model";
import { collection, getFirestore, onSnapshot, orderBy, query } from "firebase/firestore";
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";
import { useTranslation } from "react-i18next";
import { Action } from "../../components/table/table";
import TagDetails from "../../views/tag/tag-detail";

function TagListViewModel() {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const { t, i18n } = useTranslation();

    const [tags, setTags] = useState<Tag[] | undefined>(undefined);
    const [tagList, setTagList] = useState<TagList[] | undefined>(undefined);
    const [search, setSearch] = useState<string>("");
    const [searches, setSearches] = useState<TagList[] | undefined>(undefined);

    const fields = [
        { id: "code", display: t("field.code").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "description", display: t('field.description').toUpperCase() + " (DE)", align: "left", sort: true, type: 'string' },
        { id: "description_de", display: t('field.description').toUpperCase() + " (EN)", align: "left", sort: true, type: 'string' },
        { id: "display_en", display: "Group Description (DE)".toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "display_de", display: "Group Description (EN)".toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "arrangement", display: t('field.arrangement').toUpperCase(), align: "right", sort: true, type: 'number' },
        { id: "background", display: t('field.backgroundcolor').toUpperCase(), align: "center", sort: false, type: 'color' },
        { id: "fontcolor", display: t('field.fontcolor').toUpperCase(), align: "center", sort: false, type: 'color' },
        { id: "active", display: t("field.active").toUpperCase(), align: "center", sort: false, type: 'status' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' }
    ] as TableField[];

    useEffect(() => {
        let items: Tag[] = [];
        let itemList: TagList[] = [];

        const q = query(collection(firestore, "tags"), orderBy("arrangement", "asc"));

        let unSubcribe = onSnapshot(q, (snap) => {

            snap.docChanges().forEach((change) => {
                let tag: Tag = change.doc.data() as Tag;
                tag.id = change.doc.id;

                if (change.type === 'added') {
                    items.push(tag);
                    itemList.push(convertSizeList(tag))
                }
                if (change.type === 'modified') {
                    let idx = items.findIndex(x => x.id === change.doc.id);
                    if (idx >= 0) items[change.newIndex] = { ...items[change.newIndex], ...change.doc.data() }

                    idx = itemList.findIndex(x => x.id === change.doc.id);
                    if (idx >= 0) itemList[idx] = { ...itemList[idx], ...tag }
                }
            });

            setTags([...items])
            setTagList([...itemList])
        });

        return () => {
            unSubcribe();
        }
    }, [])

    useEffect(() => {
        if (search !== '' && tags) {
            let items: Tag[] = []
            tags.forEach(item => {
                let code: string = item.code ? item.code.toLowerCase() : '';
                let description: string = item.description ? item.description.toLowerCase() : '';
                let description_de: string = item.description_de ? item.description_de.toLowerCase() : '';
                if (description.includes(search.toLowerCase()) || description_de.includes(search.toLowerCase()) || code.includes(search.toLowerCase())) {
                    items.push(item);
                }
            })
            let itemList: TagList[] = [];
            items.forEach(item => itemList.push(convertSizeList(item)));
            setSearches([...itemList])
        }
        else {
            setSearches(undefined)
        }
    }, [search])

    const convertSizeList = (tag: Tag): TagList => {
        let action: Action = new Action();
        action.type = 'component';
        action.component = TagDetails;
        action.props = {
            mode: 'edit',
            tag: { ...tag }
        }
        return { ...tag, action: action }
    }

    return { tags, tagList, searches, fields, setSearch };
}

export default TagListViewModel;