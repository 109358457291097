import { useReducer } from "react";
import serviceProvider from "../../services/serviceprovider";
import { AccountService } from "../../services/account.service";
import { useNavigate } from "react-router-dom";
import validation from "./valdiation";
import { useSnackbar } from "notistack";
import validator from "validator";

export const ACTION = {
  LOAD: "load",
  SAVE: "save",
  SAVEANDNEW: "saveandnew",
  REGISTER: "register",
  FIELDCHANGE: "fieldchange",
  FIELDBLUR: "fieldblur",
  UPDATEMODEL: "updatemodel",
};

function useEditViewModel(
  newModel,
  docName,
  validateModel = null,
  redirects = null
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [viewModel, dispatch] = useReducer(reducer, {
    formState: {
      id: "",
      isNew: true,
      isValid: true,
      isSaveAttempt: false,
      newModel: newModel,
      validateRule: validateModel !== null ? validateModel.rules : null,
      validateModel: validateModel !== null ? validateModel.errors : null,
      docName: docName,
      redirects: redirects,
    },
    model: newModel,
    validate: {},
  });

  const snackbarSuccess = {
    variant: "success",
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  };

  function reducer(viewModel, action) {
    switch (action.type) {
      case ACTION.LOAD:
        load(action.id).then((model) =>
          dispatch({ type: ACTION.UPDATEMODEL, viewModel: model })
        );
        return viewModel;
      case ACTION.SAVE:
        viewModel.formState.isSaveAttempt = true;
        validation(viewModel.formState.validateRule, viewModel.model).then(
          (errors) => {
            if (errors == null) {
              onSave().then((model) => {
                dispatch({ type: ACTION.UPDATEMODEL, viewModel: model });

                enqueueSnackbar("Saving Successfull", snackbarSuccess);
              });
            } else {
              viewModel.validate = errors;
              dispatch({ type: ACTION.UPDATEMODEL, viewModel: viewModel });
            }
          }
        );
        return viewModel;

      case ACTION.SAVEANDNEW:
        viewModel.formState.isSaveAttempt = true;
        validation(viewModel.formState.validateRule, viewModel.model).then(
          (errors) => {
            if (errors == null) {
              onSaveAndNew().then((model) => {
                dispatch({ type: ACTION.UPDATEMODEL, viewModel: model });
                enqueueSnackbar("Saving Successfull", snackbarSuccess);
              });
            } else {
              viewModel.validate = errors;
              dispatch({ type: ACTION.UPDATEMODEL, viewModel: viewModel });
            }
          }
        );
        return viewModel;

      case ACTION.REGISTER:
        viewModel.formState.isSaveAttempt = true;
        validation(viewModel.formState.validateRule, viewModel.model).then(
          (errors) => {
            if (errors == null) {
              onRegister().then((model) => {
                viewModel.formState.isSaveAttempt = false;
                dispatch({
                  type: ACTION.UPDATEMODEL,
                  viewModel: viewModel.formState.newModel,
                });
                enqueueSnackbar("Registration Successfull", snackbarSuccess);
              });
            } else {
              viewModel.validate = errors;
              dispatch({ type: ACTION.UPDATEMODEL, viewModel: viewModel });
            }
          }
        );
        return viewModel;

      case ACTION.FIELDCHANGE:
        let data = onChange(action.elem.field, action.elem.value);
        let newData = {
          formState: { ...data.formState },
          model: { ...data.model },
          validate: { ...data.validate },
        };

        if (newData.formState.isSaveAttempt) {
          validation(newData.formState.validateRule, newData.model).then(
            (errors) => {
              newData.validate = errors;
              dispatch({ type: ACTION.UPDATEMODEL, viewModel: newData });
            }
          );
        }

        return newData;

      case ACTION.FIELDBLUR:
        let value = onBlur(action.elem.field, action.elem.value, action.elem.decimal);
        let newValue = {
          formState: { ...value.formState },
          model: { ...value.model },
          validate: { ...value.validate },
        };

        return newValue;

      case ACTION.UPDATEMODEL:
        return {
          formState: { ...action.viewModel.formState },
          model: { ...action.viewModel.model },
          validate: { ...action.viewModel.validate },
        };

      default:
        break;
    }

    async function load(id) {
      let service = serviceProvider(viewModel.formState.docName);
      if (id) {
        let data = await service.getById(id);
        Object.entries(viewModel.model).forEach(([key, value]) => {
          viewModel.model[key] = data[key]
            ? data[key]
            : viewModel.formState.newModel[key];
        });
        viewModel.formState.id = id;
        viewModel.formState.isNew = false;
        return viewModel;
      } else {
        viewModel.model = viewModel.formState.newModel;
        viewModel.formState.id = "";
        viewModel.formState.isNew = true;
        return viewModel;
      }
    }

    function onChange(field, value) {
      viewModel.model[field] = value;
      return viewModel;
    }

    function onBlur(field, value, decimal) {
      if (validator.isNumeric(value)){
        viewModel.model[field] = Number(value).toFixed(decimal);
      }
      return viewModel;
    }

    async function addSave() {
      try {
        let service = serviceProvider(viewModel.formState.docName);
        return await service.add(viewModel.model);
      } catch (err) {
      }
    }

    async function updateSave() {
      try {
        let service = serviceProvider(viewModel.formState.docName);
        return await service.update(viewModel.formState.id, viewModel.model);
      } catch (err) {
      }
    }

    async function onSave() {
      try {
        if (viewModel.formState.isNew === true) {
          let service = serviceProvider(viewModel.formState.docName);
          var newid = await addSave();
          var data = await service.getById(newid);
          viewModel.model = data;
          viewModel.formState.id = data.id;
          viewModel.formState.isNew = false;
          if (redirects.open != null) {
            navigate(redirects.open + "/" + data.id);
          }
          if (redirects.parent != null) {
            navigate(redirects.parent);
          }
          return viewModel;
        } else {
          await updateSave();
          return viewModel;
        }
      } catch (err) {
      }
    }

    async function onSaveAndNew() {
      try {
        if (viewModel.formState.isNew === true) {
          await addSave();
        } else {
          await updateSave();
        }
        viewModel.model = viewModel.formState.newModel;
        viewModel.formState.id = "";
        viewModel.formState.isNew = true;
        viewModel.formState.isSaveAttempt = false;
        return viewModel;
      } catch (err) {
      }
    }

    async function onRegister() {
      try {
        let email = viewModel.model.email;
        let password = viewModel.model.password;
        let accountService = new AccountService();
        let id = await accountService.register(email, password);
        let model = {};
        Object.entries(viewModel.model).forEach(([key, value]) => {
          if (["password", "confirm"].findIndex((x) => x === key) < 0) {
            model[key] = value;
          }
        });
        let service = serviceProvider(viewModel.formState.docName);
        await service.set(id, model);
        viewModel.model = viewModel.formState.newModel;
        return viewModel;
      } catch (err) {
      }
    }
  }

  return [viewModel, dispatch];
}

export default useEditViewModel;
