import { useEffect, useState } from "react";
import pass, { Validation } from "../../models/validation.model";
import { useSnackbar } from "notistack";
import { Size } from "../../models/size.model";
import { SizeService } from "../../services/size.service";
import { useTranslation } from "react-i18next";

function SizeDetailViewModel(mode: 'add' | 'edit', size: Size) {
    const { t } = useTranslation();
    
    const [model, setModel] = useState<Size>(size);
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [process, setProcess] = useState(false);

    const sizeService: SizeService = new SizeService();

    const { enqueueSnackbar } = useSnackbar();

    const rules: any[] = [
        {
            field: "description",
            type: "required",
            message: "error.descriptionrequire",
        },
        {
            field: "description_de",
            type: "required",
            message: "error.descriptionrequire",
        },
        {
            field: "arrangement",
            type: "required",
            message: "error.arrangementrequire",
        },
    ]

    useEffect(() => { setModel(size) }, [size])

    const change = (field: string, value: any) => {
        setModel({ ...model, [field]: value });
        if (submitted) isValid();
    };

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message)
        }
        return message;
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, model[validation.field as keyof typeof model]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const clear = () => {
        setModel(new Size());
    }

    const submit = async (): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);
            setProcess(true);

            if (isValid() === false) {
                setProcess(false);
                resolve(false);
                return;
            }

            if (mode === 'add') {
                await sizeService.add(model);
                clear();
            }
            else {
                await sizeService.update(model.id, model);
            }

            setSubmitted(false);
            setProcess(false);
            enqueueSnackbar("Saved Details", {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });
            resolve(true);
        });
    }

    return { model, submit, change, getError, getErrorMessage, process }
}

export default SizeDetailViewModel;