import { initializeApp } from "firebase/app";
import {
  Auth,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import firebaseConfig from "../config";
import { getFunctions, httpsCallable } from "firebase/functions";

export class AccountService {
  app = initializeApp(firebaseConfig);
  functions = getFunctions(this.app);
  auth: Auth

  constructor() {
    this.auth = getAuth(this.app);
  }

  register = (email: string, password: string): Promise<string> =>
    new Promise((resolve, rejects) => {
      createUserWithEmailAndPassword(this.auth, email, password)
        .then((user) => {
          resolve(user.user.uid);
        })
        .catch((err) => rejects(err.code.toString()));
    });

  authenticate = (email: string, password: string): Promise<UserCredential> =>
    new Promise((resolve, rejects) => {
      signInWithEmailAndPassword(this.auth, email, password)
        .then((userdata) => {
          resolve(userdata);
        })
        .catch((error) => {
          rejects("Not Found");
        });
    });

  changepassword = (user: User, newpassword: string): Promise<void> =>
    new Promise((resolve) => {
      updatePassword(user, newpassword).then(() => {
        resolve();
      });
    });

  searchEmail = (email: string): Promise<string[]> =>
    new Promise((resolve) => {
      fetchSignInMethodsForEmail(this.auth, email).then((data) => resolve(data));
    });

  sendChangePassword = (email: string): Promise<void> =>
    new Promise((resolve) => {
      const sendPasswordLink = httpsCallable(this.functions, "sendPasswordLink");
      sendPasswordLink(email).then((values: any) => resolve(values.data));
    });
}
