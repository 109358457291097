import { Box, Button, Card, CardContent, Grid, TextField } from "@mui/material";
import React from "react";
import ProductLimitedOfferViewModel from "../../../viewmodels/product/limitedoffer.viewmodel";
import { useTranslation } from "react-i18next";
import Toggle from "../../../components/toggle/toggle";

interface ContainerProps {
  id: string;
}

const ProductLimitedOffer: React.FC<ContainerProps> = ({ id }) => {
  const { t } = useTranslation();

  const { data, limited, change, save, onEnable, getError, getErrorMessage } = ProductLimitedOfferViewModel(id);

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          save();
        }}
      >
        <Grid xs={12} item container direction="row" alignItems="center" justifyContent="space-between" marginTop={1}>
          <h5 className="subtext">{t('title.limitedoffer').toUpperCase()}</h5>
          <Toggle value={limited} label="Enable Limited Offer" onChange={(value: boolean) => onEnable(value)}></Toggle>
        </Grid>

        <Grid container spacing={1} direction="row" alignItems="center" justifyContent="center" marginTop={2}>
          <Grid xs={3} item>
            <TextField
              label={t('field.startdatetime')}
              variant="outlined"
              size="small"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={data.start ? data.start : ''}
              error={getError("start") !== undefined}
              helperText={getErrorMessage("start")}
              onChange={(e) => change("start", e.target.value)}
            ></TextField>
          </Grid>
          <Grid xs={3} item>
            <TextField
              label={t('field.enddatetime')}
              variant="outlined"
              size="small"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={data.end ? data.end : ''}
              error={getError("end") !== undefined}
              helperText={getErrorMessage("end")}
              onChange={(e) => change("end", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" marginTop={3}>
            <Button type="submit" variant="contained" size="large">
              {t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default ProductLimitedOffer;
