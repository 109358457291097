import { influencerstatus, influencerstatuses, racedealstatus, racedealstatuses, transactionstatus } from "../config";
import { Order } from "../models/order.model";
import { Payment } from "../models/payment.model";

export function isRaceClubTransaction(payment: Payment): boolean {
    return payment.dealdetails ? false : true;
}

export function isRaceClubOrder(order: Order): boolean {
    return order.deal ? false : true;
}

export function getPaymentStatus(payment: Payment): any {
    if (payment.status) {
        return transactionstatus.find(x => x.id === payment.status)!;
    }
    else {
        if (isRaceClubTransaction(payment)) return racedealstatus;
        else return influencerstatus;
    }
}

export function getPaymentStatuses(payment: Payment | undefined): any[] {
    if (payment) {
        if (isRaceClubTransaction(payment)) return racedealstatuses;
        else return influencerstatuses;
    }
    else return [];
}