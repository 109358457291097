import { useEffect, useRef, useState } from "react";
import { Product, ProductList } from "../../models/product.model";
import { ProductService } from "../../services/product.service";
import { SupplierService } from "../../services/supplier.service";
import { Brand } from "../../models/brand.model";
import { Category } from "../../models/category.model";
import { CategoryService } from "../../services/category.service";
import { useTranslation } from "react-i18next";
import { reviewstatus, stockstatus } from "../../config";
import { StorageService } from "../../services/storage.service";
import { Tag } from "../../models/tag.model";
import { TagService } from "../../services/tag.service";
import { TableField } from "../../models/table.model";
import { Action } from "../../components/table/table";
import { ProductLogService } from "../../services/productlog.service";
import { ProductLog } from "../../models/productlog.model";
import { collection, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";

function ProductListViewModel(_mode: '' | 'moderate') {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const { t, i18n } = useTranslation();
    const initialized = useRef(false);
    const [mode, setMode] = useState<'' | 'moderate' | undefined>(undefined);
    const [supplier, setSupplier] = useState<string | undefined>(undefined);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [productLogs, setProductLogs] = useState<ProductLog[]>([]);
    const [products, setProducts] = useState<Product[] | undefined>(undefined);
    const [productLists, setProductLists] = useState<ProductList[] | undefined>(undefined);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('');
    const [searches, setSearches] = useState<ProductList[] | undefined>(undefined);

    const productLogService: ProductLogService = new ProductLogService();
    const supplierService: SupplierService = new SupplierService();
    const categoryService: CategoryService = new CategoryService();
    const tagService: TagService = new TagService();

    const fields = [
        { id: "id", display: "#", align: "left", sort: false, type: 'string' },
        { id: "brandimage", display: "", align: "right", sort: false, type: 'image' },
        { id: "brand", display: t("field.brand").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "article", display: t("field.article").toUpperCase(), align: "left", sort: false, type: 'string' },
        { id: "productimage", display: "", align: "right", sort: false, type: 'image' },
        { id: "name", display: t("field.name").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "category", display: t("field.category").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "originalprice", display: t("field.originalprice").toUpperCase(), align: "right", sort: true, type: 'decimal' },
        { id: "buyonly", display: t("field.buyonlydeal").toUpperCase(), align: "center", false: true, type: 'boolean' },
        { id: "fanfluencerdeal", display: t("field.influencerdeal").toUpperCase(), align: "center", sort: false, type: 'boolean' },
        { id: "active", display: t("field.status").toUpperCase(), align: "center", sort: false, type: 'status' },
        { id: "stockstatus", display: t("field.stockstatus").toUpperCase(), align: "center", sort: false, type: 'stockstatus' },
        { id: "review", display: t("field.review").toUpperCase(), align: "center", sort: false, type: 'review' },
        { id: "openlink", display: "Open Link".toUpperCase(), align: "right", sort: false, type: 'number' },
        { id: "views", display: "Views".toUpperCase(), align: "right", sort: false, type: 'number' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' },
    ] as TableField[]

    useEffect(() => {
        loadSuppliers();
        loadCategories();
        loadTags();
        loadProductLogs();

        const storageService: StorageService = new StorageService()
        let isUserLogin = storageService.isUserLogin();
        if (isUserLogin === false) return;

        let isSupplier = storageService.isSupplier();
        if (isSupplier) {
            let data = storageService.getSaveLogin();
            if (data) setSupplier(data.supplier.id);
            else setSupplier('');
        }
        else setSupplier('');
    }, [])

    useEffect(() => {
        setProducts(undefined);
        setProductLists(undefined);
        setSearches(undefined);
        setSearch("");
        setFilter("");
        setMode(_mode)
    }, [_mode]);

    useEffect(() => {
        if (mode !== undefined && supplier != undefined) {
            let constraints = [];
            let items: Product[] = [];
            let itemList: ProductList[] = [];
            
            if (supplier !== '') constraints.push(where('brandid', '==', supplier));
            if (mode !== '') constraints.push(where('review', '==', 'pending'));
            constraints.push(orderBy('created', 'desc'));
            const q = query(collection(firestore, "products"), ...constraints);
            let unSubcribe = onSnapshot(q, (snap) => {

                snap.docChanges().forEach((change) => {
                    let product: Product = change.doc.data() as Product;
                    product.id = change.doc.id;

                    if (change.type === 'added') {
                        items.push(product);
                        itemList.push(convertProductList(product))
                    }

                    if (change.type === 'modified') {
                        let idx = items.findIndex(x => x.id === change.doc.id);
                        if (idx >= 0) items[idx] = { ...items[idx], ...change.doc.data() }

                        idx = itemList.findIndex(x => x.id === change.doc.id);
                        if (idx >= 0) itemList[idx] = { ...itemList[idx], ...convertProductList(product) }
                    }

                });

                console.log(items)

                setProducts([...items])
                setProductLists([...itemList])
            });

            return () => {
                unSubcribe();
            }
        }
    }, [mode, supplier])

    useEffect(() => {
        const process = async () => {
            if (products) {
                const productService: ProductService = new ProductService();
                for await (const product of products) {
                    await productService.updateModified(product.id);
                }
            }
        }
        if (products && products.length > 0 && initialized.current === false) {
            initialized.current = true;
            //process();
        }
    }, [products])

    useEffect(() => {
        if (search !== '' || filter !== '') {
            let items: ProductList[] = productLists ? productLists : [];

            if (filter !== '') {
                if (filter === "raceclubdeal") items = items.filter(x => x.buyonly === true);
                else if (filter === "influencerdeal") items = items.filter(x => x.fanfluencerdeal === true);
                else if (filter === "active") items = items.filter(x => x.active === true);
                else if (filter === "inactive") items = items.filter(x => x.active === false);
                else if (stockstatus.findIndex(x => x.id === filter) >= 0) items = items.filter(x => x.stockstatus === filter);
                else if (reviewstatus.findIndex(x => x.id === filter) >= 0) items = items.filter(x => x.review === filter);
                else if (tags.findIndex(x => x.code === filter) >= 0) items = items.filter(x => x.tags.findIndex(y => y === filter) >= 0);
            }

            if (search !== '') {
                let list: ProductList[] = []
                items.forEach(item => {
                    let id: string = item.id ? item.id.toLowerCase() : '';
                    let brand: string = item.brand ? item.brand.toLowerCase() : '';
                    let article: string = item.article ? item.article.toLowerCase() : '';
                    let name: string = item.name ? item.name.toLowerCase() : '';
                    if (id === search.toLowerCase() || brand.includes(search.toLowerCase()) || article.includes(search.toLowerCase()) || name.includes(search.toLowerCase())) {
                        list.push(item);
                    }
                })
                items = list
            }

            setSearches([...items])
        }
        else {
            setSearches(undefined)
        }
    }, [search, filter])

    const convertProductList = (product: Product): ProductList => {
        let item: ProductList = new ProductList();
        item.id = product.id;

        let brand: Brand | undefined = undefined;
        if (product.brandid) brand = brands.find(x => x.id === product.brandid)
        item.brand = brand ? brand.name : '';
        item.brandimage = brand ? brand.image: '';
        item.article = product.article;
        item.productimage = product.primary;
        item.name = i18n.language === "en" ? product.name_en : product.name_de;

        let category: Category | undefined = undefined;
        if (product.categoryid) category = categories.find(x => x.id === product.categoryid);
        let categoryname = i18n.language === "en" ? (category ? category.text_en : '') : (category ? category.text_de : '');
        item.category = categoryname;

        item.originalprice = product.originalprice ? product.originalprice : 0;
        item.buyonly = product.buyonly ? product.buyonly : false;
        item.fanfluencerdeal = product.fanfluencerdeal ? product.fanfluencerdeal : false;
        item.active = product.active ? product.active : false;
        item.stockstatus = product.stockstatus ? product.stockstatus : stockstatus[0].id as 'in-stock' | 'out-of-stock' | 'limited-time';
        item.review = product.review ? product.review : 'pending';

        let log = productLogs.find(x => x.id === product.id);
        if (log) {
            if (log.link) item.openlink = log.link.filter((item, i, ar) => ar.indexOf(item) === i).length;
            else item.openlink = 0;
            if (log.opened) item.views = log.opened.filter((item, i, ar) => ar.indexOf(item) === i).length;
            else item.views = 0;
        }
        else {
            item.openlink = 0;
            item.views = 0;
        }

        let action: Action = new Action();
        action.type = 'url';
        action.display = mode === "" ? t("button.open") : t("button.view");
        action.url = mode === "" ? `/products/` + product.id : "/products/view/" + product.id;
        item.action = action;

        return item;
    }

    useEffect(() => {
        if (products) {
            let list: ProductList[] = [];
            products.forEach(product => {
                list.push(convertProductList(product));
            });
            setProductLists(list);
        }
    }, [products, productLogs, brands, categories])

    const loadSuppliers = async () => {
        let data = await supplierService.get() as Brand[];
        setBrands(data);
    }

    const loadCategories = async () => {
        let data = await categoryService.get() as Category[];
        setCategories(data);
    }

    const loadTags = async () => {
        let data = await tagService.get() as Tag[];
        setTags(data);
    }

    const loadProductLogs = async () => {
        let logs = await productLogService.getAll() as ProductLog[];
        setProductLogs(logs);
    }

    return { tags, fields, productLists, setSearch, setFilter, searches };
}

export default ProductListViewModel;