import { collection, doc, getDocFromServer, getFirestore, onSnapshot, orderBy, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { BaseService } from "./baseservice";
import { Notification } from "../models/notification.model";
import { UserNotification } from "../models/usernotification.model";

export class NotificationService extends BaseService {
    colname = "notifications";
    userNotif = "usernotifications";

    constructor() {
        super("notifications", "");
        this.firestore = getFirestore(this.app);
    }

    getNotifications(to: "admin" | "brand", brandid: string): Promise<Notification[]> {
        return new Promise((resolve) => {
            let col = collection(this.firestore, this.colname);
            let constraints = [];
            let data: any[] = [];
            constraints.push(where('to', '==', to));
            if (to === 'brand') constraints.push(where('brandid', '==', brandid));
            constraints.push(orderBy('datetime', 'desc'));
            const q = query(col, ...constraints);
            onSnapshot(q, snap => {
                snap.docs.forEach(doc => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    })
                });
                resolve(data);
            })
        })
    }

    getUserNotifications(id: string): Promise<string[]> {
        return new Promise((resolve) => {
            getDocFromServer(doc(this.firestore, this.userNotif + '/' + id)).then(data => {
                let notif = data.data() as UserNotification;
                if (notif.notifications) resolve(notif.notifications);
                else resolve([]);
            }).catch(err => resolve([]))
        });
    }

    setUserNotifications(id: string, list: string[]): Promise<void> {
        const docs = doc(this.firestore, this.userNotif + '/' + id);
        return setDoc(docs, {
            notifications: list,
            last: serverTimestamp()
        });
    }
}