import { AdminService } from "./admin.service";
import { CategoryService } from "./category.service";
import { ColorService } from "./color.service";
import { ProductService } from "./product.service";
import { SizeService } from "./size.service";
import { SupplierService } from "./supplier.service";
import { SupplierContactService } from "./suppliercontact.service";
import { SupplierUserService } from "./supplieruser.service";
import { TagService } from "./tag.service";

function serviceProvider(docName: string) {
  switch (docName) {
    case "categories":
      return new CategoryService();
    case "administrators":
      return new AdminService();
    case "brands":
      return new SupplierService();
    case "brandusers":
      return new SupplierUserService();
    case "brandcontacts":
      return new SupplierContactService();
    case "sizes":
      return new SizeService();
    case "colors":
      return new ColorService();
    case "tags":
      return new TagService();
    case "products":
      return new ProductService();
    default:
      return null;
  }
}
export default serviceProvider;
