import React from "react";
import { Box, Button, Card, CardContent, Grid } from "@mui/material";

function EditForm(props) {
  const getSaveButton = () => {
    if (props.OnSave != null) {
      return (
        <Button type="button" variant="contained" onClick={props.OnSave} size="large">
          Save
        </Button>
      );
    }
  };

  const getSaveAndAddNewButton = () => {
    if (props.OnSaveAndNew != null) {
      return (
        <Button type="button" variant="contained" onClick={props.OnSaveAndNew} size="large">
          Save and add new
        </Button>
      );
    }
  };

  const getRegisterButton = () => {
    if (props.OnRegister != null) {
      return (
        <Button type="button" variant="contained" onClick={props.OnRegister} size="large">
          Register
        </Button>
      );
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Card variant="outlined">
        <CardContent>
          <Grid container spacing={2} paddingTop={3}>
            {props.children}
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box sx={{ "& button": { marginTop: 2 } }}>
                {getSaveButton()}
                {getSaveAndAddNewButton()}
                {getRegisterButton()}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default EditForm;
