import {
  Button,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";
import FormLayout from "../../components/form/FormLayout";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Link } from "react-router-dom";
import SupplierListViewModel from "../../viewmodels/supplier/list.viewmodel";
import { useTranslation } from "react-i18next";
import TableLayout from "../../components/table/table";

const Suppliers: React.FC = () => {
  const { t } = useTranslation();

  const { brandList, searches, fields, setSearch } = SupplierListViewModel();

  const handleKeyUp = (event: any) => {
    setSearch(event.target.value);
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle={t('title.brands')} Breadcrumbs={[{ title: t('title.dashboard'), url: "/" }, { title: t('title.brands') }]}></FormLayout>

      <Grid container sx={{ paddingBottom: 2 }}>
        <Grid xs={6} container item>
          <TextField
            id="outlined-search"
            size="small"
            placeholder={t('text.search')}
            type="search"
            name="search"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onKeyUp={(e) => handleKeyUp(e)}
          />
        </Grid>
        <Grid xs={6} container direction="row" justifyContent="right" alignItems="center" item>
          <Button component={Link} to="/brands/new" variant="contained" size="medium">
            {t('button.create')}
          </Button>
        </Grid>
      </Grid>

      <TableLayout fields={fields} data={searches !== undefined ? searches : brandList}></TableLayout>
    </React.Fragment>
  );
};

export default Suppliers;
