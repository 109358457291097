import React, { useEffect, useState } from "react";
import { BuyOnlyDeal } from "../../../models/product.model";
import "./../product.css";
import { Box, Button, Grid, InputAdornment, Modal, TextField } from "@mui/material";
import { Validation } from "../../../models/validation.model";
import { useTranslation } from "react-i18next";
import { fanfluencers } from "../../../config";
import { CalculationService } from "../../../services/calculation.service";
import CurrencyInput from "react-currency-input-field";

interface ContainerProps {
  deal: BuyOnlyDeal;
  originalprice: number;
  save: (deal: BuyOnlyDeal) => void;
}

const ProductBuyOnly: React.FC<ContainerProps> = ({ deal, originalprice, save }) => {
  const { t } = useTranslation();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  const [original, setOriginal] = useState(0);
  const [buyonlydeal, setBuyonlydeal] = useState<BuyOnlyDeal>(new BuyOnlyDeal());
  const [newDeal, setNewDeal] = useState<BuyOnlyDeal>(new BuyOnlyDeal());

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<Validation[]>([]);

  useEffect(() => {
    if (originalprice) setOriginal(originalprice);
  }, [originalprice]);

  useEffect(() => {
    if (deal) {
      setBuyonlydeal(deal);
      setNewDeal(deal);
    }
  }, [deal]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const compute = (type: "percent" | "amount" | "price", value: number) => {
    let e = errors;

    const calculationService: CalculationService = new CalculationService();

    if (type === "percent") {
      if (Number(value) > 100) {
        setNewDeal({ ...newDeal, percent: Number(value), amount: 0, price: 0 });
        e.push({
          field: "percent",
          type: "invalid",
          message: "error.invalidpercent",
        } as Validation);
        setErrors(e);
      } else if (Number(value) >= 0) {
        setErrors([]);
        let result = calculationService.racedealcalculationbypercent(Number(value), Number(original));
        setNewDeal({ ...newDeal, percent: Number(value), amount: Number(result.amount), price: Number(result.price) });
      }
    } else if (type === "amount") {
      if (Number(value) > original) {
        setNewDeal({ ...newDeal, percent: 0, amount: Number(value), price: 0 });
        e.push({
          field: "amount",
          type: "invalid",
          message: "error.mustnotexceedprice",
        } as Validation);
        setErrors(e);
      } else if (Number(value) >= 0) {
        setErrors([]);
        let result = calculationService.racedealcalculationbyamount(Number(value), Number(original));
        setNewDeal({ ...newDeal, percent: Number(result.percent), amount: Number(value), price: Number(result.price) });
      }
    } else {
      if (Number(value) > original) {
        setNewDeal({ ...newDeal, percent: 0, amount: 0, price: Number(value) });
        e.push({
          field: "price",
          type: "invalid",
          message: "error.mustnotexceedprice",
        } as Validation);
        setErrors(e);
      } else if (Number(value) >= 0) {
        setErrors([]);
        let result = calculationService.racedealcalculationbyprice(Number(value), Number(original));
        setNewDeal({ ...newDeal, percent: Number(result.percent), amount: Number(result.amount), price: Number(value) });
      }
    }
  };

  const change = (field: string, value: number) => {
    compute(field as "percent" | "amount" | "price", Number(value));
  };

  const getError = (field: string): Validation | undefined => {
    return errors.find((x) => x.field === field);
  };

  const getErrorMessage = (field: string): string | undefined => {
    let message: string | undefined = undefined;
    if (errors.find((x) => x.field === field)) {
      message = t(errors.find((x) => x.field === field)!.message);
    }
    return message;
  };

  return (
    <React.Fragment>
      <Box border={1} borderColor={"lightgray"} borderRadius={1}>
        <Box paddingX={1} paddingTop={1} borderBottom={1} borderColor={"lightgray"}>
          <h6>{buyonlydeal.id ? fanfluencers.find((x) => x.id === buyonlydeal.id)!.display : ""}</h6>
        </Box>
        <Box padding={2}>
          <Grid container>
            <Grid item xs={7}>
              {t("field.discount")} %
            </Grid>
            <Grid item xs={5} container justifyContent={"right"}>
              {new Intl.NumberFormat("en-DE").format(Number(buyonlydeal.percent))}
            </Grid>
            <Grid item xs={7}>
              {t("field.discount")}
            </Grid>
            <Grid item xs={5} container justifyContent={"right"}>
              {new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(buyonlydeal.amount))}
            </Grid>
            <Grid item xs={7}>
              {t("field.price")}
            </Grid>
            <Grid item xs={5} container justifyContent={"right"}>
              {new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(buyonlydeal.price))}
            </Grid>
          </Grid>
        </Box>
        <Box borderTop={1} borderColor={"lightgray"} textAlign={"right"}>
          <Button onClick={handleOpen}>{t("button.edit")}</Button>
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form>
            <Box sx={headerStyle}>{newDeal.id ? fanfluencers.find((x) => x.id === newDeal.id)!.display : ""}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={1}>
                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                  <label>
                    {t("field.originalprice")} : {original ? new Intl.NumberFormat('en-DE', { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(original)) : 0}
                  </label>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label={t("field.discount") + " %"}
                    variant="outlined"
                    size="small"
                    value={newDeal.percent}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    onChange={(e) => change("percent", Number(e.target.value))}
                    onFocus={(e) => e.target.select()}
                    error={getError("percent") !== undefined}
                    helperText={getErrorMessage("percent")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className="priceinputdiv">
                    <label>{t("field.discount")}</label>
                    <CurrencyInput
                      className="priceinput"
                      defaultValue={Number(newDeal.amount ? newDeal.amount : 0) === 0 ? "" : Number(newDeal.amount).toFixed(2)}
                      decimalsLimit={2}
                      decimalScale={2}
                      fixedDecimalLength={2}
                      suffix=" €"
                      decimalSeparator=","
                      groupSeparator="."
                      onValueChange={(value, name, values) => {
                        if (values) change("amount", Number(values.float))
                      }}
                    />
                  </div>
                  {/* 
                  <TextField
                    label={t("field.discount")}
                    variant="outlined"
                    size="small"
                    value={newDeal.amount}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    onChange={(e) => change("amount", Number(e.target.value))}
                    onFocus={(e) => e.target.select()}
                    error={getError("amount") !== undefined}
                    helperText={getErrorMessage("amount")}
                    fullWidth
                  />
                  */}
                </Grid>
                <Grid item xs={4}>
                  <div className="priceinputdiv">
                    <label>{t("field.price")}</label>
                    <CurrencyInput
                      className="priceinput"
                      defaultValue={Number(newDeal.price ? newDeal.price : 0) === 0 ? "" : Number(newDeal.price).toFixed(2)}
                      decimalsLimit={2}
                      decimalScale={2}
                      fixedDecimalLength={2}
                      suffix=" €"
                      decimalSeparator=","
                      groupSeparator="."
                      onValueChange={(value, name, values) => {
                        if (values) change("price", Number(values.float))
                      }}
                    />
                  </div>
                  {/* 
                  <TextField
                    label={t("field.price")}
                    variant="outlined"
                    title="Price"
                    placeholder="Enter Price"
                    size="small"
                    value={newDeal.price}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                    onChange={(e) => change("price", Number(e.target.value))}
                    onFocus={(e) => e.target.select()}
                    error={getError("price") !== undefined}
                    helperText={getErrorMessage("price")}
                    fullWidth
                  />
                  */}
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button
                variant="outlined"
                size="medium"
                sx={{ ml: 1 }}
                disabled={errors.length > 0 ? true : false}
                onClick={async (e) => {
                  let temp = { ...newDeal };
                  await save(temp);
                  setBuyonlydeal(temp);
                  setOpen(false);
                }}
              >
                {t("button.update")}
              </Button>
              <Button
                variant="outlined"
                size="medium"
                sx={{ ml: 1 }}
                color="error"
                onClick={(e) => {
                  setNewDeal({ ...buyonlydeal });
                  setOpen(false);
                }}
              >
                {t("button.cancel")}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ProductBuyOnly;
