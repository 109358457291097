export class ProductCard{
    public id: 'in-stock' | 'out-of-stock' | 'limited-time';
    public card: ProductCardSetting;
    public tag: ProductCardTagSetting;
}

export class ProductCardSetting {
    public background: string;
    public border: string;
}

export class ProductCardTagSetting{
    public background: string;
    public font: string;
}

