import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import React, { useState } from "react";
import CustomerRegisterViewModel from "../../viewmodels/customer/register.viewmodel";
import { useTranslation } from "react-i18next";

const CustomerRegister: React.FC = () => {
  const { t } = useTranslation();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  const { model, change, processing, getError, getErrorMessage, onSubmit, onReset } = CustomerRegisterViewModel();

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    onReset();
  };

  const onHandleSubmit = async (e: any) => {
    e.preventDefault();
    let isSuccessful = await onSubmit();
    if (isSuccessful) {
      await onSubmit();
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" size="medium" onClick={handleOpen}>
        {t("button.create")}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <form onSubmit={onHandleSubmit}>
          <Box sx={style}>
            <Box sx={headerStyle}>{t("text.createcustomeraccount")}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={t("field.email")}
                    type="email"
                    variant="outlined"
                    size="small"
                    value={model.email}
                    error={getError("email") !== undefined}
                    helperText={getErrorMessage("email")}
                    onChange={(e) => change("email", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t("field.firstname")}
                    type="text"
                    variant="outlined"
                    size="small"
                    value={model.firstname}
                    error={getError("firstname") !== undefined}
                    helperText={getErrorMessage("firstname")}
                    onChange={(e) => change("firstname", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t("field.lastname")}
                    type="text"
                    variant="outlined"
                    size="small"
                    value={model.lastname}
                    error={getError("lastname") !== undefined}
                    helperText={getErrorMessage("lastname")}
                    onChange={(e) => change("lastname", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t("field.followers")}
                    type="number"
                    variant="outlined"
                    size="small"
                    value={model.followers === 0 ? "" : model.followers}
                    onChange={(e) => change("followers", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth label={t("field.ratings")} type="text" variant="outlined" size="small" value={model.ratings} disabled={true} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t("field.password")}
                    type="password"
                    variant="outlined"
                    size="small"
                    value={model.password}
                    error={getError("password") !== undefined}
                    helperText={getErrorMessage("password")}
                    onChange={(e) => change("password", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={t("field.confirm")}
                    type="password"
                    variant="outlined"
                    size="small"
                    value={model.confirm}
                    error={getError("confirm") !== undefined}
                    helperText={getErrorMessage("confirm")}
                    onChange={(e) => change("confirm", e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} type="submit" disabled={processing}>
                {t("button.create")}
              </Button>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={handleClose}>
                {t("button.cancel")}
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </React.Fragment>
  );
};

/*
class customerRegister extends Component {
    state = {  
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        confirm: ''
    }     

    save = (e) => {
        e.preventDefault();

        // const service = new AdminService();
        // service.register(this.state.email, this.state.password).then(id => {
        //     service.add(id, this.state.email, this.state.firstname, this.state.lastname);
        //     this.clear();
        // })
    }

    change = (field, value) => {
        this.setState({[field]: value })
    }

    clear(){
        this.setState({
            email: '',
            firstname: '',
            lastname: '',
            password: '',
            confirm: ''
        })
    }

    render() { 
        return (
            <React.Fragment>
                <h3>Customers</h3>
                <Breadcrumbs datas={[{title: "Dashboard", url:"/"},{title:"Customers", url:"/customers"},{title:"Register Customer"}]} />
                <Row className='mt-4 justify-content-md-center'>
                    <Col md="6">
                        <Form onSubmit={this.save}>
                            <Card variant="outlined">
                                <Card.Header>
                                    <Card.Title as="h4">Register Customer</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col md="6">
                                            <Form.Group className="mb-3" controlId="email">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control type="email" placeholder="Enter email" name='email' value={this.state.email} onChange={(e) => this.change('email', e.target.value)}  />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Form.Group className="mb-3" controlId="firstname">
                                                <Form.Label>First name</Form.Label>
                                                <Form.Control type="text" placeholder="First name" name='firstname' value={this.state.firstname} onChange={(e) => this.change('firstname', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md="6">
                                            <Form.Group className="mb-3" controlId="lastname">
                                                <Form.Label>Last name</Form.Label>
                                                <Form.Control type="text" placeholder="Last name" name='lastname' value={this.state.lastname} onChange={(e) => this.change('lastname', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Form.Group className="mb-3" controlId="password">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control type="password" placeholder="Password" name='password' value={this.state.password} onChange={(e) => this.change('password', e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md="6">
                                            <Form.Group className="mb-3" controlId="confirm">
                                                <Form.Label>Confirm password</Form.Label>
                                                <Form.Control type="password" placeholder="Confirm password" name='confirm' value={this.state.confirm} onChange={(e) => this.change('confirm', e.target.value)}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className='mb-2 text-end'>
                                    <Button variant='primary' type="submit">Save</Button>
                                </Card.Footer>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
*/
export default CustomerRegister;
