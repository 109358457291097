import { useEffect, useState } from "react";

function ListViewModel(length: number) {
    const [count, setCount] = useState(0);
    const [row, setRow] = useState(10)
    const [page, setPage] = useState(0);

    useEffect(() => {
        setCount(Number(length));
    }, [length]);

    const onRowChange = (row: any) => {
        setRow(Number(row))
        if (Number(page) * Number(row) + Number(row) > count) {
            setPage(0)
        }
    }

    const onPageChange = (page: any) => {
        setPage(Number(page))
    }

    return { count, row, page, onRowChange, onPageChange };
}

export default ListViewModel;