import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./menu.css";
import { useTranslation } from "react-i18next";
import { StorageService } from "../../services/storage.service";

const Menus: React.FC = () => { 
  const { t } = useTranslation();

  const storageService: StorageService = new StorageService();

  const admin = [
    {
      name: "dashboard",
      type: "main",
      title: t("title.dashboard"),
      url: "/",
      parent: "",
      show: true,
    },
    {
      name: "users",
      type: "parent",
      title: t("title.users"),
      url: "",
      parent: "",
      show: true,
    },
    {
      name: "admins",
      type: "child",
      title: t("title.admins"),
      url: "/admins",
      parent: "users",
      show: true,
    },
    {
      name: "customers",
      type: "child",
      title: t("title.customers"),
      url: "/customers",
      parent: "users",
      show: true,
    },
    {
      name: "customerstomnoderate",
      type: "child",
      title: t("title.customerstomoderate"),
      url: "/customerstomoderate",
      parent: "users",
      show: true,
    },
    {
      name: "brands",
      type: "main",
      title: t("title.brands"),
      url: "/brands",
      parent: "users",
      show: true,
    },
    {
      name: "products",
      type: "parent",
      title: t("title.products"),
      url: "",
      parent: "",
      show: true,
    },
    {
      name: "categorylist",
      type: "child",
      title: t("title.categories"),
      url: "/categories",
      parent: "products",
      show: true,
    },
    {
      name: "color",
      type: "child",
      title: t("title.colors"),
      url: "/colors",
      parent: "products",
      show: true,
    },
    {
      name: "size",
      type: "child",
      title: t("title.sizes"),
      url: "/sizes",
      parent: "products",
      show: true,
    },
    {
      name: "tag",
      type: "child",
      title: t("title.tags"),
      url: "/tags",
      parent: "products",
      show: true,
    },
    {
      name: "productlist",
      type: "child",
      title: t("title.products"),
      url: "/products",
      parent: "products",
      show: true,
    },
    {
      name: "productstomoderate",
      type: "child",
      title: t("title.productstomoderate"),
      url: "/productstomoderate",
      parent: "products",
      show: true,
    },
    {
      name: "transactions",
      type: "main",
      title: t("title.transactions"),
      url: "/transactions",
      parent: "",
      show: true,
    },
    {
      name: "contents",
      type: "parent",
      title: t("title.contents"),
      url: "",
      parent: "",
      show: true,
    },
    {
      name: "partners",
      type: "child",
      title: t("title.partners"),
      url: "/partners",
      parent: "contents",
      show: true,
    },
    {
      name: "settings",
      type: "child",
      title: t("title.settings"),
      url: "/settings",
      parent: "contents",
      show: true,
    },
    {
      name: "emaillog",
      type: "child",
      title: "Email Logs",
      url: "/emails",
      parent: "contents",
      show: true,
    },
  ];

  const supplier = [
    {
      name: "dashboard",
      type: "main",
      title: t("title.dashboard"),
      url: "/",
      parent: "",
      show: true,
    },
    {
      name: "mydetails",
      type: "main",
      title: t("title.mydetails"),
      url: "/mydetails/[supplierid]",
      parent: "",
      show: true,
    },
    {
      name: "products",
      type: "main",
      title: t("title.products"),
      url: "/products",
      parent: "",
      show: true,
    },
    {
      name: "transactions",
      type: "main",
      title: t("title.transactions"),
      url: "/transactions",
      parent: "",
      show: true,
    },
    {
      name: "wishlist",
      type: "main",
      title: t("title.customerswishlist"),
      url: "/customers/wishlist",
      parent: "",
      show: true,
    },
    {
      name: "settings",
      type: "main",
      title: t("title.settings"),
      url: "/settings",
      parent: "",
      show: true,
    },
  ];

  const [rcList, setrcList] = useState(admin);

  useEffect(() => {
    loadListMenu();
  }, []);

  useEffect(() => {
    loadListMenu();
  }, [t]);

  const loadListMenu = () => {
    if (storageService.isSupplier()) setrcList([...supplier]);
    else setrcList([...admin]);
  };

  const handleParentClick = (menu: any) => {
    let menus = [...rcList];

    menus.map((item) => {
      if (item.name === menu.name) {
        item.show = !item.show;
      }
      if (item.parent === menu.name) {
        item.show = !item.show;
      }
      return item;
    });

    setrcList(menus);
  };

  const getIcon = (item: any) => {
    if (item.show) return <i className="bi bi-chevron-up"></i>;
    else return <i className="bi bi-chevron-down"></i>;
  };

  const extra = (url: string) => {
    const storageService: StorageService = new StorageService();
    let path = url;
    if (path.indexOf("[supplierid]") >= 0) {
      if (storageService.isSupplier()){
        let data = storageService.getSaveLogin();
        if (data) path = path.replace("[supplierid]", data.supplier.id);
      } 
    }
    return path;
  };

  const renderMenu = (menu: any) => {
    if (menu.type === "main") {
      return (
        <Link to={extra(menu.url)} key={menu.name} className="link">
          <div className="menuitem parent">
            <span>{menu.title.toUpperCase()}</span>
          </div>
        </Link>
      );
    } else if (menu.type === "parent") {
      return (
        <div key={menu.name} onClick={() => handleParentClick(menu)}>
          <div className="menuitem parent">
            <span>{menu.title.toUpperCase()}</span>
            {getIcon(menu)}
          </div>
        </div>
      );
    } else if (menu.type === "child") {
      if (!menu.show) {
        return;
      }

      return (
        <Link to={extra(menu.url)} key={menu.name} className="link">
          <div className="menuitem menuchild">
            <span>{menu.title.toUpperCase()}</span>
          </div>
        </Link>
      );
    } else {
      if (!menu.show) {
        return;
      }

      return (
        <Link to={extra(menu.url)} key={menu.name} className="link">
          <div className="menuitem menuchild">
            <span>{menu.title.toUpperCase()}</span>
          </div>
        </Link>
      );
    }
  };

  return <div>{rcList.map((menu) => renderMenu(menu))}</div>;
};

export default Menus;
