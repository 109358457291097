import { addDoc, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { Tag } from '../models/tag.model';
import { BaseService } from './baseservice';

export class TagService extends BaseService {
    constructor() {
        super("tags", "arrangement");
    }

    add = (tag: Tag) => new Promise((resolve) => {
        const col = collection(this.firestore, this.colname);
        addDoc(col, {
            code: tag.code ? tag.code : '',
            description: tag.description ? tag.description : '',
            description_de: tag.description_de ? tag.description_de : '',
            display_en: tag.display_en ? tag.display_en : '',
            display_de: tag.display_de ? tag.display_de : '',
            arrangement: tag.arrangement ? tag.arrangement : 0,
            background: tag.background ? tag.background : '',
            fontcolor: tag.fontcolor ? tag.fontcolor : '',
            active: tag.active,
            created: serverTimestamp(),
            createdby: this.userid
        }).then(value => {
            resolve(value.id);
        });
    });

    update = (id: string, tag: Tag): Promise<void> => {
        const docs = doc(this.firestore, this.colname + '/' + id);
        return updateDoc(docs, {
            code: tag.code ? tag.code : '',
            description: tag.description ? tag.description : '',
            description_de: tag.description_de ? tag.description_de : '',
            display_en: tag.display_en ? tag.display_en : '',
            display_de: tag.display_de ? tag.display_de : '',
            arrangement: tag.arrangement ? tag.arrangement : 0,
            background: tag.background ? tag.background : '',
            fontcolor: tag.fontcolor ? tag.fontcolor : '',
            active: tag.active,
            modified: serverTimestamp(),
            modifiedby: this.userid
        })
    }
}