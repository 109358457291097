import { useEffect, useState } from "react";
import { Brand, BrandList } from "../../models/brand.model";
import { SupplierService } from "../../services/supplier.service";
import { Category } from "../../models/category.model";
import { CategoryService } from "../../services/category.service";
import { TableField } from "../../models/table.model";
import { useTranslation } from "react-i18next";
import { collection, getFirestore, onSnapshot, orderBy, query, where } from "firebase/firestore";
import firebaseConfig from "../../config";
import { initializeApp } from "firebase/app";
import { BrandLogService } from "../../services/brandlog.service";
import { BrandLog } from "../../models/brandlog.model";
import { Action } from "../../components/table/table";

function SupplierListViewModel() {
    const app = initializeApp(firebaseConfig);
    const firestore = getFirestore(app);
    const { t } = useTranslation();

    const [categories, setCategories] = useState<Category[] | undefined>(undefined);
    const [brandLogs, setBrandLogs] = useState<BrandLog[] | undefined>(undefined);
    const [brands, setBrands] = useState<Brand[] | undefined>(undefined);
    const [brandList, setBrandList] = useState<BrandList[] | undefined>(undefined);
    const [search, setSearch] = useState('');
    const [searches, setSearches] = useState<BrandList[] | undefined>(undefined);

    const categoryService: CategoryService = new CategoryService();
    const brandLogService: BrandLogService = new BrandLogService();

    const fields = [
        { id: "id", display: "#", align: "left", sort: false, type: 'string' },
        { id: "image", display: "", align: "right", sort: true, type: 'image' },
        { id: "name", display: t("field.name").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "website", display: t("field.website").toUpperCase(), align: "left", sort: true, type: 'string' },
        { id: "active", display: t("field.status").toUpperCase(), align: "center", sort: true, type: 'status' },
        { id: "categories", display: t("field.categories").toUpperCase(), align: "left", sort: true, type: 'array' },
        { id: "gateway", display: t("field.gateway").toUpperCase(), align: "left", sort: true, type: 'array' },
        { id: "openlink", display: "Open Link".toUpperCase(), align: "right", sort: false, type: 'number' },
        { id: "views", display: "Views".toUpperCase(), align: "right", sort: false, type: 'number' },
        { id: "action", display: t("field.action").toUpperCase(), align: "right", sort: false, type: 'action' },
    ] as TableField[]

    useEffect(() => {
        loadCategories();
        loadBrandLogs();
    }, [])

    useEffect(() => {
        if (categories !== undefined && brandLogs !== undefined) {
            let items: Brand[] = [];
            let itemList: BrandList[] = [];
            let unSubcribe = onSnapshot(collection(firestore, "brands"), (snap) => {

                snap.docChanges().forEach((change) => {

                    let brand: Brand = change.doc.data() as Brand;
                    brand.id = change.doc.id;


                    if (change.type === 'added') {
                        items.push(brand);
                        itemList.push(convertBrandList(brand))
                    }
                    if (change.type === 'modified') {
                        let idx = items.findIndex(x => x.id === change.doc.id);
                        if (idx >= 0) items[idx] = { ...items[idx], ...change.doc.data() }

                        idx = itemList.findIndex(x => x.id === change.doc.id);
                        if (idx >= 0) itemList[idx] = { ...itemList[idx], ...convertBrandList(brand) }
                    }
                });

                setBrands([...items])
                setBrandList([...itemList])
            });

            return () => {
                unSubcribe();
            }
        }

    }, [categories, brandLogs])

    useEffect(() => {
        if (search !== '' && brands) {
            let items: Brand[] = [];
            brands.forEach(item => {
                let id: string = item.id ? item.id.toLowerCase() : '';
                let name: string = item.name ? item.name.toLowerCase() : '';
                if (id === search  || name.includes(search.toLowerCase())) {
                    items.push(item);
                }
            })

            let itemList: BrandList[] = [];
            items.forEach(item => itemList.push(convertBrandList(item)));
            setSearches([...itemList])
        }
        else {
            setSearches(undefined)
        }
    }, [search])

    const convertBrandList = (brand: Brand): BrandList => {
        let item: BrandList = new BrandList();
        item.id = brand.id;
        item.image = brand.image;
        item.name = brand.name;
        item.website = brand.website;
        item.active = brand.active;

        if (categories !== undefined) {
            let categoryList: string[] = [];
            if (brand.categories) {
                brand.categories.forEach(id => {
                    let category = categories.find(x => x.id === id)
                    if (category) categoryList.push(category.text_en)
                })
            }
            item.categories = categoryList.splice(0, 2);
        }

        let gateway: string[] = [];
        if (brand.paypal) gateway.push("PayPal".toUpperCase())
        if (brand.creditcard) gateway.push("Credit Card".toUpperCase())
        if (brand.invoice) gateway.push("Invoice".toUpperCase())
        item.gateway = gateway;

        if (brandLogs !== undefined) {
            let log = brandLogs.find(x => x.id === brand.id);
            if (log) {
                if (log.link) item.openlink = log.link.filter((item, i, ar) => ar.indexOf(item) === i).length;
                else item.openlink = 0;
                if (log.opened) item.views = log.opened.filter((item, i, ar) => ar.indexOf(item) === i).length;
                else item.views = 0;
            }
            else {
                item.openlink = 0;
                item.views = 0;
            }
        }

        let action: Action = new Action();
        action.type = 'url';
        action.display = t("button.open");
        action.url = `/brands/` + brand.id;
        item.action = action;

        return item
    }

    const loadCategories = async () => {
        let data = await categoryService.get() as Category[];
        setCategories(data);
    }

    const loadBrandLogs = async () => {
        let data = await brandLogService.get() as BrandLog[];
        setBrandLogs(data);
    }

    return { brandList, searches, fields, setSearch };
}


export default SupplierListViewModel;