import React, { useState } from "react";
import { Category } from "../../models/category.model";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import CategoryDetailViewModel from "../../viewmodels/category/detail.viewmodel";
import Toggle from "../../components/toggle/toggle";
import Upload from "../../components/upload/Upload";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  mode: "add" | "edit";
  category: Category
}

const CategoryDetails: React.FC<ContainerProps> = ({ mode, category }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { model, submit, change, getError, getErrorMessage, process  } = CategoryDetailViewModel(mode, category);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "1px solid gray",
    boxShadow: 24,
  };
  const headerStyle = {
    bgcolor: "#1976d2",
    color: "white",
    p: 2,
    fontSize: "20px",
  };
  const detailStyle = {
    pl: 2,
    pr: 2,
    pt: 4,
    pb: 4,
  };
  const footerStyle = {
    p: 2,
    borderTop: "1px solid #eee",
    textAlign: "right",
  };

  const getButton = () => {
    if (mode === "add") {
      return (
        <Button variant="contained" size="medium" onClick={handleOpen}>
          {t('button.create')}
        </Button>
      );
    } else {
      return (
        <Button color="secondary" size="medium" onClick={handleOpen}>
        {t('button.open')}
        </Button>
      );
    }
  };

  const getTitle = () => {
    if (mode === "add") return t('text.createcategory');
    else return t('text.categorydetails');
  };

  const getButtonTitle = () => {
    if (mode === "add") return t('button.create')
    else return t('button.update')
  };

  const getUploadDiv = () => {
    if (mode === 'edit') {
      return (
        <Grid item xs={12}>
          <Grid item xs={4} border={1} borderColor={"lightgray"}>
            <Upload category={"categories"} id={model.id} image={model.image} onSetImage={(image: string) => change("image", image)}></Upload>
          </Grid>
        </Grid>
      );
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      {getButton()}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            <Box sx={headerStyle}>{getTitle().toUpperCase()}</Box>
            <Box sx={detailStyle}>
              <Grid container spacing={2}>
                {getUploadDiv()}
                <Grid item xs={6}>
                  <TextField
                    label={t('field.code')}
                    variant="outlined"
                    size="small"
                    value={model.code}
                    error={getError("code") !== undefined}
                    helperText={getErrorMessage("code")}
                    onChange={(e) => change("code", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label={t('field.orderno')}
                    variant="outlined"
                    size="small"
                    value={model.orderno}
                    error={getError("orderno") !== undefined}
                    helperText={getErrorMessage("orderno")}
                    onChange={(e) => change("orderno", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('field.text') + ' (EN)'}
                    variant="outlined"
                    size="small"
                    value={model.text_en}
                    error={getError("text_en") !== undefined}
                    helperText={getErrorMessage("text_en")}
                    onChange={(e) => change("text_en", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={t('field.text') + ' (DE)'}
                    variant="outlined"
                    size="small"
                    value={model.text_de}
                    error={getError("text_de") !== undefined}
                    helperText={getErrorMessage("text_de")}
                    onChange={(e) => change("text_de", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Toggle value={model.active} label="ACTIVE" onChange={(value: boolean) => change("active", value)}></Toggle>
                </Grid>
              </Grid>
            </Box>
            <Box sx={footerStyle}>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} type="submit" disabled={process}>
                {getButtonTitle()}
              </Button>
              <Button variant="outlined" size="medium" sx={{ ml: 1 }} color="error" onClick={handleClose} disabled={process}>
                {t('button.cancel')}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
/*
function CategoryRegister() {
  const { id } = useParams();
  const [image, setImage] = useState("");

  let _model = {
    code: "",
    orderno: 0,
    text_en: "",
    text_de: "",
    photopath: "",
    image: "",
    active: true,
  };

  let _validate = {
    rules: [
      {
        field: "code",
        type: "required",
        message: "Code is Required",
      },
      {
        field: "orderno",
        type: "valid",
        datatype: "number",
        message: "Not a Valid Number",
      },
      {
        field: "text_en",
        type: "required",
        message: "English Text is Required",
      },
      {
        field: "text_de",
        type: "required",
        message: "German Text is Required",
      },
    ],
    errors: {},
  };

  const [viewModel, dispatch] = useEditViewModel(_model, "categories", _validate, { open: "/categories" });
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch({ type: ACTION.LOAD, id: id });
    }

    if (id) {
      setBreadcrumbs([{ title: "Dashboard", url: "/" }, { title: "Categories", url: "/categories" }, { title: "Category Details" }]);
    } else {
      setBreadcrumbs([{ title: "Dashboard", url: "/" }, { title: "Categories", url: "/categories" }, { title: "Create Category" }]);
    }
  }, []);

  useEffect(() => {
    setImage(viewModel.model.image);
  }, [viewModel.model.image]);

  const getUploadDiv = () => {
    if (id) {
      return (
        <Grid item xs={12}>
          <Grid item xs={3} border={1} borderColor={"lightgray"}>
            <Upload category={"categories"} id={id} image={image} onSetImage={onSetImage}></Upload>
          </Grid>
        </Grid>
      );
    } else {
      return;
    }
  };

  const onSetImage = (image) => {
    let service = new CategoryService();
    service.updateField(id, "image", image);
    let model = viewModel;
    model.model.image = image;
    dispatch({ type: ACTION.UPDATEMODEL, viewModel: model });
  };

  return (
    <React.Fragment>
      <FormLayout PageTitle="Categories" Breadcrumbs={breadcrumbs}></FormLayout>
      <Grid container direction="row" justifyContent="center" alignItems="top" spacing={1}>
        <Grid item xs={6}>
          <EditForm OnSave={(e) => dispatch({ type: ACTION.SAVE })}>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox />}
                label="Active"
                checked={viewModel.model.active}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "active", value: e.target.checked },
                  })
                }
              />
              <hr />
            </Grid>
            {getUploadDiv()}
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Code"
                required
                variant="outlined"
                size="small"
                value={viewModel.model.code}
                error={viewModel.validate.code !== undefined}
                helperText={viewModel.validate.code}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "code", value: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Order No"
                variant="outlined"
                size="small"
                value={viewModel.model.orderno === 0 ? "" : viewModel.model.orderno}
                error={viewModel.validate.orderno !== undefined}
                helperText={viewModel.validate.orderno}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "orderno", value: e.target.value },
                  })
                }
                inputProps={{ style: { textAlign: "right" } }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Text(EN)"
                variant="outlined"
                size="small"
                value={viewModel.model.text_en}
                error={viewModel.validate.text_en !== undefined}
                helperText={viewModel.validate.text_en}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "text_en", value: e.target.value },
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Text(DE)"
                variant="outlined"
                size="small"
                value={viewModel.model.text_de}
                error={viewModel.validate.text_de !== undefined}
                helperText={viewModel.validate.text_de}
                onChange={(e) =>
                  dispatch({
                    type: ACTION.FIELDCHANGE,
                    elem: { field: "text_de", value: e.target.value },
                  })
                }
              />
            </Grid>
          </EditForm>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
*/
export default CategoryDetails;
