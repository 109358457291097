import Badge from "@mui/material/Badge";
import { Box, Button, CircularProgress, Drawer, Grid, Hidden } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationViewModel from "../../viewmodels/notifications/notification.viewmodel";
import React, { useState } from "react";
import {
  AccountCreate,
  AccountUpdate,
  FieldChange,
  NotificationDisplay,
  ProductCreate,
  ProductModeration,
  ProductUpdate,
  TransactionCreate,
  TransactionUpdate,
} from "../../models/notification.model";
import "./notif.css";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { accountfields, productfields, reviewstatus, stockstatus } from "../../config";
import Toggle from "../toggle/toggle";

const Notif: React.FC = () => {
  const navigate = useNavigate();
  const helper = [
    {
      type: "product-create",
      label: "New Product Created",
    },
    {
      type: "product-update",
      label: "Product Details Updated",
    },
    {
      type: "product-moderation",
      label: "Product Moderation",
    },
    {
      type: "account-create",
      label: "New Customer Register",
    },
    {
      type: "account-update",
      label: "Customer Detail Updated",
    },
    {
      type: "transaction-create",
      label: "Transaction Received",
    },
    {
      type: "transaction-update",
      label: "Transaction Status Changed",
    },
  ];

  const [open, setOpen] = useState(false);
  const [marking, setMarking] = useState(false);

  const { count, notificationDisplays, addRead, AddReadAll, showAll, setShowAll } = NotificationViewModel();

  const toggleDrawer = (_open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    setOpen(_open);
  };

  const getStatus = (status: string): string => {
    let result: string = "";
    if (status !== '') {
      return stockstatus.find((x) => x.id === status)!.value.toUpperCase();
    }
    return result;
  }

  const getFieldValues = (field: FieldChange) => {
    let fieldDesign = productfields.find((x) => x.field === field.fieldname)!;
    if (fieldDesign) {
      if (fieldDesign.datatype === "number") return Number(field.old).toFixed(2) + " => " + Number(field.new).toFixed(2);
      if (fieldDesign.datatype === "boolean")
        return Boolean(field.old).toString().toUpperCase() + " => " + Boolean(field.new).toString().toUpperCase();
      if (fieldDesign.datatype === "custom") {
        if (fieldDesign.field === "stockstatus") {
          return (
            getStatus(field.old as string) + " => " + getStatus(field.new as string)
          );
        }
      }
    }
  };

  const productUpdateDetail = (data: ProductUpdate): any => {
    return (
      <React.Fragment>
        {data.fields.map((field, idx) => (
          <Grid key={"p" + idx} xs={12} item sx={{ paddingInline: "10px" }}>
            {productfields.find((x) => x.field === field.fieldname)!.display} : {getFieldValues(field)}
          </Grid>
        ))}
      </React.Fragment>
    );
  };

  const accountUpdateDetail = (data: AccountUpdate): any => {
    const getDisplay = (field: FieldChange, id: number) => {
      let idx = accountfields.findIndex(x => x.field === field.fieldname);
      if (idx >= 0) {
        return <Grid key={"a" + id} xs={12} item sx={{ paddingInline: "10px" }}>
          {accountfields[idx].display} : {getFieldValues(field)}
        </Grid>
      }
      else return;
    }

    if (data.fields) {
      return data.fields.map((field, idx) => getDisplay(field, idx))  
    }
  };

  const displayDetails = (notification: NotificationDisplay) => {
    if (notification.type === "product-create") {
      let detail: ProductCreate = notification.data[0] as ProductCreate;
      return (
        <React.Fragment>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Name: {detail.name_en}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Brand: {detail.brand}
          </Grid>
          <Grid xs={12} item style={{ textAlign: "end" }}>
            <label style={{ fontSize: "11px" }}>{new Date(notification.datetime.toDate()).toLocaleString()}</label>
          </Grid>
        </React.Fragment>
      );
    } else if (notification.type === "product-update") {
      let detail: ProductUpdate = notification.data[0] as ProductUpdate;
      return (
        <React.Fragment>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Name: {detail.name_en}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Brand: {detail.brand}
          </Grid>
          {notification.data.map((detail) => productUpdateDetail(detail as ProductUpdate))}
          <Grid xs={12} item style={{ textAlign: "end" }}>
            <label style={{ fontSize: "11px" }}>{new Date(notification.datetime.toDate()).toLocaleString()}</label>
          </Grid>
        </React.Fragment>
      );
    } else if (notification.type === "product-moderation") {
      let detail: ProductModeration = notification.data[0] as ProductModeration;
      return (
        <React.Fragment>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Name: {detail.name_en}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Brand: {detail.brand}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Status: {reviewstatus.find((x) => x.id === detail.old)!.value} {" => "} {reviewstatus.find((x) => x.id === detail.new)!.value}
          </Grid>
          <Grid xs={12} item style={{ textAlign: "end" }}>
            <label style={{ fontSize: "11px" }}>{new Date(notification.datetime.toDate()).toLocaleString()}</label>
          </Grid>
        </React.Fragment>
      );
    } else if (notification.type === "account-create") {
      let detail: AccountCreate = notification.data[0] as AccountCreate;
      return (
        <React.Fragment>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Email: {detail.email}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Name: {detail.firstname ? detail.firstname : ""} {detail.lastname ? detail.lastname : ""}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            License #: {detail.license ? detail.license : ""}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Official #: {detail.official ? detail.official : ""}
          </Grid>
          <Grid xs={12} item style={{ textAlign: "end" }}>
            <label style={{ fontSize: "11px" }}>{new Date(notification.datetime.toDate()).toLocaleString()}</label>
          </Grid>
        </React.Fragment>
      );
    } else if (notification.type === "account-update") {
      let detail: AccountUpdate = notification.data[0] as AccountUpdate;
      return (
        <React.Fragment>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Email: {detail.email}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Name: {detail.firstname ? detail.firstname : ""} {detail.lastname ? detail.lastname : ""}
          </Grid>
          {notification.data.map((detail) => accountUpdateDetail(detail as AccountUpdate))}
          <Grid xs={12} item style={{ textAlign: "end" }}>
            <label style={{ fontSize: "11px" }}>{new Date(notification.datetime.toDate()).toLocaleString()}</label>
          </Grid>
        </React.Fragment>
      );
    } else if (notification.type === "transaction-create") {
      let detail: TransactionCreate = notification.data[0] as TransactionCreate;
      return (
        <React.Fragment>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Name: {detail.customername}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Brand: {detail.brand}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Type: {detail.type === "raceclubdeal" ? "Race Deal" : "Influencer Deal"}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Status: {detail.status.toUpperCase()}
          </Grid>
          <Grid xs={12} item style={{ textAlign: "end" }}>
            <label style={{ fontSize: "11px" }}>{new Date(notification.datetime.toDate()).toLocaleString()}</label>
          </Grid>
        </React.Fragment>
      );
    } else if (notification.type === "transaction-update") {
      let detail: TransactionUpdate = notification.data[0] as TransactionUpdate;
      return (
        <React.Fragment>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Name: {detail.customername}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Brand: {detail.brand}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Type: {detail.type === "raceclubdeal" ? "Race Deal" : "Influencer Deal"}
          </Grid>
          <Grid xs={12} item sx={{ paddingInline: "10px" }}>
            Status: {detail.oldstatus.toUpperCase() + " => " + detail.newstatus.toUpperCase()}
          </Grid>
          <Grid xs={12} item style={{ textAlign: "end" }}>
            <label style={{ fontSize: "11px" }}>{new Date(notification.datetime.toDate()).toLocaleString()}</label>
          </Grid>
        </React.Fragment>
      );
    }
  };

  const openLink = (notification: NotificationDisplay) => {
    if (notification.type === "product-create") {
      let detail: ProductCreate = notification.data[0] as ProductCreate;
      navigate("/products/" + detail.id);
    } else if (notification.type === "product-update") {
      let detail: ProductUpdate = notification.data[0] as ProductUpdate;
      navigate("/products/" + detail.id);
    } else if (notification.type === "product-moderation") {
      let detail: ProductModeration = notification.data[0] as ProductModeration;
      navigate("/products/" + detail.id);
    } else if (notification.type === "account-create") {
      let detail: AccountCreate = notification.data[0] as AccountCreate;
      navigate("/customers/" + detail.id);
    } else if (notification.type === "account-update") {
      let detail: AccountUpdate = notification.data[0] as AccountUpdate;
      navigate("/customers/" + detail.id);
    } else if (notification.type === "transaction-create") {
      let detail: TransactionCreate = notification.data[0] as TransactionCreate;
      navigate("/transactions/" + detail.id);
    } else if (notification.type === "transaction-update") {
      let detail: TransactionUpdate = notification.data[0] as TransactionUpdate;
      navigate("/transactions/" + detail.id);
    }

    addRead(notification.id);
    setOpen(false);
  };

  const markAllAsRead = async () => {
    try {
      setMarking(true);
      await AddReadAll()
    }
    finally {
      setMarking(false);
    }
  }

  return (
    <React.Fragment>
      <Button onClick={toggleDrawer(true)}>
        <Badge badgeContent={count} color="secondary">
          <NotificationsIcon color="primary" />
        </Badge>
      </Button>
      <Drawer anchor={"right"} open={open}>
        <div style={{ overflow: "hidden" }}>
          <Box sx={{ minWidth: "400px", maxWidth: "400px", padding: 2 }} role="presentation" className="div-header">
            <Grid container>
              <Grid xs={11} container direction="row" alignItems="center">
                <h5 style={{ margin: "0px" }}>Notifications</h5>
                <span
                  style={{
                    marginLeft: "10px",
                    height: "28px",
                    width: "28px",
                    paddingBlock: "3px",
                    borderRadius: "50%",
                    border: "1px solid white",
                    textAlign: "center",
                  }}
                >
                  {count}
                </span>
              </Grid>
              <Grid xs={1} container direction="row" justifyContent="right" alignItems="center">
                <button style={{ background: "none", border: "none", color: "white" }} onClick={(e) => setOpen(false)}>
                  <CloseIcon style={{ cursor: "pointer" }}></CloseIcon>
                </button>
              </Grid>
            </Grid>
          </Box>
          <div style={{ maxWidth: "400px", paddingInline: "8px", paddingBottom: "65px", overflowX: "hidden", overflowY: "auto", height: "100%" }}>
            <Grid container item marginY={2}>
              <Grid xs={6} item>
                <Toggle value={showAll} label="Show All Notifications" onChange={(e: boolean) => setShowAll(e)}></Toggle>
              </Grid>
              <Grid xs={6} item >
                <Grid container direction="row" justifyContent="right" alignItems="center">
                  <Button variant="text" disabled={count <= 1 || marking} onClick={(e) => markAllAsRead()}>
                    {
                      marking && <CircularProgress size={10} style={{ marginRight: "5px" }}></CircularProgress>
                    }
                    Mark All as Read
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {(showAll ? notificationDisplays : notificationDisplays.filter(x => x.read !== true)).map((notificationDisplay, idx) => (
              <div className={notificationDisplay.read ? "notif-item-read" : "notif-item"} key={"n" + idx} onClick={(e) => openLink(notificationDisplay)}>
                <Grid container>
                  <Grid xs={12} item>
                    {helper.find((x) => x.type === notificationDisplay.type)!.label.toUpperCase()}
                  </Grid>
                  {displayDetails(notificationDisplay)}
                </Grid>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default Notif;
